import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircleSharp";
import { Grid, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import OtpInput from "react-otp-input";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(4),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    width: "330px",
    borderRadius: "10px",
    // height: "18em",
  },
}));

const useStyles = makeStyles({
  label: {
    fontWeight: "600",
    fontSize: "0.8rem",
    color: "#756F86",
  },
  labelError: {
    fontWeight: "400",
    fontSize: "0.8rem",
    color: "#D32F2F",
    marginTop: "8px",
    position: "absolute"
  },
  formButton: {
    width: "100%",
    backgroundColor: "#616161 !important",
    textTransform: "capitalize !important",
    marginTop: "2rem !important",
  },
  verifyButton: {
    width: "60% !important",
    color: "#fff !important",
    backgroundColor: "#616161 !important",
    textTransform: "capitalize !important",
    fontSize: "0.8rem !important",
  },
  formLink: {
    textDecoration: "underline",
    padding: "6px 9px 0 0",
    float: "right",
    color: "gray",
    fontWeight: "600",
    cursor: "pointer",
  },
});

const formContent = {
  formHeading: "Payment Verification",
  formSubHeading: "Please check your email for the OTP",
  confirmButton: "Confirm",
  resendText: "Resend",
};

const CheckoutVerificationModal = (props) => {
  const { open, handleClose, modalData: { title, content, buttonText }, t,contactData} = props;
  const navigate = useNavigate();
  const classes = useStyles();

  const { formHeading, formSubHeading, confirmButton, resendText } = formContent;
  const dispatch = useDispatch();
  const [values, setValues] = useState({ otp: "" });
  const [view, setView] = useState("otp");
  const [otpSentSuccessfully, setOtpSentSuccessfully] = useState(false);
  const handleChange = (val) => {
    setValues({ ...values, otp: val });
  };
  const [error, setError] = useState();

  const handleSubmit = () => {
    var matches = (values.otp).match(/^[0-9a-zA-Z]{6,6}$/g);
    if (matches) {
      setError(false);
      dispatch({
        type: "validateCheckoutEmailOtpAction",
        payload: {
          otp: values.otp,
          onSuccessCB: (res) => {
            setView('success');
          },
          onErrorCB: (err) => { }
        },
      });
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    handleSendOtp();
  }, []);

  const handleSendOtp = () => {
    setValues({ ...values, otp: '' });
    setOtpSentSuccessfully(false);
    dispatch({
      type: "sendCheckoutEmailOtpAction",
      payload: {
        data:contactData,
        onSuccessCB: (res) => {
          setOtpSentSuccessfully(true);
        },
        onErrorCB: (err) => {
          setOtpSentSuccessfully(false);}
      },
    });
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        disableEscapeKeyDown
        onBackdropClick={(e) => e.preventDefault()}
      >
        <>
          <IconButton className="position-absolute top-0 end-0 m-2" size="small" onClick={(e) => handleClose(e, null, true)}>
            <Close fontSize="small" />
          </IconButton>
          {view === 'otp' ?
            <div className="p-5">
              <h3>{t(formHeading)}</h3>
              <span className="text-12 font-medium mr-1">{t(formSubHeading)}</span>
              <div className="mt-5">
                <OtpInput
                  value={values.otp}
                  onChange={handleChange}
                  numInputs={6}
                  inputStyle={{
                    width: "79%",
                    textAlign: "center",
                    height: "3em",
                    borderRadius: "5px",
                    border: "1px lightgray solid",
                    boxShadow: "0 0.8pt 1.8pt 0 #d3d3d3",
                  }}
                />
                {error && (
                  <label
                    className={`${error
                      ? classes.labelError
                      : classes.label
                      }`}
                  >
                    {"Please enter valid OTP"}
                  </label>
                )}

                {otpSentSuccessfully ? <span
                  className={`${classes.formLink} text-12 link-color font-medium `}
                  onClick={handleSendOtp}
                >
                  {t(resendText)}
                </span> : <span className="text-12 float-end p-1">{t("Sending OTP, please wait...!")}</span>}
                <Button
                  onClick={handleSubmit}
                  className={`${classes.formButton} mb-1 pt-3 pb-3 right-gradient text-white flex items-center`}
                  variant="contained"
                >
                  {t(confirmButton)}
                </Button>
              </div>
            </div>
            :
            <div>
              <Grid sx={{ display: "flex", justifyContent: "center" }}>
                <CheckCircleIcon
                  sx={{
                    fontSize: "47px",
                    color: "#10BA10",
                    margin: "19px 0 -14px 0",
                  }}
                />
              </Grid>

              <DialogContent>
                <Typography gutterBottom variant="h6" align="center">
                  {t(title)}
                </Typography>
                <Typography gutterBottom variant="body2" align="center">
                  {t(content)}
                </Typography>
              </DialogContent >
              <DialogActions sx={{ display: 'flex', justifyContent: "center", marginBottom: '2px' }}>
                <Button
                  autoFocus
                  onClick={handleClose}
                  className={`${classes} mb-1 pt-3 pb-3 right-gradient text-white flex items-center`}
                >
                  {t(buttonText)}
                </Button>
              </DialogActions>
            </div>
          }
        </>
      </BootstrapDialog>
    </div>
  );
};
export default CheckoutVerificationModal;
