import { makeStyles, styled } from "@mui/styles";
import React, { useEffect } from "react";
import ResetConfirmPasswordForm from "./components/ResetConfirmPasswordForm";
import SwitchLanguage from "components/Shared/SwitchLanguage";
import { Box } from "@mui/system";
import Carousels from "components/Shared/Carousels";
import constant from "../../constant/";
import { Grid } from "@mui/material";

const { IMAGES_ARRAY } = constant;

const StyledBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  "& .MuiSvgIcon-root": {
    fontSize: "1.2rem",
    marginTop: "6px",
  },
});

const useStyles = makeStyles({
  loginBanner: {
    position: "absolute",
    top: 0,
    objectFit: "cover",
    width: "70%",
    height: "100%",
    '@media(max-width:600px)':{
      display:"none"
    },
    // small laptops
    '@media screen and (max-width: 1339px) and (min-width: 993px)':{
      top:"-10%",
      width:"65%",
      height:"110vh"
    },
    // Tabs
    '@media screen and (max-width: 992px) and (min-width: 700px) ':{
      top:"-10%",
      width:"55%",
      height:"110vh"
    }
  },
  card: {
    position: "absolute",
    right: "1%",
    top: "10%",
    height: "300px",
    backgroundColor: "white",
    padding: "1rem",
    // maxWidth: "500px",
    width: "430px",
    '@media(max-width:600px)':{
      width:"100%",
      right:"0",
      padding:0
    },
    '@media screen (max-width:1468px)':{
      width:"490px !important",
      padding:"0",
      right:0
    },
    '@media screen and (max-width: 1474px) and (min-width: 1341px)':{
      width:"390px !important",
      padding:"0",
      right:0
    },
      // small laptops
      '@media screen and (max-width: 1339px) and (min-width: 993px)':{
        width:"420px !important",
        padding:"0",
        right:0
      },
      //very small laptop
      '@media screen and (max-width: 1190px) and (min-width: 994px)':{
        width:"335px !important",
      },
      // Tabs
    '@media screen and (max-width: 992px) and (min-width: 760px) ':{
      width:"340px",
      right:"0",
      padding:0
    },
    '@media screen and (max-width: 760px) and (min-width: 600px) ':{
      width:"75%",
      right:"4rem",
      padding:0
    }
  },
  header:{
    position: "absolute",
    top: "1rem",
    zIndex: 1,
    backgroundColor: "white",
    width: "425px !important",
    right: "0%",
    display: "flex",
    alignItems: "center",
    '@media(max-width:600px)':{
      width: "100% !important",
    },
    '@media screen and (max-width: 1474px) and (min-width: 1341px)':{
      width:"380px !important",
      right:"1%",
    },
    // small laptops
    '@media screen and (max-width: 1339px) and (min-width: 993px)':{
      width:"400px !important",
      right:"1%",
    },
    //very small laptop
    '@media screen and (max-width: 1190px) and (min-width: 994px)':{
      width:"347px !important",
    },
    // Tabs
    '@media screen and (max-width: 992px) and (min-width: 700px)':{
      width:"340px !important"
    },
    '@media screen and (max-width: 760px) and (min-width: 600px) ':{
      width: "100% !important"
    }
  },
  headerButton: {
    '@media(max-width: 760px)' :{
      display: "flex",
      justifyContent: "flex-end",
    }
  }
});

const ResetConfirmPassword = () => {
  const classes = useStyles();
  const formContent = {
    formHeading: "Create Password",
    formSubHeading: "Create your new password.",
    buttonText: "Create",
    formLabels: [
      {
        label: "Password*",
        name: "password",
      },
      {
        label: "Confirm Password*",
        name: "confirmPassword",
      },
    ],
  };
  return (
    <div>
      <img
        className={classes.loginBanner}
        src={require("assets/images/login-banner.jpg")}
        alt="login-banner"
      />
      <Carousels
        items={IMAGES_ARRAY}
        originalHeight={'20px'}
        originalWidth={20}
        showBullets={true}
        showNav={false}
        autoPlay={true}
        slideInterval={3000}
        showFullscreenButton={false}
        showPlayButton={false}
      />
      <Grid container className={classes.header}>
          <Grid item xs={8}>
            <img src={require("assets/images/logo.png")} className="ml-4" />
          </Grid>
          <Grid item xs={4} >
            <SwitchLanguage StyledBox={StyledBox} />
          </Grid>
        </Grid>
      <div className={classes.card}>
        <ResetConfirmPasswordForm formContent={formContent} />
      </div>
    </div>
  );
};

export default ResetConfirmPassword;
