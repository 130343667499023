
import { config } from 'config.js';
import { xhrClient } from 'views/utilities/DataRequest';
const { isProd } = config;
const API_ENDPOINT = isProd
    ? config.production.api_endpoint
    : config.development.api_endpoint;
 
const getUserProfileService=()=>{
    return xhrClient.get(`${API_ENDPOINT}/user-profile`);
}

const setUserProfile=(data)=> {
    return xhrClient.put(`${API_ENDPOINT}/update-profile`, null, data);
}
 
const saveAddressService= (data) => {
    return xhrClient.put(`${API_ENDPOINT}/add-address`, null, data);
}

const updateAddressService = (data) => {
    return xhrClient.put(`${API_ENDPOINT}/default-address`, null, data);
}
 
const getAddressListService= () => {
    return xhrClient.get(`${API_ENDPOINT}/address/all`);
}
export {
    getUserProfileService,
    getAddressListService,
    setUserProfile,
    saveAddressService,
    updateAddressService
}