import { call, put, takeEvery, all } from "redux-saga/effects";
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from "redux/slices/snackbar";
import i18n from 'i18next';
import {
  setAuthenticationService,
  setRegistrationService,
  setLogoutService,
  setAuthenticateEmailService,
  setAuthenticateResetPasswordService,
  setAuthenticateUpdatePasswordService,
  setAuthenticateChangePasswordService,
  setCountryDataService,
  setStateDataService,
  sendCheckoutEmailOtpService,
  validateCheckoutEmailOtpService,
  setSendEmailService,
} from "redux/AuthManagement/AuthManagementService";
import {
  setAuthorization,
  removeAuthorization,
  removeBasicAuthorization,
  setLoginDetails,
  setSignUpBasicDetails,
  setSignUpDetails,
  setIsEmailVerified,
  setIsResetPassword,
  setIsSignup,
  setOtpDetails,
  setIsResetPasswordConfirmed,
  setInitialState,
  setCountryRecords,
  setStateRecords,
  setPreviosStateData,
  setResetToken,
} from "redux/AuthManagement/AuthMangementSlice";
import ApiConstant from "../../constant/ApiConstant";
import { setLoading, setMyTickets } from "redux/EventManagement/EventManagementSlice";
import { openErrorModal } from "redux/ErrorModal/ErrorModalSlice";
import { loading } from "redux/Loader/LoaderSlice";
const { RESPONSE } = ApiConstant;

function* translateAndDispatchSuccess(message) {
  const translation = yield call([i18n, 't'], message);
  yield put(SNACKBAR_SUCCESS(translation));
}
function* translateAndDispatchError(message) {
  const translation = yield call([i18n, 't'], message);
  yield put(SNACKBAR_ERROR(translation));
}

function* setAuthInitialState() {
  try {
    yield put(setInitialState());
  } catch (error) {
    yield translateAndDispatchError(error.message);
  }
}

function* authenticationAction(payload) {
  const { data, requestPage } = payload;
  let requestId = Math.random();
  try {
    yield put (loading({loading:true,requestId}));
    let response;
    if (requestPage === "Login") {
      response = yield call(setAuthenticationService, data);
      yield put(setAuthorization(response));
    } else {
      response = yield call(setRegistrationService, data);
      if (response?.status === RESPONSE.SUCCESS) {
        // yield put(setAuthorization(response));
        yield put(setIsSignup(data.emailId));
      }
    }
    yield translateAndDispatchSuccess(response.message);
    yield put (loading({loading:false,requestId}));
  } catch (error) {
    if (requestPage === 'Login') {
      yield put(openErrorModal({ content: error.error }));
      yield put (loading({loading:false,requestId}));
    }
    else
      yield translateAndDispatchError(error.error);
    console.error(error.error);
    yield put (loading({loading:false,requestId}));
  }
}
function* removeAuthenticationToken() {
  let requestId = Math.random();
  try {
    yield put (loading({loading:true,requestId}));
    let response;
    response = yield call(setLogoutService);
    yield put(removeAuthorization(response));
    yield put(setMyTickets([]));
    yield put(setLoading(true));
    const translation = yield call([i18n, 't'], response.message);
    yield translateAndDispatchSuccess(translation);
    yield put (loading({loading:false,requestId}));
  } catch (error) {
    const translation = yield call([i18n, 't'], error.error);
    yield translateAndDispatchError(translation);
    console.error(error.error);
    yield put (loading({loading:false,requestId}));
  }
}
function* authenticationFormAction(payload) {
  try {
    yield put(setLoginDetails(payload));
    yield put(setSignUpDetails(payload));
  } catch (error) {
    yield translateAndDispatchError(error.message);
    console.error(error.message);
  }
}

function* authenticateEmailVerificationAction(payload) {
  let requestId = Math.random();
  try {
    let response;
    const { data } = payload;
    yield put (loading({loading:true,requestId}));
    response = yield call(setAuthenticateEmailService, data);
    yield put(setIsEmailVerified());
    yield put(setAuthorization(response));
    // yield translateAndDispatchSuccess(response.message);
    yield put (loading({loading:false,requestId}));
  } catch (error) {
    yield translateAndDispatchError(error.error);
    yield put (loading({loading:false,requestId}));
    console.error(error.error);
  }
}
function* SignUpBasicDetailsFormAction(payload) {
  try {
    yield put(setSignUpBasicDetails());
  } catch (error) {
    yield translateAndDispatchError(error.message);
    console.error(error.message);
  }
}

function* authenticationResetPasswordAction(payload) {
  let requestId = Math.random();
  try {
    const { data:{emailId,type} } = payload;
    let response;
    yield put (loading({loading:true,requestId}));
    response = yield call(setAuthenticateResetPasswordService,{emailId:emailId});
    if (response?.status === RESPONSE.SUCCESS) {
      if (type === 'reset') {
        yield put(setIsResetPassword(response));
      }
      const translation = yield call([i18n, 't'], response.message);
      yield put (loading({loading:false,requestId}));
      yield translateAndDispatchSuccess(translation);
    }
  } catch (error) {
    const translation = yield call([i18n, 't'], error.error);
    yield translateAndDispatchError(translation);
    yield put (loading({loading:false,requestId}));
    console.error(error.error);
  }
}

function* authenticationConfirmPassword(payload) {
  let requestId = Math.random();
  try {
    const { data } = payload;
    let response;
    yield put (loading({loading:true,requestId}));
    response = yield call(setAuthenticateUpdatePasswordService, data);
      yield put(setIsResetPasswordConfirmed());
      yield translateAndDispatchSuccess(response.message);
      yield put (loading({loading:false,requestId}));
  } catch (error) {
    yield translateAndDispatchError(error.error);
    yield put (loading({loading:false,requestId}));
    console.error(error.error);
  }
}

function* authenticationConfirmChangePassword(payload) {
  const { data, onSuccessCB, onErrorCB } = payload;
  let requestId = Math.random();
  try {
    let response;
    yield put (loading({loading:true,requestId}));
    response = yield call(setAuthenticateChangePasswordService, data);
    if (response?.status === RESPONSE.SUCCESS) {
      yield put(setResetToken(response))
      yield put(setIsResetPasswordConfirmed());
      onSuccessCB(response);
      yield translateAndDispatchSuccess(response.message);
      yield put (loading({loading:false,requestId}));
    }
  } catch (error) {
    onErrorCB(error);
    yield translateAndDispatchError(error.error);
    yield put (loading({loading:false,requestId}));
    console.error(error.error);
  }
}

function* setStateData(payload) {
  let requestId = Math.random();
  try {
    const { data } = payload;
    let response;
    yield put (loading({loading:true,requestId}));
    response = yield call(setStateDataService, data.countryId);
    yield put(setStateRecords(response));
    yield put (loading({loading:false,requestId}));
  } catch (error) {
    yield translateAndDispatchError(error.error);
    yield put (loading({loading:false,requestId}));
    console.error(error.error);
  }
}

function* setCountryData() {
  let requestId = Math.random();
  try {
    let response;
    yield put (loading({loading:true,requestId}));
    response = yield call(setCountryDataService);
    yield put(setCountryRecords(response));
    yield put (loading({loading:false,requestId}));
  } catch (error) {
    yield translateAndDispatchError(error.error);
    yield put (loading({loading:false,requestId}));
    console.error(error.error);
  }
}

function* setPreviosState(payload) {
  try {
    const { data } = payload;
    yield put(setPreviosStateData(data));
  } catch (error) {
    yield translateAndDispatchError(error.error);
    console.error(error.error);
  }
}

function* sendCheckoutEmailOtp(action) {
  const { emailId, onSuccessCB,data, onErrorCB } = action.payload;
  let requestId = Math.random();
  try {
    const response = yield call(sendCheckoutEmailOtpService, data);
    yield put (loading({loading:true,requestId}));
    if (response) {
      onSuccessCB(response);
      yield translateAndDispatchSuccess("OTP Sent Successfully");
      yield put (loading({loading:false,requestId}));
    }
  } catch (error) {
    onErrorCB(error);
    yield translateAndDispatchError(error.error);
    yield put (loading({loading:false,requestId}));
    console.error(error.error);
  }
}

function* validateCheckoutEmailOtp(action) {
  const { otp, onSuccessCB, onErrorCB } = action.payload;
  let requestId = Math.random();
  try {
    yield put (loading({loading:true,requestId}));
    const response = yield call(validateCheckoutEmailOtpService, otp ? { otp } : {});
    if (response) {
      onSuccessCB(response);
      yield put (loading({loading:false,requestId}));
    }
  } catch (error) {
    onErrorCB(error);
    yield translateAndDispatchError(error.error);
    yield put (loading({loading:false,requestId}));
    console.error(error.error);
  }
}

function* sendEmail(payload){
  try {
    const { data } = payload;
    let response;
    response = yield call(setSendEmailService, data);
  } catch (error) {
    yield translateAndDispatchError(error.error);
    console.error(error.error);
  }
}
function* watchSendCheckoutEmailOtp() {
  yield takeEvery("sendCheckoutEmailOtpAction", sendCheckoutEmailOtp);
}
function* watchValidateCheckoutEmailOtp() {
  yield takeEvery("validateCheckoutEmailOtpAction", validateCheckoutEmailOtp);
}

function* watchSetAuthInitialState() {
  yield takeEvery("setAuthInitialStateAction", setAuthInitialState);
}
function* watchSignUpBasicDetailsFormAction() {
  yield takeEvery(
    "setSignUpBasicDetailsFormAction",
    SignUpBasicDetailsFormAction
  );
}
function* watchAuthentication() {
  yield takeEvery("setAuthenticationAction", authenticationAction);
}
function* watchAuthenticationLogout() {
  yield takeEvery("removeAuthenticationAction", removeAuthenticationToken);
}
function* watchAuthenticationFormAction() {
  yield takeEvery("setAuthenticationFormAction", authenticationFormAction);
}

function* watchAuthenticationConfirmPassword() {
  yield takeEvery(
    "setAuthenticationConfirmPasswordAction",
    authenticationConfirmPassword
  );
}
function* watchAuthenticationConfirmChangePassword() {
  yield takeEvery(
    "setAuthenticationConfirmChangePasswordAction",
    authenticationConfirmChangePassword
  );
}
function* watchAuthenticationEmailVerify() {
  yield takeEvery(
    "setAuthenticateEmailVerification",
    authenticateEmailVerificationAction
  );
}
function* watchAuthenticationResetPassword() {
  yield takeEvery(
    "setAuthenticationResetPasswordAction",
    authenticationResetPasswordAction
  );
}
function* watchSetCountryDataAction() {
  yield takeEvery("setCountryDataAction", setCountryData);
}
function* watchSetStateDataAction() {
  yield takeEvery("setStateDataAction", setStateData);
}
function* watchSetPreviosState() {
  yield takeEvery("setPreviosStateAction", setPreviosState);
}

function* watchSendEmailAction(){
  yield takeEvery("sendEmailAction",sendEmail);
}

// Actions
export default function* authManagementSaga() {
  yield all([
    watchSetAuthInitialState(),
    watchAuthentication(),
    watchAuthenticationFormAction(),
    watchSignUpBasicDetailsFormAction(),
    watchAuthenticationLogout(),
    watchAuthenticationEmailVerify(),
    watchAuthenticationResetPassword(),
    watchAuthenticationConfirmPassword(),
    watchAuthenticationConfirmChangePassword(),
    watchSetCountryDataAction(),
    watchSetStateDataAction(),
    watchSetPreviosState(),
    watchSendCheckoutEmailOtp(),
    watchValidateCheckoutEmailOtp(),
    watchSendEmailAction()
  ]);
}
