import { 
Grid, 
Typography, 
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { getLocalData } from 'utils';
import { convertTimeDuration } from 'helper';

const StyledGrid = styled(Grid)(({ theme }) => ({ 
  display: 'flow-root',
  width: '100%',
    '@media (min-width: 600px)': {
      display: 'flex', 
      justifyContent: 'space-between',
      '& .MuiButton-root': {
        width: '100%'
      }
    },
    '& .MuiButton-root': {
      padding: "6px 36px"
    }
}));

const useStyles = makeStyles({
  cardContainer:{
    border:"1px solid #005EFF33",
    borderRadius:"5px",
    marginBottom:"3rem !important",
    marginTop:"2rem !important",
    '@media(max-width:600px)':{
      border:"1px solid #005EFF33",
      borderRadius:"5px",
      margin:"1rem 1rem !important",
      marginBottom:"3rem !important"
    }
  },
    cardOne: {
      backgroundColor: "white",
      marginBottom: '-14px',
      padding: "1rem",
      width: "100%",
      '@media(max-width:600px)':{
        padding:'1.5rem'
      }
    }, 
    cardTwo: {
      backgroundColor: "white",
      padding: "1rem",
      width: "100%",
    },
});

const ContentItem = (props) => {
  const { t, i18n } = useTranslation();
  const { aboutContent, contentItmeData,eventData } = props;
    const {
        mainHeading,
    } = contentItmeData;   
    const classes = useStyles();
    const lan = getLocalData("lan");
    return (
      <StyledGrid container>
        <Grid className={classes.cardContainer} item xs={12} sm={12} md={12} lg={12}>
            <div className={classes.cardOne}>
            <h5>{eventData?.name}</h5>
              <span className='text-12 font-medium mr-1'>
                {eventData?.type} <span style={{ margin: '0 8px' }}>|</span> {eventData?.language} <span style={{ margin: '0 8px' }}>|</span> {eventData.age + ' years'} <span style={{ margin: '0 8px' }}>|</span> {convertTimeDuration(eventData?.duration)}
              </span>
              </div>
              <div className={classes.cardTwo}>
              {eventData.description}
              {aboutContent.map((val, index) => {
                return(
                  <div key={index}>
                    <Typography className='text-12 font-medium mr-1'>{t(val.value)}</Typography><br />
                  </div>
                )
              })}
            </div>
        </Grid>
      </StyledGrid>
    )
}

export default ContentItem;