import React from 'react';
import { createTheme } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import { getTheme } from 'theme';
import RootRoutes from 'routes';
import './App.css';
import Authenticate from 'components/Authenticate';
import GlobalCss from 'styles/GlobalCss';
import { Store } from 'redux/store';
import { SnackbarProvider } from 'notistack';
import { ErrorSnackbar, SuccessSnackbar } from 'components/snackbar';
import ErrorModal from 'components/Shared/ErrorModal';

const App = () => {
  const theme = createTheme(getTheme('light'));
  return (
    <ThemeProvider theme={theme}>
      <Provider store={Store}>
        <SuccessSnackbar />
        <ErrorSnackbar />
        <ErrorModal />
        <SnackbarProvider>
          <GlobalCss />
            <Authenticate>
              <RootRoutes />
            </Authenticate>
        </SnackbarProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
