import { eventChannel } from 'redux-saga';
import { takeEvery, all, put, call } from 'redux-saga/effects'
import { isValidJsonString } from 'utils';
import { getCardsListService, saveCardTokenService, updateCardTokenService } from './CardValidationService';
import { setCardErrors, setCardInfo, setCardsList, setInitialState } from './CardValidationSlice';
import { SNACKBAR_ERROR } from 'redux/slices/snackbar';
import i18n from 'i18next';
import { loading } from 'redux/Loader/LoaderSlice';
function* translateAndDispatchError(message) {
    const translation = yield call([i18n, 't'], message);
    yield put(SNACKBAR_ERROR(translation));
}

function makeChannel(events) {
    return eventChannel(emitter => {
        for (const eventName of Object.keys(events)) {
            window.addEventListener(eventName, (...args) => {
                events[eventName](emitter, ...args);
            }, false);
        }
        return () => window.removeEventListener('message', emitter);
    });
}

function* watchForToken() {
    const messageChannel = makeChannel({
        message: (emit, msg) => {
            emit(msg);
        },
    });

    yield takeEvery(messageChannel, function* onTokenRecevied({ data }) {
        const parsed = isValidJsonString(data) ? JSON.parse(data) : null;
        yield put(setInitialState())
        if (parsed && parsed.message) {
            const payload = { token: parsed.message, expiry: parsed.expiry };
            yield put(setCardInfo(payload));
        } else if (parsed && parsed.validationError) {
            console.log(parsed.validationError)
            if(parsed.validationError === 'Expiry is Required'){
                parsed.validationError = 'Expiration Date Is Required'
            }
            else if (parsed.validationError === 'Invalid Expiry') {
                parsed.validationError = 'Invalid Expiration Date'
            }
            yield put(setCardErrors(parsed.validationError))
        }
    });
}

function* saveCardToken(action) {
    const { reqPayload, onSuccessCB, onErrorCB } = action.payload;
    try {
        const data = yield call(saveCardTokenService, reqPayload);
        if (data) {
            yield put(setCardInfo({}));
            onSuccessCB(data);
        }
    } catch (error) {
        onErrorCB(error);
        yield translateAndDispatchError(error.error);
    }
}

function* watchSaveCardToken() {
    yield takeEvery('saveCardTokenAction', saveCardToken);
}

function* updateCardToken(action) {
    const { reqPayload, onSuccessCB, onErrorCB } = action.payload;
    let requestId = Math.random();
    try {
        yield put(loading({ loading: true, requestId }));
        const data = yield call(updateCardTokenService, reqPayload);
        if (data) {
            yield put(setCardInfo({}));
            onSuccessCB(data);
        }
        yield put(loading({ loading: false, requestId }));
    } catch (error) {
        onErrorCB(error);
        yield translateAndDispatchError(error.error);
        yield put(loading({ loading: false, requestId }));
    }
}

function* watchupdateCardToken() {
    yield takeEvery('updateCardTokenAction', updateCardToken);
}

function* getCardsList(payload) {
    try {
        // const { userId } = payload.data;
        const response = yield call(getCardsListService);
        if (response) {
            yield put(setCardsList(response));
        }
    } catch (error) {
        yield translateAndDispatchError(error.error);
    }
}

function* watchGetCardsList() {
    yield takeEvery('getCardsListAction', getCardsList);
}

// Actions
export default function* cardValidationSaga() {
    yield all([
        watchForToken(),
        watchSaveCardToken(),
        watchGetCardsList(),
        watchupdateCardToken(),
    ])
}
