import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import artistImage from "../../assets/images/Ed_Sheeran_artist.webp";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { ExpandMore, Visibility } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router";
import { convertDate } from "helper";
import { setInitialState } from "redux/EventManagement/EventManagementSlice";
import BuyTickets from "views/LandingPage/components/BuyTickets";
import { useTranslation } from "react-i18next";
import breakpoints from 'components/Shared/MediaQueries/BreakPoints';
import { loading, setLoaderInitialState } from "redux/Loader/LoaderSlice";
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles(theme => ({
    container: {
        width: "100%",
        // height: "100vh",
        // marginTop: "3.7rem",
        letterSpacing: 1,
    },
    accordianDetails: {

        [breakpoints.sm_down]: {
            flexDirection: "column !important"
        },
        [breakpoints.sm_up]: {
            flexWrap: "wrap !important"
        },
        [breakpoints.md_up]: {
            display: 'flex',
            flexWrap: 'nowrap !important'
        }
    },
    banner: {
        backgroundImage: "linear-gradient(260deg, rgb(0, 45, 161), rgb(2, 108, 223) 55%, rgb(0, 45, 161))",
        color: '#FFF',
        padding: 24,
        flexDirection: 'row',
        alignItems: 'center',
        "& .artist-name": {
            fontWeight: 500,
            fontSize: 46,
            '& .label': {
                fontWeight: 300,
            }
        },
        "& .events-count": {
            fontSize: 20
        },
        "& img": {
            width: 224
        },
        [theme.breakpoints.down('sm')]: {
            padding: 16,
            flexDirection: 'column-reverse',
            alignItems: 'start',
            "& .artist-name": {
                fontWeight: 500,
                fontSize: 32,
                '& .label': {
                    fontWeight: 300,
                }
            },
            "& img": {
                width: "100%"
            }
        },
    },
    concertsRoot: {
        padding: 24,
        boxShadow: "none",
        [theme.breakpoints.down('sm')]: {
            padding: 16
        }
    },
    accordion: {
        boxShadow: "none !important",
        '&:hover': {
            backgroundColor: '#f2f2f2'
        },
    },
    accordionSummary: {
        flexDirection: "row-reverse",
        paddingLeft: '0px !important',
        '& svg': {
            // marginRight: 5
        }
    },
    summaryContent: {
        justifyContent: "space-between",
    },
    labelHeader: {
        fontSize: 12,
        color: '#262626a6',
        fontWeight: 600,
        textTransform: 'uppercase'
    },
    eventDetails: {
        [breakpoints.sm_down]: {
            marginLeft: "0 !important",
            marginTop: "2rem !important"
        },
        [breakpoints.sm_up]: {
            marginLeft: "0 !important",
            marginTop: "2rem !important"
        },
        [breakpoints.md_up]: {
            marginLeft: "5% !important",
            display: 'flex',
            flexDirection: 'column',
        }
    },
    dateConatiner: {
        width: '100%',
        flexShrink: 0,
        display: "flex",
        flexFlow: 'column nowrap'
    },
    placeContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: "2rem"
    },
    aboutContainer: {
        width: "50%",
        [breakpoints.sm_down]: {
            width: "100%",
            marginTop: "2rem !important"
        },
        [breakpoints.sm_up]: {
            width: "50%",
            marginTop: "2rem !important"
        }
    },
    artistName: {
        margin: 0,
        fontSize: '0.8rem',
        [breakpoints.sm_down]: {
            whiteSpace: "nowrap !important"
        }
    },
    bookButton: {
        backgroundColor: '#026cdf !important',
        height: 30,
        textTransform: 'capitalize !important',
        whiteSpace: 'nowrap !important',
        width: 'auto !important',
    },
    expandIcon: {
        marginLeft: 10
    },
    expandTitle: {
        width: '30%', 
        flexShrink: 0, 
        display: "flex", 
        flexFlow: 'column nowrap', 
        marginLeft: '10px'
    }
}));

const EventsList = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [expanded, setExpanded] = React.useState(null);
    const [openPopUp, setOpenPopUp] = React.useState(false);
    const [selectedEventId, setSelectedEventId] = React.useState("");
    const [myBookings, setMyBookings] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { artistEventDetails, artistMedia, artistMediaId, myTickets } = useSelector((state) => state.event);
    const { listOfEvents } = artistEventDetails;
    const params = useParams(); 
    const {loadingArray} = useSelector((state)=>state.loader);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    useEffect(() => {
        dispatch({
            type: "getArtistEventsAction",
            data: {
                artistId: params.artistId
            }
        });
        dispatch({
            type: "emptyArtistMediaAction"
        });
        dispatch({
            type: "getMyTicketsAction"
        });
       
    }, []);
    useEffect(() => {
        if (myTickets?.length > 0) {
            setMyBookings(myTickets.map(order => order.eventId));
        }
    }, [myTickets]);

    // useEffect(() => {
    //     if (listOfEvents) {
    //         listOfEvents?.map((event) => {
    //             event?.eventArtists?.map((artist) => {
    //                 // alert(artist.mediaId)
    //                 dispatch({ type: "getArtistMediaAction", mediaId: artist.mediaId })
    //             })

    //         })
    //     }
    // }, [listOfEvents]);

    const renderArtistImages = (mediaId) => {
        return artistMedia.find(artistMedia => artistMedia.mediaId === mediaId)?.artistUrl
    }
    const handleCloseBuyTicketPopup = (isClose, priceId, count) => {
        if (!isClose) {
            setOpenPopUp(false);
        }
        else {
            navigate(`/events/${selectedEventId}/checkout?priceId=${priceId}&count=${count}`);
        }
        setSelectedEventId("");
    }
    const handleViewEvent = (e, eventId) => {
        e.stopPropagation();
        navigate(`/events/${eventId}/details`);
    };

    const handleBooking = (e, eventId, openModal) => {
        e.stopPropagation();
        dispatch({ type: "getEventAction", data: { eventId: eventId } });
        setSelectedEventId(eventId);
        setOpenPopUp(openModal);
    }    
    return (
        <>
            <div className={classes.container}>
                {
                    (openPopUp && loadingArray.length===0) &&<BuyTickets selectedEventId={selectedEventId} open={openPopUp} handleCloseBuyTicketPopup={handleCloseBuyTicketPopup} />
                }
                <div className={`d-flex justify-content-between ${classes.banner}`}>
                    <div>
                        <p className="artist-name my-3">{artistEventDetails?.fullname} <span className="label">{t('Tickets')}</span></p>
                        <p className="events-count">{t("Events")} ({artistEventDetails?.listOfEvents?.length})</p>
                    </div>
                    {artistEventDetails&&artistEventDetails?.mediaUrl ? <img className="rounded-3" src={artistEventDetails?.mediaUrl} alt="artist-name" /> : <></>}
                </div>
                <div className={classes.concertsRoot}>
                    {listOfEvents?.map((item, index) => <Accordion key={index} classes={{ root: classes.accordion }} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            classes={{ root: classes.accordionSummary, content: classes.summaryContent, expandIconWrapper: classes.expandIcon }}
                        >
                            <div className="d-flex w-100 align-items-center">
                                <span className={classes.expandTitle}>
                                    <Typography sx={{ color: "#904eba", fontSize: 16, fontWeight: 600, textTransform: "uppercase" }}>{convertDate(item?.startDate)}</Typography>
                                    <Typography sx={{ color: "#262626a6", fontSize: 14, fontWeight: 400 }}>{item?.startTime}</Typography>
                                </span>
                                <Typography sx={{ color: '#757575' }} className="font-bold mr-5">{item?.name}</Typography>

                            </div>
                            <div className="d-flex align-items-center">
                                <Tooltip title={t("View Details")}>
                                    <span>
                                    <IconButton key='view details' aria-label='view' onClick={(e) => handleViewEvent(e, item?.eventId)} className='mr-3'>
                                        <Visibility />
                                    </IconButton>
                                    </span>
                                </Tooltip>
                                <Button size="small" className={classes.bookButton} variant="contained" onClick={(e) => handleBooking(e, item?.eventId, true)}>{t(myBookings?.includes(item?.eventId) ? "Buy More Tickets" : "Buy Tickets")}</Button>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordianDetails}>
                            <div className="w-25">
                                <p className={classes.labelHeader} >{t("Artists")}</p>
                                {
                                    item?.eventArtists?.map((artist,index) => {
                                        return <Typography key={index} sx={{ marginTop: '10px' }} className="d-flex align-items-center">
                                            <Avatar component="span" sx={{ marginRight: '10px' }} alt="Remy Sharp" src={artist?.mediaThumbnail} />
                                            <span className={classes.artistName} >{artist.fullname}</span>
                                        </Typography>
                                    })
                                }

                            </div>
                            <div className={classes.aboutContainer}>
                                <p className={classes.labelHeader} >{t("About")}</p>
                                <Typography sx={{ fontSize: '12px', width: "100%" }}>
                                    {t(item?.description)}
                                </Typography>
                            </div>
                            <div className={classes.eventDetails}>
                                {/* <Typography className={classes.dateConatiner}>
                                    <p className={classes.labelHeader}>Date & Time</p>
                                    <Typography sx={{ color: "#904eba", fontSize: 16, fontWeight: 600, whiteSpace: 'nowrap', textTransform: "uppercase" }}>{convertDate(item?.startDate)}</Typography>
                                    <Typography sx={{ color: "#262626a6", fontSize: 14, fontWeight: 400 }}>{item?.startTime}</Typography>
                                </Typography> */}
                                {/* <div className={classes.placeContainer}> */}
                                <p className={classes.labelHeader}>{t("Place")}</p>
                                <Typography sx={{ color: '#262626', fontSize: "12px" }}>{item?.stateName}, {item?.countryName}</Typography>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    )}
                </div>
            </div>
        </>
    );
};

export default EventsList;
