import DataTable from 'components/Shared/Table';
import React from 'react'
import { ExpandMore } from '@mui/icons-material';
import { FormLabel, Grid, MenuItem, Select, TablePagination } from '@mui/material';
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router';

function SingleConcert() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const useStyles = makeStyles((theme) => ({
        icon: {
            // Add custom styles for the arrow icon here
            right: 12,
            position: 'absolute',
            userSelect: 'none',
            pointerEvents: 'none',
        },
        select: {
            padding: '0 2rem',
            width: '12rem',
            height: '2.2rem',
            '&:before': {
                borderColor: 'red', // Replace 'your_desired_border_color' with your desired color
            },
            '&:after': {
                borderColor: '#eee', // Replace 'your_desired_border_color' with your desired color
            },
        },
        tableContainer: {
            padding: "0.5rem 0",
            border: "1px solid #ededed",
            boxShadow: "0px 1px 6px 1px #ededed",
            borderRadius: "6px"
        },
        formLabel: {
            fontSize: "0.9rem !important"
        },
        pagination: {
            '& .MuiTablePagination-toolbar': {
                display: 'flex',
                justifyContent: 'end',
                padding: 0,
                alignItems: 'baseline !important' // Customize the background color of the pagination controls
            }
        },
        eventDetails:{
            fontSize:"0.8rem"
        }
    }));
    const { reportSingleConcertTableHeaders, reportSingleConcertTableData } = useSelector((state) => state.reports);
    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <div className='mt-5'>
            <div className='flex'>
                <ArrowBackIosIcon onClick={() => navigate('/reports')} className='color-primary cursor-pointer' />
                <h3 className='mb-5'>Ticket Sold Reports</h3>
            </div>
            <div className='flex justify-between mb-5 mt-5'>
                <span className={classes.eventDetails}>Concert: <span className='font-medium'>Arijit Singh Lucknow Tour</span></span>
                <span className={classes.eventDetails}>Event Date: <span className='font-medium'>23-12-01</span></span>
                <span className={classes.eventDetails}>Remaining Bookings: <span className='font-medium'>120</span></span>
                <span className={classes.eventDetails}>Total Bookings: <span className='font-medium'>920</span></span>
            </div>
            <div className={classes.tableContainer}>
                <Grid className='flex float-right mb-2 mr-2'>
                    <Grid>
                        <FormLabel className={`mr-1 ${classes.formLabel}`}>Ticket Type:</FormLabel>
                        <Select className={classes.select} IconComponent={ExpandMore}
                            inputProps={{
                                classes: {
                                    icon: classes.icon,
                                },
                            }}>
                            <MenuItem></MenuItem>
                        </Select>
                    </Grid>
                    <Grid className='ml-3'>
                        <FormLabel className={`mr-1 ${classes.formLabel}`}>Filter:</FormLabel>
                        <Select className={classes.select} IconComponent={ExpandMore}
                            inputProps={{
                                classes: {
                                    icon: classes.icon,
                                },
                            }}>
                            <MenuItem></MenuItem>
                        </Select>
                    </Grid>
                </Grid>
                <DataTable
                    tableHeading={reportSingleConcertTableHeaders}
                    tableData={reportSingleConcertTableData}
                />
                 <TablePagination
                    component="div"
                    count={100}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className={classes.pagination}
                />
            </div>
        </div>
    )
}

export default SingleConcert