
import { config } from 'config.js';
import { xhrClient } from 'views/utilities/DataRequest';
const { isProd } = config;
const API_ENDPOINT = isProd
    ? config.production.api_endpoint
    : config.development.api_endpoint;
    
const setArtistListService = (data) => {
    if(data){
        const {pageNumber,pageLimit} = data;
        return xhrClient.get(`${API_ENDPOINT}/allArtists?pageLimit=${pageLimit}&pageNumber=${pageNumber}`);
    }else{
        return xhrClient.get(`${API_ENDPOINT}/allArtists`);
    }
}
const setAdminArtistListService = (data)=> {
    if(data){
        const {pageNumber,pageLimit} = data;
        return xhrClient.get(`${API_ENDPOINT}/admin-artists?pageLimit=${pageLimit}&pageNumber=${pageNumber}`);
    }else{
        return xhrClient.get(`${API_ENDPOINT}/admin-artists`);
    }
}
const setAddArtistService = (data) => {
    return xhrClient.put(`${API_ENDPOINT}/eventArtist`,null, data);
}
export {
    setArtistListService,
    setAdminArtistListService,
    setAddArtistService
}