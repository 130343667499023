import React from 'react'

function EventCreation({}) {

  return (
    <div>
    Welcome to Event creation page
    </div>
  )
}

export default EventCreation