import React, { useState } from "react";
import { Grid } from "@mui/material";
import OrderSummary from "./components/OrderSummary";
import TicketDonation from "./components/TicketDonation";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import CardValidation from "views/CardValidation";
import ApiConstant from "../../constant/ApiConstant";
import ModalPopup from "components/Shared/ModalPopup";
import { Loader } from "components/Shared/Loader";
import { setCouponCode } from "redux/EventManagement/EventManagementSlice";
import { useTranslation } from "react-i18next";

const { RESPONSE } = ApiConstant;

const TicketTypeDonation = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { t } = useTranslation();
  const [isCardVerify,setCardVerify] = useState(false);
  // const { token, expiry } = useSelector((state) => state.cardInfo);
  const { eventData, eventBookingDetails } = useSelector((state) => state.event);
  const formContent = [
    {
      label: "Discount Coupon",
      name: "discountCode",
    },
    {
      label: "Donate To Hurricane Ian Victims",
      name: "donateAmount",
    },
  ];
  const orderSummaryText = {
    orderSummaryTitle: "ORDER SUMMARY",
    ticketType: "M-Ticket",
    buyNowText: "Buy Now",
    subTotalText: "Subtotal",
  };
  useEffect(() => {
    dispatch({
      type: "getEventAction",
      data: {
        eventId: params.eventId,
      },
    });
  }, []);

  const [values, setValues] = useState({
    discountCode: "",
    donateAmount: "",
  });
  const [orderSummary, setOrderSummary] = useState({});
  const [count, setCount] = useState(1);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [eventPriceId, setEventPriceId] = useState();
  const [offerId, setOfferId] = useState();
  const [validCouponCode, setValidCouponCode] = useState("");
  const [cardValidationView, setCardValidationView] = useState(false);

  const buyNowHandle = () => {
    dispatch({
      type:setCouponCode.type,
      couponCode:""
    })
    setCardValidationView(true);
  };
  const handleCardDetailsSavedSuccessfully = (res) => {
   if(res.status === RESPONSE.SUCCESS){
    setCardValidationView(false);
    setCardVerify(true)
    dispatch({
          type: "eventBookingAction",
          data: {
            eventId: params.eventId,
            couponCode: validCouponCode,
            bookingType: "LIMITED",
            donation: values.donateAmount,
            eventPriceId: eventPriceId,
            offerId: offerId,
            quantity: count,
          },
        });
   }
  };

  return (
    <>
    { isCardVerify && <Loader isOpen={true} /> }
    {eventBookingDetails?.isEventBookingDone && <Loader isOpen={false} />}
      {
        cardValidationView ?
          <CardValidation handleCardDetailsSavedSuccessfully={handleCardDetailsSavedSuccessfully} />
          : <Grid container sx={{ display: "flex", justifyContent: "space-evenly", marginTop: "1rem" }}>
            <TicketDonation
              formContent={formContent}
              eventData={eventData}
              values={values}
              setValues={setValues}
              setOrderSummary={setOrderSummary}
              count={count}
              setCount={setCount}
              setDiscountAmount={setDiscountAmount}
              setEventPriceId={setEventPriceId}
              setOfferId={setOfferId}
              setValidCouponCode={setValidCouponCode}
            />
            <OrderSummary
              orderSummaryText={orderSummaryText}
              eventData={eventData}
              values={values}
              orderSummary={orderSummary}
              count={count}
              discountAmount={discountAmount}
              buyNowHandle={buyNowHandle}
            />
          </Grid>}
          {eventBookingDetails?.isEventBookingDone && <ModalPopup isOpen={true} 
                                                                  modalData={ {title: `${t("Order ID")} : ${eventBookingDetails.orderId}`,
                                                                  content: `${t("Event is booked, confirmation sent to")} ${eventBookingDetails.message?.split(' ').pop()}`
                                                                  , buttons:[{text:'Okay',
                                                                  style:'mb-1 pt-3 pb-3 right-gradient text-white flex items-center',
                                                                  }]}} 
                                                                  url={`/events/${params.eventId}/details`} />}
    </>
  );
};

export default TicketTypeDonation;
