import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles';
import { Button, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Ed_Sheeran_artist from 'assets/images/Ed_Sheeran_artist.webp'
import ProfileImage from 'assets/images/Person.svg'
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles({
    bannerImg: {
        objectFit: "cover",
        objectPosition: "center"
    },
    formConteiner: {
        width: "80%",
        marginLeft: "12%",
        '@media(max-width:899px)': {
            marginTop: "5rem",
            margin: "0 auto",
        }
    },
    formButton: {
        width: "40%",
        textTransform: "capitalize !important",
        color: "#FFFFFF!important",
        '@media(max-width:600px)': {
            width:'100%',
        },
        '@media screen and (max-width: 902px) and (min-width: 600px)': {
            width: "50%",
            margin: "0 auto"
        },
    },
    imageWrap: {
        border: "1px solid #f1ebeb",
        borderRadius: "50%",
        overflow: "hidden",
        backgroundColor: "#f1ebeb",
        width: "200px",
        height: "200px",
        marginTop: "1rem",
        '@media(max-width:899px)': {
            position: "absolute",
            top: "200px",
            marginTop: "0",
        },
        '& img' : {
            objectFit: "cover",
            width: "200px",
            height: "200px",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    profileImg: {
        display: "flex",
        justifyContent: "center"
    }
});

const Profile = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { userDetails } = useSelector((state) => state.profile);

    useEffect(()=>{
        dispatch({
          type:"getUserProfileAction"
        })
    },[]);

    const [values, setValues] = useState({
        fullName: '',
        displayName: '',
        emailAddress: '',
    });
    useEffect(() => {
        setValues({
          fullName: userDetails?.fullName || '',
          displayName: userDetails?.displayName || '',
          emailAddress: userDetails?.email || '',
        });
    }, [userDetails]);
    const formContent = [
        {
            label: "Full Name",
            name: "fullName",
        },
        {
            label: "Display Name",
            name: "displayName",
        },
        {
            label: "Email Address",
            name: "emailAddress"
        }
    ];

    const handleChange = (prop) => (event) => {
        const { value, name } = event.target;
        setValues({ ...values, [prop]: value });
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch({
            type : "updateUserProfileAction",
            payload: {
                fullName: values.fullName,
                displayName: values.displayName,
            }
        })
    }
    const buttonDisable = values.fullName === userDetails.fullName && values.displayName === userDetails.displayName;
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <img className={classes.bannerImg} width='100%' height='230' src={Ed_Sheeran_artist} />
                </Grid>
                <Grid item xs={12} className='mt-10'>
                    <Grid container justifyContent="center">
                        {/* <Grid item lg={6} md={6} sm={12} xs={12} className={classes.profileImg}>
                            <div className={classes.imageWrap}>
                                <img 
                                src={"https://www.shutterstock.com/image-photo/head-shot-portrait-close-smiling-260nw-1714666150.jpg"} 
                                onError={event => {
                                    event.target.src = ProfileImage;
                                    event.onerror = null;
                                }}
                                />
                            </div>
                        </Grid> */}
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <div className={`p-3 ${classes.formConteiner}`}>
                                <form onSubmit={handleSubmit}>
                                        {formContent.map((el, index) => {
                                            return (
                                                < div key={index}>
                                                    <label>{t(el.label)}</label><br />
                                                    <TextField 
                                                        name={el.name} 
                                                        onChange={handleChange(el.name)} 
                                                        className={`${classes.input} mb-5`}
                                                        sx={{
                                                            "& .MuiInputBase-root": {
                                                                height: 40,
                                                                border: "1px solid #DBE2EA"
                                                            }
                                                        }}
                                                        fullWidth
                                                        disabled={el.name === "emailAddress"}
                                                        // type={!showPassword && el.name === "password" ? "password" : "text"}
                                                        value={values[el.name]}
                                                    //     error={errorState
                                                    //         && errorState.userDetails
                                                    //         && errorState.userDetails[el.name]
                                                    //         && errorState.userDetails[el.name].error}
                                                    //     helperText={errorState
                                                    //         && errorState.userDetails
                                                    //         && errorState.userDetails[el.name]
                                                    //         && errorState.userDetails[el.name].error
                                                    //         ? <span>{errorState.userDetails[el.name].error}</span>
                                                    //         : null}
                                                    />
                                                    <br />
                                                </div>
                                            )
                                        })}
                                        <Button type="submit" disabled={buttonDisable} className={`${classes.formButton} mb-5 mt-3 py-2`} variant='contained'>{t(`Save Changes`)}</Button>
                                </form>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Profile;