import { call, put, takeEvery, all } from "redux-saga/effects";
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from "redux/slices/snackbar";
import {getRedeemTicketService, getTicketsService, shareTicketService,} from "redux/TicketManagement/TicketManagementService";
import ApiConstant from "constant/ApiConstant";
import i18n from 'i18next';
import { loading } from "redux/Loader/LoaderSlice";
import { setTicketShareAndRedeemSuccess } from "./TicketManagementSlice";

function* translateAndDispatchSuccess(message) {
  const translation = yield call([i18n, 't'], message);
  yield put(SNACKBAR_SUCCESS(translation));
}
function* translateAndDispatchError(message) {
  const translation = yield call([i18n, 't'], message);
  yield put(SNACKBAR_ERROR(translation));
}

const { RESPONSE } = ApiConstant;


function* setShareTicket(data){
  let requestId = Math.random();
  try {
    yield put (loading({loading:true,requestId}));
    const {payload} = data;
    const response = yield call(shareTicketService,payload);
    yield translateAndDispatchSuccess(response.message);
    yield put(setTicketShareAndRedeemSuccess(true));
    yield put (loading({loading:false,requestId}));
  } catch (error) {
    yield translateAndDispatchError(error.error);
    yield put(setTicketShareAndRedeemSuccess(false));
    yield put (loading({loading:false,requestId}));
  }
}

function* getUserTickets(action){
  let requestId = Math.random();
    const {onSuccessCB, onErrorCB } = action.payload;
    try {
        yield put (loading({loading:true,requestId}));
        const response = yield call(getTicketsService);
        onSuccessCB(response);
        yield put (loading({loading:false,requestId}));
    } catch (error) {
        yield translateAndDispatchError(error.error);
        yield put (loading({loading:false,requestId}));
        onErrorCB(error);
    }
}
function* fetchTicketMessage(action) {
  let requestId = Math.random();
  try {
      yield put (loading({loading:true,requestId}));
      const response = yield call(getRedeemTicketService, action.data);
      yield translateAndDispatchSuccess(response.message);
      yield put(setTicketShareAndRedeemSuccess(true));
      yield put (loading({loading:false,requestId}));
    } catch (error) {
      yield translateAndDispatchError(error.error);
      yield put(setTicketShareAndRedeemSuccess(false));
      yield put (loading({loading:false,requestId}));
    }
  }
  
function* watchSetShareTicket(){
  yield takeEvery("setShareTicketAction",setShareTicket);
}
function* watchGetUserTickets(){
    yield takeEvery("getUserTicketsAction",getUserTickets);
}
function* watchFetchTicketMessage() {
    yield takeEvery('fetchTicketMessageAction', fetchTicketMessage);
}
// Actions
export default function* ticketManagementSaga() {
  yield all([
    watchSetShareTicket(),
    watchGetUserTickets(),
    watchFetchTicketMessage()
  ]);
}
