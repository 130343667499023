import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import EmailVerificationModal from "./EmailVerificationModal";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  label: {
    fontWeight: "600",
    fontSize: "0.8rem",
    color: "#756F86",
  },
  labelError: {
    fontWeight: "400",
    fontSize: "0.8rem",
    color: "#D32F2F",
    marginTop:"8px",
    position:"absolute"
  },
  formButton: {
    width: "100%",
    backgroundColor: "#616161 !important",
    textTransform: "capitalize !important",
    marginTop: "6.75rem !important",
  },
  verifyButton: {
    width: "60% !important",
    color: "#fff !important",
    backgroundColor: "#616161 !important",
    textTransform: "capitalize !important",
    fontSize: "0.8rem !important",
  },
  formLink: {
    textDecoration: "underline",
    padding: "6px 9px 0 0",
    float: "right",
    color: "gray",
    fontWeight: "600",
    cursor: "pointer",
  },
});
const EmailVerificationForm = (props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const emailId = localStorage.getItem('emailId');
  const {
    formContent: { formHeading, formSubHeading, buttonText, resendText },
  } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { errorState, isEmailVerified, isResetConfirmPassword } =
    useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    otp: "",
  });
  const handleChange = (val) => {
    setValues({ ...values, otp: val });
  };
  const [error,setError] = useState();

  const handleSubmit = (event) => {
    event.preventDefault();
    var matches = (values.otp).match(/^[0-9a-zA-Z]{6,6}$/g);
    if (matches) {
      setError(false);
      dispatch({
        type: "setAuthenticateEmailVerification",
        data: {
          emailId: localStorage.getItem("emailId"),
          verificationCode: values.otp,
        },
      });
    }else{
      setError(true);
    }
  };

  useEffect(() => {
    if (isEmailVerified) {
      dispatch({
        type: "setPreviosStateAction",
        data: [{
          state:'isEmailVerified',
          value:false,
        },
      ],
      });
      setOpen(true);
    }
    if (isResetConfirmPassword) {
      navigate("/reset-confirmation-password");
    }

  }, [isEmailVerified, isResetConfirmPassword]);

  const modalData = {
    title: "Email Verification Successful",
    content:
      "Thank you for verifying your email.",
    buttonText: "Ok",
  };

  const handleResendOtp = () => {
    setValues({ ...values, otp: '' });
    dispatch({
      type: "sendEmailAction",
      data: {
        emailId: localStorage.getItem('emailId'),
      },
    });
  };

  useEffect(() => {
    if (emailId && !isEmailVerified) {
      handleResendOtp();
    }
    else {
      // navigate('/login');
      // dispatch({
      //   type: "setAuthInitialStateAction"
      // });
    };
  }, [emailId]);

  let atIndex = emailId?.indexOf('@'); 
  let maskedEmail = '*****' + emailId?.slice(atIndex);

  return (
    <div className="p-3 mt-25" >
      <form onSubmit={handleSubmit}>
      <h3>{t(formHeading)}</h3>
      <span className="text-12 font-medium mr-1">{`${t(formSubHeading)} ${maskedEmail}`}</span>
      <div className="mt-5">
        <OtpInput
          value={values.otp}
          onChange={handleChange}
          numInputs={6}
          inputStyle={{
            width: "79%",
            textAlign: "center",
            height: "3em",
            borderRadius: "5px",
            border: "1px lightgray solid",
            boxShadow: "0 0.8pt 1.8pt 0 #d3d3d3",
          }}
        />
        {error && (
          <label
            className={`${
              error
                ? classes.labelError
                : classes.label
            }`}
          >
            {"Please enter valid OTP"}
          </label>
        )}

        <span
          className={`${classes.formLink} text-12 link-color font-medium ` }
          onClick={handleResendOtp}
        >
          {t(resendText)}
        </span>
        <Button
          type="submit"
          className={`${classes.formButton} mb-1 pt-3 pb-3 right-gradient text-white flex items-center`}
          variant="contained"
        >
          {t(buttonText)}
        </Button>
        <EmailVerificationModal
          setOpen={setOpen}
          open={open}
          classes={classes.verifyButton}
          modalData={modalData}
          t={t}
        />
      </div>
      </form>
    </div>
  );
};

export default EmailVerificationForm;
