// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slick-arrow {
    height: 35% !important;
    background-color: transparent !important;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    z-index: 10;
    width: 2.5vw;
    background-repeat: no-repeat;
    background-position: center center;
}
.slick-slide {
    display: flex !important;
    justify-content: center !important;
}
.slickSlider * {
    -webkit-user-select: text;
            user-select: text;
}
.slick-list {
    overflow: hidden;
}
.slick-next {
    right: -20px !important;
}
.slick-prev {
    left: -20px !important;
} 
.slick-prev:before, .slick-next:before {
    font-size: 2rem;
    color: black;
}

@media (max-width: 600px) {
    .slick-prev {
        left: 5px !important;
    }
    .slick-next {
        right: 30px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/views/My-order/style.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,wCAAwC;IACxC,oBAAoB;IACpB,mCAAmC;IACnC,kCAAkC;IAClC,WAAW;IACX,YAAY;IACZ,4BAA4B;IAC5B,kCAAkC;AACtC;AACA;IACI,wBAAwB;IACxB,kCAAkC;AACtC;AACA;IACI,yBAAiB;YAAjB,iBAAiB;AACrB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI;QACI,oBAAoB;IACxB;IACA;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".slick-arrow {\n    height: 35% !important;\n    background-color: transparent !important;\n    text-rendering: auto;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n    z-index: 10;\n    width: 2.5vw;\n    background-repeat: no-repeat;\n    background-position: center center;\n}\n.slick-slide {\n    display: flex !important;\n    justify-content: center !important;\n}\n.slickSlider * {\n    user-select: text;\n}\n.slick-list {\n    overflow: hidden;\n}\n.slick-next {\n    right: -20px !important;\n}\n.slick-prev {\n    left: -20px !important;\n} \n.slick-prev:before, .slick-next:before {\n    font-size: 2rem;\n    color: black;\n}\n\n@media (max-width: 600px) {\n    .slick-prev {\n        left: 5px !important;\n    }\n    .slick-next {\n        right: 30px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
