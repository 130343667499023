import { ExpandMore } from '@mui/icons-material';
import { FormLabel, Grid, MenuItem, Select, TablePagination } from '@mui/material';
import { makeStyles } from '@mui/styles'
import DataTable from 'components/Shared/Table';
import React from 'react'
import { useSelector } from 'react-redux';

function Reports() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const useStyles = makeStyles((theme) => ({
        icon: {
            // Add custom styles for the arrow icon here
            right: 12,
            position: 'absolute',
            userSelect: 'none',
            pointerEvents: 'none',
        },
        select: {
            padding: '0 2rem',
            width: '12rem',
            height: '2.2rem',
            '&:before': {
                borderColor: 'red', // Replace 'your_desired_border_color' with your desired color
            },
            '&:after': {
                borderColor: '#eee', // Replace 'your_desired_border_color' with your desired color
            },
        },
        tableContainer: {
            padding: "0.5rem 0",
            border: "1px solid #ededed",
            boxShadow: "0px 1px 6px 1px #ededed",
            borderRadius: "6px",
            height: '100vh'
        },
        formLabel: {
            fontSize: "0.9rem !important"
        },
        pagination: {
            '& .MuiTablePagination-toolbar': {
                display: 'flex',
                justifyContent: 'end',
                padding: 0,
                alignItems: 'baseline !important' // Customize the background color of the pagination controls
            }
        },
    }));
    const classes = useStyles();
    const { reportsTableHeaders, reportsTableData } = useSelector((state) => state.reports);
    return (
        <div className='mt-5'>
            <h3 className='mb-5'>Ticket Sold Reports</h3>
            <div className={classes.tableContainer}>
                <Grid className='flex float-right mb-2 mr-2'>
                    <Grid>
                        <FormLabel className={`mr-1 ${classes.formLabel}`}>Concerts:</FormLabel>
                        <Select className={classes.select} IconComponent={ExpandMore}
                            inputProps={{
                                classes: {
                                    icon: classes.icon,
                                },
                            }}>
                            <MenuItem></MenuItem>
                        </Select>
                    </Grid>
                    <Grid className='ml-3'>
                        <FormLabel className={`mr-1 ${classes.formLabel}`}>Ticket Type:</FormLabel>
                        <Select className={classes.select} IconComponent={ExpandMore}
                            inputProps={{
                                classes: {
                                    icon: classes.icon,
                                },
                            }}>
                            <MenuItem></MenuItem>
                        </Select>
                    </Grid>
                </Grid>
                <DataTable
                    tableHeading={reportsTableHeaders}
                    tableData={reportsTableData}
                    hasNoData={reportsTableData.length===0?true:false}
                />
                {reportsTableData&&reportsTableData.length>0&&<TablePagination
                    component="div"
                    count={100}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className={classes.pagination}
                />}
            </div>
        </div>
    )
}

export default Reports;