import { containsSpecialChars, hasNumber } from 'helper';
import JwtDecode from 'jwt-decode';
// converts mm-yy-dd or other formats to dd-mm-yy format
export function formatDate(input) {
    var datePart = input.match(/\d+/g),
        year = datePart[0], // get only two digits
        month = datePart[1], day = datePart[2];

    return day + '-' + month + '-' + year;
}
// formats date in dd mm yy directly from date object
export function DateFormatFromDateObj(input) {
    const d = new Date(input);
    let date;
    if (d.getDate() < 10 || d.getMonth() < 10) {
        date = `${"0" + d.getDate() + "-" + "0" + Number(d.getMonth() + 1) + "-" + d.getFullYear()}`
    } else {
        date = `${d.getDate() + "-" + Number(d.getMonth() + 1) + "-" + d.getFullYear()}`
    }
    return date;
}
// formats date in day month name and date format
export function DayMonthDateFormatDate(input) {
    const d = new Date(input);
    let day, month, date;
    switch (d.getDay()) {
        case 0: day = "Sun"
            break;
        case 1: day = "Mon"
            break;
        case 2: day = "Tue"
            break;
        case 3: day = "Wed"
            break;
        case 4: day = "Thur"
            break;
        case 5: day = "Fri"
            break;
        case 6: day = "Sat"
            break;
    }
    switch (d.getMonth()) {
        case 0: month = "Jan"
            break;
        case 1: month = "Feb"
            break;
        case 2: month = "Mar"
            break;
        case 3: month = "Apr"
            break;
        case 4: month = "May"
            break;
        case 5: month = "June"
            break;
        case 6: month = "July"
            break;
        case 7: month = "Aug"
            break;
        case 8: month = "Sep"
            break;
        case 9: month = "Oct"
            break;
        case 10: month = "Nov"
            break;
        case 11: month = "Dec"
            break;
    }
    date = `${day + " " + month + " " + d.getDate()}`;
    return date
}

export function validateName(value) {
    let error = false;
    if ((value && hasNumber(value) || !containsSpecialChars(value)) || value.length < 3 || value.length > 15) {
        error = true;
    }
    return error;
}
export function validateEmail(value) {
    let error = false;
    // if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    if (!/^[a-zA-Z0-9]+([._-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/i.test(value)) {
        error = true;
    }
    return error;
}
export function validatePassword(value) {
    let error = false;
    if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/.test(value)) {
        error = true;
    }
    return error;
}
export function validateMobile(value) {
    let error = false;
    if (value && (value.length < 10 || value.length > 10)) {
        error = true;
    }
    return error;
}
export function validatePin(value) {
    let error = false;
    if (value.length > 6 || value.length < 6) {
        error = true;
    }
    return error;
}
export function capitalize(input) {  
    var words = input.split(' ');  
    var CapitalizedWords = [];  
    words.forEach(element => {  
        CapitalizedWords.push(element[0]?.toUpperCase() + element?.slice(1, element?.length));  
    });  
    return CapitalizedWords.join(' ');  
}  
export function fieldLevelValidation(fieldName, fieldValue) {
    let isError = false;
    switch (fieldName) {
        case 'fullName':
            isError = validateName(fieldValue);
            break;
        case 'emailId':
        case 'email':
            isError = validateEmail(fieldValue);
            break;
        case 'password':
            isError = validatePassword(fieldValue)
            break;
        case 'confirmPassword':
            isError = validatePassword(fieldValue)
            break;
        case 'mobile':
            isError = validateMobile(fieldValue);
            break;
        case 'pinCode':
            isError = validatePin(fieldValue);
            break;
        default:
            break;
    }
    return isError;
}
export function isEmpty(value) {
    return value === "";
}

export const isValidJsonString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}


export const formatJSDateToNormalDate = (str) => {
    let date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join("-");

}
export const formatJSDateToNormalTime = (str) => {
    let date = new Date(str),
        HH = ("0" + (date.getHours())).slice(-2),
        MM = ("0" + date.getMinutes()).slice(-2);
    return [HH, MM].join(":");
}
export const convertToDateAndTime = (dateValue)=>{

// Convert the string to a Date object
const dateObj = new Date(dateValue);

// Extract date and time components
const year = dateObj.getFullYear();
const month = dateObj.getMonth() + 1; // Month is zero-indexed, so add 1 to get the correct month number
const day = dateObj.getDate();
const hours = dateObj.getHours();
const minutes = dateObj.getMinutes();

// Format the date and time components if needed
const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;

// Output the results
return `${formattedDate}, ${formattedTime}`

}
export const validateEmailOtp = (value) => {
    let error = false;
    if (value.length < 7) {
        error = true;
    }
    return error;
}
export function getLocalData(name) {
    const value = localStorage.getItem(name);
    return value;
}
export function setLocalData(name, val) {
    const value = localStorage.setItem(name, val);
    return value;
}
export function removeLocalData(name) {
    if (Array.isArray(name)) {
        name.map((item) => {
            const value = localStorage.removeItem(item);
            return value;
        })
    } else {
        const value = localStorage.removeItem(name);
        return value;
    }
}

export function isLoggedIn() {
    return !!localStorage.getItem('accessToken')
}

export const isAdmin = () => {
    const jwtToken = localStorage.getItem('accessToken');
    if (!jwtToken) {
        return false;
    }
    const decoded_token = JwtDecode(jwtToken);
    return decoded_token.typeOfUser !== 'USER'
}

export const getProfileName = () => {
    const jwtToken = localStorage.getItem('accessToken');
    if (!jwtToken) {
        return false;
    }
    const decoded_token = JwtDecode(jwtToken);
    return decoded_token.fullName
}

export const getProfileDetails = () => {
    const jwtToken = localStorage.getItem('accessToken');
    if (!jwtToken) {
        return false;
    }
    const decoded_token = JwtDecode(jwtToken);
    return decoded_token
}

export const removeUnderscoresAndCapitalize = (str) => {
    if (str) {
        const formattedString = str.replace(/_/g, ' ');
        return formattedString.toLowerCase().replace(/\b\w/g, function (match) {
            return match.toUpperCase();
        });
    } return null;


}

export const timeStringToJsDate =(timeString) =>{
    if(timeString){
        const [hours, minutes, seconds] = timeString.split(":").map(Number);
         const date = new Date();
         date.setHours(hours);
         date.setMinutes(minutes);
         date.setSeconds(seconds);
         return date;
    }
}