import React, { createContext, useState } from 'react';

export const GlobalContext = createContext(null);

const GlobalContextProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);

  return (
    <GlobalContext.Provider
      value={{
        auth, setAuth
      }}
    >
      { children }
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;