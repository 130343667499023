/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Tabs, Tab, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import DashboardTable from 'views/Dashboard/components/DashboardTable';
import { useDispatch, useSelector } from 'react-redux';
import Header from 'views/Header';
import ReportHeader from './components/ReportHeader';
import Paginate from 'views/Dashboard/components/Paginate';
import { useEffect } from 'react';
import { createBrowserHistory } from 'history';
import ReportInshightHeader from './components/ReportInshightHeader';
import { useParams } from 'react-router-dom';
const useStyles = makeStyles({
  mainContainer: {
    width: "100%",
    minHeight: "75vh"
  },
  bannerImage: {
    width: "100%",
    height: "50vh",
  },
  gridContainer: {
    // marginTop: '3rem',
    marginLeft: '1vh',
    marginRight: '1vh',
    marginBottom: '5vh',
  }
});

const Dashboard = () => {
  const [value, setValue] = React.useState(0);
  const [activePage, setActivePage] = React.useState(1);
  const [reportsFilter, setReportsFilter] = useState({
    ticketType: "",
    fromDate: "",
    toDate: ""
  });
  const classes = useStyles();
  const history = createBrowserHistory();
  const { dashboardTableHeaders, commissionReportsData, totalCommission, totalRecords, reportsInsightTableHeaders, dashboardReportsData, currentPage } = useSelector((state) => state.dashboard);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const dispatch = useDispatch();
  const params = useParams();
  const pageMode = window.location.pathname?.includes('/reports') ? '/reports' : '/commission-report';

  useEffect(() => {
    dispatch({ type: "getTicketTypeAction" });
  }, []);

  useEffect(() => {
    if (pageMode === '/reports')
      applyFilter();
    else
      dispatch({ type: "getCommissionReportsAction", page: activePage });
  }, [pageMode]);

  useEffect(() => {
    applyFilter();
  }, [activePage]);

  useEffect(() => {
    if (pageMode === '/reports') {
      setValue(0);
    } else {
      setValue(1);
    }
  }, [pageMode]);

  const handleChange = (event, newValue) => {
    if (newValue === 1) {
      history.push(`/commission-report`);
      setActivePage(0);
    } else {
      history.push(`/reports`);
      setActivePage(0);
    }
    setValue(newValue);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const paginate = (pageNumber) => {
    setActivePage(pageNumber);
  }

  const applyFilter = (fromFilter) => {
    if (fromFilter) {
      setActivePage(1);
    }
    dispatch({
      type: "getAdminReportAction",
      data: reportsFilter,
      page: fromFilter ? 1 : activePage
    })
  }

  return (
    <Grid className={classes.mainContainer} >
      {/* <Header /><br /> */}
      <Grid className={classes.gridContainer}>
        {/* <h5>Reports</h5> */}
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Reports" {...a11yProps(0)} />
              <Tab label="Commission Report" {...a11yProps(1)} />
            </Tabs>
          </Box>
        </Box>
        {pageMode === '/commission-report' ? <ReportInshightHeader data={commissionReportsData} /> : <ReportHeader pageMode={pageMode} filter={reportsFilter} setFilter={setReportsFilter} applyFilter={applyFilter} />}
        {pageMode === '/commission-report' && <div className='mb-3'>
          <span>Total Commission Amount :</span> <span className='font-bold'>{totalCommission}</span>
        </div>}
        <DashboardTable pageMode={pageMode} headers={pageMode === '/commission-report' ? reportsInsightTableHeaders : dashboardTableHeaders} data={pageMode === '/commission-report' ? commissionReportsData : dashboardReportsData} />
      </Grid>
      {pageMode === '/reports' &&
        <div className='justify-end mr-10 pb-10 flex'>
          <Paginate totalRecords={totalRecords} activePage={activePage} paginate={paginate} pageMode={pageMode} />
        </div>
      }
    </Grid>
  );
};
export default Dashboard;