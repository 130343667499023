import { useTheme } from '@mui/material/styles';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { Loader } from './Shared/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { loading, setLoaderInitialState } from 'redux/Loader/LoaderSlice';

const Layout = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const { loadingArray } = useSelector((state) => state.loader);
  const [openLoader,setOpenLoader] = useState(true);
  const dispatch = useDispatch();
  useEffect(()=>{
    if(loadingArray.length>0){
      setOpenLoader(true);
    }else{
      setOpenLoader(false);
    }
  },[loadingArray]);

 useEffect(()=>{
  if(!openLoader){
      dispatch({
          type:setLoaderInitialState.type
      });
  }
 },[openLoader]);
 
  return (
    <Box>
      <Loader isOpen={openLoader} content={"Loading..."} />
      <Grid container>
        {!isMobile && <Grid item xs={.64}>
          {/* <CssDrawer variant='permanent' /> */}
        </Grid>}
        <Grid item xs={12}>
          {/* <Header /> */}
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Layout;