import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '../../../assets/icons/CloudUpload.svg';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  Container: {
    borderColor: '#BEBEBE',
    borderStyle: 'dashed',
    backgroundColor: '#F1F1F1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  cancelIcon: {
    position: 'absolute',
    color: 'red',
    cursor: 'pointer',
  },
  fileItems: {
    maxWidth: '60px',
    maxHeight: '60px',
    marginTop: '0.5rem',
    marginLeft: '1rem'
  },
  fileName: {
    display: 'contents',
    maxWidth: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginTop: '1rem'
  },
}))

const Dropzone = ({ acceptedTypes,mediaThumbnail,handleRemoveBanner, maxSize, onDrop, acceptedfileSizeText, height, width}) => {
  const classes = useStyles();
  const [hoveredFile, setHoveredFile] = useState(null);
  
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [prevAcceptedFiles, setPrevAcceptedFiles] = useState([]);
  useEffect(() => {
    setPrevAcceptedFiles(acceptedFiles);
  }, [acceptedFiles]);

  const handleRemoveImage = (event, file) => {
    event.stopPropagation();
    const updatedFiles = acceptedFiles.filter((f) => f !== file);
    console.log('Before:', acceptedFiles);
    console.log('After:', updatedFiles);
    setAcceptedFiles(updatedFiles);
    // setValue((prevState) => ({
    //   ...prevState,
    //   mediaId: 0
    // }));
  };

  useEffect(() => {
    console.log('Accepted Files::::', acceptedFiles);
  }, [acceptedFiles]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedTypes,
    maxSize: maxSize,
    onDrop: (droppedFiles) => {
      const updatedFiles = [...acceptedFiles, ...droppedFiles];
      setAcceptedFiles(updatedFiles);
      if (onDrop) {
        onDrop(updatedFiles);
      }
    },
    acceptedfileSizeText: acceptedfileSizeText,
    height: height,
    width: width,
    multiple: false,
  });

  let fileItems = acceptedFiles.map((file) => (
    <p key={file.path} onMouseEnter={() => setHoveredFile(file)}
      onMouseLeave={() => setHoveredFile(null)}><b className={classes.fileName} title={file.name}>
        {file.name.length > 16 ? file.name.substr(0, 16) + '...' : file.name}
      </b></p>

  ));
  

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => acceptedFiles.forEach(file => URL.revokeObjectURL(file.name));
  }, []);

  const imageItems = acceptedFiles.map((file) => (
    <div key={file.path} style={{ position: 'relative' }}>
      <img
        src={URL.createObjectURL(file)}
        alt={file.name}
        className={classes.fileItems}
      />
      <CloseIcon
        className={classes.cancelIcon}
        onClick={(event) => handleRemoveImage(event, file)}
      />
    </div>
  ));
  
  const uploadedThumbnail = mediaThumbnail&&mediaThumbnail.length>0&&mediaThumbnail.map((thumbnail)=>{
    return(
      <div key={thumbnail} style={{ position: 'relative' }}>
        <img
          src={thumbnail}
          className={classes.fileItems}
        />
        <CloseIcon
          className={classes.cancelIcon}
        onClick={() => handleRemoveBanner(true)}
        />
      </div>
    )
  })
  return (
    <>
      <div {...getRootProps()} style={{ height: height, width: width }} className={classes.Container} >
        <input  {...getInputProps()} onClick={(event) => event.stopPropagation()} type="file" accept={acceptedTypes} />
        <img src={CloudUploadIcon} />
        <div><b>Drag and drop banner here , or</b> <span style={{ color: '#267ABE' }}>Upload Photo</span></div>
        <br />
        <span>{acceptedfileSizeText}</span>
        <div className={classes.fileName}>{uploadedThumbnail}</div>
        <div className='d-flex' style={{ alignItems: 'center', }}> {acceptedFiles.length > 0 ? (
          <div className={classes.fileName}>{fileItems}</div>
        ) : null}<span>{imageItems}</span></div>
      </div>
    </>
  );
}

export default Dropzone;
