import { makeStyles } from '@mui/styles';
import React from 'react';
import PlayerAPI from 'ibm-video-streaming-web-player-api';
import { useEffect } from 'react';

const useStyles = makeStyles({
    videoPlayer: {
        position: 'relative',
        overflow: 'hidden',
        borderRadius: "20px",
        height: "400px",
        marginTop: "2rem",
        '@media (max-width:600px)': {
            position: 'relative',
            overflow: 'hidden',
            borderRadius: "20px",
            height: "400px",
            margin: "0 0.8rem"
        }
    }
});

const VideoStreamingPlayer = () => {
    const classes = useStyles();

    useEffect(() => {
        let viewer = PlayerAPI('PlayerIframe');
        // // viewer.callMethod('load', 'video', 132796426);
        // viewer.callMethod('play');
        // viewer.callMethod('load', 'channel', "dTFRL6KRByD");
    }, []);

    return (
        <div className={classes.videoPlayer}>
            {/* <iframe
                id="PlayerIframe"
                src={`https://video.ibm.com/embed/25066080`}
                // style="border: 0;"
                webkitallowfullscreen={"true"}
                allowFullScreen
                frameBorder="no"
                width="720"
                height="405"
                referrerPolicy="no-referrer-when-downgrade"
            >
            </iframe> */}
            <iframe id="PlayerIframe" src="https://video.ibm.com/embed/25076883?hideShare" style={{ border: 0 }} webkitallowfullscreen="true" allowFullScreen={true} frameBorder="no" width="100%" height="400" referrerPolicy="no-referrer-when-downgrade"></iframe>
            {/* <iframe
                id="iframeId"
                src="http://www.youtube.com/embed/xDMP3i36naA"
                width="100%"
                position="absolute"
                height="400vh"
                allowfullscreen
                webkitallowfullscreen
            >
            </iframe> */}
        </div>
    );
}
export default VideoStreamingPlayer;