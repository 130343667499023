import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  Divider,
  TextField,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import { find } from "lodash";
import AddRemoveItem from "./AddRemoveItem";
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from "react-i18next";
import { setCouponCode } from "redux/EventManagement/EventManagementSlice";
import { createBrowserHistory } from "history";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { capitalize } from "utils";

const coupon = {
  fontWeight: "bold",
  fontSize: "13px",
  cursor: "pointer",
  "@media (max-width: 500px)": {
    margin: "10px 0 0 -188px",
  },
  float: "right",
  margin: "12px 0px 0px -500px",
  position: "absolute",
};
const useStyles = makeStyles({
  label: {
    fontWeight: "600",
    fontSize: "0.8rem",
    color: "#756F86",
  },
  paper: {
    borderRadius: 6,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    "& li": {
      fontWeight: 200,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: "12px",
    },
    "& li.Mui-selected": {
      width: "100%",
    },
    "& li.Mui-selected:hover": {
      width: "100%",
    },
    "& li:hover": {
      width: "100%",
    },
  },
  dollar: {
    position: "absolute",
    padding: "15px 0px 0 9px",
  },
  applyCoupon: {
    fontWeight: "bold",
    fontSize: "13px",
    cursor: "pointer",
    color: "#4688fc",
  },
  appliedCoupon: {
    fontWeight: "bold",
    fontSize: "13px",
    cursor: "pointer",
    color: "#70c44e",
  },
  crossIcon: {
    fontWeight: "bold",
    fontSize: "13px",
    cursor: "pointer",
    // margin: "13px 0px 0px -541px",
    fontSize: "18px !important",
    color: "#1c6afc",
    "@media (max-width: 500px)": {
      margin: "12px 0 0 -227px",
    },
  },
  decButton: {
    color: "#1c6afc !important",
  },
  incButton: {
    color: "#fff !important",
    background: "#1c6afc !important",
  },
  media: {
    width: "100%",
    maxWidth: "100% !important",
    "@media (max-width: 500px)": {
      margin: "20px 20px 20px 20px",
      minHeight: "140px !important",
    },
  },
  buyButton: {
    width: "100%",
    backgroundColor: "#1c6afc !important",
  },
  cartButton: {
    width: "100%",
    border: "1px solid #1c6afc !important",
    background: 'transparent !important',
    color: "#1c6afc !important",
    marginRight: '1rem !important'
  }
});

const TicketDonation = (props) => {
  const { t, i18n } = useTranslation();
  const {
    formContent,
    eventData,
    buyNowText,
    getSubTotalAmout=()=>{},
    setValues,
    values,
    count,
    setCount,
    setDiscountAmount,
    setEventPriceId,
    setOfferId,
    setValidCouponCode,
    cardValidationView,
    totalPrice,
    setSelectedTicket,
    getTicketType,
    cartTextValue
  } = props;
  const classes = useStyles();
  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper,
    },
  };
  const { errorState, couponData, couponCode, eventBookingDetails } = useSelector((state) => state.event);

  const [ticketType, setTicketType] = useState("");
  const [cartText,setCartText] = useState("Add To Cart")
  // const [selectedTicket,setSelectedTicket] = useState({});
  const [tempCoupon, setTempCoupon] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const {cartItems} = useSelector((state)=>state.cart);
  // const handleChange = (prop) => (event) => {
  //   const { value, name } = event.target;
  //   if ((/^[0-9]+$/.test(value) && name === "donateAmount") || value === "") {
  //     if (value.length <= 6)
  //       setValues({ ...values, [prop]: value });
  //   } else if (name !== "donateAmount") {
  //     dispatch({
  //       type:setCouponCode.type,
  //       payload:value
  //     });
  //     setValues({ ...values, [prop]: value });
  //   }
  // };
  const handleTicketChange = (event) => {
    let ticketTypeId;
    if (typeof event !== "object") {
      ticketTypeId = event;
    } else {
      let { value } = event?.target;
      ticketTypeId = value;
    }
    let obj = find(eventData?.priceList, { priceId: ticketTypeId });
    if (obj) {
      obj = { ...obj, eventId: eventData?.eventId };
    }
    setSelectedTicket(obj);
    getSubTotalAmout(obj);
    setTicketType(ticketTypeId);
    setEventPriceId(ticketTypeId);
  };

  useEffect(()=>{
    if(cartItems.length){
      handleTicketChange(cartItems[0]?.priceId);
    }
  },[cartItems]);

  useEffect(()=>{
    getTicketType(ticketType);
  },[ticketType,cartItems]);
  // const cancelCoupon = () => {
  //   setDiscountAmount(0);
  //   setTempCoupon(false);
  //   dispatch({
  //     type:setCouponCode.type,
  //     payload:""
  //   });
  //   setValues({ ...values, 'discountCode': '' });
  // }
  // const applyCoupon = () => {
  //   dispatch({
  //     type: "getVerifyCouponCodeAction",
  //     data: {
  //       eventId: localStorage.getItem("eventId"),
  //       couponCode: values.discountCode,
  //       priceId: ticketType,
  //     },
  //   });
  // };
  // const handleAddToCart = () => {  
  //   if(cartItems.length>0){
  //    let duplicateItem = cartItems.some(obj => obj.bookingTypeLabel === selectedTicket.bookingTypeLabel);
  //    if(!duplicateItem){
  //     dispatch({
  //       type:'addToCartAction',
  //       data:{
  //         selectedTicket,
  //         eventData,
  //         count,
  //         totalPrice
  //       }
  //     })
  //    }
  //   }else{
  //     dispatch({
  //       type:'addToCartAction',
  //       data:{
  //         selectedTicket,
  //         eventData,
  //         count,
  //         totalPrice
  //       }
  //     })
  //   }
  // }
  useEffect(() => {
    if (couponData?.isCouponApplied && couponCode) {
      setTempCoupon(true);
      setDiscountAmount(couponData.dicountPrice);
      setOfferId(couponData.offerId);
      setValidCouponCode(couponData.code);
    }
  }, [couponData]);

  // useEffect(()=>{
  //   let duplicateItem = cartItems.some(obj => obj.bookingTypeLabel === selectedTicket.bookingTypeLabel);
  //   setCartText(duplicateItem?"go to cart":"add to cart");
  // },[cartItems,selectedTicket]);

  useEffect(() => {
    if (eventData?.priceList?.[0]?.priceId) {
      setSelectedTicket(eventData?.priceList?.[0]);
      handleTicketChange(eventData?.priceList?.[0]?.priceId);
    }
  }, []);
  // useEffect(()=>{
  //   setValues({ ...values, 'discountCode': couponCode });
  // },[couponCode]);

  return (
    <Grid
      item
      xs={12}
      md={7}
      sx={{
        height: "auto",
      }}
      className={classes.media}
    >

      {/* <Box
        sx={{
          padding: "16px",
        }}
      > */}
      <Grid container spacing={4}>
        <Grid item lg={cartTextValue === 'add to cart'? 7.5 : 12}>
          <label className={classes.label}>{t('Ticket Type*')}</label>
          <br />
          <Select
            value={ticketType}
            onChange={handleTicketChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              height: "45",
              width: "100%",
              marginTop: "5px",
              fontSize: "12px",
            }}
            MenuProps={menuProps}
            classes={{
              select: classes.select,
            }}
          >
            {eventData?.priceList?.length &&
              eventData.priceList.map((obj, key) => {
                return (
                  <MenuItem
                    key={key}
                    value={obj.priceId}
                    sx={{ fontSize: "13px" }}
                  >
                    {capitalize(obj.bookingTypeLabel || obj.bookingType)}
                  </MenuItem>
                );
              })}
          </Select>
        </Grid>
        {
          cartTextValue === 'add to cart'&& <Grid item lg={2} mt={3.5} ml={"-22px"}>
          <AddRemoveItem
            count={count}
            setCount={setCount}
            decButton={classes.decButton}
            incButton={classes.incButton}
          />
        </Grid>
        }
      </Grid>
      {/* <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "2rem" }}> */}
        {/* {cartText==='add to cart'?<Button
          // onClick={buyNowHandle}
          className={`${classes.cartButton}`}
          variant="contained"
          onClick={handleAddToCart}
        >
          {t(cartText)}
        </Button>:<Link style={{marginRight:"1rem",width:"100%"}} to={`/events/${params.eventId}/cart`}>
        <Button
          // onClick={buyNowHandle}
          className={`${classes.cartButton}`}
          variant="contained"
          onClick={handleAddToCart}
        >
          {t(cartText)}
        </Button>
        </Link>} */}
        {/* <Button
          // onClick={buyNowHandle}
          className={`${classes.buyButton}`}
          variant="contained"
        >
          {t(buyNowText)}
        </Button>
      </Grid> */}
      <Divider
        sx={{
          marginTop: "15px",
        }}
      />
      {/* <div className={classes.discountBox}>
          {formContent?.map((el, index) => {
            return (
              <>
                <Grid className="items-center" container>
                  <Grid md={5}>
                    <label className={classes.label}>{t(el.label)}</label>
                    <br />
                    {el.name === "donateAmount" && (
                      <label className={classes.dollar}>$ </label>
                    )}
                    <TextField
                      InputProps={{
                        endAdornment: <InputAdornment position="end">
                          {(el.name === "discountCode" && tempCoupon&&couponCode)&&<IconButton
                            aria-label="toggle password visibility"
                          >
                              <CancelIcon className={classes.crossIcon} onClick={cancelCoupon} />
                          </IconButton>}
                        </InputAdornment>,
                         maxLength: 9 
                      }}
                      name={el.name}
                      fullWidth
                      onChange={handleChange(el.name)}
                      value={values[el.name]}
                      className={`mb-5 mt-1 ${el.name === "discountCode" && classes.hideText}`}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "45px",
                          width: "80%",
                          boxShadow: "0 0.8pt 1.8pt 0 #d3d3d3",
                          paddingLeft: el.name === "donateAmount" ?? "12px",
                        },
                      }}
                      type={el.name === "discountCode" ? "text" : "number"}
                      error={
                        errorState &&
                        errorState.userDetails &&
                        errorState.userDetails[el.name] &&
                        errorState.userDetails[el.name].error
                      }
                      helperText={
                        errorState &&
                        errorState.userDetails &&
                        errorState.userDetails[el.name] &&
                        errorState.userDetails[el.name].error &&
                        errorState.userDetails[el.name].errorMsg
                      }
                    />
                  </Grid>
                  <Grid md={1} sx={{ marginLeft: "-1.3rem",zIndex:1 }}>
                    {el.name === "discountCode" && !tempCoupon && (
                      <label className={classes.applyCoupon} onClick={applyCoupon}>
                        {t('APPLY')}
                      </label>
                    )}
                    {(el.name === "discountCode" && tempCoupon&&couponCode) && (
                      <>
                        <label
                          className={classes.appliedCoupon}
                          onClick={applyCoupon}
                        >
                          {t('APPLIED')}
                        </label>
                      </>

                    )}
                  </Grid>
                </Grid>


               
              </>
            );
          })}
        </div> */}
      {/* </Box> */}
    </Grid>
  );
};

export default TicketDonation;
