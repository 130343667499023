import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import EventIcon from '@mui/icons-material/Event';
import PeopleIcon from '@mui/icons-material/People';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PaymentIcon from '@mui/icons-material/Payment';
import DiscountIcon from '@mui/icons-material/Discount';
import { useMediaQuery } from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
    activeButton: {
      backgroundColor: '#ebf2ff !important',
    } ,
    container : {
      display:'flex',
      '@media screen and (max-width: 797px) and (min-width: 768px)':{
        width:"23vw"
      }
    },
    drawerConainer: {
      '@media screen and (max-width: 992px) and (min-width: 700px)': {
        width:'23vw !important'
      },
    },
    
  }));

function SideBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [activeButton, setActiveButton] = React.useState("");
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const [value , setValue] = React.useState("");

  const MenuItems = [
  {
    label: "Events",
    Icon: <EventIcon />,
    path: "/events"
  },
  {
    label: "Artists",
    Icon: <PeopleIcon />,
    path: "/artists"
  },
  {
    label: "Reports",
    Icon: <SummarizeIcon />,
    path: "/reports"
  },
  // {
  //   label: "Payment Setup",
  //   Icon: <PaymentIcon />,
  //   path: "/payment-setup"
  // },
  // {
  //   label: "Coupon Configuration ",
  //   Icon: <DiscountIcon />,
  //   path: "/discount-code-setUp"
  // }
  ]

  const handleClick = (data) => {
    navigate(data.path);
    // setActiveButton(data.path)
  }

  useEffect(() => {
    setActiveButton(location.pathname)
  }, [location])

  return (
    <Box className={!isMobile && classes.container}>
      {!isMobile ?  
        <Box sx={{ display: 'flex' }}> 
        <Drawer
        className={classes.drawerConainer}
        variant="permanent"
        sx={{
          width: '20vw',
          position: 'relative',
          height: '100vh',
          zIndex: 'auto',
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {  position: 'relative', boxSizing: 'border-box', zIndex: 'auto' },
        }}
      >
        {/* <Toolbar /> */}
        <Box sx={{ overflow: 'auto' }}>
          <List >
            {
              MenuItems.map((data, index) => {
                return (
                  <ListItem key={index} disablePadding  >
                    <ListItemButton className={activeButton === data.path ? classes.activeButton : ""} onClick={() => handleClick(data)}>
                      <ListItemIcon>
                        {data.Icon}
                      </ListItemIcon>
                      <ListItemText primary={data.label} />
                    </ListItemButton>
                  </ListItem>
                )
              })
            }
          </List>
        </Box>
      </Drawer>
      </Box>
       :  
        <Box sx={{ display: 'flex' }}>
          <Paper sx={{ position: 'absolute', bottom: 0, left: 0, right: 0, zIndex:10 }} elevation={3}>
            <BottomNavigation
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              showLabels
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            >
              {MenuItems.map((data, index) => {
                return (
                  <BottomNavigationAction className={activeButton === data.label ? classes.activeButton : ""} onClick={() => handleClick(data)} label={data.label} icon={data.Icon} />
                )
              })}
            </BottomNavigation>
          </Paper>
        </Box>
        }
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Outlet />
      </Box>
    </Box>
   
  );
}

export default SideBar;