import React, { useEffect } from 'react'
import { makeStyles } from "@mui/styles";
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import breakpoints from 'components/Shared/MediaQueries/BreakPoints';

const useStyles = makeStyles({
    emptyArtist: {
        width: "100%",
        minHeight: "50vh",
        display: 'grid',
        placeItems: 'center',
    },
    cardImage: {
        width: "100% !important",
        height: "12rem",
        objectFit: "cover",
        marginLeft: "0rem",
        borderRadius: "1rem",
        cursor: "pointer",
        transition: "all 1s ease-in-out",
        "&:hover": {
            transform: "scale(1.09,1.09)"
        },
        [breakpoints.sm_down]:{
            width:"100%"
        },
        [breakpoints.sm_up]:{
            width:"100%"
        },
        [breakpoints.md_up]: {
            width: "288px",
        }
    },
    imageContainer: {
        width: "100%",
        height: "12rem",
        borderRadius: "0.3rem",
        position: "relative",
        overflow: "hidden",
        [breakpoints.sm_down]:{
            width:"88vw"
        },
        [breakpoints.sm_up]:{
            width:"45vw"
        },
        [breakpoints.md_up]: {
            width: "100%",
        }
    },
    cardContainer:{
        padding:"1.25rem",
        [breakpoints.sm_down]:{
            padding:"0.3rem !important"
        },
    },
    cardOverLay: {
        position: 'absolute',
        width: "auto",
        height: "60px",
        background: "rgba(38, 38, 38, 0.65)",
        bottom: "0.5rem",
        left: "0.5rem",
        padding: "4px 8px",
        borderRadius: "0.3rem"
    },
    artistName: {
        color: "#fff",
        fontWeight: "500",
    },
    totalEvents: {
        color: "#fff",
        fontWeight: "400",
        fontSize: "0.9rem",
    },
});

function Card(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    const { artistList, loading } = props;

    return (
        <Grid container columnSpacing={{ xs: 2, sm:5, md: 5 }} className={`mb-5 ${classes.cardContainer}`}>
            {artistList?.map((artist,index) => {
                return <Grid key={index} item lg={4} md={4} sm={6} xs={12} className='mt-3 py-2 flex justify-center'>
                    <Link to={`/artist/${artist.artistId}`} style={{width:'100%'}} >
                        <Grid className={classes.imageContainer}>
                            <img className={classes.cardImage} src={artist?.mediaUrl} />
                            <div className={classes.cardOverLay}>
                                <div className={classes.artistName}>{artist?.fullname}</div>
                                <div className={classes.totalEvents}>{artist?.eventCount} {t("Events")}</div>
                            </div>
                        </Grid>
                    </Link>
                </Grid>
            })}
            {(!loading&&artistList?.length===0)&&
                <div className={classes.emptyArtist}>
                    <h4 className='text-center'>{t("Artists Not Found")}</h4>
                </div>
            }
        </Grid>
    )
}

export default Card;