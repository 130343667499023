import { createSlice } from '@reduxjs/toolkit';
import CloseIcon from '@mui/icons-material/Close';

const initialState = {
    cartItems:[],
    cartTableHeaders: [
        { key: 'actionHeader', name: 'REMOVE', icon: <CloseIcon/> },
        { key: 'eventName', name: 'CONCERT', hasSorting: false },
        {key:'bookingTypeLabel',name:'TICKET TYPE',hasSorting:false},
        { key: 'price', name: 'PRICE', hasSorting: false, multiChipView: true, displayAssignLink: false },
        { key: 'quantity', name: 'QUANTITY', hasSorting: false, multiChipView: true },
        { key: 'total', name: 'TOTAL' },
    ],
}

const cartManagementSlice = createSlice({
    name: 'cartInfo',
    initialState,
    reducers: {
        setCartInitialState(state, action) {
            state.cartItems = initialState.cartItems;
        },
        setCartItems(state,action){
            let data = action.payload;
            if(data.length>0){
                state.cartItems = data;
            }else{
                state.cartItems = initialState.cartItems;
            }
         },
         updateCartItems(state,action){
            let data = action.payload;
            const {cart,key} = data;
            let updateCartItem = {...cart}
            if(key==='add'){
                updateCartItem['quantity'] = updateCartItem['quantity']+1;
                updateCartItem['total'] = parseFloat(parseInt(updateCartItem['quantity']) * parseFloat( updateCartItem['price'])).toFixed(2);
                const index =  state.cartItems.findIndex(cartItem =>cartItem.bookingTypeLabel === cart.bookingTypeLabel);
                if (index !== -1) {
                    state.cartItems[index] = updateCartItem;
                  }
            }else if(key==='remove'){
                const index =  state.cartItems.findIndex(cartItem =>cartItem.bookingTypeLabel === cart.bookingTypeLabel);
                if(state.cartItems.length === 1){
                    state.cartItems = [];
                }else{
                    if (index !== -1) {
                        state.cartItems.splice(index,1);
                      }
                }
            }else{
                if(updateCartItem['quantity']!==1){
                    let singleTicketValue =  parseFloat(parseFloat(updateCartItem['total'])/parseInt(updateCartItem['quantity'])).toFixed(2)
                    updateCartItem['quantity'] = updateCartItem['quantity']-1;
                    updateCartItem['total'] = parseFloat(parseInt(updateCartItem['quantity']) * parseFloat(singleTicketValue)).toFixed(2);
                    const index =  state.cartItems.findIndex(cartItem =>cartItem.bookingTypeLabel === cart.bookingTypeLabel);
                    if (index !== -1) {
                        state.cartItems[index] = updateCartItem;
                      }
                }
            }
         },
    },
});

export default cartManagementSlice.reducer;

// Actions
export const {
    setCartInitialState,
    setCartItems,
    updateCartItems
} = cartManagementSlice.actions;
