import { config } from 'config.js';
import { xhrClient } from 'views/utilities/DataRequest';
const { isProd } = config;
const API_ENDPOINT = isProd
    ? config.production.api_endpoint
    : config.development.api_endpoint;

const addToCartService = async (data) => {
    return xhrClient.post(`${API_ENDPOINT}/createAddtoCart`, null, data);
}
const getCartService = async()=>{
    return xhrClient.get(`${API_ENDPOINT}/get-user-carts`);
}
const removeCartService = async(eventId)=>{
    return xhrClient.delete(`${API_ENDPOINT}/delete-cart?eventId=${eventId}`);
}

export {
    addToCartService,
    getCartService,
    removeCartService
};