import { config } from 'config.js';
import { xhrClient } from 'views/utilities/DataRequest';
const { isProd } = config;
const API_ENDPOINT = isProd
    ? config.production.api_endpoint
    : config.development.api_endpoint;

const getTicketsService = ()=>{
    return xhrClient.get(`${API_ENDPOINT}/user/tickets`);
}
const shareTicketService = (data)=>{
    return xhrClient.put(`${API_ENDPOINT}/share-ticket`,null,data);
}
const getRedeemTicketService = (data) => {
    return xhrClient.post(`${API_ENDPOINT}/redeem-ticket?ticketId=${data.ticketId}`, null);
}
export {
    shareTicketService,
    getTicketsService,
    getRedeemTicketService
}