import {
  TextField,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTranslation } from "react-i18next";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { setInitialState } from "redux/AuthManagement/AuthMangementSlice";

const useStyles = makeStyles({
  label: {
    fontWeight: "600",
    fontSize: "0.8rem",
    color: "#756F86",
  },
  labelError: {
    fontWeight: "600",
    fontSize: "0.8rem",
    color: "#D32F2F",
  },
  formButton: {
    width: "100%",
    backgroundColor: "#616161 !important",
    textTransform: "capitalize !important",
  },
  formLink: {
    textDecoration: "underline",
  },
  helperText: {
    width: "292px",
  },
});

const stateMessage = "Not required for Other countries Except USA";

function SignUpForm(props) {
  const queryParams = new URLSearchParams(window.location.search);
  const isTicket = queryParams.get("isTicket");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { signUpContent, basicDetailsContent, hasFormSubHeadingLink } = props;
  const [values, setValues] = useState({
    fullName: "",
    emailId: "",
    password: "",
    confirmPassword: "",
    ticketCode: "",
    isChecked: false,
  });
  const [formDetails, setFormDetails] = useState([]);
  const [buttonText, setButtonText] = useState("");
  const [disableButton, setDisableButton] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = useDispatch();
  const { errorState, isDetailsPage, isSignup, countryRecords, stateRecords } =
    useSelector((state) => state.auth);
  const { userDetails } = errorState;
  const classes = useStyles();

  useEffect(() => {
    setFormDetails(signUpContent);
    setButtonText("Sign Up");
    if (
      isTicket === "0" &&
      (values.emailId &&
      values.fullName &&
      values.password &&
      values.confirmPassword &&
      values.isChecked &&
      !userDetails.confirmPassword.error &&
      !userDetails.emailId.error &&
      !userDetails.fullName.error &&
      !userDetails.password.error &&
      (values.password === values.confirmPassword)) ||
      isTicket === "1" && (
      values.emailId &&
      values.fullName &&
      values.password &&
      values.confirmPassword &&
      values.isChecked &&
      !userDetails.confirmPassword.error &&
      !userDetails.emailId.error &&
      !userDetails.fullName.error &&
      !userDetails.password.error &&
      (values.password === values.confirmPassword) &&
      values.ticketCode
      )
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [values]);
  
  let obj = signUpContent.find((o) => o.name === "ticketCode");
  if (isTicket === "1" ) {
    if (!obj) {
      signUpContent.push({
        label: "Ticket ID*",
        name: "ticketCode",
      });
    }
  } else if (isTicket === "0" ) {
    if(obj){
      signUpContent.pop({
        label: "Ticket ID*",
        name: "ticketCode",
      });
    }
  }


  const handleChange = (prop) => (event) => {
    const { value, name } = event.target;
      setValues({ ...values, [prop]: value.trimStart() });
      if (["emailId", "fullName", "password", "confirmPassword"].includes(name)) {
        dispatch({
          type: "setAuthenticationFormAction",
          name,
          value,
        });
      }
  };
  const handleSubmit = (event) => {
      event.preventDefault();
      dispatch({
        type: "setAuthenticationAction",
        data: {
          fullName: values.fullName,
          emailId: values.emailId,
          password: values.password,
          ticketCode: values.ticketCode,
        },
        requestPage: "SignUp",
      });
      // setDisableButton(true);
  };

  useEffect(() => {
    if(isSignup) {
      navigate("/email-verification");
    }
  },[isSignup])

  const isChecked = (e) => {
    setValues({ ...values, isChecked: e.target.checked });
  };

  const handleClickShowPassword = (name) => {
    if (name === 'password') {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  }
  useEffect(()=>{
    dispatch({
        type:setInitialState.type
    })
  },[]);
  return (
    <div className="p-3 mt-3" style={{height:'200px'}}>
     <form onSubmit={handleSubmit}>
      <div className="flex">
        <h3 style={{ whiteSpace: "nowrap" }}>{t(isTicket === "1" ? "Sign up to watch Concert" : "Sign up to buy tickets")}</h3>
      </div>
      <span className="text-12 font-medium text-gray">
        {t("Already have an account?")}
      </span>
      {hasFormSubHeadingLink && (
        <Link
          className={`${classes.formLink} text-12 link-color font-medium px-sm-9`}
          to="/login"
          undeline='true'
        >
          {t("Sign in")}
        </Link>
      )}
      <br />
      {hasFormSubHeadingLink && (
        <Link
          className={`${classes.formLink} text-12 link-color font-medium`}
          to={`/signup?isTicket=${isTicket === "1" ? 0 : 1}`}
          undeline='true'
        >
          {t(isTicket === "1" ? "Sign up to buy tickets" : "Sign up with ticket ID")}
        </Link>
      )}
      <div className="mt-2">
        {formDetails.map((el, index) => {
          return (
            <div key={index}>
              <label
                className={`${errorState.userDetails[el.name]?.error
                  ? classes.labelError
                  : classes.label
                  }`}
              >
                {t(el.label)}
              </label>
              <br />

              <TextField
                name={el.name}
                fullWidth
                onChange={handleChange(el.name)}
                className="mb-2 mt-0"
                sx={{
                  "& .MuiInputBase-root": {
                    height: 45,
                    border: "1px solid #DBE2EA",
                  },
                }}
                value={values[el.name]}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    {(['password', 'confirmPassword'].includes(el.name)) && <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword(el.name)}
                    // onMouseDown={handleMouseDownPassword}
                    >
                      {!(showPassword && el.name === "password" || showConfirmPassword && el.name === "confirmPassword") ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                    </IconButton>}
                  </InputAdornment>,
                }}
                type={
                  (!showPassword && el.name === "password" || !showConfirmPassword && el.name === "confirmPassword") ? "password"
                   : "text"
                }
                error={
                  (errorState &&
                    errorState.userDetails &&
                    errorState.userDetails[el.name] &&
                    errorState.userDetails[el.name].error) ||
                  (values["password"] !== values["confirmPassword"] &&
                    values["confirmPassword"] !== "" &&
                    values["password"] !== "")
                }
                helperText={
                  (errorState &&
                    errorState.userDetails &&
                    errorState.userDetails[el.name] &&
                    errorState.userDetails[el.name].error &&
                    t(errorState.userDetails[el.name].errorMsg)) ||
                  (["confirmPassword"].includes(el.name) &&
                    values["password"] !== values["confirmPassword"] &&
                    values["confirmPassword"] !== "" &&
                    values["password"] !== "" &&
                    t(errorState.userDetails[el.name].errorMsg))
                }
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
              >
              </TextField>
              <br />
            </div>
          );
        })}
          <FormGroup>
            <FormControlLabel
              className="text-gray font-medium"
              control={<Checkbox required />}
              checked={values.isChecked}
              onChange={isChecked}
              label={t("By signing up, I agree to the terms.")}
            />
          </FormGroup>
        <Button
          disabled={disableButton}
          type="submit"
          className={`${classes.formButton} pt-2 pb-2 mb-3 right-gradient flex items-center `}
          variant="contained"
        >
          {t(buttonText)}
          <ArrowForwardIcon className="mb-1 ml-5 text-20" />
        </Button>
        <br />
      </div>
      </form>
    </div>
  );
}

export default SignUpForm;
