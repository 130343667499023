import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircleSharp";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
    overflowY:'clip'
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    width: "330px",
    borderRadius: "10px",
    height: "16em",
  
  },
}));


const ModalPopup = (props) => {

  const useStyles = makeStyles({
    cancelButton:{
     margin:'4% 0 0 80%',
     cursor:'pointer'
    }
  });

  const {
    isOpen,
    modalData: { title, content, buttons },
    url,
    setShowWarningPopup,
    hideCheckBox,
    onClose = () => { }
  } = props;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(isOpen);
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
    if(setShowWarningPopup){
      setShowWarningPopup(false);
    }
    if (onClose) onClose();
    navigate(url);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        style={{overflowY:'clip'}}
        open={open}
      >
        <Grid sx={{ display: "flex", justifyContent: "center" }}>
          {!hideCheckBox&&<CheckCircleIcon
            sx={{
              fontSize: "47px",
              color: "#10BA10",
              margin: "19px 0 -14px 0",
            }}
          />}
          {hideCheckBox&&<CloseIcon style={{color:'gray'}} onClick={handleClose} className={classes.cancelButton}/>}
        </Grid>

        <DialogContent>
          <Typography gutterBottom variant="h6" align="center">
            {t(title)}
          </Typography>
          <Typography gutterBottom variant="body2" align="center">
            {t(content)}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "2px",
          }}
        >
        {buttons.map((button)=>{
          return(
          <Button
            autoFocus
            onClick={button.handler?button.handler:handleClose}
            className={button.style}
            sx={{ width: "35%" }}
          >
            {t(button.text)}
          </Button>
          )
        })}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
export default ModalPopup;
