
import { config } from 'config.js';
import { xhrClient } from 'views/utilities/DataRequest';
const { isProd } = config;
const API_ENDPOINT = isProd
    ? config.production.api_endpoint
    : config.development.api_endpoint;
 
const getAllEventsService=()=>{
    return xhrClient.get(`${API_ENDPOINT}/events`);
}
const getAllAdminEventsService=(data)=>{
    const {pageLimit,pageNumber} = data;
    return xhrClient.get(`${API_ENDPOINT}/admin-events?pageLimit=${pageLimit}&pageNumber=${pageNumber}`);
}
const uploadMediaService =(payload) =>{
    const{file,mediaType} = payload;
    return xhrClient.post(`${API_ENDPOINT}/uploadMedia?mediaType=${mediaType}`,null,file);
}
const setEventsService=(data)=>{
    return xhrClient.post(`${API_ENDPOINT}/events`,null,data);
}
const getMediaService = (isEvent,mediaId) => {
    return xhrClient.get(`${API_ENDPOINT}/getMedia?event=${isEvent}&mediaId=${mediaId}`,null,null,'blob');
}
const getEventService = (eventId) => {
    return xhrClient.get(`${API_ENDPOINT}/get-event?eventId=${eventId}`);
}
const getVerifyCouponService = (eventId,couponCode,priceId) => {
    return xhrClient.get(`${API_ENDPOINT}/validate-coupon?eventId=${eventId}&priceId=${priceId}&couponCode=${couponCode}`);
}
const eventBookingService = (data) => {
    return xhrClient.post(`${API_ENDPOINT}/eventbooking`,null,data);
}
const getMyTicketsService=()=>{
    return xhrClient.get(`${API_ENDPOINT}/getEventbookings`);
}

const cancelEventService = (eventId) => {
    return xhrClient.put(`${API_ENDPOINT}/cancelConcert/${eventId}`);
}
const getArtistEventsService = (artistId, pageLimit = 1000, pageNumber = 1) => {
    return xhrClient.get(`${API_ENDPOINT}/events/${artistId}?sort=ASC&pageLimit=${pageLimit}&pageNumber=${pageNumber}`);
}
const getAmountDetailsService = (addressId, eventId, quantity, couponCode) => {
    return xhrClient.get(`${API_ENDPOINT}/events/amount-details?addressId=${addressId}&eventId=${eventId}&quantity=${quantity}&couponCode=${couponCode}`);
}
const getEventByIdService = (eventId) =>{
    return xhrClient.get(`${API_ENDPOINT}/admin-events/${eventId}`);
}
export {
    getMediaService,
    getAllEventsService,
    getEventService,
    getVerifyCouponService,
    eventBookingService,
    getMyTicketsService,
    cancelEventService,
    getArtistEventsService,
    setEventsService,
    getAmountDetailsService,
    uploadMediaService,
    getEventByIdService,
    getAllAdminEventsService
}