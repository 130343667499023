import { eventChannel } from 'redux-saga';
import { takeEvery, all, put, call } from 'redux-saga/effects'
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from 'redux/slices/snackbar';
import i18n from 'i18next';
import { loading } from 'redux/Loader/LoaderSlice';
import { setCartItems, updateCartItems } from './CartManagementSlice';
import { addToCartService, getCartService, removeCartService } from './CartManagementService';
import { isLoggedIn } from 'utils';

// Actions

function* translateAndDispatchSuccess(message) {
    const translation = yield call([i18n, 't'], message);
    yield put(SNACKBAR_SUCCESS(translation));
}
function* translateAndDispatchError(message) {
    const translation = yield call([i18n, 't'], message);
    yield put(SNACKBAR_ERROR(translation));
}

export default function* cartManagementSaga() {
    function* addToCart(payload) {
        let requestId = Math.random();
        try {
          if (isLoggedIn()) {
            yield put(loading({loading:true,requestId}));
          const { data } = payload;
          const {eventId,count,actionType,selectedTicket:{bookingType}} = data;
          let cartData = {
            "bookingType": bookingType,
            "eventId": eventId,
            "quantity": count
          }
        let response = yield call(addToCartService,cartData);
          if(response){
              yield put(loading({loading:false,requestId}));
              let cart = yield call(getCartService);
                         yield put(setCartItems(cart));
              if(actionType === 'add'){
                yield translateAndDispatchSuccess("Item Added To Cart");
              }else{
                yield translateAndDispatchSuccess("Item Removed From Cart");
              }
          }
        }
        } catch (error) {
          yield put(loading({loading:false,requestId}));
          yield translateAndDispatchError(error.error);
          console.error(error.error);
        }
      }
      function* updateCart(payload) {
        try {
          const { data } = payload;
          yield put(updateCartItems(data));
        } catch (error) {
          yield translateAndDispatchError(error.error);
          console.error(error.error);
        }
      }
      function* getCartItems(){
        let requestId = Math.random();
        try {
          if (isLoggedIn()) {
            yield put(loading({loading:true,requestId}));
            let response = yield call(getCartService);
            if(response){
                yield put(setCartItems(response));
                yield put(loading({loading:false,requestId}));
            }
          }
        } catch (error) {
            yield put(loading({loading:false,requestId}));
            yield translateAndDispatchError(error.error);
            console.error(error.error);
        }
      }
      function* removeFromCart(payload){
        let requestId = Math.random();
        try {
            yield put(loading({loading:true,requestId}));
            const {data:{eventId,showMessage}} = payload;
            console.log(showMessage,"showMessage")
           let response = yield call(removeCartService,eventId);
           if(response){
            let cart = yield call(getCartService);
            yield put(setCartItems(cart));
            yield put(loading({loading:false,requestId}));
            if(showMessage){
              yield translateAndDispatchSuccess("Cart Item Removed Successfully");
            }
           }
        } catch (error) {
            yield put(loading({loading:false,requestId}));
            yield translateAndDispatchError(error.error);
            console.error(error.error);
        }
      }
      function* watchAddToCart() {
        yield takeEvery("addToCartAction", addToCart);
      }
      function* watchUpdateCart() {
        yield takeEvery("updateCartAction", updateCart);
      }
      function* watchGetCart(){
        yield takeEvery("getCartItemsAction",getCartItems);
      }
      function* watchRemoveFromCart(){
        yield takeEvery("removeFromCartAction",removeFromCart);
      }
    yield all([
        watchAddToCart(),
        watchUpdateCart(),
        watchGetCart(),
        watchRemoveFromCart()
    ])
}
