import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TicketDonation from 'views/TicketTypeDonation/components/TicketDonation';
import { useSelector } from 'react-redux';
import OrderSummary from 'views/TicketTypeDonation/components/OrderSummary';
import { useState } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function BuyTickets(props) {
    const { open, handleCloseBuyTicketPopup,selectedEventId } = props;
    const handleClose = () => handleCloseBuyTicketPopup(false);
    const { eventData, eventBookingDetails } = useSelector((state) => state.event);
    const [orderSummary,setOrderSummary] = useState({});
    const [eventPriceId, setEventPriceId] = useState("");
    const orderSummaryText = {
        orderSummaryTitle: "ORDER SUMMARY",
        ticketType: "M-Ticket",
        buyNowText: "Buy Now",
        cartText:"Add To Cart",
        subTotalText: "Subtotal",
    };
   
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{display:'grid',placeItems:'center'}}
            >
                <>
                <OrderSummary
                    orderSummaryText={orderSummaryText}
                    eventData={eventData}
                    values={""}
                    orderSummary={""}
                    setOrderSummary={setOrderSummary}
                    count={""}
                    discountAmount={""}
                    buyNowHandle={(count) => handleCloseBuyTicketPopup(true, eventPriceId, count)}
                    setEventPriceId={setEventPriceId}
                    eventPriceId={eventPriceId}
                    selectedEventId={selectedEventId}
                    handleClose={handleClose}
                />
                </>
            </Modal>
        </div>
    );
}