
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import Footer from 'views/Footer';
import Header from 'views/Header';
import SideBar from 'views/SideBar/SideBar';
import { isAdmin } from 'utils';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import ConfirmModal from 'components/Shared/ConfirmModal';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  // container: {
  //   display: "flex",
  //   flexDirection: "column",
  //   minHeight: "90.5vh",
  //   marginTop:'9.5vh'
  // }
});

function PrivateRoute() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const AccessToken = localStorage.getItem("accessToken");
  const isEmailVerified = localStorage.getItem("verificatonStatus") !== "EMAIL_NOT_VERIFIED";
  const {admin} = useSelector((state)=>state.auth);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const classes = useStyles();
  const [openConfirmModal, setOpenConfimModal] = useState(false);

  useEffect(()=>{
    if(!(AccessToken && isEmailVerified)) {
      setOpenConfimModal(true);
    }
  },[AccessToken,isEmailVerified])

  const searchParams = new URLSearchParams(location.search);
  const ticketParam = searchParams.get('tkt');
  const redirectUrl = ticketParam ? `/login?tkt=${ticketParam}` : ticketParam === null && "/login";

  const handleredirect=()=>{
    setOpenConfimModal(false);
    navigate(redirectUrl + `?redirect=${encodeURIComponent(location.pathname + location.search)}`);
  }

  const handleClose=()=>{
    setOpenConfimModal(false);
    navigate('/');
  }

  return (
    (isAdmin()?(AccessToken):(AccessToken && isEmailVerified)) ? <>
      <div >
        {(isAdmin() || admin) && window.location.pathname != '/event-creation' && 
          <div>
            <SideBar/>
          </div>}
        {!(isAdmin() || admin) &&
        <div className={classes.container}>
          <Outlet />
        </div>}
      </div>
    </> 
    :
    <ConfirmModal open={openConfirmModal} content={t("Please Sign in to access this page")} buttonText={t("Sign In")} handleConfirm={handleredirect} handleClose={handleClose} />
  )
}

export default PrivateRoute;