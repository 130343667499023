import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircleSharp";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Cancel } from "@mui/icons-material";
import { closeErrorModal } from "redux/ErrorModal/ErrorModalSlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    width: "330px",
    borderRadius: "10px",
    height: "16em",
  },
}));

const ErrorModal = ({ }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  // const [open, setOpen] = React.useState(isOpen);
  const { open, title, content, buttonText } = useSelector((state) => state.errorModal);

  const handleClose = () => {
    dispatch(closeErrorModal())
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Grid sx={{ display: "flex", justifyContent: "center" }}>
          <Cancel
            sx={{
              fontSize: "47px",
              color: "red",
              margin: "19px 0 -14px 0",
            }}
          />
        </Grid>

        <DialogContent>
          <Typography gutterBottom variant="h6" align="center">
            {t(title)}
          </Typography>
          <Typography gutterBottom variant="body2" align="center">
            {t(content)}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "2px",
          }}
        >
          <Button
            autoFocus
            onClick={handleClose}
            className={`mb-1 right-gradient text-white flex items-center`}
            // sx={{ width: "35%" }}
            size="small"
          >
            {buttonText}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
export default ErrorModal;
