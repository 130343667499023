import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(3),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
    "& .MuiPaper-root": {
        width: "330px",
        borderRadius: "10px",
        // height: "16em",
    },
}));

const ConfirmModal = ({ open, buttonText, content, handleConfirm, handleClose }) => {
    const { t, i18n } = useTranslation();

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >

                <DialogContent>
                    <Typography sx={{ fontSize: 15 }} gutterBottom variant="body2" align="center">
                        {t(content)}
                    </Typography>
                </DialogContent>
                <DialogActions
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "2px",
                    }}
                >
                    <Button
                        autoFocus
                        onClick={handleClose}
                        className={`mb-1 text-11 flex items-center`}
                        size="small"
                        variant="outlined"
                    >
                        {t("Cancel")}
                    </Button>
                    <Button
                        autoFocus
                        onClick={handleConfirm}
                        className={`mb-1 text-11 right-gradient text-white flex items-center`}
                        // sx={{ width: "35%" }}
                        size="small"
                    >
                        {buttonText}
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
};

export default ConfirmModal;
