import React, { useEffect, useState } from "react";
import { Button, FormLabel, Grid, TextField, Pagination, TablePagination, Select, MenuItem, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DataTable from "components/Shared/Table";
import GlobalDrawer from "components/Shared/Drawer";
import Dropzone from "components/Shared/FileUpload";
import ConfirmModal from "components/Shared/ConfirmModal";
import { useTranslation } from "react-i18next";
import { ExpandMore, FirstPage, LastPage, NavigateBefore, NavigateNext } from '@mui/icons-material';

const useStyles = makeStyles({
  mainContainer: {
    width: "100%",
    minHeight: "75vh",
    padding: "0.5rem 0",
    border: "1px solid #ededed",
    boxShadow: "0px 1px 6px 1px #ededed",
    borderRadius: "6px"
  },
  gridContainer: {
    // marginTop: '3rem',
    marginLeft: '1vh',
    marginRight: '1vh',
    marginBottom: '5vh',
  },
  closeButton: {
    marginLeft: 'auto',
    marginTop: '2rem',
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
  createArtistText: {
    marginLeft: '1rem',
    marginTop: '2rem',
  },
  divider: {
    height: '0.2rem !important',
  },
  artistDescription: {
    width: '48rem'
  },
  footer: {
    position: 'absolute !important',
    // top:'100% !important'
  },
  deletePopupContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '30%',
    height: '0%',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  descriptionLabel: {
    '@media only screen and (max-width: 600px)': {
      marginLeft: '0.25rem !important'
    },
  },
  errorMessage: {
    color: '#ff0000',
    fontSize: 12,
    margin: '3px 0 0 2px'
  },
  pagination: {
    '& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar': {
      display: 'flex',
      alignItems: 'end',
    },
    '& .css-1cgewji-MuiInputBase-root-MuiTablePagination-select': {
      marginBottom: '0.5rem'
    },
    '& .css-levciy-MuiTablePagination-displayedRows': {
      margin: 'auto 1rem'
    }
  },
});

const validations = {
  artistName: { isRequired: true, requireMsg: 'Artist Name is required' },
  artistDescription: { isRequired: true, requireMsg: 'Artist Description is required' },
  mediaId: { isRequired: true, requireMsg: 'Please upload profile photo' },
}

function Artists() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const { t, i18n } = useTranslation();
  const { artistsTableHeaders, adminArtistList } = useSelector((state) => state.artist);
  const { eventMediaUrl } = useSelector((state) => state.event);
  const value = { adminArtistList }
  const [pageSize, setPageSize] = useState(10);
  const [open, setOpen] = React.useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedArtist, setSelectedArtist] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [allArtistList, setAllArtistList] = useState(value)
  const [mediaId, setMediaId] = useState(0)
  const [mediaThumbnail, setMediaThumbnail] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [closeDrawer,setCloseDrawer] = useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    dispatch({
      type: "getAdminArtistListAction",
      data: {
        pageLimit: 10,
        pageNumber: (page + 1)
      },
      onSuccessCB: ({ count }) => {
        setTotalRecords(count)
      }
    });
  }, [page]);
  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: "getAdminArtistListAction",
      data: {
        pageLimit: parseInt(event.target.value, 10),
        pageNumber: 1
      },
      onSuccessCB: ({ count }) => {
        setTotalRecords(count)
      }
    });
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [errors, setErrors] = useState({});
  const [values, setValues] = React.useState({
    artistName: '',
    artistShortDescrition: '',
    artistDescription: '',
    mediaId: 0,
  });

  const dropzoneWidth = window.innerWidth >= 797 ? 450 : 345;
  // const [artistsTableData, setArtistsTableData] = useState([]);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);


  const handleOpen = () => {
    setOpen(true);
    setOpenDrawer(true);
    setValues({});
    setSelectedArtist('')
  };

  const handleClose = () => {
    setOpen(false);
    setErrors({});
    setOpenDrawer(false);
  };

  const handleChange = (prop) => (event) => {
    const { value } = event.target;
    isValidValue(prop, value);
    setValues((prevValues) => ({
      ...prevValues,
      [prop]: value
    }));
  };

  const handleDrop = (acceptedFiles) => {
    console.log(acceptedFiles);
    let data = new FormData();
    data.append('file', acceptedFiles[0], acceptedFiles[0].name);
    dispatch({
      type: "uploadMediaAction",
      data: {
        mediaType: 'ARTIST_PROFILE',
        file: data
      },
      onSuccessCB(data) {
        // console.log(data, "dataaa::::")
        isValidValue('mediaId', data);
        setValues((prevState) => ({
          ...prevState,
          mediaId: data.id
        }));
      }
    })
  };


  const isValidArtist = (artist) => {
    let errors = {};
    let isValid = true;
    Object.entries(validations)?.forEach(([key, value]) => {
      console.log('Test ', key, value, artist);
      if (value.isRequired && (artist[key] === 0 || artist[key]?.length === 0 || artist[key] == null)) {
        errors[key] = value.requireMsg;
        isValid = false;
      }
    });
    return { isValid, errors };
  };

  const isValidValue = (name, value) => {
    const { isRequired, requireMsg } = validations[name] || {};
    if (isRequired) {
      setErrors(prevState => ({ ...prevState, [name]: value ? '' : requireMsg }));
    }
  }

  const handleCreateArtist = (event) => {
    const { isValid, errors } = isValidArtist(values);
    event.preventDefault();
    if (isValid) {
      if (selectedArtist?.artistId) {
        // Editing existing artist
        const editedArtist = {
          id: selectedArtist?.artistId,
          description: values.artistDescription || '',
          fullName: values.artistName || '',
          shortDescription: values.artistShortDescrition || '',
          mediaId: values.mediaId || 0
        };
        dispatch({
          type: "setAddedArtistAction",
          payload: {
            reqPayload: editedArtist,
            onSuccessCB: (res) => {
              if(res){
                setCloseDrawer(true);
              }
            },
            onErrorCB: (err) => {
            },
          },
        });
      } else {
        // Adding new artist
        const newArtist = {
          description: values.artistDescription || '',
          fullName: values.artistName || '',
          mediaId: values.mediaId || 0,
          shortDescription: values.artistShortDescrition || '',
        };
        dispatch({
          type: "setAddedArtistAction",
          payload: {
            reqPayload: newArtist,
            onSuccessCB: (res) => {
             if(res){
              setCloseDrawer(true);
             }
            },
            onErrorCB: (err) => {
            },
          },
        });
      }
    }
    else {
      setErrors(errors);
    }
  };

  useEffect(() => {
    dispatch({
      type: "getAdminArtistListAction",
      data: {
        pageLimit: 10,
        pageNumber: 1
      },
      onSuccessCB: ({ count }) => {
        setTotalRecords(count)
      }
    });
  }, []);

  useEffect(() => {
    if(closeDrawer){
      dispatch({
        type: "getAdminArtistListAction",
        data: {
          pageLimit: 10,
          pageNumber: 1
        },
      });
      setCloseDrawer(false);
      handleClose();
    }
  }, [closeDrawer]);

  const handleAction = (userProp) => {
      setMediaId(userProp?.mediaId)
      setMediaThumbnail([userProp?.mediaThumbnail]);
      setOpen(true);
      setOpenDrawer(true);
      setSelectedArtist(userProp);
      setValues({
        artistName: userProp.fullname,
        artistShortDescrition: values.shortDescription || '',
        artistDescription: userProp.description || '',
        mediaId: eventMediaUrl || 0
      });
  }


  const handleRemoveBanner = (isRemoved) => {
    if (isRemoved) {
      setMediaThumbnail([]);
      setValues((prevState) => ({
        ...prevState,
        mediaId: 0
      }));
    }
  }
  const drawerProps = {
    header: [{ title: selectedArtist == '' ? 'Create Artist' : 'Update Artist' }, { icon: <CloseIcon />, handler: handleClose }],
    fields: [
      {
        component: [
          <Grid container spacing={3} className="">
            <Grid item xs={12} md={6} lg={6} className="flex flex-column">
              <FormLabel id="demo-row-radio-buttons-group-label">Artist Name*</FormLabel>
              <TextField fullWidth sx={{
                "& .MuiInputBase-root": {
                  height: 45,
                  width: '100%'
                }
              }} value={values.artistName} onChange={handleChange('artistName')} />
              {errors['artistName'] && (<p className={classes.errorMessage}>{errors['artistName']}</p>)}
            </Grid>
            <Grid item xs={12} md={6} lg={6} className="flex flex-column">
              <FormLabel id="demo-row-radio-buttons-group-label">Artist Short Description</FormLabel>
              <TextField fullWidth sx={{
                "& .MuiInputBase-root": {
                  height: 45,
                  width: '100%'
                }
              }} value={values.artistShortDescrition} onChange={handleChange('artistShortDescrition')} />
            </Grid>
            <Grid item xs={12} className="flex flex-column">
              <FormLabel id="demo-row-radio-buttons-group-label">Artist Description*</FormLabel>
              <TextField multiline minRows={2} maxRows={4} fullWidth value={values.artistDescription} onChange={handleChange('artistDescription')} />
              {errors['artistDescription'] && (<p className={classes.errorMessage}>{errors['artistDescription']}</p>)}
            </Grid>
            <Grid item xs={12} md={12} lg={12} className="flex flex-column">
              <FormLabel id="demo-row-radio-buttons-group-label">Artist Picture*</FormLabel>
              <Dropzone

                height={300}
                width={'100%'}
                acceptedTypes={{
                  'image/jpeg': [],
                  'image/png': []
                }}
                maxSize={1 * 1024 * 1024}
                acceptedfileSizeText={"Recommended 1280 * 720px and not more than 10Mb"}
                name="mediaId"
                mediaThumbnail={mediaThumbnail}
                handleRemoveBanner={handleRemoveBanner}
                onDrop={handleDrop} />
              {errors['mediaId'] && (<p className={classes.errorMessage}>{errors['mediaId']}</p>)}
            </Grid>
          </Grid>
        ]
      }
    ],
    footer: [{ title: 'Cancel', style: "outlined", handler: handleClose }, { title: selectedArtist == '' ? 'CREATE' : 'UPDATE', style: "contained", handler: handleCreateArtist }],
    footerStyle: classes.footer
  }
  return (
    <>
      <div className="d-flex justify-content-between">
        <h2>Artists</h2>
        <Button variant="contained" onClick={handleOpen} className="mb-5 float-right"><AddIcon className="mr-2" />CREATE ARTIST</Button>
      </div>
      <Grid className={classes.mainContainer} >
        <Grid className={classes.gridContainer}>
          <GlobalDrawer open={open} drawerProps={drawerProps} setOpen={setOpenDrawer} drawerClass={classes.drawer} drawerPaperClass={classes.drawerPaper} />

          <DataTable
            tableHeading={artistsTableHeaders}
            tableData={adminArtistList}
            handleAction={handleAction}
            hasData={adminArtistList.length > 0 ? true : false}
          />
          {adminArtistList && adminArtistList.length > 0 && <TablePagination
            component="div"
            count={totalRecords}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={() => (
              <div style={{ display: 'flex', flexWrap: 'nowrap', alignSelf: 'center' }}>
                <IconButton
                  onClick={() => { setPage(0) }}
                  disabled={page === 0}
                  aria-label="first page"
                >
                  <FirstPage />
                </IconButton>
                <IconButton disabled={page === 0} onClick={() => { setPage(page - 1) }} aria-label="previous page">
                  <NavigateBefore />
                </IconButton>
                <IconButton
                  onClick={() => { setPage(page + 1) }}
                  disabled={page >= Math.ceil(totalRecords / 10) - 1}
                  aria-label="next page"
                >
                  <NavigateNext />
                </IconButton>
                <IconButton
                  onClick={() => { setPage(Math.ceil(totalRecords / 10) - 1) }}
                  disabled={page >= Math.ceil(totalRecords / 10) - 1}
                  aria-label="last page"
                >
                  <LastPage />
                </IconButton>
              </div>
            )}
            className={classes.pagination}
          />}
        </Grid>
        {/* <Pagination onChange={handlePageChange} className="mt-5" count={Math.ceil(artistList.length / 10)} color="primary" /> */}
      </Grid>
    </>
  );
}

export default Artists;
