import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: false,
    email:''
};

const modalPopupSlice = createSlice({
    name: 'ModalPopUp',
    initialState,
    reducers: {
        setOpenModal(state) {
            state.isOpen = !state.isOpen;
        }
    }
})

//Reducer
export default modalPopupSlice.reducer;

//Actions
export const {setOpenModal} = modalPopupSlice.actions;