
import { config } from 'config.js';
import { xhrClient } from 'views/utilities/DataRequest';
const { isProd } = config;
const API_ENDPOINT = isProd
    ? config.production.api_endpoint
    : config.development.api_endpoint;
    
const setAuthenticationService = (data) => {
    return xhrClient.post(`${API_ENDPOINT}/login`, null, data);
}
const setRegistrationService=(data)=>{
    return xhrClient.post(`${API_ENDPOINT}/signup`,null,data);
}
const setLogoutService=()=>{
    return xhrClient.put(`${API_ENDPOINT}/logout`,null, null);
}
const setAuthenticateEmailService=(data)=>{
    return xhrClient.put(`${API_ENDPOINT}/verify-email`,null,data);
}
const setAuthenticateResetPasswordService=(data)=>{
    return xhrClient.post(`${API_ENDPOINT}/user-reset-password`,null,data);
}
const setSendEmailService=(data)=>{
    return xhrClient.put(`${API_ENDPOINT}/sendemail`,null,data);
}
const setAuthenticateUpdatePasswordService=(data)=>{
    return xhrClient.put(`${API_ENDPOINT}/user-reset-password
    `,null,data);
}
const setAuthenticateChangePasswordService=(data)=>{
    return xhrClient.put(`${API_ENDPOINT}/update-password
    `,null,data);
}
const setCountryDataService=()=>{
    return xhrClient.get(`${API_ENDPOINT}/getCountry`);
}
const setStateDataService=(countryId)=>{
    return xhrClient.get(`${API_ENDPOINT}/getState?countryId=${countryId}`);
}

const sendCheckoutEmailOtpService = (data) => {
    return xhrClient.put(`${API_ENDPOINT}/user-checkout-email-otp?contactMethod=${data.contactMethod}&countryCode=${data.countryCode}`);
}
const validateCheckoutEmailOtpService = (data = {}) => {
    return xhrClient.put(`${API_ENDPOINT}/verifyOtpCode`, null, data);
}

export {
    setAuthenticationService,
    setRegistrationService,
    setLogoutService,
    setAuthenticateEmailService,
    setAuthenticateResetPasswordService,
    setAuthenticateUpdatePasswordService,
    setAuthenticateChangePasswordService,
    setCountryDataService,
    setStateDataService,
    setSendEmailService,
    sendCheckoutEmailOtpService,
    validateCheckoutEmailOtpService,
}