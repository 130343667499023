import { call, put, takeEvery, all } from 'redux-saga/effects'
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from 'redux/slices/snackbar';
import { getAddressListService, getUserProfileService, saveAddressService, setUserProfile, updateAddressService } from 'redux/ProfileManagement/ProfileManagementService';
import { setAddressList, setUserProfileImage, setInitialState, setUpdateProfile } from 'redux/ProfileManagement/ProfileManagementSlice';
import ApiConstant from 'constant/ApiConstant';
import i18n from 'i18next';
import { isLoggedIn } from 'utils';

function* translateAndDispatchSuccess(message) {
    const translation = yield call([i18n, 't'], message);
    yield put(SNACKBAR_SUCCESS(translation));
}
function* translateAndDispatchError(message) {
    const translation = yield call([i18n, 't'], message);
    yield put(SNACKBAR_ERROR(translation));
}

const { RESPONSE } = ApiConstant;

function* getUserProfile() {
    try {
        if (isLoggedIn()) {
            const response = yield call(getUserProfileService);
            yield put(setInitialState(response));
            // console.log('response ', response);
            // let profileUrl = window.URL.createObjectURL(response);
            // yield put(setUserProfileImage(profileUrl));
        }
    } catch (error) {
        // console.log('error saga', error);
        yield translateAndDispatchError(error.message);
        console.error(error);
    }
}

function* updateUserProfile(action){
    try {
        const response = yield call(setUserProfile, action.payload);
        yield put(setUpdateProfile(action.payload));
        yield translateAndDispatchSuccess(response.message);
    } catch (error) {
        console.error(error);
        yield translateAndDispatchError(error.error);
    }
}

function* watchGetUserProfile(){
    yield takeEvery('getUserProfileAction',getUserProfile);
}

function* saveAddress(action) {
    const { reqPayload, onSuccessCB, onErrorCB } = action.payload;
    try {
        const data = yield call(saveAddressService, reqPayload);
        if (data) {
            // yield put(setCardInfo({}));
            onSuccessCB(data);
        }
    } catch (error) {
        onErrorCB(error);
        yield translateAndDispatchError(error.error);
        console.error(error);
    }
}

function* watchSaveAddress() {
    yield takeEvery('saveAddressAction', saveAddress);
}

function* updateAddress(action) {
    const { reqPayload, onSuccessCB, onErrorCB } = action.payload;
    try {
        const data = yield call(updateAddressService, reqPayload);
        if (data) {
            onSuccessCB(data);
        }
    } catch (error) {
        onErrorCB(error);
        yield translateAndDispatchError(error.error);
        console.error(error);
    }
}

function* watchUpdateAddress() {
    yield takeEvery('updateAddressAction', updateAddress);
}

function* getAddressList(payload){
    try {
        const { userId } = payload.data;
        const response = yield call(getAddressListService, userId);
        if (response) {
          yield put(setAddressList(response));
        }
      } catch (error) {
        yield translateAndDispatchError(error.error);
        console.error(error);
      }
}

function* watchGetAddressList(){
    yield takeEvery('getAddressListAction', getAddressList);
}

function* watchUpdateUserProfile(){
    yield takeEvery('updateUserProfileAction', updateUserProfile);
}

// Actions
export default function* profileManagementSaga() {
    yield all([
        watchGetUserProfile(),
        watchGetAddressList(),
        watchSaveAddress(),
        watchUpdateAddress(),
        watchUpdateUserProfile()
    ])
}
