import { makeStyles, styled } from "@mui/styles";
import React, { useState } from "react";
import { Grid, Button, Divider } from "@mui/material";
import { convertDate } from "helper";
import { useTranslation } from "react-i18next";

const halfCirlce = {
    width: "1.4rem",
    height: "0.7rem",
    border: "1px solid #d2caca",
    borderRadius: "10rem 10rem 0 0",
    borderBottomColor: "transparent",
    position: "absolute",
};

const useStyles = makeStyles({
    card: {
        right: "10%",
        top: "10%",
        backgroundColor: "#f2f2f2",
        // height: "28rem",
        padding: "1rem",
        maxWidth: "500px",
        // width: "370px",
        borderRadius: "4px",
        // border: "1px solid lightgray",
        "&.h6,h6": {
            color: "#92bafc",
            textTransform: "uppercase",
            fontWeight: "600",
            fontSize: "0.8rem",
        },
    },
    startTime: {
        width: "40%",
        fontSize: "0.7rem",
        marginBottom: "24px",
    },
    event: {
        width: "55%",
        fontSize: "0.8rem",
        fontWeight: "bold",
        textTransform: "uppercase",
    },
    discountBox: {
        marginTop: "8px",
    },
    buyButton: {
        width: "100%",
        // backgroundColor: "#1c6afc !important",
        marginTop: "39px !important",
    },
    sideText: {
        color: "#c0e0fd",
        textTransform: "capitalize",
        transform: "rotate(90deg)",
        fontWeight: "500",
        position: "absolute",
        right: -25,
        top: 25,
        // float: "right",
        // paddingTop: "40px",
        // marginRight: "-36px",
    },
    leftHalfCircle: {
        ...halfCirlce,
        transform: "rotate(90deg)",
        margin: "-5px 0px 0px -21px",
    },
    rightHalfCircle: {
        ...halfCirlce,
        transform: "rotate(270deg)",
        // margin: "-7px 0 0 334px",
        right: -20,
        marginTop: -7,
    },
    styledGrid: {
        display: "flex",
        justifyContent: "flex-end",
        fontSize: "0.8rem",
        fontWeight: "bold",
    },
    serviceElements: {
        // display: "flex",
        // justifyContent: "flex-end",
        float: "right",
        fontSize: "0.7rem",
        fontWeight: "500",
        padding: "2px 0 2px 0"
    },
    itemsElements: {
        padding: "2px 0 2px 0px",
        fontSize: "0.7rem !important",
        fontWeight: "600"
    },
    media: {
        // marginLeft: 20,
        "@media (max-width: 500px)": {
            margin: "20px 20px 20px 20px",
        }
    }
});

const OrderCheckoutSummary = (props) => {
    const { t, i18n } = useTranslation();
    const {
        eventData,
        values,
        orderSummary = {},
        count,
        discountAmount,
        handleCheckout,
        hasValidDetails,
    } = props;
    const classes = useStyles();

    // const processingFee = orderSummary.paymentProcessingFee < orderSummary.maxProcessingFee ? ((count * orderSummary.paymentProcessingFee) < orderSummary.maxProcessingFee ? (count * orderSummary.paymentProcessingFee) : orderSummary.maxProcessingFee) : orderSummary.maxProcessingFee;
    // const subTotal = parseFloat(parseInt(count) * parseFloat(orderSummary?.totalPrice) - (discountAmount && parseFloat(discountAmount))).toFixed(2);
    //  + (values.donateAmount && parseFloat(values.donateAmount)))?.toFixed(2)
    const formatNumber = (number) =>{
        if (Number.isInteger(number)) {
          return number.toFixed(2);
        } else {
          return Number(number).toFixed(2);
        }
      }

    return (
        <div className={classes.media}>
            <div className={classes.card}>
                <h6 className="position-relative">
                    {t("ORDER SUMMARY")}
                    <span className={classes.sideText}>{t("M-Ticket")}</span>
                </h6>

                <div className={classes.event}>
                    <p>{eventData.name}</p>
                </div>
                <div className={classes.startTime}>
                    <p>
                        {t('Starts At')}: {eventData.startTime}, <span>{convertDate(eventData.startDate)}</span>
                    </p>
                </div>
                <div className="position-relative">
                    <div className={classes.leftHalfCircle}></div>
                    <Divider
                        sx={{
                            border: "2px dotted lightgray",
                            borderStyle: "none none dotted",
                            marginLeft: "-1%",
                            marginRight: "-1%",
                        }}
                    />
                    <div className={classes.rightHalfCircle}></div>
                </div>
                <Grid container direction="row" padding="24px 0px 10px 0px">
                    <Grid item xs={8} className={classes.itemsElements}>
                        {orderSummary?.bookingTypeLabel || orderSummary?.bookingType || "Actual ticket Price"} {`(${orderSummary?.price} x ${count})`}
                    </Grid>
                    <Grid item className={classes.styledGrid} xs={4}>
                        + ${formatNumber(count * orderSummary?.price)}
                    </Grid>

                    <Grid item xs={8} className={classes.itemsElements}>
                        {t('Service Fee')}
                    </Grid>
                    <Grid item className={classes.styledGrid} xs={4}>
                        + $
                        {formatNumber(orderSummary?.paymentProcessingFee + (orderSummary?.ticketingFee))}
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        marginLeft={1}
                        sx={{ fontSize: "0.7rem", fontWeight: "500" }}
                    >
                        {t('Ticketing Fee')}
                        <span className={classes.serviceElements}>
                            + ${formatNumber(orderSummary?.ticketingFee)}
                        </span>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        marginLeft={1}
                        sx={{ fontSize: "0.7rem", fontWeight: "500" }}
                    >
                        {t('Payment Processing Fee')}
                        <span className={classes.serviceElements}>
                            + ${formatNumber(orderSummary?.paymentProcessingFee)}
                        </span>
                    </Grid>

                    {/* Will use in future */}
                    {/* <Grid item xs={8} className={classes.itemsElements}>
                        {t('Donate To Hurricane Ian Victims')}
                    </Grid>
                    <Grid item className={classes.styledGrid} xs={4}>
                        + ${values.donateAmount ? values.donateAmount : 0}
                    </Grid> */}

                    <Grid item xs={8} className={classes.itemsElements}>
                        {t('Discount')}
                    </Grid>
                    <Grid item className={classes.styledGrid} xs={4}>
                        - ${formatNumber(orderSummary?.discountAmount)}
                    </Grid>
                </Grid>
                <Divider />
                <Grid container padding="10px 0 0 0">
                <Grid item xs={10} className={classes.itemsElements}>
                        {t("Subtotal")}
                    </Grid>
                    <Grid item xs={2} className={classes.styledGrid}>
                        ${formatNumber(parseFloat(orderSummary?.totalPrice - orderSummary.taxPrice)?.toFixed(2))}
                    </Grid>
                    <Grid item xs={8} className={classes.itemsElements}>
                        {t('Tax')}
                    </Grid>
                    <Grid item className={classes.styledGrid} xs={4}>
                        + ${formatNumber(orderSummary.taxPrice)}
                    </Grid>
                </Grid>
                <Divider />
                <Grid container padding="10px 0 0 0">
                    <Grid item xs={10} className={classes.itemsElements} sx={{ fontSize: "0.7rem"}}>
                        {t("Total")}
                    </Grid>
                    <Grid item xs={2} className={classes.styledGrid}>
                        ${formatNumber(orderSummary?.totalPrice)}
                    </Grid>
                </Grid>
                <div title={hasValidDetails ? t("Checkout") : t("Please provide valid details..!")}>
                    <Button
                        onClick={handleCheckout}
                        className={`${classes.buyButton}`}
                        variant="contained"
                        color="primary"
                        disabled={!hasValidDetails}
                    >
                        {t("Checkout")}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default OrderCheckoutSummary;
