import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import HeaderContent from './components/HeaderContent';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Tickets from 'views/Tickets';
import { getProfileName } from 'utils';
import ChangePassword from 'views/ChangePassword';

export default function Header(props) {
  const navigate = useNavigate();
  const { isLogout } = useSelector((state) => state.auth);
  const { userDetails } = useSelector((state) => state.profile);
  const [open,setOpen]=useState(false);
  const [popupOpen , setPopupOpen] = useState(false);
  const formContent = [
    {
      label: "Old Password*",
      name: "oldPassword",
      type: "oldPassword"
    },
    {
      label: "New Password*",
      name: "password",
    },
    {
      label: "Confirm New Password*",
      name: "confirmPassword",
    }
  ];
  useEffect(() => {
    if (isLogout) {
      navigate('/');
      dispatch({
        type: "setAuthInitialStateAction"
      });
    }
  }, [isLogout])

  const profileNameAndImage = {
    name: getProfileName(),
    image: "https://cdn-icons-png.flaticon.com/512/147/147142.png"
  }
  const logoIcon = {
    logoUrl: "https://upload.wikimedia.org/wikipedia/commons/e/ec/Etikimza_logo_icon.png?20210403184337"
  }
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch({
      type:"getUserProfileAction"
    })
  },[]);
  const showTicket =(isOpen)=>{
    setOpen(isOpen);
  }

  const showChangePasswordPopup = (isOpen) => {
    setPopupOpen(isOpen);
  }

  return (
    <>
    <HeaderContent profileNameAndImage={profileNameAndImage} userDetails={userDetails} showTicket={showTicket} showChangePasswordPopup={showChangePasswordPopup} />
    <Tickets isOpen={open} showTicket={showTicket} />
    <ChangePassword isOpen={popupOpen} showChangePasswordPopup={showChangePasswordPopup}  formContent={formContent} buttonText="Submit" />
    </>
    
  );
}
