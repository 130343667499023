import { TextField, Button, InputAdornment, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { setInitialState } from 'redux/AuthManagement/AuthMangementSlice';
const useStyles = makeStyles({
    label: {
        fontWeight: "600",
        fontSize: "0.8rem",
        color: "#756F86"
    },
    labelError: {
        fontWeight: "600",
        fontSize: "0.8rem",
        color: "#D32F2F"
    },
    formButton: {
        width: "100%",
        backgroundColor: "#616161 !important",
        textTransform: "capitalize !important"
    },
    formLink: {
        textDecoration: "underline"
    },
    loginFormContainer:{
        marginRight:"1.75rem",
        '@media(max-width:600px)':{
            marginRight:"0 !important"
        }
    }
});
function LoginForm(props) {
    const {
        formHeading,
        formSubHeading,
        formContent,
        buttonText,
        hasFormSubHeadingLink,
        formSubHeadingLinkOne,
        formSubHeadingLinkTwo,
        hasFormFooterLink,
        FormFooterLink
    } = props;
    const [values, setValues] = React.useState({ emailId: '', password: '' });
    const [disableButton, setDisableButton] = useState(true);
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { errorState } = useSelector((state) => state.auth);
    const { userDetails } = errorState;
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const handleChange = (prop) => (event) => {
        const { value, name } = event.target;
        setValues({ ...values, [prop]: value });
        if (['emailId', 'password'].includes(name)) {
            dispatch({
                type: "setAuthenticationFormAction",
                name,
                value
            })
        }

    };
    const handleLogin = (event) => {
        event.preventDefault();
        dispatch({
            type: "setAuthenticationAction",
            data: {
                emailId: values.emailId,
                password: values.password,
            },
            requestPage: "Login"
        })
    }
    useEffect(() => {
    if (
        values.emailId &&
        values.password &&
        !userDetails.emailId.error &&
        !userDetails.password.error
      ) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    }, [values]);
    const handleClickShowPassword = (name) => {
        if (name === 'password') {
            setShowPassword(!showPassword);
        } 
    }
    useEffect(()=>{
        dispatch({
            type:setInitialState.type
        })
    },[]);
    
    const handleCopy = (e) => {
        e.preventDefault();
    }

    return (
        <form onSubmit={handleLogin}>
        <div className={`p-3 mt-15 ${classes.loginFormContainer}`}>
            <h3>{t(formHeading)}</h3>
            <span className='text-12 font-medium mr-1'>{t(formSubHeading)}</span>
            {hasFormSubHeadingLink && <Link className={`${classes.formLink} whitespace-no-wrap text-12 link-color font-medium`} to={`/signup?isTicket=${0}`} undeline='true'>{t(formSubHeadingLinkOne)}</Link>}<br/>
            {hasFormSubHeadingLink && <Link className={`${classes.formLink} whitespace-no-wrap text-12 link-color font-medium`} to={`/signup?isTicket=${1}`} undeline='true'>{t(formSubHeadingLinkTwo)}</Link>}
            <br />
            <div className='mt-5'>
                {formContent.map((el, index) => {
                    return (
                        < div key={index}>
                            <label className={classes.label}>{t(el.label)}</label><br />
                            {/* {console.log(errorState.userDetails[el.name],"error")} */}
                            <TextField name={el.name} fullWidth onChange={handleChange(el.name)} className='mb-5 mt-1' sx={{
                                "& .MuiInputBase-root": {
                                    height: 45,
                                    border:"1px solid #DBE2EA"
                                }
                            }}
                                type={!showPassword && el.name === "password" ? "password" : "text"}
                                value={values[el.name]}
                                InputProps={{
                                    onCopy: handleCopy,
                                    endAdornment: <InputAdornment position="end">
                                        {(['password'].includes(el.name)) && 
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword(el.name)}
                                        >
                                            {!(showPassword && el.name === "password") ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                        </IconButton>}
                                    </InputAdornment>,
                                }}
                                error={errorState
                                    && errorState.userDetails
                                    && errorState.userDetails[el.name]
                                    && errorState.userDetails[el.name].error}
                                helperText={errorState
                                    && errorState.userDetails
                                    && errorState.userDetails[el.name]
                                    && errorState.userDetails[el.name].error
                                    ? <span>{t(errorState.userDetails[el.name].errorMsg)}</span>
                                    : null }
                            />
                            <br />
                        </div>
                    )
                })}
                <Button disabled={disableButton} type="submit" className={`${classes.formButton} mb-5 pt-3 pb-3 mt-3 right-gradient flex items-center justify-center`} variant='contained'>{t(buttonText)} <ArrowForwardIcon className='text-20 mb-1 ml-5'/></Button>
                <br />
                {hasFormFooterLink && <Link className={`${classes.formLink} text-12 link-color font-medium`} to="/reset">{t(FormFooterLink)}</Link>}
            </div>
        </div>
        </form>
    )
}

export default LoginForm