import { ContentCopy } from '@mui/icons-material';
import { Button, Grid, Tooltip, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import breakpoints from 'components/Shared/MediaQueries/BreakPoints';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react'
import RedeemTicketModal from 'components/Shared/ReusableModal';
import { useDispatch, useSelector } from 'react-redux';
import { removeUnderscoresAndCapitalize } from 'utils';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';
import { setInitialState } from 'redux/EventManagement/EventManagementSlice';
import DoneIcon from '@mui/icons-material/Done';
import { Link } from 'react-router-dom';

function Ticket(props) {
    const { tickets, handleTicketButtonClick, ticketButtons, tabValue } = props;
    const useStyles = makeStyles({
        ticketContainer: {
            border: "1px solid lightgray",
            borderRadius: "5px",
            height: "auto",
            margin: "1rem 0",
            [breakpoints.sm_down]: {
                height: "30% !important"
            }
        },
        ticketImage: {
            width: "100%",
            height: "100%",
            maxHeight: "150px",
            objectFit: "cover",
            borderRadius: "5px 0 0 5px"
        },
        imageContainer: {
            [breakpoints.sm_down]: {
                margin: "0.2rem !important",
                height: "120px !important"
            }
        },
        ticketDetailsContainer: {
            marginLeft: "1rem"
        },
        ticketType: {
            marginLeft: "2rem",
            color: "white",
            padding: '0.3rem 1rem',
            borderRadius: "5px",
            fontSize: "0.8rem",
            textTransform: "capitalize !important",
            [breakpoints.sm_down]: {
                marginLeft: "0rem"
            }
        },
        ticketDescription: {
            fontSize: "0.8rem",
            [breakpoints.sm_down]: {
                display: 'none !important'
            }
        },
        ticketCode: {
            fontSize: "0.8rem"
        },
        ticketStartDate: {
            fontSize: "0.8rem"
        },
        ticketActionButtonContainer: {
            display: "flex",
            flexDirection: "column !important",
            alignItems: "end",
            marginTop: "2rem",
            marginRight: "1rem"
        },
        mobileTicketActionButtonContainer: {
            [breakpoints.sm_down]: {
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                margin: "0.5rem 0.3rem"
            }
        },
        shareBtn: {
            background: "transparent !important",
            color: "#1F262D !important",
            border: "1px solid #1F262D !important",
            boxShadow: "none !important",
        },
        eventDetails: {
            [breakpoints.sm_down]: {
                width: '100%'
            }
        },
        eventName: {
            whiteSpace: 'nowrap'
        },
        ticketNameContainer: {
            [breakpoints.sm_down]: {
                flexDirection: "column !important",
                alignItems: "flex-start !important"
            }
        },
        actionBtn: {
            [breakpoints.sm_down]: {
                fontSize: "2rem"
            }
        }
    })
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
    const { ticketMediaUrls } = useSelector((state) => state.event);
    const [modalStates, setModalStates] = useState({});
    const [ticketId, setTicketId] = useState("");
    const [copytext, setCopyText] = useState([]);
    const dispatch = useDispatch();
    const handleToggleModal = (buttonText, id) => {
        setTicketId(id);
        const updatedFormFields = formRedeemTicket.map((formField) => {
            if (buttonText === "Watch Show With This Ticket") {
                return { ...formField, value: id }
            }
            return formField
        });

        setFormRedeemTicket(updatedFormFields);
        setModalStates((prevState) => ({
            ...prevState,
            [buttonText]: !prevState[buttonText]
        }));
    };

    const [formRedeemTicket, setFormRedeemTicket] = useState([
        {
            label: "Enter Your Ticket ID",
            name: "ticketId",
            type: "text",
            value: ""
        }
    ]);

    const handleRedeemPopUpSubmit = (data) => {
        dispatch({
            type: "fetchTicketMessageAction",
            data: {
                ticketId: data.ticketId
            }
        })
        handleToggleModal('Watch Show With This Ticket')
        const updatedFormContent = formRedeemTicket?.map((item) => ({
            ...item, value: ""
        }));
        handleTicketButtonClick(true);
        setFormRedeemTicket(updatedFormContent);
    }

    const [formShareTicket, setFormShareTicket] = useState([
        {
            label: "Enter Email ID",
            name: "emailId",
            type: "email",
            value: ""
        }
    ]);

    const handleSharePopUpSubmit = (data) => {
        dispatch({
            type: "setShareTicketAction",
            payload: {
                toEmail: data.emailId,
                ticketId: ticketId
            }
        });
        setTicketId("");
        handleToggleModal('Gift Ticket To Others')
        const updatedFormContent = formShareTicket?.map((item) => ({
            ...item, value: ""
        }));
        handleTicketButtonClick(true);
        setFormShareTicket(updatedFormContent);
    }
    useEffect(() => {
        tickets.forEach((ticket) => {
            if (ticket?.mediaId) {
                dispatch({
                    type: "getTicketMediaAction",
                    mediaId: ticket?.mediaId
                });
            }
        });
    }, []);
    useEffect(() => {
        dispatch({
            type: setInitialState.type
        })
    }, [dispatch]);
    useEffect(() => {
        setCopyText(new Array(tickets.length).fill(false));
    }, [tickets]);

    const renderMedia = (ticket) => {
        return ticketMediaUrls.find((media) => media.mediaId === ticket.mediaId)?.ticketUrl;
    }
    function hasDatePassed(providedDate) {
        // Convert the provided date to a Date object
        var provided = new Date(providedDate);
        // Get the current date
        var currentDate = new Date();
        if (provided < currentDate) {
            return true;
        } else {
            return false;
        }
    }
    const renderButtonNames = (button, ticket) => {
        if (button.buttonText === "Watch Now" || button.buttonText === "Watch Show") {
            if (hasDatePassed(ticket.eventStartDate)) {
                return button.buttonText;
            } else {
                return `${button.buttonSecondaryText} ${ticket.eventStartDate}`
            }
        }else{
            if (ticket.assignedUserId && ticket.sharedTo && button.buttonText === "Gift Ticket To Others") {
                return button.buttonSecondaryText;
            } else if (ticket.assignedUserId && !ticket.sharedTo && button.buttonText === "Watch Show With This Ticket") {
                return (hasDatePassed(ticket.eventStartDate)?button.buttonSecondaryText:`Live On ${ticket.eventStartDate}`);
            } else if (!ticket.assignedUserId && ticket.sharedTo && button.buttonText === "Gift Ticket To Others") {
                return button.buttonSecondaryText;
            }
        }
        return button.buttonText;
    }

    const disableButton = (button, ticket) => {
        if (hasDatePassed(ticket.eventStartDate) && button.buttonText === 'Watch Now') {
            return false;
        }else if(!hasDatePassed(ticket.eventStartDate)&&ticket.assignedUserId){
            return true;
        } else {
            if (ticket.assignedUserId && ticket.sharedTo) {
                return true;
            } else if (ticket.assignedUserId && !ticket.sharedTo&& button.buttonText === 'Gift Ticket To Others') {
                return true;
            }else if(ticket.assignedUserId && !ticket.sharedTo){
                return false;
            } else if (!ticket.assignedUserId && ticket.sharedTo) {
                return true;
            }else if(!ticket.assignedUserId&&!ticket.sharedTo){
                return false;
            }
        }
        return false;
    }
    const renderToolTip = (button, ticket) => {
        if (renderButtonNames(button, ticket) === ("Watch Show")) {
            return "This Ticket Has Been Redeemed"
        } else if (renderButtonNames(button, ticket) === "Watch Show With This Ticket") {
            return "This Ticket Has Been Shared"
        } else if (renderButtonNames(button, ticket)?.includes("Live On")) {
            return `Event Will Be Live On ${ticket.eventStartDate}`
        }
    }
    const handleCopy = (text,index) => {
        navigator?.clipboard?.writeText(text);
        const updatedCopyTextArr = [...copytext];
        updatedCopyTextArr[index] = true;
        setCopyText(updatedCopyTextArr);
        setTimeout(() => {
            const resetCopyTextArr = [...copytext];
            resetCopyTextArr[index] = false;
            setCopyText(resetCopyTextArr);
        },800)
    };
    return (
        tickets && tickets.length > 0 && tickets.map((ticket, index) => {
            return <Grid key={ticket.ticketIds} container className={classes.ticketContainer}>
                <Grid item xs={4} sm={2} md={2} lg={2} className={classes.imageContainer}>
                    <img className={classes.ticketImage} src={ticket?.mediaIds?.[0]?.mediaUrl || "https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM="} />
                </Grid>
                <Grid item xs={7} sm={10} md={10} lg={10} className={`flex justify-between ${classes.eventDetails}`}>
                    <Grid className={classes.ticketDetailsContainer}>
                        <Grid className={`flex items-center mt-3 ${classes.ticketNameContainer}`}>
                            <Link to={`/events/${ticket.eventId}/details`}><h6 className={classes.eventName}>{ticket.eventName}</h6></Link>
                            <h6 className={`${classes.ticketType} bg-primary`}>{removeUnderscoresAndCapitalize(ticket?.bookingType)}</h6>
                        </Grid>
                        <p className={classes.ticketDescription}>{ticket.eventDescription}</p>
                        <span className={`font-medium text-gray mb-5 ${classes.ticketCode}`}>{t('Ticket Code')} : {ticket.ticketIds}
                            {copytext[index] ? <><DoneIcon className='pl-1' color='primary' style={{ fontSize: '20px' }} /><span style={{ color: '#1c6afc' }}>{t('copied')}</span></> : <ContentCopy onClick={() => handleCopy(ticket.ticketIds, index)} sx={{ fontSize: "0.8rem", ml: "0.4rem", cursor: 'pointer' }} />}</span>
                        <br />
                        <p className={`text-gray mt-3 ${classes.ticketStartDate}`}>{ticket.eventStartDate}, {ticket.eventStartTime}</p>
                    </Grid>
                    {!isMobile && <Grid className={classes.ticketActionButtonContainer}>
                        {ticketButtons && ticketButtons.length > 0 && ticketButtons.map((button, index) => {
                            const isOpen = modalStates[button.buttonText];
                            return (
                                <>
                                    <Grid className='flex min-w-200' >
                                        {(disableButton(button, ticket) && index === 0) && <Tooltip title={renderToolTip(button, ticket)}>
                                            <InfoOutlinedIcon className='mt-2 mr-2 color-gray cursor-pointer' />
                                        </Tooltip>}
                                        {[ "Watch Show"].includes(renderButtonNames(button, ticket))? <Link to={`/events/${ticket.eventId}/details`}> 
                                        <Button sx={{ width: '100%' }} disabled={disableButton(button, ticket)}
                                            onClick={() => button.showModal && handleToggleModal(button.buttonText, ticket.ticketIds)}
                                            className={`mb-3 min-w-250 flex capitalize items-center ${button.class === 'shareBtn' && classes.shareBtn}`}
                                            variant='contained'>
                                            <span className='mr-2'>{["Gifted", "Watch Show", "Watch Now"].includes(renderButtonNames(button, ticket)) && button.icon}</span>{renderButtonNames(button, ticket)}
                                        </Button>
                                        </Link>: 
                                        ["Watch Now"].includes(renderButtonNames(button, ticket)) ? <Link to={`/events/${ticket.eventId}/video`}>
                                        <Button sx={{ width: '100%' }} disabled={disableButton(button, ticket)}
                                            onClick={() => button.showModal && handleToggleModal(button.buttonText, ticket.ticketIds)}
                                            className={`mb-3 min-w-250 flex capitalize items-center ${button.class === 'shareBtn' && classes.shareBtn}`}
                                            variant='contained'>
                                            <span className='mr-2'>{["Gifted", "Watch Show", "Watch Now"].includes(renderButtonNames(button, ticket)) && button.icon}</span>{renderButtonNames(button, ticket)}
                                        </Button>
                                        </Link>:
                                        <Button sx={{ width: '100%' }} disabled={disableButton(button, ticket)}
                                            onClick={() => button.showModal && handleToggleModal(button.buttonText, ticket.ticketIds)}
                                            className={`mb-3 min-w-250 flex capitalize items-center ${button.class === 'shareBtn' && classes.shareBtn}`}
                                            variant='contained'>
                                            <span className='mr-2'>{["Gifted", "Watch Show", "Watch Now"].includes(renderButtonNames(button, ticket)) && button.icon}</span>{renderButtonNames(button, ticket)}
                                        </Button>
                                        }
                                    </Grid>
                                    {button.buttonText === 'Watch Show With This Ticket' ? <RedeemTicketModal
                                        label="Redeem To Watch Show"
                                        buttonName="Redeem Now"
                                        formData={formRedeemTicket}
                                        setFormData={setFormRedeemTicket}
                                        handlePopupSubmit={handleRedeemPopUpSubmit}
                                        isOpen={isOpen}
                                        setIsOpen={() => handleToggleModal(button.buttonText, ticket.ticketIds)}
                                        handleClosePopup={() => { }}
                                    /> :
                                        <RedeemTicketModal
                                            label="Gift Ticket To Others"
                                            buttonName="Share Ticket"
                                            formData={formShareTicket}
                                            setFormData={setFormShareTicket}
                                            handlePopupSubmit={handleSharePopUpSubmit}
                                            isOpen={isOpen}
                                            ticketId={ticket.ticketIds}
                                            setIsOpen={() => handleToggleModal(button.buttonText)}
                                            handleClosePopup={() => { }}
                                        />
                                    }
                                </>
                            )
                        })}
                    </Grid>}
                </Grid>
                {isMobile && <Grid className={classes.mobileTicketActionButtonContainer}>
                    {ticketButtons && ticketButtons.length > 0 && ticketButtons.map((button) => {
                        return <Button disabled={disableButton(button, ticket)}
                            onClick={() => button.showModal && handleToggleModal(button.buttonText, ticket.ticketIds)} className={`mb-3 min-w-150 flex capitalize items-center ${classes.actionBtn}  ${button.class === 'shareBtn' && classes.shareBtn}`} variant='contained'>
                            <span className='mr-2'>{["Gifted", "Watch Show", "Watch Now"].includes(renderButtonNames(button, ticket)) && button.icon}</span>{renderButtonNames(button, ticket)}
                        </Button>

                    })}
                </Grid>}
            </Grid>
        })
    )
}

export default Ticket