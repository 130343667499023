import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PurchasedTicket from './components/PurchasedTicket';
import Ticket from './components/Ticket';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import { makeStyles } from '@mui/styles';
import breakpoints from 'components/Shared/MediaQueries/BreakPoints';
import { useTranslation } from 'react-i18next';
import { createBrowserHistory } from 'history';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';
import { getLocalData } from 'utils';
import { setTicketInitialState } from 'redux/TicketManagement/TicketManagementSlice';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ pt:3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }


export default function MyTickets() {
  const history = createBrowserHistory();
  const [value, setValue] = React.useState(0);
  const [purchasedTickets, setPurchasedTickets] = React.useState([]);
  const [allTickets, setAllTickets] = React.useState([]);
  const { ticketShareAndRedeemSuccess } = useSelector((state) => state.ticket);
  const dispatch = useDispatch();
  const { loadingArray } = useSelector((state) => state.loader);
  const useStyles = makeStyles({
    eventTicketContainer: {
      margin: "2rem",
      [breakpoints.sm_down]: {
        margin: "2rem 1rem"
      }
    },
    tabContainer: {
      width: '70%',
      borderBottom: 1,
      borderColor: 'divider',
      [breakpoints.sm_down]: {
        width: '100%',
        marginLeft: 0
      },
    }
  });
  const pageMode = window.location.pathname?.includes('/purchased-ticket') ? '/purchased-ticket' : '/active-ticket';

  useEffect(() => {
    if (pageMode === '/purchased-ticket') {
      setValue(0);
    } else {
      setValue(1);
    }
  }, [pageMode]);

  const handleChange = (event, newValue) => {
    if (newValue === 1) {
      history.push(`/my-tickets/active-ticket`);
    } else {
      history.push(`/my-tickets/purchased-ticket`);
    }
    setValue(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  useEffect(() => {
    dispatch({
      type: "getUserTicketsAction",
      payload: {
        onSuccessCB: (res) => {
          let ticketPurchased = res.filter((ticket) => ticket?.purchased === true);
          if (ticketPurchased) {
            const groupedPurchasedTickets = _.groupBy(ticketPurchased, 'eventBookingsId');
            const sortedPurchasedTickets = _.sortBy(groupedPurchasedTickets, group => -group[0].eventBookingsId);
            const groupedPurchasedTicketsArray = sortedPurchasedTickets.map(group => group.reverse());
            setPurchasedTickets(groupedPurchasedTicketsArray);
          }
          const groupedAllTickets = _.groupBy(res, 'eventBookingsId');
          const sortedAllTickets = _.sortBy(groupedAllTickets, group => -group[0].eventBookingsId);
          const groupedAllTicketsArray = sortedAllTickets.map(group => group.reverse());
          setAllTickets(groupedAllTicketsArray);
        },
        onErrorCB: (error) => {
          console.log(error)
        }
      },
    })
  }, []);
  useEffect(() => {
    if (ticketShareAndRedeemSuccess) {
      dispatch({
        type: "getUserTicketsAction",
        payload: {
          onSuccessCB: (res) => {
            let ticketPurchased = res.filter((ticket) => ticket?.purchased === true);
          if (ticketPurchased) {
            const groupedPurchasedTickets = _.groupBy(ticketPurchased, 'eventBookingsId');
            const sortedPurchasedTickets = _.sortBy(groupedPurchasedTickets, group => -group[0].eventBookingsId);
            const groupedPurchasedTicketsArray = sortedPurchasedTickets.map(group => group.reverse());
            setPurchasedTickets(groupedPurchasedTicketsArray);
          }
          const groupedAllTickets = _.groupBy(res, 'eventBookingsId');
          const sortedAllTickets = _.sortBy(groupedAllTickets, group => -group[0].eventBookingsId);
          const groupedAllTicketsArray = sortedAllTickets.map(group => group.reverse());
          setAllTickets(groupedAllTicketsArray);
          },
          onErrorCB: (error) => {
            console.log(error)
          }
        },
      })
    }
  }, [ticketShareAndRedeemSuccess]);

  const ticketButtons = [{
    buttonText: "Watch Now",
    buttonSecondaryText: "Live On",
    icon: <VideocamOutlinedIcon />,
    showModal: false
  }]
  const handleTicketButtonClick = () => {
    dispatch({
      type: setTicketInitialState.type,
    });
  }

  const renderActiveTickets = (listOfTickets) => {
    const activeTickets = listOfTickets
      .filter((tickets) => tickets.some((ticket) => String(ticket.assignedUserId) === getLocalData('userId')))
      .map((tickets) => tickets.filter((ticket) => String(ticket.assignedUserId) === getLocalData('userId')))
      .flat();
    if (activeTickets.length > 0) {
      return activeTickets.map((ticket) => {
        return (<>
          <span className='text-light-gray text-10 ml-3'>{t("Booked On")} {ticket?.bookingDate}</span>
          <Ticket tabValue="Active" tickets={[ticket]} ticketButtons={ticketButtons} handleTicketButtonClick={handleTicketButtonClick} />
        </>
        )
      })
    } else {
      return <span style={{ textAlign: 'center', display: 'grid' }}>{t("No Active Ticket Found.")}</span>
    }
  }
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.eventTicketContainer}>
      <Box className={classes.tabContainer}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab className='capitalize' label={t("My Purchased Tickets")} {...a11yProps(0)} />
          <Tab className='capitalize' label={t("My Active Tickets")} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={1}>
        {renderActiveTickets(allTickets)}
      </TabPanel>
      <Box sx={{ pt: 3 }}>
        {
          pageMode === '/purchased-ticket' &&
          <PurchasedTicket tickets={purchasedTickets} handleTicketButtonClick={handleTicketButtonClick} />
        }
      </Box>
    </Box>
  );
}