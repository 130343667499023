import { createSlice } from '@reduxjs/toolkit';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';

const initialState = {
    artistList:[],
    adminArtistList:[],
    loading:true,
    artistsTableHeaders:[
        { key: "fullname", name: "Artist Name", hasSorting: false },
        { key: "description", name: "Artist description", hasSorting: false },
        { key: "actionHeader", name: "Actions" ,icon:<ModeEditIcon/>}
    ]
}

const artistInfoSlice = createSlice({
    name: 'artistInfo',
    initialState,
    reducers: {
        setInitialState(state, action) {
           state.artistList = initialState.artistList
        },
        setArtistList(state,action){
            state.artistList = action.payload;
        },
        setAdminArtistList(state,action){
            state.adminArtistList = action.payload;
        },
        setLoading(state,action){
            state.loading = action.payload;
        }
    },
});

export default artistInfoSlice.reducer;
// Actions
export const {
    setInitialState,
    setArtistList,
    setAdminArtistList,
    setLoading
} = artistInfoSlice.actions;
