import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import authInfoReducer from './AuthManagement/AuthMangementSlice'
import CardValidationReducer from './CardValidation/CardValidationSlice';
import eventInfoReducer from './EventManagement/EventManagementSlice'
import profileInforReducer from './ProfileManagement/ProfileManagementSlice'
import dashboardInfoReducer from './DashboardManagement/DashboardManagementSlice'
import SnackbarReducer from './slices/snackbar'
import ErrorModalReducer from './ErrorModal/ErrorModalSlice';
import artistInfoReducer from './ArtistManagement/ArtistManagementSlice';
import modalPopupReducer from './ModalPopUp/ModalPopUpSlice';
import TicketInfoReducer from './TicketManagement/TicketManagementSlice';
import LoaderSlice from './Loader/LoaderSlice';
import cartInfoReducer from './CartManagement/CartManagementSlice';
import reportsInfoReducer  from './ReportsManagement/ReportsManagementSlice';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['settings'],
};

const rootReducer = combineReducers({
  auth:authInfoReducer,
  event:eventInfoReducer,
  profile:profileInforReducer,
  dashboard:dashboardInfoReducer,
  SnackbarReducer: SnackbarReducer,
  cardInfo: CardValidationReducer,
  errorModal: ErrorModalReducer,
  modalPopup: modalPopupReducer,
  artist:artistInfoReducer,
  ticket:TicketInfoReducer,
  cart:cartInfoReducer,
  loader: LoaderSlice,
  reports:reportsInfoReducer

});

export { rootPersistConfig, rootReducer };