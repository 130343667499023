import constant from "./constant";

const { MONTHS, DAY } = constant;

export const convertDate = (value, validation) => {
  const date = new Date(value);
  const year = date.getFullYear();
  const month = date.getMonth();
  let formattedMonth = month + 1;
  let dt = date.getDate();
  let day = date.getDay();
  if (formattedMonth < 10) {
    formattedMonth = `0${formattedMonth.toString()}`;
  }

  if (dt < 10) {
    dt = `0${dt}`;
  }

  return validation
    ? `${year}-${formattedMonth}-${dt}`
    : `${DAY[day]}, ${dt} ${MONTHS[month]} ${year}`;
};

export const convertTimeDuration = (n) => {
  var a = n?.split(":");
  if (a?.length) {
    let hr = checkDigit(a, 0, "hr");
    let min = checkDigit(a, 1, "min");
    return `${hr} ${min}`;
  }
};

const checkDigit = (arr, index, key) => {
  let hr = "";
  if (arr[index] !== "00") {
    var as = arr[index]?.split("");
    if (as.length) {
      if (as[0] === "0") {
        hr = as[1] + key;
      } else {
        hr = as[0] + as[1] + key;
      }
    }
  }
  return hr;
};

export const hasNumber = (string) => {
  var matches = string.match(/\d+/g);
  if (matches != null) {
    return true;
  }
}

export const containsSpecialChars = (string) =>  {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return !specialChars.test(string);
}