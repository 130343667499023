import { createTheme } from '@mui/material/styles';

const theme = createTheme();

const breakpoints = {
  xs_up: theme.breakpoints.up('xs'),
  xs_down: theme.breakpoints.down('xs'),
  sm_up: theme.breakpoints.up('sm'),
  sm_down: theme.breakpoints.down('sm'),
  md_up: theme.breakpoints.up('md'),
  md_down: theme.breakpoints.down('md'),
  lg_up: theme.breakpoints.up('lg'),
  lg_down: theme.breakpoints.down('lg'),
  xl_up: theme.breakpoints.up('xl'),
  xl_down: theme.breakpoints.down('xl'),
};

export default breakpoints;