import { call, put, takeEvery, all } from "redux-saga/effects";
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from "redux/slices/snackbar";
import { setAdminArtistList, setArtistList, setInitialState, setLoading } from "./ArtistManagementSlice";
import { setArtistListService , setAddArtistService, setAdminArtistListService} from "./ArtistManagementService";
import i18n from 'i18next';
import { loading } from "redux/Loader/LoaderSlice";

function* translateAndDispatchError(message) {
  const translation = yield call([i18n, 't'], message);
  yield put(SNACKBAR_ERROR(translation));
}

function* setArtistInitialState() {
  try {
    
    yield put(setInitialState());
  } catch (error) {
    yield translateAndDispatchError(error.message);
  }
}

function* getArtistList(payload){
    let requestId = Math.random();
    try {
      if(payload?.data){
        const { onSuccessCB } = payload;
        yield put (loading({loading:true,requestId}));
        let response = yield call(setArtistListService,payload.data);
        const {data,count} = response;
        onSuccessCB({count,data});
        yield put(setArtistList(data));
        yield put(setLoading(false));
        yield put (loading({loading:false,requestId}));
      }else{
        yield put (loading({loading:true,requestId}));
        let response = yield call(setArtistListService);
        const {data} = response;
        yield put(setArtistList(data));
        yield put(setLoading(false));
        yield put (loading({loading:false,requestId}));
      }
    } catch (error) {
        yield put (loading({loading:false,requestId}));
        yield put(setLoading(false));
        yield translateAndDispatchError(error.message);
    }
}
function* getAdminArtistList(payload){
  let requestId = Math.random();
  try {
      if(payload?.data){
        // const { onSuccessCB } = payload;
        yield put (loading({loading:true,requestId}));
        let response = yield call(setAdminArtistListService,payload.data);
        const {data,count} = response;
        yield put(setAdminArtistList(data));
        if(payload.onSuccessCB){
          payload.onSuccessCB({count,data});
        }
        yield put(setLoading(false));
        yield put (loading({loading:false,requestId}));
      }else{
        yield put (loading({loading:true,requestId}));
        let response = yield call(setAdminArtistListService);
        const {data} = response;
        yield put(setAdminArtistList(data));
        yield put(setLoading(false));
        yield put (loading({loading:false,requestId}));
      }
  } catch (error) {
      yield put (loading({loading:false,requestId}));
      yield put(setLoading(false));
      yield translateAndDispatchError(error.message);
  }
}
function* addArtist(action) {
  let requestId = Math.random();
  if (!action.payload) {
    console.error("addArtist: Missing payload in action object");
    return;
  }
  const { reqPayload, onSuccessCB, onErrorCB } = action.payload;
  try {
      yield put (loading({loading:true,requestId}));
      const data = yield call(setAddArtistService, reqPayload);
      if (data) {
        yield put (loading({loading:false,requestId}));
        yield put(SNACKBAR_SUCCESS(data.message));
        onSuccessCB(data);
      }
  } catch (error) {
    yield put (loading({loading:false,requestId}));
    yield translateAndDispatchError(error.error);
    onErrorCB(error);
  }
}

function* watchSetArtistInitialState() {
  yield takeEvery("setArtistInitialStateAction", setArtistInitialState);
}
function* watchGetArtistList(){
    yield takeEvery('getArtistListAction',getArtistList);
}
function* watchGetAdminArtistList(){
  yield takeEvery('getAdminArtistListAction',getAdminArtistList)
}
function* watchAddArtsit() {
  yield takeEvery('setAddedArtistAction',addArtist)
}
// Actions
export default function* artistManagementSaga() {
  yield all([
    watchSetArtistInitialState(),
    watchGetArtistList(),
    watchAddArtsit(),
    watchGetAdminArtistList()
  ]);
}
