import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Backdrop, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Loader = (props) => {
  const { isOpen, content = "Payment Processing" } = props;
  const { t } = useTranslation();
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: 10000 }}
      open={isOpen}
    >
      <Paper variant="outlined" square sx={{ width: "240px", height: "70px", borderRadius: 2, padding: "12px", display: 'flex', flexDirection: 'row-reverse', justifyContent: 'center' }} >
        <Typography sx={{
          paddingTop: "10px",
          fontWeight: 500,
          marginLeft:"1rem"
        }}>{t(content)}</Typography>
        <CircularProgress title='Process' sx={{ color: "blue" }} />
      </Paper>
    </Backdrop>
  );
}