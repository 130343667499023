import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  open: false,
  title: "Error",
  content: "Oops! Something went wrong",
  buttonText: "Ok"
};

const ErrorModal = createSlice({
  name: 'ErrorModal',
  initialState,
  reducers: {
    openErrorModal(state, action) {
      state.open = true;
      state.title = action.payload?.title || initialState.title;
      state.content = action.payload?.content || initialState.content;
      state.buttonText = action.payload?.buttonText || initialState.buttonText;
    },
    closeErrorModal(state, action) {
      state.open = false;
      state.title = initialState.title;
      state.content = initialState.content;
      state.buttonText = initialState.buttonText;
    }
  },
});

// Reducer
export default ErrorModal.reducer;

// Actions
export const { openErrorModal, closeErrorModal } = ErrorModal.actions;