import { makeStyles, styled } from "@mui/styles";
import React from "react";
import SignUpForm from "views/SignUp/components/SignUpForm";
import SwitchLanguage from "components/Shared/SwitchLanguage";
import Carousels from "components/Shared/Carousels";
import constant from "../../constant/";
import { Grid,Box } from "@mui/material";

const { IMAGES_ARRAY } = constant;

const useStyles = makeStyles({
  signUpBanner: {
    position: "fixed",
    top: 0,
    objectFit: "cover",
    width: "70%",
    height: "100%",
    '@media(max-width:600px)':{
      display:'none'
    },
    // '@media screen (max-width:1468px)':{
    //   top:"-10%",
    //   width:"70%",
    //   height:"110vh"
    // }, 
    // '@media screen and (max-width: 1474px) and (min-width: 1341px)':{
    //   top:"-10%",
    //   width:"70%",
    //   height:"110vh"
    // },
     // small laptops
     '@media screen and (max-width: 1339px) and (min-width: 993px)':{
      position:"absolute",
      top:"-10%",
      width:"65%",
      height:"110vh"
    },
     //very small laptop
     '@media screen and (max-width: 1190px) and (min-width: 994px)':{
      position:"absolute",
      width:"63%",
     },
    // Tabs
    '@media screen and (max-width: 992px) and (min-width: 700px) ':{
      position:"absolute",
      top:"-10%",
      width:"55%",
      height:"110vh"
    },
    '@media screen and (max-width: 760px) and (min-width: 600px) ':{
      display:"none"
    }
  },
  card: {
    position: "absolute",
    right: "1%",
    top: "5%",
    // height: "100px",
    backgroundColor: "white",
    padding: "1rem",
    // maxWidth: "500px",
    width: "420px",
    '@media(max-width:600px)':{
      width:"100%",
      right:"0",
      top:"9%",
      padding:0
    },
    '@media screen (max-width:1468px)':{
      width:"490px !important",
      top:"9%",
      padding:"0",
      right:0
    },
    '@media screen and (max-width: 1474px) and (min-width: 1341px)':{
      width:"360px !important",
      padding:"0",
      right:"2%",
      top:"9%"
    },
     // small laptops
     '@media screen and (max-width: 1339px) and (min-width: 993px)':{
      width:"390px !important",
      top:"8%",
      padding:"0",
      right:"2%"
    },
     //very small laptop
     '@media screen and (max-width: 1190px) and (min-width: 994px)':{
      width:"360px !important",
      right:"1%"
     },
    // Tabs
    '@media screen and (max-width: 992px) and (min-width: 760px) ':{
      width:"340px",
      top:"9%",
      right:"0",
      padding:0
    },
    '@media screen and (max-width: 760px) and (min-width: 600px) ':{
      width:"75%",
      right:"5rem",
      top:'9%',
      padding:0
    }
  },
  header:{
    position: "absolute",
    top: "1rem",
    zIndex: 1,
    backgroundColor: "white",
    width: "430px !important",
    right: "0%",
    display: "flex",
    alignItems: "center",
    '@media(max-width:600px)':{
      width:"100% !important"
    },
    '@media screen and (max-width: 1474px) and (min-width: 1341px)':{
      width:"380px !important",
      right:"1%",
    },
     // small laptops
     '@media screen and (max-width: 1339px) and (min-width: 993px)':{
      width:"400px !important",
      right:"1%"
    },
     //very small laptop
     '@media screen and (max-width: 1190px) and (min-width: 994px)':{
      width:"360px !important",
      right:"1%"
     },
    // Tabs
    '@media screen and (max-width: 992px) and (min-width: 700px)':{
      width:"340px !important"
    },
    '@media screen and (max-width: 760px) and (min-width: 600px) ':{
      width: "100% !important"
    }
  },
  headerButton: {
    '@media(max-width: 760px)' :{
      display: "flex",
      justifyContent: "flex-end",
    }
  }
});

const StyledBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  "& .MuiSvgIcon-root": {
    fontSize: "1.2rem",
    marginTop: "6px",
  },
});

function SignUp() {
  const classes = useStyles();
  const signUpContent = [
    {
      label: "Full Name*",
      name: "fullName",
    },
    {
      label: "Email*",
      name: "emailId",
    },
    {
      label: "Password*",
      name: "password",
    },
    {
      label: "Confirm Password*",
      name: "confirmPassword",
    },
  ];
  return (
    <div>
      <img
        className={classes.signUpBanner}
        src={require("assets/images/login-banner.jpg")}
        alt="login-banner"
      />
      <Carousels
        items={IMAGES_ARRAY}
        originalHeight={'20px'}
        originalWidth={20}
        showBullets={true}
        showNav={false}
        autoPlay={true}
        slideInterval={3000}
        showFullscreenButton={false}
        showPlayButton={false}
      />
       <div style={{overflowY:"hidden"}}>
       <Grid container className={classes.header}>
          <Grid item xs={8}>
            <img src={require("assets/images/logo.png")} className="ml-4" />
          </Grid>
          <Grid item xs={4} className={classes.headerButton}>
            <SwitchLanguage StyledBox={StyledBox} />
          </Grid>
        </Grid>
      <div className={`${classes.card}`}>
        <SignUpForm
          hasFormSubHeadingLink={true}
          signUpContent={signUpContent}
        />
      </div>
       </div>
     
    </div>
  );
}

export default SignUp;
