import React from "react";

function PaymentSetUp() {
  return (
    <div style={{marginTop:'3rem'}}>
     PaymentSetUp 
    </div>
  );
}

export default PaymentSetUp;
