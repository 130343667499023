import { makeStyles } from '@mui/styles';
import {Grid} from '@mui/material';
import React, { useEffect } from 'react';
import ContentItem from 'views/VideoStreaming/components/ContentItem';
import VideoStreamingPlayer from 'views/VideoStreaming/components/VideoStreamingPlayer';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles({
  mainContainer: {
    width: "100%",
    // marginTop:"4rem"
  },
  bannerImage: {
    width: "100%",
    height: "50vh",
  },
  gridContainer: {
    margin: 0,
    '@media (min-width: 950px)': {
      marginTop: '5vh',
      marginLeft: '5vh',
      marginRight: '5vh',
      marginBottom: '5vh',
    },
  }
});

function VideoStreaming() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const params = useParams();
  const { eventData  } = useSelector((state) => state.event);
  const aboutContent = [
  {
      value:"Toasted Life Annual Halloween Day Party"
  },  
  {
    value:"Who's pulling up in the dopest costume this year?! The biggest Bay Area Halloween day party is back and we're ready for all the fire fites this year."
  },
  {
    value:"Wear your best costume and be ready throw down! This event is sells out every year so be sure to buy your tickets in advance."
  },
  {
    value:"Hip/Hop R&B"
  },
  {
    value:"Saturday October 29th / 3PM - 8PM / 21+ "
  }
  ];
  const contentItmeData = {
    mainHeading: "TOASTED LIFE x HALLOWEEN COSTUME DAY PARTY",
    concert: "Concert",
    english: "English",
    year: "18+ year",
    time: "2h 30min",
  }
  useEffect(() => {
    dispatch({ type:"getEventAction", data: {eventId: params?.eventId}  });
  }, []);
  return (
    <Grid className={classes.mainContainer}>   
      <Grid className={classes.gridContainer}>
        <VideoStreamingPlayer />     
        <ContentItem 
        aboutContent={aboutContent}
        contentItmeData={contentItmeData}
        eventData={eventData}
        />
      </Grid>
    </Grid>
  )
}

export default VideoStreaming;