import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';

// Styled CircularProgress component with width and height
const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
    width: '20px !important', // Set the desired width
    height: '20px !important', // Set the desired height
    color:"#fff"
  }));

function ButtonLoader() {
  return (
    <StyledCircularProgress />
  )
}

export default ButtonLoader