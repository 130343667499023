import { config } from 'config.js';
import { xhrClient } from 'views/utilities/DataRequest';
const { isProd } = config;
const API_ENDPOINT = isProd
    ? config.production.api_endpoint
    : config.development.api_endpoint;

const getReportsService = (data, page) => {
    if (data) {
        const { fromDate, toDate, ticketType } = data;
        return xhrClient.get(`${API_ENDPOINT}/reports?pageLimit=10&pageNumber=${page - 1}${fromDate&&"&fromDate="}${fromDate ? fromDate : ""}${toDate&&"&toDate="}${toDate ? toDate : ""}${ticketType&&"&ticketType="}${ticketType?ticketType:""}`);
    } else {
        return xhrClient.get(`${API_ENDPOINT}/reports?pageLimit=10&pageNumber=${page - 1}`);
    }
}
const getTicketTypeService = () => {
    return xhrClient.get(`${API_ENDPOINT}/getBookingType`);
}
const getReportsCommissionService = (data, page) => {
    if (data) {
        const { partnerName, ticketingType } = data;
        return xhrClient.get(`${API_ENDPOINT}/commission-report?pageLimit=10&pageNumber=${page - 1}&partnerName=${partnerName ? partnerName : ""}&ticketType=${ticketingType}`);
    } else {
        return xhrClient.get(`${API_ENDPOINT}/commission-report?pageLimit=10&pageNumber=${page - 1}`);
    }
}
export {
    getReportsService,
    getTicketTypeService,
    getReportsCommissionService
}