import { makeStyles } from '@mui/styles';
import React from 'react';
import ImageGallery from "react-image-gallery";

const useStyles = makeStyles({
  Carousel: {
    width: "30%",
    zIndex: '1',
    position: "absolute",
    top: "30%",
    left: "20%",
    '@media(max-width:600px)': {
      width: "90%",
      top: "570px",
      left: "5%",
      bottom: "5%",
      paddingBottom: "20rem",
      zIndex: 1
    },
    '@media screen and (max-width: 760px) and (min-width: 600px)': {
      width: "90%",
      top: "570px",
      left: "5%",
      bottom: "5%",
      paddingBottom: "20rem",
      zIndex: 1
    },
    '@media screen and (max-width: 992px) and (min-width: 760px) ': {
      left: "3%",
      width: "50%",
    },
    '& .image-gallery-slides':{
      borderRadius:'5px',
    },
    '& .image-gallery-slide': {
      height: '220px',
      '& .image-gallery-image': {
        height: '100%',
        objectFit: 'cover'
      }
    }
  }
})
const Carousels = (props) => {
  const classes = useStyles();
  const { items, originalHeight, originalWidth, showBullets, showNav, autoPlay, slideInterval, showFullscreenButton, showPlayButton, originalClass } = props;
  return (
    <div className={classes.Carousel}>
      <ImageGallery
        items={items}
        originalHeight={originalHeight}
        originalWidth={originalWidth}
        showBullets={showBullets}
        showNav={showNav}
        autoPlay={autoPlay}
        slideInterval={slideInterval}
        showFullscreenButton={showFullscreenButton}
        showPlayButton={showPlayButton}
        originalClass={originalClass}
      />
    </div>
  );
}

export default Carousels;