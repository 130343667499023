import { call, put, takeEvery, all } from "redux-saga/effects";
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from "redux/slices/snackbar";
import { getReportsCommissionService, getReportsService, getTicketTypeService } from "./DashboardManagementService";
import { setCommissionReportsData, setReportsData, setTicketType } from "./DashboardManagementSlice";
import i18n from 'i18next';

function* translateAndDispatchError(message) {
  const translation = yield call([i18n, 't'], message);
  yield put(SNACKBAR_ERROR(translation));
}

function* getAdminReport(payload) {
  try {
    const { data, page } = payload;
    let response;
    if (data) {
      response = yield call(getReportsService, data, page);
    } else {
      response = yield call(getReportsService, null, page);
    }
    yield put(setReportsData(response));
  } catch (error) {
    yield translateAndDispatchError(error);
    console.error(error)
  }
}
function* getTicketType() {
  try {
    const response = yield call(getTicketTypeService);
    yield put(setTicketType(response));
  } catch (error) {
    console.error(error)
  }
}
function* getCommissionReports(payload) {
  try {
    const { data, page } = payload;
    let response;
    if (data) {
      response = yield call(getReportsCommissionService, data, page);
    } else {
      response = yield call(getReportsCommissionService, null, page);
    }
    if(response.error){
      yield translateAndDispatchError(response.error);
    }else{
      yield put(setCommissionReportsData(response));
    }
  } catch (error) {
    console.error(error);
  }
}
function* watchGetAdminReportAction() {
  yield takeEvery("getAdminReportAction", getAdminReport);
}
function* watchGetTicketTypeAction() {
  yield takeEvery("getTicketTypeAction", getTicketType);
}
function* watchGetCommissionReportsAction() {
  yield takeEvery('getCommissionReportsAction', getCommissionReports);
}

// Actions
export default function* dashboardManagementSaga() {
  yield all([
    watchGetAdminReportAction(),
    watchGetTicketTypeAction(),
    watchGetCommissionReportsAction()
  ]);
}
