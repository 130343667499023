import { createSlice } from '@reduxjs/toolkit';
import { fieldLevelValidation, isEmpty , removeLocalData, validateEmailOtp } from 'utils';
import axios from 'axios';
import JwtDecode from 'jwt-decode';
const initialState = {
    isSignup:false,
    isLogout: false,
    emailId:"",
    isPasswordChanged:false,
    isResetConfirmPassword:false,
    isResetPassword:false,
    isEmailVerified:false,
    isAuthenticated: false,
    admin:false,
    userInfo:{},
    isInitialised: true,
    isDetailsPage:false,
    countryRecords:[],
    stateRecords:[],
    errorState: {
        userDetails: {
            fullName: {
                error: false,
                errorMsg: "Min 3 characters & max 15 characters & alphabets only"
            },
            emailId: {
                error: false,
                errorMsg: "Please enter a valid mail"
            },
             email: {
                error: false,
                errorMsg: "Please enter a valid mail"
            },
            password: {
                error: false,
                errorMsg: "Min 8 characters with (uppercase, lowercase, numeric and special characters)"
            },
            confirmPassword:{
                error:false,
                errorMsg:"Passwords are not same"
            },
            oldPassword:{
                error:false,
                errorMsg: "Enter correct old password"
            }
        },
    },
}

const authInfoSlice = createSlice({
    name: 'authInfo',
    initialState,
    reducers: {
        setInitialState(state, action) {
            state.isInitialised = true;
            state.isLogout=initialState.isLogout;
            state.isAuthenticated=initialState.isAuthenticated;
           // state.userInfo = initialState.userInfo;
            state.errorState = initialState.errorState;
        },
        setAuthorization(state, action) {
            const { token,eventId,verificatonStatus,emailId,id} = action.payload;
            if (token) {
                const decoded_token = JwtDecode(token);
                const userType=decoded_token.typeOfUser === 'ADMIN';
                state.admin = userType;
                state.isAuthenticated = true;
                state.userInfo = action.payload;
                state.emailId = emailId;
                localStorage.setItem('emailId', emailId);
                localStorage.setItem('userId',id);
                setSession(token,eventId,verificatonStatus);
            } else {
                setSession("");
            }
        },
        setResetToken(state, action) {
            const {token,verificatonStatus} = action.payload;
            if (token) {
                state.userInfo.token = token;
                setSession(token,null,verificatonStatus);
            }
        },
        removeAuthorization(state, action) {
               const { message } = action.payload;
               if(message) {
                setSession(null); 
                state.isLogout = !state.isLogout;   
               }                
        },
        setLoginDetails(state, action) {
            const { name, value } = action.payload;
            //..Validation
            let isError = false;
            if (['emailId', 'password'].includes(name)) {
                if (!isEmpty(value) && fieldLevelValidation(name, value)) {
                    state.errorState.userDetails[name].error = true;
                    isError = true;
                }
                else {
                    state.errorState.userDetails[name].error = false;
                    isError = false;
                }
            }
        },
        setSignUpDetails(state, action) {
            const { name, value } = action.payload;
            //..Validation
            let isError = false;
            if (['email', 'fullName', 'password','confirmPassword'].includes(name)) {
                if (!isEmpty(value) && fieldLevelValidation(name, value)) {
                    state.errorState.userDetails[name].error = true;
                    isError = true;
                }
                else {
                    state.errorState.userDetails[name].error = false;
                    isError = false;
                }
            }
        },
        setSignUpBasicDetails(state,action){
            state.isDetailsPage=!state.isDetailsPage;
        },
        setIsSignup(state,action) {
            localStorage.setItem('emailId', action.payload);
            state.isSignup = true;
        },
        setIsEmailVerified(state,action) {
            state.isSignup = false;
            if(state.isResetPassword === true){
                state.isResetConfirmPassword = true;
            } else{
                state.isEmailVerified = true;
                const accessToken = localStorage.getItem("accessToken");
                setSession(accessToken, null, "EMAIL_VERIFIED");
            }
        },
        setIsResetPassword(state,action){
            const {  status } = action.payload;
            if(status==="Success"){
                state.isResetPassword = true;
            }
            state.isPasswordChanged = false;
        },
        setIsResetPasswordConfirmed(state,action){
            state.isPasswordChanged = true;
            state.isResetPassword = false;
            state.isEmailVerified = false;
            state.isResetConfirmPassword=false;
            localStorage.setItem('emailId', '');
        },
        setCountryRecords(state,action){
            state.countryRecords = action.payload;
        },
        setStateRecords(state,action){
            state.stateRecords = action.payload;
        },
        setPreviosStateData(state,action){
           let data = action.payload;
           data.map(item => state[item.state] = item.value)
        }
        
    },
});

export const setSession = (accessToken,eventId,verificatonStatus) => {
    if(verificatonStatus){
        localStorage.setItem('verificatonStatus',verificatonStatus);
    }
    if(eventId){
        localStorage.setItem('eventId',eventId);
    }
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        removeLocalData(["accessToken","eventId","verificatonStatus","userId","emailId"]);
        delete axios.defaults.headers.common.Authorization;
    }
}
export default authInfoSlice.reducer;
// Actions
export const {
    setInitialState,
    setLoginDetails,
    setAuthorization,
    setResetToken,
    removeAuthorization,
    setSignUpDetails,
    setSignUpBasicDetails,
    setIsSignup,
    setIsEmailVerified,
    setIsResetPassword,
    setIsResetPasswordConfirmed,
    setCountryRecords,
    setStateRecords,
    setPreviosStateData,
} = authInfoSlice.actions;
