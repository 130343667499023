import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  loadingArray:[]
};

const Loader = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setLoaderInitialState(state, action) {
      state.loadingArray = initialState.loadingArray;
    },
    loading(state, action) {
      const{loading,requestId} = action.payload;
      if(loading){
        state.loadingArray = [...state.loadingArray,requestId]
      }else{
        const index =  state.loadingArray.findIndex(loadingObj => loadingObj === requestId);
        if (index !== -1) {
          state.loadingArray.splice(index, 1);
        }
      }
    },
  },
});

// Reducer
export default Loader.reducer;

// Actions
export const { loading,setLoaderInitialState } = Loader.actions;