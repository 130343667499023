
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Footer from 'views/Footer';
import Header from 'views/Header';

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        minHeight: "90.5vh",
        marginTop: '9.5vh'
    }
});

function CommonLayout() {
    const classes = useStyles();

    return (
        <div >
            <div className={classes.container}>
                <Header />
                <Outlet />
                <Footer />
            </div>
        </div>
    )
}

export default CommonLayout;