import { createSlice } from '@reduxjs/toolkit';
import { blobToBase64 } from 'helper';
import { SNACKBAR_ERROR } from 'redux/slices/snackbar';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';

const initialState = {
    artistMedia: [],
    allEventsData: [],
    allAdminEventsData:[],
    eventData:{},
    couponData:{},
    eventBookingDetails:{},
    artistMediaUrl:'',
    eventMediaUrl:'',
    ticketMediaUrls:[],
    myTickets:[],
    loading:true,
    couponCode:"",
    artistEventDetails:{},
    artistMediaId:null,
    eventsTableHeaders: [
        { key: "eventTitle", name: "Event Title", hasSorting: false },
        { key: "shortDescription", name: "Short description", hasSorting: false },
        { key: "startDate", name: "Start Date and Time", hasSorting: false },
        // { key: "endDateAndTime", name: "End Date and Time", hasSorting: true },
        { key: "actionHeader", name: "Actions",icon:<ModeEditIcon/>}
    ],
}

const eventInfoSlice = createSlice({
    name: 'eventInfo',
    initialState,
    reducers: {
        setInitialState(state, action) {
            state.artistMedia = initialState.artistMedia;
            state.ticketMediaUrls = initialState.ticketMediaUrls;
            state.artistEventDetails = initialState.artistEventDetails;
        },
        setCouponCode(state,action){
            state.couponCode=action.payload;
        },
        setArtistMedia(state,action){
            state.artistMedia = [...state.artistMedia, action.payload];
        },
        setEventMedia(state,action){
           state.eventMediaUrl = action.payload;
        },
        setTicketMedia(state,action){
            state.ticketMediaUrls=[...state.ticketMediaUrls,action.payload];
        },
        setEventData(state,action){
            const {data} = action.payload
            state.eventData = data;
        },
        setCouponDetails(state,action){
            state.couponData = {...action.payload,isCouponApplied:true};
        },
        setRemoveCoupon(state) {
            state.couponData = {}
        },
        setEventBookingDetails(state,action){
            state.eventBookingDetails = { ...action.payload, isEventBookingDone: true};
        },
        removeEventBookingDetails(state,action){
            state.eventBookingDetails = { ...action.payload, isEventBookingDone: false};
        },
        setMyTickets(state,action){
            state.myTickets=action.payload;
        },
        setLoading(state,action){
            state.loading=action.payload;
        },
        setPreviosStateData(state,action){
            let data = action.payload;
            data.map(item => state[item.state] = item.value)
         },
        setArtistEvents(state,action){
            state.artistEventDetails = action.payload;
        },
        emptyArtistMedia(state,action){
            state.artistMedia = [];
        },
        setArtistMediaId(state,action){
            state.artistMediaId = action.payload;
        },
        setAllEvents(state,action){
            state.allEventsData = action.payload;
        },
        setAllAdminEvents(state,action){
            state.allAdminEventsData = action.payload;
        }
    },
});

export default eventInfoSlice.reducer;
// Actions
export const {
    setInitialState,
    setCouponCode,
    setArtistMedia,
    setEventData,
    setCouponDetails,
    setRemoveCoupon,
    setEventBookingDetails,
    setEventMedia,
    setTicketMedia,
    setMyTickets,
    setLoading,
    setPreviosStateData,
    setArtistEvents,
    emptyArtistMedia,
    setArtistMediaId,
    setAllEvents,
    removeEventBookingDetails,
    setAllAdminEvents
} = eventInfoSlice.actions;
