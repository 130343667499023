import React, { useDebugValue } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import i18n from "i18n";
import { KeyboardArrowDown, Translate } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { getLocalData, setLocalData } from "utils";
import { useEffect } from "react";
import { ClickAwayListener } from "@material-ui/core";
import LanguageIcon from '@mui/icons-material/Language';
import TranslateImg from '../../../assets/icons/Translate.svg';

const useStyles = makeStyles({
  userMenuPaper: {
    width: 120,
  },
  selectedLang: {
    background: "rgba(0, 0, 0, 0.04) !important"
  },
  languageText: {
    marginTop: "1.1rem",
    paddingLeft: '0.3rem',
    paddingRight: '0.4rem',
    textTransform: 'none',
    fontSize: 13
  }
});

const SwitchLanguage = (props) => {
  const classes = useStyles();
  const [anchorElLang, setAnchorElLang] = React.useState(null);
  const [lang, setLang] = React.useState('en');
  const handleOpenLangMenu = (event) => {
    setAnchorElLang(event.currentTarget);
  };
  const handleCloseLangMenu = (lan) => {
    setLang(lan)
    setLocalData('lan', lan)
    i18n.changeLanguage(lan);
    setAnchorElLang(null);
  };

  useEffect(() => {
    if(getLocalData('lan')){
      setTimeout(() => {
        i18n.changeLanguage(getLocalData('lan'));
        setLang(getLocalData('lan'))
      }, 1000)
    }
  }, []);
  useEffect(()=>{
    if(props.language){
      setLang(props.language);
      setLocalData('lan', props.language)
      i18n.changeLanguage(props.language);
    }
  },[props]);
  
  return (
    <>
      <ClickAwayListener onClickAway={() => setAnchorElLang(null)}>
        <Button
          onClick={handleOpenLangMenu}
          variant="contained"
          style={{
            display: "flex",
            alignItems: "center",
            padding: "5px 10px",
            backgroundColor: "#1c6afc17",
            borderRadius: 5,
            boxShadow: "none",
            color: "#000",
            height: 37,
            margin: "0 10px",
          }}
        >
          <img src={TranslateImg} className="px-1"/>
          <p className={classes.languageText}>{lang === 'en' ? 'English' : lang === 'es' ? 'Spanish' : ''}</p>
          <KeyboardArrowDown fontSize="small" />
        </Button>
      </ClickAwayListener>

      <Menu
        sx={{ mt: "35px" }}
        classes={{ paper: classes.userMenuPaper }}
        id="menu-appbar"
        anchorEl={anchorElLang}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElLang)}
      >
        <MenuItem onClick={() => handleCloseLangMenu("en")} className={lang === 'en' ? classes.selectedLang : ''}>
          <div>
            <span style={{ fontSize: 12 }}>English</span>
          </div>
        </MenuItem>
        <MenuItem onClick={() => handleCloseLangMenu("es")} className={lang === 'es' ? classes.selectedLang : ''}>
          <div>
            <span style={{ fontSize: 12 }}>Spanish</span>
          </div>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SwitchLanguage;
