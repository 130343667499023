import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    ticketShareAndRedeemSuccess:false
}

const ticketInfoSlice = createSlice({
    name: 'ticketInfo',
    initialState,
    reducers: {
        setTicketInitialState(state, action) {
           state.ticketShareAndRedeemSuccess = initialState.ticketShareAndRedeemSuccess
        },
        setTicketShareAndRedeemSuccess(state,action){
            state.ticketShareAndRedeemSuccess = action.payload;
        }
    },
});

export default ticketInfoSlice.reducer;
// Actions
export const {
    setTicketInitialState,
    setTicketShareAndRedeemSuccess
} = ticketInfoSlice.actions;
