import { Button, Divider, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles'
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddRemoveItem from 'views/TicketTypeDonation/components/AddRemoveItem';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useNavigate } from 'react-router';
import DataTable from 'components/Shared/Table';
import { useTranslation } from 'react-i18next';

function Cart() {
    const { t } = useTranslation();
    const useStyles = makeStyles({
        cartItemContainer: {
            width: "100%",
            minHeight: "75vh",
            display: 'grid',
            placeItems: 'center',
            // marginTop: "0.5rem",
        },
        orderSummaryContainer: {
            width: "100%",
            margin:'1.5rem 0.5rem !important'
            // position: 'relative'
            // display: 'grid',
            // placeItems: 'center'
        },
        cartItem: {
            borderRadius: "10px",
            height: "auto",
            boxShadow: "0 1px 2px 1px #00000026",
            margin:'1.5rem 0.5rem !important'
        },
        orderSummary: {
            borderRadius: "10px",
            background: "#fff",
            margin:'1.5rem 0.5rem !important',
            boxShadow: "0 1px 2px 1px #00000026",
            height: "auto",
            width: "32.33%",
            marginTop: "3.5rem",
            top: "4rem"

        },
        checkoutBtn: {
            background: "#1c6afc !important",
            borderRadius: "100px !important",
            alignSelf: 'center',
            textTransform:'uppercase',
            width: "90%",
            marginTop: '1rem !important'
        }
    })
    const classes = useStyles();
    const [count, setCount] = useState(1);
    const [totalCartItems, setTotalCartItems] = useState(0);
    const [totalCartPrice, setTotalCartPrice] = useState(0);
    const [tableData,setTableData] = useState([]);
    const { cartItems,cartTableHeaders } = useSelector((state) => state.cart);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        if (cartItems.length > 0) {
            let totalItem = 0;
            let totalCartAmount = 0;
            cartItems.map((cart) => {
                totalItem = totalItem + cart?.userCart[0]?.quantity;
                totalCartAmount = totalCartAmount + Number(cart.totalPrice);
                setTotalCartItems(totalItem);
                setTotalCartPrice(totalCartAmount);
                setCount(cart.quantity);
            })
        }
    }, [cartItems]);

    useEffect(()=>{
        dispatch({
            type:"getCartItemsAction"
        })
    },[]);

    const handleAddItem = (cart) => {
        dispatch({
            type:'addToCartAction',
            data:{
              selectedTicket:{bookingType:cart.bookingType},
              eventId:cart.eventId,
              count:cart.quantity+1,
              actionType:'add'
            }
          })
    }
    const handleSubstractItem = (cart) => {
        dispatch({
            type:'addToCartAction',
            data:{
              selectedTicket:{bookingType:cart.bookingType},
              eventId:cart.eventId,
              count:cart.quantity-1,
              actionType:'substract'
            }
          })
    }
    const handleRemoveItem = (cart) => {
        const {eventId} = cart;
        dispatch({
            type: "removeFromCartAction",
            data: {
                eventId,
                showMessage:'true'
            }
        })
    }
    const proceedToCheckOut = (eventId, priceId, count) => {
        navigate(`/events/${eventId}/checkout?priceId=${priceId}&count=${count}`);
    }

   useEffect(()=>{
    let ticketCart = [];
    cartItems.map((cart)=>{
        let cartItem = {...cart?.userCart[0]}
        cartItem.bookingTypeLabel = cart.bookingTypeLabel;
        cartItem.bookingType = cart.bookingType;
        cartItem.priceId = cart.priceId;
        ticketCart.push(cartItem);
    });
    setTableData(ticketCart);
   },[cartItems]);
    
    return (
        <>
            {cartItems.length === 0 ?
                <Grid className={classes.cartItemContainer}>
                    <h1 className='flex items-center'>{t("Your Cart Is Empty")} <ShoppingCartOutlinedIcon sx={{ mr: 1, ml: 2, cursor: 'pointer', fontSize: '2.5rem', color: '#1c6afc' }} /></h1>
                </Grid> :
                <>
                 {/* <h2 className='mx-5 my-3'>Your Cart</h2> */}
                <Grid conatiner spacing={2} className='flex mt-5'>
                    <Grid item lg={8} className={classes.cartItem}>
                        <DataTable tableHeading={cartTableHeaders} tableData={tableData} handleAction={handleRemoveItem} handleAddItem={handleAddItem} handleSubstractItem={handleSubstractItem} setCount={setCount}/>    
                    </Grid>

                    <Grid item lg={4} className={classes.orderSummary}>
                        <Grid className='flex justify-center w-full'>
                            <Button onClick={() => proceedToCheckOut(cartItems[0]?.userCart[0]?.eventId, cartItems[0]?.priceId, cartItems[0]?.userCart[0]?.quantity)} className={classes.checkoutBtn} variant='contained'>{t("continue to checkout")}</Button>
                        </Grid>
                        <Divider
                            sx={{
                                marginTop: "15px",
                            }}
                        />
                        <Grid className='flex justify-between mt-5 p-3'>
                            <span className='font-bold'>{t("Subtotal")} <span className='font-light'>({totalCartItems} {totalCartItems === 1 ? t("item") : t("items")})</span></span>
                            <span>${String(cartItems[0].total)}</span>
                        </Grid>
                        <Divider
                            sx={{
                                marginTop: "15px",
                            }}
                        />
                        <Grid className='flex justify-between mt-2 p-3'>
                            <span className='font-bold'>{t("Tax")}</span>
                            <span>{t("Calculated At Checkout")}</span>
                        </Grid>
                    </Grid>

                </Grid>
                </>
            }
        </>
    )
}

export default Cart