import { TextField, Button, InputAdornment, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";

const useStyles = makeStyles({
    label: {
        fontWeight: "600",
        fontSize: "0.8rem",
        color: "#756F86"
    },
    formButton: {
        width: "100%",
        backgroundColor: "#616161 !important",
        textTransform:'capitalize !important'
    },
    resetFormContainer: {
        marginRight: "1.75rem",
        '@media(max-width:600px)': {
            marginRight: "0 !important"
        }
    }
});
const  ResetConfirmPasswordForm = (props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [disableButton,setDisableButton]= useState(true);
    const { 
        formContent: {formHeading,formSubHeading,buttonText,formLabels} 
     } = props;
     const [values, setValues] = React.useState({
        password: '',
        confirmPassword: '',
    });
    const dispatch = useDispatch();
    const { errorState,isPasswordChanged } = useSelector((state) => state.auth)
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [queryToken,setQueryToken]  = useState("");
    const handleChange = (prop) => (event) => {
        const { value, name } = event.target;
        setValues({ ...values, [prop]: value });
        if (['password','confirmPassword'].includes(name)) {
            dispatch({
                type: "setAuthenticationFormAction",
                name,
                value
            })
        }

    };
    const handleReset = (event) => {
        event.preventDefault();
        dispatch({
            type: "setAuthenticationConfirmPasswordAction",
            data: {
                "confirmPassword": values.confirmPassword,
                "password": values.password,
                "token": queryToken
            }
        })
        setDisableButton(true);
    }
    const handleClickShowPassword = (name) => {
        if (name === 'password') {
          setShowPassword(!showPassword);
        } else {
          setShowConfirmPassword(!showConfirmPassword);
        }
    }
    useEffect(()=>{
        if(isPasswordChanged){
            navigate('/login') 
        }
        if( values.password && values.confirmPassword && !errorState.userDetails.password.error && !errorState.userDetails.confirmPassword.error && (values.password  === values.confirmPassword)){
            setDisableButton(false);
        }else{
            setDisableButton(true);
        }
    },[isPasswordChanged,values,errorState]);

    useEffect(()=>{
        let currentUrl = window.location.href.split('=');
        let token = currentUrl[currentUrl.length-1];
        setQueryToken(token);
      },[]);

    return (
        <div  className={`p-3 mt-15 ${classes.resetFormContainer}`}>
            <form onSubmit={handleReset}>
            <h3>{t(formHeading)}</h3>
            <span className='text-12 font-medium mr-1'>{t(formSubHeading)}</span>
 
            <div className='mt-5'>
                {formLabels.length && formLabels.map((el, index) => {
                    return (
                        < div key={index}>
                            <label className={classes.label}>{t(el.label)}</label><br />
                            <TextField name={el.name} fullWidth onChange={handleChange(el.name)} className='mb-5 mt-1' sx={{
                                "& .MuiInputBase-root": {
                                    height: 45
                                }
                            }}
                            value={values[el.name]}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    {(['password', 'confirmPassword'].includes(el.name)) && <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => handleClickShowPassword(el.name)}
                                    >
                                        {!(showPassword && el.name === "password" || showConfirmPassword && el.name === "confirmPassword") ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                    </IconButton>}
                                </InputAdornment>,
                            }}
                            type={
                                (!showPassword && el.name === "password" || !showConfirmPassword && el.name === "confirmPassword") ? "password"
                                    : "text"
                            }
                                error={errorState
                                    && errorState.userDetails
                                    && errorState.userDetails[el.name]
                                    && errorState.userDetails[el.name].error
                                    || (
                                        values["password"] !== values["confirmPassword"]
                                        && values["confirmPassword"] !== "" && values["password"] !== ""
                                    )}
                                helperText={errorState
                                    && errorState.userDetails
                                    && errorState.userDetails[el.name]
                                    && errorState.userDetails[el.name].error
                                    && t(errorState.userDetails[el.name].errorMsg)
                                    || (
                                        ["confirmPassword"].includes(el.name) &&
                                        values["password"] !== values["confirmPassword"] &&
                                        values["confirmPassword"] !== "" && values["password"] !== "" &&
                                        t(errorState.userDetails[el.name].errorMsg)
                                    )
                                }
                            /><br />
                        </div>
                    )
                })}

                <Button disabled={disableButton} type="submit" className={`${classes.formButton} mb-5 pt-3 pb-3 mt-3 right-gradient flex items-center`} variant='contained'>{t(buttonText)}</Button>
            </div>
            </form>
        </div>
    )
}

export default ResetConfirmPasswordForm