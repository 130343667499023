import React from "react";

function DiscountCodeSetUp() {
  return (
    <div style={{marginTop:'3rem'}}>
     DiscountCodeSetUp 
    </div>
  );
}

export default DiscountCodeSetUp;
