import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    reportsTableHeaders: [
        { key: "date", name: "Date Of Concert", hasSorting: true },
        { key: "event", name: "Event Name", hasSorting: true , type:'Link' },
        { key: "sold", name: "Sold Tickets", hasSorting: true },
        { key: "available", name: "Available Tickets", hasSorting: true },
        { key: "total", name: "Total Amount", hasSorting: true },
        { key: "status", name: "Event Status", hasSorting: true },
        // { key: "actionHeader", name: "Actions",icon:[{iconValue:<ModeEditIcon/>,iconName:'edit'}] }
    ],
    reportSingleConcertTableHeaders:[
        { key: "date", name: "Booking Date", hasSorting: true },
        { key: "type", name: "Ticket Type", hasSorting: true  },
        { key: "sold", name: "Sold Tickets", hasSorting: true },
        { key: "available", name: "Available Tickets", hasSorting: true },
        { key: "total", name: "Revenue", hasSorting: true } 
    ],
    reportSingleConcertTableData:[
        {date:'23-12-01',type:'Live Streaming With Commercials',sold:'235',available:'15',total:'10'},
        {date:'23-12-01',type:'Live Streaming With Commercials',sold:'235',available:'15',total:'10'},
        {date:'23-12-01',type:'Live Streaming With Commercials',sold:'235',available:'15',total:'10'},
        {date:'23-12-01',type:'Live Streaming With Commercials',sold:'235',available:'15',total:'10'},
        {date:'23-12-01',type:'Live Streaming With Commercials',sold:'235',available:'15',total:'10'},
        {date:'23-12-01',type:'Live Streaming With Commercials',sold:'235',available:'15',total:'10'},
        {date:'23-12-01',type:'Live Streaming With Commercials',sold:'235',available:'15',total:'10'},
        {date:'23-12-01',type:'Live Streaming With Commercials',sold:'235',available:'15',total:'10'},
        {date:'23-12-01',type:'Live Streaming With Commercials',sold:'235',available:'15',total:'10'},
        {date:'23-12-01',type:'Live Streaming With Commercials',sold:'235',available:'15',total:'10'},
    ],
    reportsTableData:[
        // {date:'23-12-01',event:'Arijit Singh Lucknow Tour',sold:'235',available:'15',total:'10',status:'Completed'},
        // {date:'23-12-01',event:'Arijit Singh Lucknow Tour',sold:'235',available:'15',total:'10',status:'Bookings Closed'},
        // {date:'23-12-01',event:'Arijit Singh Lucknow Tour',sold:'235',available:'15',total:'10',status:'Completed'},
        // {date:'23-12-01',event:'Arijit Singh Lucknow Tour',sold:'235',available:'15',total:'10',status:'Completed'},
        // {date:'23-12-01',event:'Arijit Singh Lucknow Tour',sold:'235',available:'15',total:'10',status:'Completed'},
        // {date:'23-12-01',event:'Arijit Singh Lucknow Tour',sold:'235',available:'15',total:'10',status:'Completed'},
        // {date:'23-12-01',event:'Arijit Singh Lucknow Tour',sold:'235',available:'15',total:'10',status:'Completed'},
        // {date:'23-12-01',event:'Arijit Singh Lucknow Tour',sold:'235',available:'15',total:'10',status:'Completed'},
        // {date:'23-12-01',event:'Arijit Singh Lucknow Tour',sold:'235',available:'15',total:'10',status:'Completed'},
        // {date:'23-12-01',event:'Arijit Singh Lucknow Tour',sold:'235',available:'15',total:'10',status:'Completed'},

    ]
}

const reportsInfoSlice = createSlice({
    name: 'reportsInfo',
    initialState,
    reducers: {
        setInitialState(state, action) {
           
        },
        
    },
});

export default reportsInfoSlice.reducer;
// Actions
export const {
    setInitialState,
   
} = reportsInfoSlice.actions;
