import React from 'react'
import Pagination from '@mui/material/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage } from 'redux/DashboardManagement/DashboardManagementSlice';

function Paginate({ paginate, pageMode, activePage,totalRecords }) {
  const dispatch = useDispatch();
  const handlePagination = (event, page) => {
    paginate(page);
  }
  return (
    <Pagination
      page={activePage}
      count={Math.ceil(totalRecords / 10)}
      onChange={(event, page) => handlePagination(event, page)}
    />
  )
}

export default Paginate