import { all } from 'redux-saga/effects';
import authManagementSaga from './AuthManagement/AuthManagementSaga';
import cardValidationSaga from './CardValidation/CardValidationSaga';
import dashboardManagementSaga from './DashboardManagement/DashboardManagementSaga';
import eventManagementSaga from './EventManagement/EventManagementSaga';
import locationSaga from './Location/LocationSaga';
import profileManagementSaga from './ProfileManagement/ProfileManagementSaga';
import artistManagementSaga from './ArtistManagement/ArtistManagementSaga'
import ticketManagementSaga from './TicketManagement/TicketManagementSaga';
import cartManagementSaga from './CartManagement/CartManagementSaga';
import reportsManagementSaga from './ReportsManagement/ReportsManagementSaga';
function* rootSaga() {
  yield all([
    authManagementSaga(),
    eventManagementSaga(),
    profileManagementSaga(),
    cardValidationSaga(),
    dashboardManagementSaga(),
    locationSaga(),
    artistManagementSaga(),
    ticketManagementSaga(),
    cartManagementSaga(),
    reportsManagementSaga()
  ]);
}

export default rootSaga;