import { config } from 'config.js';
import { xhrClient } from 'views/utilities/DataRequest';
const { isProd } = config;
const API_ENDPOINT = isProd
    ? config.production.api_endpoint
    : config.development.api_endpoint;

const saveCardTokenService = async (data) => {
    return xhrClient.put(`${API_ENDPOINT}/user-payment-token`, null, data);
}

const updateCardTokenService = async (data) => {
    return xhrClient.put(`${API_ENDPOINT}/default-card`, null, data);
}

const getCardsListService = () => {
    return xhrClient.get(`${API_ENDPOINT}/events/cards`);
}

export { saveCardTokenService, getCardsListService, updateCardTokenService };