import { Grid, Chip, styled, Select, MenuItem, Button } from '@mui/material';
import { makeStyles } from "@mui/styles";
import TagFacesIcon from '@mui/icons-material/TagFaces';
import React, { useState } from "react";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles({
  root: {
    '& .MuiPickersToolbar-toolbar': {
      backgroundColor: "red !important",
    },
  },
  label: {
    fontWeight: "600",
    fontSize: "0.8rem",
    color: "#756F86",
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    "& li": {
      fontWeight: 200,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: "12px",
    },
    "& li.Mui-selected": {
      width: '100%',
    },
    "& li.Mui-selected:hover": {
      width: '100%'
    },
    "& li:hover": {
      width: '100%'
    }
  },
  chipContainer: {
    display: "flex",
    listStyle: "none",
    alignItems: "center",
    flexDirection: "row",
    '@media(max-width:600px)': {
      flexDirection: "column !important",
      marginBottom: "1.5rem !important",
      marginLeft: "0"
    },
    '@media screen and (max-width: 992px) and (min-width: 700px)': {
      width: "70%",
      marginBottom: "1.5rem !important"
    }
  },
  container: {
    '@media(max-width:600px)': {
      flexDirection: "column !important"
    },
    '@media screen and (max-width: 992px) and (min-width: 700px)': {
      flexDirection: "column !important",
      alignItems: "baseline !important"
    }
  },
  formButton: {
    padding: "0.5rem !important",
    marginBottom: "1.1rem !important",
    '@media(max-width:600px)': {
      marginTop: "0.5rem !important"
    }
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    '@media(max-width:600px)': {
      flexDirection: "column !important",
      alignItems: "center !important",
      marginLeft: "0.5rem"
    },

  },
  dateIcon: {
    fontSize: "1.1rem !important",
    color: "black",
    marginRight: "0.7rem"
  }
});

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const ReportInsightHeader = ({ data }) => {
  const classes = useStyles();
  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper,
    },
  }
  const [chipData, setChipData] = useState([]);
  const { currentPage } = useSelector((state) => state.dashboard);
  const [ischipDeleted, setIsChipDeleted] = useState(false);
  const [defaultSelectValue, setDefaultSelectValue] = useState("");
  const [filter, setFilter] = useState({
    ticketingType: "",
    partnerName: "",
  });
  const dispatch = useDispatch();
  const handleDelete = (chipToDelete) => () => {
    if(chipToDelete==='ticketingType'){
      setDefaultSelectValue("Live Streaming");
    }
    setIsChipDeleted(true);
    chipData.map((chip) => {
      for (const key of Object.keys(chip)) {
        if (key === chipToDelete) {
          setFilter((prev) => ({
            ...prev,
            [key]: ""
          }));
        }
      }
    })
  };
  const handleFilter = (filter) => {
      const { name, value } = filter.target;
      if(name==='ticketingType'){
        setDefaultSelectValue("");
      }
      setFilter((prev) => ({
        ...prev,
        [name]: value
      }));
  }
  useEffect(() => {
    setChipData([filter]);
    if (ischipDeleted) {
      dispatch({
        type: "getCommissionReportsAction",
        data: filter,
        page: currentPage
      });
      setIsChipDeleted(false);
    }
  }, [filter]);

  useEffect(() => {
    setDefaultSelectValue("Live Streaming");
  }, []);

  const applyFilter = () => {
    dispatch({
      type: "getCommissionReportsAction",
      data: filter,
      page: currentPage
    })
  }
  const ticketType = {
    titleLable: "Ticket type & donation",
    ticketTypeLabel: "Ticket type",
    ticketDropDownValues: [
      {
        label: "Live Streaming",
        value: "Live Streaming",
      },
      {
        label: "Unlimited Views - Recorded",
        value: "Unlimited Views - Recorded",
      },
      {
        label: "One Day Watch - Recorded ",
        value: "One day watch - Recorded",
      },
    ],
  };

  return (
    <Grid className={`flex justify-between items-center mt-8 ${classes.container}`}>
      {(filter.partnerName || filter.ticketingType) && <Grid className={classes.chipContainer}>
        {chipData.map((data) => {
          let icon;
          let keys = Object.keys(data);
          if (data.label === 'React') {
            icon = <TagFacesIcon />;
          }
          return (
            <>
              {keys.map((el) => {
                return (<ListItem
                  sx={{
                    width: '100%',
                    '& .MuiButtonBase-root': {
                      border: '1px solid lightgray',
                      backgroundColor: 'white',
                    },
                    '@media(max-width: 600px)': {
                      width: '-webkit-fill-available',
                    },
                  }}>
                  {data[el] && <Chip
                    icon={icon}
                    label={data[el]}
                    onDelete={data[el] === 'React' ? undefined : handleDelete(el)}
                  />}

                </ListItem>)
              })}
            </>

          );
        })}
      </Grid>}
      <Grid className={`${classes.filterContainer}`} md={6}>
        <Select
          value={defaultSelectValue ? defaultSelectValue : filter.ticketingType}
          onChange={(e) => handleFilter(e)}
          name="ticketingType"
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            height: "5vh",
            width: "100%",
            padding: "1.3rem",
            marginRight: "1rem",
            marginBottom: "0.70rem",
            fontSize: "12px",
            '@media screen and (max-width: 900px)': {
              height: "4vh",
              width: "100%",
              padding: "1.3rem",
            },
          }}
          MenuProps={menuProps}
          classes={{
            select: classes.select,
          }}
        >
          {ticketType.ticketDropDownValues.length &&
            ticketType.ticketDropDownValues.map((obj, key) => {
              return (
                <MenuItem key={key} value={obj.value} sx={{ fontSize: "13px", }}>
                  {obj.label}
                </MenuItem>
              );
            })
          }
        </Select>
       
        <Select
      displayEmpty
          value={filter.partnerName}
          onChange={(e) => handleFilter(e)}
          name="partnerName"
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            height: "5vh",
            width: "100%",
            padding: "1.3rem",
            marginRight: "1rem",
            marginBottom: "0.70rem",
            fontSize: "12px",
            '@media screen and (max-width: 900px)': {
              height: "4vh",
              width: "100%",
              padding: "1.3rem",
            },
          }}
          MenuProps={menuProps}
          classes={{
            select: classes.select,
          }}
        >
           <MenuItem value="" disabled>
            - Source Name -
          </MenuItem>
          {data?.map((el, index) => <MenuItem key={index} value={el.partnerName}>{el.partnerName}</MenuItem>)}
        </Select>
       
      </Grid>
      <Grid>
        <Button onClick={applyFilter} className={`${classes.formButton} bg-primary`} variant='contained'>Apply Filter</Button>
      </Grid>
    </Grid>
  );
}
export default ReportInsightHeader;
