import React , {useState} from 'react'
import { makeStyles } from '@mui/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { Modal ,TextField, Button, 
    InputAdornment, 
    IconButton } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalData } from 'utils';
import { useTranslation } from 'react-i18next';
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { Loader } from 'components/Shared/Loader';

const useStyles = makeStyles({
    ticketContainerHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "-0.5rem"
    },
    parent: {
        width: "350px",
        boxSizing: "border-box",
        padding: "30px",
        whiteSpace: "nowrap",
        borderRadius: "8px",
        boxShadow: "1px 2px 10px 0.5px #00000029",
        backgroundColor: "#fff",
    },
    formButton: {
        width: "100%",
        backgroundColor: "#616161 !important",
        textTransform: "capitalize !important"
    },
    childContainer: {
        width: "100%",
        height: "100%",
        marginTop:'1rem',
        // marginLeft:"1.2rem",
        '&::-webkit-scrollbar': {
            width: "0"
        }
    },
    helperTextStyle: {
        width: "292px",
        whiteSpace:'pre-wrap'
    },
    formContainer:{
        display:"grid",
        placeItems:'center',
        marginTop:"5rem"
    }
});
function ChangePassword(props) {
    const {isOpen, showChangePasswordPopup , formContent ,buttonText } = props;
    const { errorState, emailId , isPasswordChanged } = useSelector((state) => state.auth);
    const { userDetails } = errorState;
    const [disableButton, setDisableButton] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();
    const [values, setValues] = React.useState({
        password: '',
        confirmPassword: '',
        oldPassword:''
    });
    const [passwordChangeProcess, setPasswordChangeProcess] = useState(false);
    const handleClose = () => { 
        showChangePasswordPopup(false);
        setValues("");
    }
    const closePopUp = () => { 
        showChangePasswordPopup(false)
        setValues("");
    }
    const dispatch = useDispatch();
    
    const handleClickShowPassword = (name) => {
        if (name === 'password') {
          setShowPassword(!showPassword);
        } else if (name === 'confirmPassword') {
          setShowConfirmPassword(!showConfirmPassword);
        } else {
            setShowOldPassword(!showOldPassword);
        }
      }
    
    const handlePasswordChange = (prop) => (event) => {
        const { value, name } = event.target;
        setValues({ ...values, [prop]: value });
         if (['password'].includes(name)) {
            dispatch({
                type: "setAuthenticationFormAction",
                name,
                value
            })
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setPasswordChangeProcess(true);
        dispatch({
            type: "setAuthenticationConfirmChangePasswordAction",
            data: {
                emailId: emailId,
                newPassword: values.password,
                oldPassword: values.oldPassword
            },
            onSuccessCB: (res) => {
                setPasswordChangeProcess(false);
            },
            onErrorCB: (err) => {
                setPasswordChangeProcess(false);
            }
        })
        showChangePasswordPopup(false)
        setValues("");
    }

    useEffect(() => {
        if (values.password &&
            values.confirmPassword &&
            values.oldPassword !== ""  &&
            !errorState.userDetails.password.error &&
            !errorState.userDetails.confirmPassword.error &&
            (values.password === values.confirmPassword)) {
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }
    }, [values, errorState])

    return (
        <>
            {passwordChangeProcess && <Loader isOpen={passwordChangeProcess}  content="Changing..." />}
            <Modal
                open={isOpen}
                disableScrollLock={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >   
                <form onSubmit={handleSubmit} className={classes.formContainer}>
                    <div className={classes.parent}>
                        <div className={classes.ticketContainerHeader}>
                            <h5 className=''>{t("Change Password")}</h5>
                            <CancelIcon onClick={closePopUp} className='color-primary cursor-pointer' />
                        </div>
                        <div className={classes.childContainer}>
                            {formContent.map((el, index) => {
                                return (
                                    <div key={index}>
                                        <label
                                            className={`${errorState.userDetails[el.name]?.error
                                                ? classes.labelError
                                                : classes.label
                                                }`}
                                        >
                                            {t(el.label)}
                                        </label>
                                        <br />
                                        <TextField
                                            name={el.name}
                                            fullWidth
                                            onChange={handlePasswordChange(el.name)}
                                            className="mb-2 mt-0"
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    height: 45,
                                                    border: "1px solid #DBE2EA",
                                                },
                                            }}
                                            value={values[el.name]}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    {(['password', 'confirmPassword', 'oldPassword'].includes(el.name)) && <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => handleClickShowPassword(el.name)}
                                                    >
                                                        {!(showPassword && el.name === "password" || showConfirmPassword && el.name === "confirmPassword" || showOldPassword && el.name === "oldPassword") ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                                    </IconButton>}
                                                </InputAdornment>,
                                            }}
                                            type={
                                                (!showPassword && el.name === "password" || !showConfirmPassword && el.name === "confirmPassword" || !showOldPassword && el.name === "oldPassword") ? "password"
                                                    : "text"
                                            }
                                            error={
                                                (errorState &&
                                                    errorState.userDetails &&
                                                    errorState.userDetails[el.name] &&
                                                    errorState.userDetails[el.name].error) ||
                                                (values["password"] !== values["confirmPassword"] &&
                                                    values["confirmPassword"] !== "" &&
                                                    values["password"] !== "")
                                            }
                                            helperText={
                                                (errorState &&
                                                    errorState.userDetails &&
                                                    errorState.userDetails[el.name] &&
                                                    errorState.userDetails[el.name].error &&
                                                    t(errorState.userDetails[el.name].errorMsg)) ||
                                                (["confirmPassword"].includes(el.name) &&
                                                    values["password"] !== values["confirmPassword"] &&
                                                    values["confirmPassword"] !== "" &&
                                                    values["password"] !== "" &&
                                                    t(errorState.userDetails[el.name].errorMsg))
                                            }
                                            FormHelperTextProps={{
                                                className: classes.helperTextStyle,
                                            }}
                                        />
                                    </div>
                                )
                            })}
                            <Button disabled={disableButton} type="submit" className={`${classes.formButton} mb-5 pt-2 pb-2 mt-3 right-gradient flex items-center justify-center`} variant='contained'>{t(buttonText)}</Button>
                        </div>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default ChangePassword