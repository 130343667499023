import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import SignUp from 'views/SignUp/index';
import Login from 'views/Login/index';
import Dashboard from 'views/Dashboard';
import HomePage from 'views/HomePage';
import Reset from 'views/ResetPassword';
import PrivateRoute from 'routes/PrivateRoute';
import VideoStreaming from 'views/VideoStreaming';
import LandingPage from 'views/LandingPage';
import TicketTypeDonation from 'views/TicketTypeDonation';
import ResetConfirmationPassword from 'views/ResetConfirmationPassword'
import EmailVerification from 'views/EmailVerification';
import CardValidation from 'views/CardValidation';
import { isAdmin } from 'utils';
import { useSelector } from 'react-redux';
import Tickets from 'views/Tickets';
import EventsList from 'views/EventsList';
import Checkout from 'views/Checkout';
import Cart from 'views/Cart';
import MyOrder from 'views/My-order';
import EventCreation from 'views/Dashboard/components/EventCreation';
import AdminEvents from 'views/AdminEvents/AdminEvents';
import PaymentSetUp from 'views/PaymentSetUp/PaymentSetUp';
import DiscountCodeSetUp from 'views/DiscountCodeSetUp/DiscountCodeSetUp';
import MyTickets from 'views/MyTickets';
import Profile from 'views/MyProfile';
import Artists from 'views/Artist/Artist';
import CommonLayout from './commonLayout';
import Reports from 'views/Reports';
import SingleConcert from 'views/Reports/components/SingleConcert';

const RootRoutes = () => {
  const { admin } = useSelector((state) => state.auth);
  return (
    <Router>
      <Routes>
        <Route element={<CommonLayout />}>
          <Route element={<PrivateRoute />}>
            {
              isAdmin() || admin ?
                <>
                  {/* <Route path='/events/:eventId/reports' element={<Dashboard />} /> */}
                  {/* <Route path='/events/:eventId/commission-report' element={<Dashboard />} /> */}
                  <Route exact path='/event-creation' element={<EventCreation />} />
                  <Route path='/events' element={<AdminEvents />} />
                  <Route path='/reports' element={<Reports />} />
                  <Route path='/reports/:reportId' element={<SingleConcert />} />
                  <Route path='/payment-setup' element={<PaymentSetUp />} />
                  <Route path='/discount-code-setUp' element={<DiscountCodeSetUp />} />
                  <Route path='/artists' element={<Artists />} />
                  <Route path='/my-profile' element={<Profile />} />
                  <Route path="*" element={<Navigate to='/events' />} />
                </>
                :
                <>
                  <Route path='/events/:eventId/ticket-type-donation' element={<TicketTypeDonation />} />
                  <Route path='/events/:eventId/checkout' element={<Checkout />} />
                  <Route path='/events/:eventId/video' element={<VideoStreaming />} />
                  {/* <Route path='/card-validation' element={<CardValidation />} /> */}
                  <Route path='/my-tickets/purchased-ticket' element={<MyTickets />} />
                  <Route path='/my-tickets/active-ticket' element={<MyTickets />} />
                  <Route path='/my-profile' element={<Profile />} />
                  <Route path='/my-orders' element={<MyOrder />} />
                  <Route path="/cart" element={<Cart />} />
                  <Route path="*" element={<Navigate to="/login" />} />
                </>
            }
          </Route>

          {!isAdmin() && <>
            <Route path='/events/:eventId/details' element={<LandingPage />} />
            <Route path='/artist/:artistId' element={<EventsList />} />
            <Route path='/' element={<HomePage />} />
          </>
          }
        </Route>

        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/reset' element={<Reset />} />
        <Route path='/reset-confirmation-password' element={<ResetConfirmationPassword />} />
        <Route path='/email-verification' element={<EmailVerification />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default RootRoutes;