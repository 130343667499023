import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircleSharp";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(4),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    width: "330px",
    borderRadius: "10px",
    height: "18em",
  },
}));

const EmailVerificationModal = (props) => {
  const { open, setOpen, classes ,modalData : {title,content,buttonText},t} = props;
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
     navigate('/login'); 
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Grid sx={{ display: "flex", justifyContent: "center" }}>
          <CheckCircleIcon
            sx={{
              fontSize: "47px",
              color:"#10BA10",
              margin: "19px 0 -14px 0",
            }}
            
          />
        </Grid>

        <DialogContent>
          <Typography gutterBottom variant="h6" align="center">
           {t(title)}
          </Typography>
          <Typography gutterBottom variant="body2" align="center">
            {t(content)}
          </Typography>
        </DialogContent >
        <DialogActions sx={{display:'flex',justifyContent: "center",marginBottom:'2px'}}>
          <Button
            autoFocus
            onClick={handleClose}
            className={`${classes} mb-1 pt-3 pb-3 right-gradient text-white flex items-center`}
          >
            {t(buttonText)}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
export default EmailVerificationModal;
