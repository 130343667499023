import React, { useEffect } from 'react'
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from 'react-redux';
import Card from './components/Card';
import { setInitialState } from 'redux/EventManagement/EventManagementSlice';
import { useTranslation } from 'react-i18next';
import RegisteredConcert from './components/RegisteredConcert';
import breakpoints from 'components/Shared/MediaQueries/BreakPoints';
import { CircularProgress, Grid } from '@mui/material';
import { isLoggedIn } from 'utils';
import InfiniteScroll from "react-infinite-scroll-component";
import { useState } from 'react';
import { Loader } from 'components/Shared/Loader';

const useStyles = makeStyles({
  titleContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginTop: "1.5rem",
    marginLeft: "1.2rem",
    fontWeight: "500",
    marginBottom: "0.3rem",
    whiteSpace:"nowrap",
    [breakpoints.sm_down]: {
      fontSize: "1.2rem",
      marginLeft: "0 !important",
    }
  },
  title:{
    display:"flex",
    marginLeft:"1.25rem",
    alignItems:"center",
    [breakpoints.sm_down]:{
      marginLeft:"0.5rem"
    }
  },
  homePageTitle: {
    fontWeight: "500",
    [breakpoints.sm_down]:{
      fontSize:"1.2rem !important"
    }
  },
  subTitle: {
    fontSize: "16px !important",
    fontWeight: "400",
    color:"gray",
    marginBottom:"0.3rem"
  },
  underLine: {
    width: "80px",
    height: "2px",
    background: "#0054C9",
    marginLeft: "3.2rem"
  },
  iconImg:{
    background:"aliceblue",
    padding:"0.6rem",
    borderRadius:"100px",
    width:'3.5rem !important',
    [breakpoints.sm_down]:{
      width:"3rem !important"
    }
},
homeContainer:{
  [breakpoints.sm_down]:{
    padding:"1.2rem !important"
  },
  [breakpoints.md_up]:{
    padding:"1.5rem 2.5rem",
  }
}
});
function Homepage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [artist, setArtist] = useState([]);
  const { loading,artistList } = useSelector((state) => state.artist);
  const [hasMoreData, setHasMoreData] = useState(true);
  const { allEventsData } = useSelector(state => state.event);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch({
      type: "getArtistListAction",
      data:{
        pageLimit:9,
        pageNumber:page
      },
      onSuccessCB:(res)=>{
        if(res.count<=9){
          setHasMoreData(false);
        }
        setArtist(res.data);
      }
    });
    dispatch({
      type: "getAllEventsAction"
    })
    //   dispatch({
    //     type:"emptyArtistMediaAction"
    // });
  }, [])
  const registeredConcert = allEventsData?.filter(event => event.loggedInUserBooked);
  const fetchData = async () => {
    try {
      dispatch({
        type: "getArtistListAction",
        data:{
          pageLimit:9,
          pageNumber:page+1
        },
        onSuccessCB:(res)=>{
          const totalRecords = [...artist, ...(res.data)]
          if(res.count === totalRecords.length){
            setHasMoreData(false);
          }
          setTimeout(()=>{
            setArtist((prevData) => [...prevData, ...res.data]);
          }, 500)
        }
      });
      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  return (
    <div className={`${classes.homeContainer}`}>
      { isLoggedIn() &&
        <RegisteredConcert registeredConcert={registeredConcert} />
      }
      <span className={`${classes.titleContainer}`}>
        <Grid className='flex items-center mt-3'>
          <img className={classes.iconImg} src={require('assets/icons/concert.png')} />
          <Grid className={classes.title}>
            <h2 className={classes.homePageTitle}>{t("All Concerts")}&nbsp;</h2>
            <span className={classes.subTitle}>{t("(Grouped By Artists)")}</span>
          </Grid>
        </Grid>
      </span>
      {/* <div className={classes.underLine}></div> */}
      <InfiniteScroll
        dataLength={artist.length}
        next={fetchData}
        hasMore={hasMoreData} // Set this to false when you have fetched all the data
        loader={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
        <CircularProgress />
      </div>}
      >
      <Card artistList={artist} loading={loading} />
      </InfiniteScroll>
    </div>
  )
}

export default Homepage;