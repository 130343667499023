import {
  Grid,
  Button,
  Typography,
  Avatar,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AccordianComponent from 'views/LandingPage/components/Accordian';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import { convertDate, convertTimeDuration } from 'helper';
import { useTranslation } from 'react-i18next';
import { getLocalData } from 'utils';
import BuyTickets from './BuyTickets';
import breakpoints from 'components/Shared/MediaQueries/BreakPoints';
import { useDispatch, useSelector } from 'react-redux';

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: '20px',
  display: 'flex',
  direction: 'column',
  alignItems: 'center',
}));
const StyledAvatar = styled(Avatar)(({ theme }) => ({
  marginRight: '5px',
  width: '40px',
  height: '40px'
}));
const StyledGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  '& .MuiButton-root': {
    padding: "6px 36px"
  }
}));

const StyledGridThree = styled(Grid)(({ theme }) => ({
  width: '100%',
  '@media (min-width: 600px)': {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const useStyles = makeStyles({
  cardOne: {
    padding: "1rem",
    width: "100%",
    boxShadow: "1px 1px 1px 2px #00000029",
    marginBottom: 0,
    '@media (min-width: 950px)': {
      marginBottom: '5vh',
      borderRadius: "10px",
    },
  },
  cardTwo: {
    padding: "1rem",
    width: "100%",
    marginLeft: 0,
    border: "1px solid #005EFF1F",
    marginBottom: "2rem",
    '@media (max-width: 600px)': {
      marginTop: '1rem',
      borderRadius: "5px",
      paddingBottom: "8rem"
    },
  },
  cardThree: {
    padding: "1rem",
    width: "100%",
    marginRight: "2rem",
    marginBottom: 0,
    borderRadius: 0,
    alignItems: 'center',
    border: '1px solid #005EFF1F',
    '@media (max-width: 600px)': {
      border: "none",
      padding: "0.2rem",
      borderTop: "1px dotted #005EFF1F",
      paddingTop: "1rem"
    },
    '@media (max-width: 992px)':{
      width:"40%"
    }
  },
  formButton: {
    width: "100%",
    display: 'flex',
    textTransform:"capitalize !important",
    justifyContent: 'center',
  },
  liveTV: {
    width: '15px',
    height: '0.6em !important',
    marginBottom: '0.3rem',
  },
  buttonConatiner: {
    '@media (max-width: 600px)': {
      position: "fixed",
      display: "flex",
      backgroundColor: "white",
      bottom: "0",
      left: '0',
      zIndex: 1,
      flexDirection: "column !important",
      width: "100vw",
      paddingTop: "1rem"

    },
    '@media (max-width: 992px)':{
      marginRight:'0 !important'
    }
  },
  aboutContainer: {
    '@media (max-width: 600px)': {
      flexDirection: "column !important"
    }
  },
  eventAndButtonContainer:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    width:"100%",
    '@media (max-width: 992px)':{
      display:"flex",
      justifyContent:"space-around",
      width:"100%"
    },
    '@media(min-width:912px) and (max-width:912px)':{
      width:"80%"
    }
  }
});


const ContentItem = (props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [accordionState, setAccordionState] = useState(false);
  const [ids, setIds] = useState();
  const [openPopUp,setOpenPopUp] = useState(false);

  const { aboutContent,accordionContentTerms, accordionContentDisclaimer, eventData, artistMedia } = props;
  const { image, title } = props.artistNameAndImage;

  const [myBookings, setMyBookings] = useState([]);

  const dispatch = useDispatch();
  const { myTickets } = useSelector((state) => state.event);

  useEffect(() => {
    dispatch({
      type: "getMyTicketsAction"
    });
  }, []);

  useEffect(() => {
      if (myTickets?.length > 0) {
          setMyBookings(myTickets.map(order => order.eventId));
      }
  }, [myTickets]);

  const {
    year,
    buttonText,
    aboutHeading,
  } = props.contentItmeData;
  const classes = useStyles();

  const params = useParams();

  const onChangeStyle = (id) => {
    setAccordionState(!accordionState);
    setIds(id)
  }
  const lan = getLocalData("lan");
  const handleBuyTicket = () =>{
    setOpenPopUp(true);
  }
  const handleCloseBuyTicketPopup = (isClose, priceId, count) => {
    if (!isClose) {
      setOpenPopUp(false);
    }
    else {
      navigate(`/events/${params.eventId}/checkout?priceId=${priceId}&count=${count}`);
    }
  }
  return (
    <Grid container
      sx={{
        marginTop: '0'
      }}>
        {
          openPopUp&&<BuyTickets open={openPopUp} handleCloseBuyTicketPopup={handleCloseBuyTicketPopup}/>
        }
      <Grid item lg={12} sm={12} md={12} xs={12}>
        <div className={classes.cardOne}>
          <StyledGrid container style={{ borderBottom: "1px dotted #005EFF33", paddingBottom: "1rem" }}>
            <Grid className={classes.eventAndButtonContainer}>
              <Grid item lg={8} sm={12} md={8} xs={12}>
                <h5>{eventData?.name}</h5>
                <span className='text-12 font-medium mr-1'>
                  <span style={{ textTransform: 'capitalize' }}>{t("Online")}</span> <span style={{ margin: '0 8px' }}>|</span> {(eventData.age==="all")||(eventData.age==="All")? "All Ages" :`${eventData.age}+` } <span style={{ margin: '0 8px' }}>|</span> {convertTimeDuration(eventData?.duration)}
                </span>
              </Grid>
              <Grid item className={` ${classes.buttonConatiner} flex items-center whitespace-no-wrap mr-10`} lg={3} sm={12} md={3} xs={12}>
                <Link className={`link-color font-bold mr-5 text-10`} onClick={handleBuyTicket}>{!(myBookings?.includes(params.eventId && parseInt(params.eventId))) ?t('Buy Tickets') : t('Buy More Tickets')}</Link>
                {/* <Link to={`/events/${params.eventId}/video`}> */}
                <div title={myBookings?.includes(params.eventId && parseInt(params.eventId)) ? "" : "Buy/Redeem A Ticket To Watch Video" }>
                  {/* disabled={!(myBookings?.includes(params.eventId && parseInt(params.eventId)))} */}
                  <Button color='primary' onClick={() => navigate(`/events/${params.eventId}/video`)} className={`${classes.formButton} mb-5 pt-3 pb-3 mt-3 float-right`} variant='contained'>{t(buttonText)}</Button>
                </div>
                {/* </Link> */}
              </Grid>
            </Grid>
            <Grid item lg={12}>
              <span className='text-12 font-medium mr-1'>
                <LiveTvIcon className={`${classes.liveTV} color-primary`} />
                {t("Live in")}: {eventData?.liveIn}
                <span style={{ margin: '0 10px' }}>|</span>
              </span>
              <span className='text-12 font-medium mr-1'>{eventData?.startTime}, {convertDate(eventData?.startDate)} </span>|
               <span className='text-12 font-medium ml-1'>{eventData?.address}, {eventData?.stateName}, {eventData?.countryName} </span>
            </Grid>
          </StyledGrid>
          <div className={`${classes.aboutContainer} mt-8 flex items-start justify-between`}>
            <div>
              <h5>{t(aboutHeading)}</h5>
                    <Typography className='text-12 font-medium mr-1'>{eventData.description}</Typography><br />
            </div>

            <StyledGridThree item lg={3} sm={12} md={3} xs={12}>
              <div className={classes.cardThree}>
                <h5>{t(title)}</h5>
                {
                  eventData.eventArtists?.map((artist, index) => 
                    <StyledTypography component={'span'} key={index}>
                      <StyledAvatar alt="Remy Sharp" src={artist?.mediaThumbnail} />
                      <h6 className="ml-3" sx={{ fontSize: '0.8rem', fontWeight: '600' }} >{artist.fullname}</h6>
                    </StyledTypography>
                  )
                }
              </div>
            </StyledGridThree>
          </div>
        </div>
      </Grid>
      <Grid item lg={12} sm={12} xs={12} md={12}>
        <div className={classes.cardTwo}>
          <h6>{t(accordionContentTerms.heading)}</h6>
          {accordionContentTerms.data.map((obj, index) => {
            return (
              <div key={index}>
                <AccordianComponent
                  title={obj.title}
                  value={eventData.termsAndConditions}
                  id={obj.id}
                  ids={ids}
                  setIds={setIds}
                  onChangeStyle={() => onChangeStyle(obj.id)}
                  accordionState={accordionState}
                />
              </div>
            )
          })}<br />
          <h6>{t(accordionContentDisclaimer.heading)}</h6>
          {accordionContentDisclaimer.data.map((obj, index) => {
            return (
              <div key={index}>
                <AccordianComponent
                  title={obj.title}
                  value={eventData.disclaimer}
                  id={obj.id}
                  ids={ids}
                  setIds={setIds}
                  onChangeStyle={() => onChangeStyle(obj.id)}
                  accordionState={accordionState} />
              </div>
            )
          })}
        </div>
      </Grid>
    </Grid>
  )
}

export default ContentItem