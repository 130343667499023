import { makeStyles } from '@mui/styles';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useMatch } from 'react-router-dom';

const useStyles = makeStyles({
    footerContainer:{
        background:'#1F262D',
        width:"100%",
        height:"15.5vh",
        marginTop: "auto",
        display:'grid',
        placeItems:'center',
        marginBottom:"0"
    },
    followText:{
        color:"#ccc",
        marginTop:"0.5rem",
        marginRight:"2rem"
    }
  });
function Footer() {
    const classes = useStyles();
    const {t} = useTranslation();
    const location = useLocation();
    const match = useMatch('/events/:id/details');
    const [hide,setHide] = useState(false);
   useEffect(()=>{
    if(match&&match?.params&&match?.params?.id){
      if(`/events/${match.params?.id}/details`=== location.pathname){
        setHide(true);
      }
    }
   },[]);

  return (
    <div className={classes.footerContainer} style={{display:`${hide && "none"}`}}>
        <div className='flex items-center'>
        <h5 className={classes.followText}>{t("Follow us on")}:</h5>
        <div>
        <InstagramIcon sx={{mr:2,color:"#fff"}}/>
            <FacebookIcon sx={{mr:2,color:"#fff"}}/>
            <TwitterIcon sx={{mr:2,color:"#fff"}}/>
        </div>
        </div>
        <h6 style={{ color:"#ccc",whiteSpace:"pre-wrap",textAlign:"center"}}>© Bookmyticketshow.com, Inc. {t("All rights reserved")}.</h6>
    </div>
  )
}

export default Footer