import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { makeStyles, styled } from '@mui/styles';
import { Grid } from '@mui/material';

const useStyles = makeStyles({
  Paper: {
    boxShadow: "1px 1px 3px 1px rgba(0,0,0,0.1) !important",
    //width:"95% !important",
    //margin:"3rem auto",
    '@media (min-width: 600px)': {
      width: '100% !important',
    },
    '@media screen and (max-width: 992px) and (min-width: 700px)': {
      width:'32rem !important',
    }
  }
});

const StyledTableRow = styled(TableRow)({
  '&:nth-of-type(even)': {
    borderBottom: "1.2px solid #EDEDED",
  },
  '&:nth-of-type(odd)': {
    borderBottom: "1.2px solid #EDEDED",
  },
  '@media (min-width: 600px)': {
    width: '100% ',
  },
});

export default function DashboardTable({
  pageMode,
  headers,
  data
}) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} className={classes.Paper}>
      <Table aria-label="simple table">
        <TableHead className={classes.Head}>
          <StyledTableRow>
            {headers.map((item) => {
              return <TableCell className='font-semibold' align='center'>{item.name}</TableCell>
            })}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {pageMode === '/commission-report' ? data?.map((el, index) => {
            return <StyledTableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align='center'>
                {index + 1}
              </TableCell>
              <TableCell component="th" scope="row" align='center'>
                {el.partnerName}
              </TableCell>
              {/* <TableCell align='center'>{el.partnerId}</TableCell> */}
              <TableCell align='center'>{el.commissionAmount}</TableCell>
              <TableCell align='center'>{el.commissionPerUser}</TableCell>
              <TableCell align='center'>{el.userCount}</TableCell>
            </StyledTableRow>
          }) : data?.map((el) => {
            return <StyledTableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align='center'>
                {el.date}
              </TableCell>
              <TableCell align='center'>{el.noOfTicketsPurchased}</TableCell>
              <TableCell align='center'>{el.noOfViewers}</TableCell>
              <TableCell align='center'>{el.totalAmountAccumulated?.toFixed(2)}</TableCell>
            </StyledTableRow>
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
