import React , {useEffect, useState} from 'react'
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Modal ,TextField, Button} from '@mui/material';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    ticketContainerHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 0.5rem"
    },
    parent: {
        position: "absolute",
        width: "400px",
        marginLeft: "-150px",
        marginTop: "-150px",
        left: "45%",
        top: "60%",
        boxSizing: "border-box",
        padding: "15px",
        whiteSpace: "nowrap",
        borderRadius: "5px",
        boxShadow: "1px 2px 10px 0.5px #00000029",
        backgroundColor: "#fff",
        animation: '$slideInFromTop 0.3s ease-out',
        '@media(max-width:600px)': {
            width:'100vw',
            margin: "0",
            left: "0",
            top: "35%"
        },
    },
    '@keyframes slideInFromTop': {
        '0%': {
          transform: 'translateY(-100%)',
        },
        '100%': {
          transform: 'translateY(0)',
        },
    },
    formButton: {
        width: "70%",
        fontWeight: "500 !important",
        textTransform: "capitalize !important"
    },
    childContainer: {
        width: "100%",
        height: "100%",
        marginTop:'0.5rem',
        backgroundColor: "lightgray",
        borderRadius: "5px",
        '&::-webkit-scrollbar': {
            width: "0"
        }
    },
    textBox: {
        paddingTop: "1rem",
        width: "90%",
        margin: "0 auto"
    },
    formButtonContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
      },
    helperTextStyle: {
        width: "292px",
        whiteSpace:'pre-wrap'
    },
});
function RedeemTicketModal(props) {
    const {label, buttonName, formData,ticketId, setFormData, handlePopupSubmit, isOpen, setIsOpen, handleClosePopup} = props;
    const [disableButton, setDisableButton] = useState(true);
    const classes = useStyles();
    const { t } = useTranslation();

    const handleClose = () => {
      setIsOpen(false);
      const updatedFormContent = formData?.map((item) => ({
        ...item, value : ""
      }));
      setFormData(updatedFormContent);
      handleClosePopup();
    }

    const handlePopUpChange=(e,name)=>{
        e.preventDefault();
        const {value} = e.target;
        const updatedFormContent = formData?.map((item) => {
          if (item.name === name) {
            return {
              ...item,
              value: value
            };
          }
          return item;
        });
        setFormData(updatedFormContent);
    }
  
    const handleSubmit = (e) => {
        e.preventDefault();
        const output = formData?.reduce((result, { name, value }) => {  
            result[name] = value;
            if(ticketId){
                result['ticketId'] = ticketId;
            }
            return result; 
        }, {});
        handlePopupSubmit(output);
    }

    useEffect(() => {
        const isAnyValueEmpty = formData.some((item) => item.value === "");
        if (isAnyValueEmpty) {
          setDisableButton(true);
        } else {
          setDisableButton(false);
        }
    }, [formData]);
    
    return (
        <>
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >   
                <form onSubmit={handleSubmit} >
                    <div className={classes.parent}>
                        <div className={classes.ticketContainerHeader}>
                            <h5 className='m-0'>{t(label)}</h5>
                            <CloseIcon onClick={handleClose} className='cursor-pointer' />
                        </div>
                        <div className={classes.childContainer}>
                            {formData?.map((el, index) => {
                                return (
                                    <div key={index} className={classes.textBox}>
                                        <TextField
                                            name={el.name}
                                            placeholder={t(el.label)}
                                            fullWidth
                                            onChange={(e)=>handlePopUpChange(e, el.name)}
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    border: "1px solid #DBE2EA",
                                                    backgroundColor: "white"
                                                },
                                            }}
                                            size='small'
                                            value={el.value}
                                            type={el.type}
                                        />
                                    </div>
                                )
                            })}
                            <div className={classes.formButtonContainer}>
                            <Button disabled={disableButton}  type="submit" className={`${classes.formButton} my-5 pt-2 pb-2 flex items-center justify-center`} variant='contained'>{t(buttonName)}</Button>
                        </div>
                        </div>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default RedeemTicketModal