import { TextField, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ButtonLoader from 'components/Shared/ButtonLoader';
import { createBrowserHistory } from 'history';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
    label: {
        fontWeight: "600",
        fontSize: "0.8rem",
        color: "#756F86"
    },
    formButton: {
        textTransform:"capitalize !important",
        width: "100%",
        backgroundColor: "#616161 !important"
    },
    formLink: {
        textDecoration: "underline"
    },
});
function ResetForm(props) {
    const { t, i18n } = useTranslation();
    const [disableButton,setDisableButton]=useState(true);
    const [loading,setLoading] = useState(false);
    const navigate = useNavigate();
    const { 
        formHeading, 
        formSubHeading, 
        formContent, 
        buttonText,
        hasFormSubHeadingLink,
        formSubHeadingLink,
        hasFormFooterLink,
        FormFooterLink
     } = props;
     const [values, setValues] = React.useState({
        email: '',
    });
    const dispatch = useDispatch();
    const { errorState,isResetPassword } = useSelector((state) => state.auth);
    const history = createBrowserHistory()
    const classes = useStyles();
    const handleChange = (prop) => (event) => {
        const { value, name } = event.target;
        setValues({ ...values, [prop]: value });
        if (name === 'email') {
            dispatch({
                type: "setAuthenticationFormAction",
                name,
                value
            })
        }

    };
    const handleReset = (event) => {
        event.preventDefault();
        setLoading(true);
        dispatch({
            type: "setAuthenticationResetPasswordAction",
            data: {
                emailId: values.email,
                type:'reset'
              },
        })
    }
    useEffect(() => {
        if(isResetPassword){
            setLoading(false);
            history.push('/login');
            window.location.reload(true);
        }
        if(!errorState.userDetails['email'].error && values.email){
            setDisableButton(false)
        }else{
            setDisableButton(true)
        }
      }, [isResetPassword,errorState,values]);
    return (
        <div className='p-3 mt-25'>
            <form onSubmit={handleReset}>
            <h3>{t(formHeading)}</h3>
            <span className='text-12 font-medium mr-1'>{t(formSubHeading)}</span> 
            {hasFormSubHeadingLink&&<a className={`${classes.formLink} text-12`} href="/" undeline='true'>{t(formSubHeadingLink)}</a>}
            <br />
            <div className='mt-5'>
                {formContent.map((el, index) => {
                    return (
                        < div key={index}>
                            <label className={classes.label}>{t(el.label)}</label><br />
                            <TextField name={el.name} fullWidth onChange={handleChange(el.name)} className='mb-5 mt-1' sx={{
                                "& .MuiInputBase-root": {
                                    height: 45
                                }
                            }}
                            type={el.name==="password"?"password":"text"}
                                error={errorState
                                    && errorState.userDetails
                                    && errorState.userDetails[el.name]
                                    && errorState.userDetails[el.name].error}
                                helperText={errorState
                                    && errorState.userDetails
                                    && errorState.userDetails[el.name]
                                    && errorState.userDetails[el.name].error
                                    && errorState.userDetails[el.name].errorMsg}
                            /><br />
                        </div>
                    )
                })}

                <Button disabled={disableButton} onClick={handleReset} className={`${classes.formButton} mb-5 pt-3 pb-3 mt-3 right-gradient flex items-center`} variant='contained'>{t(buttonText)}</Button>
                <br />
                {hasFormFooterLink &&<a className={`${classes.formLink} text-12 link-color font-medium px-sm-8`}  href="/login">{t(FormFooterLink)}</a>}
            </div>
            </form>
        </div>
    )
}

export default ResetForm