import { MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { makeStyles, styled, withStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

let StickyTableCell = withStyles((theme) => ({
    head: {
        backgroundColor:"white",
        position: 'sticky',
        border: 'none',
        width: "2rem"
    },
    body: {
        backgroundColor: 'inherit',
        marginRight: 0,
        marginLeft: 0,
        left: 0,
        position: 'sticky',
    },
}))(TableCell)

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-input": {
            padding: '0 !important'
        }
    },
    table: {
        marginTop: '0rem',
        fontSize: '1rem',
        backgroundColor: "white",
        borderRadius: '8px ',
        boxShadow: '0 3px 10px rgba(81, 190, 183, 0.3)',
        "& .MuiTableCell-root": {
            padding: "13px"
        }
    },
    Paper: {
        padding: "0.5rem",
        boxShadow: "1px 1px 3px 1px rgba(0,0,0,0.1) !important",
        borderRadius: "10px 0 0 10px !important",
        '&::-webkit-scrollbar': {
            width: "0.5rem",
            height: "0.5rem"
        },
        '&:hover': {
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#DEDFE1'
            }
        }
    },
    iconsColor: {
        color: '#9f9f9e',
        display: 'flex',
        alignItems: 'center',
    },
    iconWrap: {
        padding: "0 0.2rem",
        cursor: "pointer",
    },
    tableHeader: {
        backgroundColor: 'transparent',
        whiteSpace: 'nowrap',
        padding: '0px !important'
    },
    TableCell: {
        whiteSpace: 'nowrap',
        color: '#00000099',
        fontWeight: 400
    },
    paginationContainer: {
        height: "40px",
        alignItems: "center",
        marginBottom: "1rem",
        bottom: 0,
    },
    font: {
        fontSize: '14px',
        letterSpacing: '0.4px',
        color: '#000000DE',
        opacity: 0.7
    }
}))

const StyledTableRow = styled(TableRow)({
    '&:nth-of-type(even)': {
      borderBottom: "1.2px solid #EDEDED",
    },
    '&:nth-of-type(odd)': {
      borderBottom: "1.2px solid #EDEDED",
    },
    '@media (min-width: 600px)': {
      width: '100% ',
    },
});

function ResuableTable({
    hasDeleteIcon,
    hasEditIcon,
    tableHeading,
    tableData,
    handleEdit,
    handleDelete,
    pageSize,
    setPageSize
}) {
    const classes = useStyles();

    return (
        <div>
            <TableContainer className={classes.Paper} component={Paper}>
                <Table
                    className={`${classes.table} w-full`}
                    aria-label="simple table"
                >
                    <TableHead className={classes.tableHeader}>
                        <StyledTableRow>
                            {tableHeading?.map((heading,index) => {
                                return heading.key === 'actionHeader' ? (
                                    <StickyTableCell className={classes.head} style={{ padding: "0px !important" }}>
                                        <TableCell
                                            key={heading.key}
                                            className="font-semibold pl-5 border-none pt-0 pb-0" 
                                        >
                                            {heading.name}
                                        </TableCell>
                                    </StickyTableCell>
                                ) : (
                                    <TableCell
                                        key={heading.key}
                                        className={`font-semibold text-14 border-none ${classes.TableCell}`}
                                    >
                                        {heading.name}
                                    </TableCell>
                                )
                            })}
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {tableData &&
                            tableData?.map((userProp, index) => (
                                <StyledTableRow key={index} >
                                    {tableHeading?.map((el, index) => {
                                        return (
                                            <>
                                                {index === tableHeading.length - 1
                                                    ? (
                                                        <StickyTableCell className={` border-none p-0`} >
                                                            <TableCell className={`border-none`}>
                                                                <div className={ classes.iconsColor } >
                                                                    {hasEditIcon ? (
                                                                        <span className={`${classes.iconWrap}`}>
                                                                            <EditIcon
                                                                                color='primary'
                                                                                onClick={() => handleEdit( userProp )}
                                                                            />
                                                                        </span>
                                                                    ) : null}
                                                                    {hasDeleteIcon ? (
                                                                        <span className={`${classes.iconWrap} `}>
                                                                            <DeleteIcon
                                                                                color='primary'
                                                                                onClick={() => handleDelete( userProp )}
                                                                            />
                                                                        </span>
                                                                    ) : null}
                                                                </div>
                                                            </TableCell>
                                                        </StickyTableCell>
                                                    ) 
                                                    :
                                                    (
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            className={` border-none font-normal ${classes.TableCell} ${classes.body}`}
                                                        >
                                                            {(userProp[el.key] === ""  || (userProp[el.key] === null)) ? "-" : ""}
                                                            {userProp[el.key]}
                                                        </TableCell>
                                                    )
                                                }
                                            </>
                                        )
                                    })}
                                </StyledTableRow>
                            ))}
                    </TableBody>
                </Table>
                <div className={classes.paginationContainer}>
                    <div className="flex items-center justify-centers">
                        <p className={`mb-2 font-normal ml-3 ${classes.font}`} shrink id="demo-simple-select-placeholder-label-label">
                            Rows per page:
                        </p>
                        <Select
                            className={classes.selectDropDown}
                            value={pageSize}
                            onChange={(e) => setPageSize(e.target.value)}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </div>
                </div>
            </TableContainer>
        </div>
    )
}

export default ResuableTable;