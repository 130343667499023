import update from 'immutability-helper'
import { useCallback, useState } from 'react'
import { Card } from './Card'
import { useEffect } from 'react'
const style = {
  width: 'auto',
  minWidth:"100%",
  background:'#F1F1F1',
  height:'auto',
  minHeight:'100px',
  display:'flex',
  flexWrap:"wrap",
  padding:'1rem'
}
export const Container = (props) => {
  {
    const{artistDragDropValues,handleDragValues} = props;
    const [cards, setCards] = useState([]);

    useEffect(()=>{
     setCards(artistDragDropValues);
    },[artistDragDropValues]);

    useEffect(()=>{
      handleDragValues(cards);
    },[cards]);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
      setCards((prevCards) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        }),
      )
    }, [])
    const renderCard = useCallback((card, index) => {
      return (
        <Card
          key={card.artistId}
          index={index}
          id={card.artistId}
          text={card.fullname}
          moveCard={moveCard}
        />
      )
    }, [])
    return (
      <>
        <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>
      </>
    )
  }
}
