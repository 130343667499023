import React from 'react';
import CssContainer from 'components/CssContainer';
import CssTitle from 'components/CssTitle';

const UnAuthenticated = (props) => {
  const { errors } = props;

  return (
    <CssContainer>
      <CssTitle variant='h4' underline>UnAuthenticated</CssTitle>
      <CssTitle variant='body-1'>{errors?.error || 'Service is temporarily out of'}</CssTitle>
    </CssContainer>
  );
};

export default UnAuthenticated;