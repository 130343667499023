import * as React from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles'
import { Box, Avatar, Typography, Button, TextField, MenuItem, Menu, Badge, Grid, useMediaQuery, Drawer, List, ListItem, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18n';
import { useState } from 'react';
import logo from '../../../assets/images/logo.png';
import { KeyboardArrowDown, Translate } from '@mui/icons-material';
import SwitchLanguage from 'components/Shared/SwitchLanguage';
import { getProfileName, isAdmin, isLoggedIn } from 'utils';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ConfirmModal from 'components/Shared/ConfirmModal';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import RedeemTicketModal from 'components/Shared/ReusableModal';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { setOpenModal } from 'redux/ModalPopUp/ModalPopUpSlice';
import { setOpenModal } from 'redux/ModalPopUp/ModalPopUpSlice';
import { createBrowserHistory } from 'history';
import breakpoints from 'components/Shared/MediaQueries/BreakPoints';

const StyledTypography = styled(Typography)({
  marginTop: '10px',
  marginBottom: '10px',
  display: 'flex',
  alignItems: 'center'
});
const StyledAvatar = styled(Avatar)({
  marginRight: '5px',
  width: '25px',
  height: '25px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});
const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  '& .MuiSvgIcon-root': {
    fontSize: '1.2rem',
    marginTop: '6px'
  }
});
const useStyles = makeStyles({
  boxStyle: {
    width: "100%",
    height: "9.5vh",
    backgroundColor: '#FFF',
    borderBottom: '1px solid #f1f1f1',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.2)",
    padding: '0 3.75rem',
    zIndex: 1,
    [breakpoints.md_down]: {
      padding: '0 1.25rem',
    },
  },
  navLink: {
    marginRight: "2.5rem",
    [breakpoints.lg_down]: {
      marginRight: "1.5rem"
    },
  },
  innerHeader: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row"
  },
  btnGroup: {
    marginLeft: "7rem",
    [breakpoints.lg_down]: {
      marginLeft: "4rem"
    },
  },
  bannerImage: {
    width: "100%",
    height: "7vh",
    marginRight: "0rem",
    objectFit: "contain"
  },
  languageDropDown: {
    marginRight: "1rem"
  },
  userMenuPaper: {
    width: 140
  },
  fullWidthDrawer: {
    width: '100%',
    maxWidth: '100%',
    flexShrink: 0,
  },
  drawerPaper: {
    width: '100%',
    maxWidth: '100%',
  },
  listItem: {
    borderBottom: "1px solid #ccc"
  },
  accordion: {
    boxShadow: 'none !important',
    borderBottom: "1px solid #ccc",
    borderRadius: "0"
  },
  accordionSummary: {
    maxHeight: "0.5rem"
  },
  accordionDetails: {
    background: "transparent"
  },
  redeemButtom: {
    fontSize: "13px !important",
    fontWeight: "500 !important",
    padding: '5px 10px !important',
    borderRadius: '5px !important',
    boxShadow: 'none !important',
    height: 35,
    textTransform: "capitalize !important",
    marginRight: '1rem !important'
  }
})
export default function HeaderContent(props) {
  const { t, i18n } = useTranslation();
  const { name, image } = props.profileNameAndImage;
  const { showTicket, showChangePasswordPopup, userDetails } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const [openConfirmModal, setOpenConfimModal] = useState(false);
  const [totalCartItems, setTotalCartItems] = useState(0);
  const [activeLink, setActiveLink] = useState("Home");
  const [state, setState] = useState({ right: false });
  const [language, setLanguage] = useState('');
  const [status, setStatus] = useState('');
  const theme = useTheme();
  const history = createBrowserHistory();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const handleLogout = () => {
    dispatch({
      type: "removeAuthenticationAction",
    });
    setTotalCartItems(0);
    setAnchorElUser(null);
    navigate('/');
  }
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { cartItems } = useSelector((state) => state.cart);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleTicketPopUP = () => {
    navigate('/my-orders');
    setAnchorElUser(null);
  }

  const handleChangePasswordPopUP = () => {
    showChangePasswordPopup(true);
    setAnchorElUser(null);
  }

  const handleMyProfile = () => {
    navigate('/my-profile');
    setAnchorElUser(null);
  }

  const renderCartQuantity = () =>{
    if (cartItems.length > 0) {
      let totalItem = 0;
      cartItems.map((cart)=>{
        totalItem = totalItem + cart?.userCart[0].quantity
        setTotalCartItems(totalItem);
    });
    } else {
      setTotalCartItems(0);
    }
  }
  const handleCancelEvent = () => {
    dispatch({
      type: "cancelEvent",
      eventId: params.eventId,
      onSuccess: () => {
        setOpenConfimModal(false);
        handleCloseUserMenu();
      }
    })
  }
  useEffect(() => {
    renderCartQuantity();
  }, [cartItems]);
  
  useEffect(()=>{
    if(!isAdmin()){
      dispatch({
        type:"getCartItemsAction"
      });
      renderCartQuantity();
    }
  },[]);

  useEffect(() => {
    let currentUrl = window.location.pathname.split('/');
    let currentPage = currentUrl[currentUrl.length - 1];
    currentPage === 'purchased-ticket' || currentPage === 'active-ticket' ? setActiveLink('My Tickets') : currentPage === 'my-orders' ? setActiveLink('My Orders') : currentPage === '' ? setActiveLink('Home') : setActiveLink('');
  }, [window.location.pathname]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      ((event).key === 'Tab' ||
        (event).key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, ["right"]: open });
    setAnchorElUser(null);
  };
  const handleLanguage = (lng) => {
    setLanguage(lng);
    setState({ ...state, ["right"]: false });
  }
  const [isOpen, setIsOpen] = useState(false);
  const handleRedeemTicket = () => {
    setIsOpen(!isOpen);
  }
  const [formContent, setFormContent] = useState([
    {
      label: "Enter Your Ticket ID",
      name: "ticketId",
      type: "text",
      value: ""
    }
  ]);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const ticketParam = searchParams.get('tkt');

    if (ticketParam) {
      setIsOpen(true);
      const updatedFormContent = formContent.map((item) => {
        if (item.name === 'ticketId') {
          return {
            ...item,
            value: ticketParam
          };
        }
        return item;
      });

      setFormContent(updatedFormContent);
    }
  }, [location.search]);

  const handlePopUpSubmit = (data) => {
    dispatch({
      type: "fetchTicketMessageAction",
      data: {
        ticketId: data.ticketId
      },
      onSuccess: (res) => {
        if (res) {
          setStatus('Success');
        }
      },
      onError: (err) => {
        // setIsOpen(!isOpen);
      }
    })
    setIsOpen(!isOpen);
    const updatedFormContent = formContent?.map((item) => ({
      ...item, value: ""
    }));
    setFormContent(updatedFormContent);
    const urlParams = new URLSearchParams(location.search);
    urlParams.delete('tkt');
    const newUrl = location.pathname;
    history.push(newUrl);
  }
  const handleClosePopup = () => {
    setStatus('');
    const urlParams = new URLSearchParams(location.search);
    urlParams.delete('tkt');
    const newUrl = location.pathname;
    history.push(newUrl);
  }
  const handleStatus = () => {
    return status;
  }
  const handleLogin=()=>{
    const searchParams = new URLSearchParams(location.search);
    const ticketParam = searchParams.get('tkt');
    navigate(ticketParam ? `/login?tkt=${ticketParam}` :'/login');
  }

  return (
    <div className={`${classes.boxStyle} ${isAdmin() ? 'px-8' : ''}`}>
      <div className={classes.innerHeader}>
        <Grid>
          <Link to="/">
            <img className={classes.bannerImage} src={logo} alt='banner' />
          </Link>
        </Grid>
        {
          (!isMobile && !isTablet) && <>
            {!isAdmin() && <Grid className={classes.btnGroup}>
              <Link onClick={() => setActiveLink('Home')} to="/" className={`${classes.navLink} ${activeLink === 'Home' && "text-black"}`}>{t("Home")}</Link>
              <Link onClick={() => setActiveLink('My Tickets')} to="/my-tickets/purchased-ticket" className={`${classes.navLink} ${activeLink === 'My Tickets' && "text-black"}`}>{t("My Tickets")}</Link>
              <Link onClick={() => setActiveLink('My Orders')} to="/my-orders" className={`${activeLink === 'My Orders' && "text-black"}`}>{t("My Orders")}</Link>
            </Grid>
            }
          </>
        }
      </div>
      {
        (!isMobile && !isTablet) &&
        <Grid className='flex items-center'>
          {!isAdmin() && isLoggedIn() && <Button onClick={handleRedeemTicket} variant="outlined" color='primary' className={`${classes.redeemButtom}  whitespace-no-wrap`}>{t("Redeem Ticket")}</Button>}
          {!isAdmin() && <Link to='/cart' className='mx-4'><Badge badgeContent={totalCartItems} color="primary">
          <ShoppingCartOutlinedIcon />
          </Badge></Link>}
          {!isAdmin()&& isLoggedIn() && <RedeemTicketModal
            label="Redeem To Watch Show"
            buttonName="Redeem Ticket"
            formData={formContent}
            setFormData={setFormContent}
            handlePopupSubmit={handlePopUpSubmit}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            handleClosePopup={handleClosePopup}
            status={() => handleStatus()}
          />}
          <StyledTypography style={{ marginLeft: "auto" }}>
            {!isAdmin() && <SwitchLanguage
              StyledBox={StyledBox}
            />}
            {isLoggedIn() ? <Button onClick={handleOpenUserMenu} variant="contained" style={{ display: 'flex', alignItems: 'center', padding: '5px 10px', backgroundColor: '#1c6afc17', borderRadius: 5, boxShadow: 'none', color: '#000', height: 37 }}>
              <StyledAvatar alt="Remy Sharp" src={image} />
              <span className='text-11 font-medium mr-1' >{t("Hi")}, {userDetails?.displayName || userDetails.fullName || getProfileName()}</span>
              <KeyboardArrowDown fontSize="small" />
            </Button> : 
              <Button onClick={handleLogin} variant="contained" style={{ backgroundColor: '#1c6afc17', borderRadius: 5, boxShadow: 'none', color: '#000', height: 37, textTransform: 'capitalize' }}>
                {t("Sign In")}
              </Button>
              }
            <Menu
              sx={{ mt: '35px' }}
              classes={{ paper: classes.userMenuPaper }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {<MenuItem onClick={handleMyProfile}>
                <div><span style={{ fontSize: 12 }}>{t("My Profile")}</span></div>
              </MenuItem>}
              <MenuItem onClick={handleChangePasswordPopUP}>
                <div><span style={{ fontSize: 12 }}>{t("Change Password")}</span></div>
              </MenuItem>
              {/* {isAdmin() && <MenuItem onClick={() => { setOpenConfimModal(true); handleCloseUserMenu(); setAnchorElUser(null); }}>
                <div><span style={{ fontSize: 12 }}>{"Cancel Event"}</span></div>
              </MenuItem>} */}
              <MenuItem onClick={() => handleLogout()}>
                <div><span style={{ fontSize: 12 }}>{t("Log-out")}</span></div>
              </MenuItem>
            </Menu>
            {/* {openConfirmModal && <ConfirmModal open={openConfirmModal} content="Are you sure, you want to cancel event?" buttonText="Confirm" handleConfirm={handleCancelEvent} handleClose={() => setOpenConfimModal(false)} />} */}
          </StyledTypography>
        </Grid>
      }
      {
        (isMobile || isTablet) && <>
          <Grid className='flex items-center justify-around'>
          {isLoggedIn() &&
            <Button onClick={handleRedeemTicket} variant="outlined" color='primary' className={`${classes.redeemButtom} mr-10 whitespace-no-wrap`}>{t("Redeem Ticket")}</Button>
          }
            <MenuIcon onClick={toggleDrawer('right', true)} />
            <RedeemTicketModal
              label="Redeem Ticket"
              buttonName="Redeem Ticket"
              formData={formContent}
              setFormData={setFormContent}
              handlePopupSubmit={handlePopUpSubmit}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              handleClosePopup={handleClosePopup}
              status={() => handleStatus()}
            />
          </Grid>
          <Drawer
            className={classes.fullWidthDrawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor={'right'}
            open={state['right']}
            onClose={toggleDrawer('right', false)}
          >
            <Grid className={`flex items-center ${isLoggedIn() ? 'justify-between' : 'justify-end'} mt-10 p-5`}>
              <Grid className={`flex items-center ${!isLoggedIn() && 'show-on-mobile' }`}>
                <StyledAvatar sx={{ width: "12%", height: "12%" }} alt="Remy Sharp" src={image} />
                <span className='text-20 font-medium ml-2' >{userDetails?.displayName || userDetails.fullName || getProfileName()}</span>
              </Grid>
              <Grid>
                <CloseIcon onClick={toggleDrawer('right', false)} />
              </Grid>
            </Grid>
            <List className="pl-3">
              <Link onClick={toggleDrawer('right', false)} to="/">
                <ListItem className={classes.listItem}>{t("Home")}</ListItem>
              </Link>
              {isLoggedIn() && <Link onClick={toggleDrawer('right', false)} to="/my-profile">
                <ListItem className={classes.listItem}>{t("My Profile")}</ListItem>
              </Link>}
              {!isAdmin() && isLoggedIn() &&  <Link onClick={() => { showChangePasswordPopup(true); setState({ ...state, ["right"]: false }); }} >
                <ListItem className={classes.listItem}>{t("Change Password")}</ListItem>
              </Link>}
              {/* {
                isAdmin() && <Link onClick={() => { setOpenConfimModal(true); handleCloseUserMenu(); }} >
                  <ListItem>Cancel Event</ListItem>
                </Link>
              } */}
              {
                !isAdmin() && <Link onClick={toggleDrawer('right', false)} to="/my-tickets/purchased-ticket">
                  <ListItem className={classes.listItem}>{t("My Tickets")}</ListItem>
                </Link>
              }
              {!isAdmin() && <Link onClick={toggleDrawer('right', false)} to='/my-orders'>
                <ListItem className={classes.listItem} onClick={handleTicketPopUP}>{t("My Orders")}</ListItem>
              </Link>}
              {!isAdmin() && <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={classes.accordionSummary}
                >
                  <ListItem sx={{ pl: 0 }}>{t("Language")}</ListItem>
                </AccordionSummary>
                {
                  <Grid style={{ display: 'none' }}>
                    <SwitchLanguage
                      language={language}
                      StyledBox={StyledBox}
                    />
                  </Grid>
                }
                <AccordionDetails className={classes.accordionDetails}>
                  <ListItem onClick={() => handleLanguage("en")}>English</ListItem>
                  <ListItem onClick={() => handleLanguage("es")}>Spanish</ListItem>
                </AccordionDetails>
              </Accordion>}
              {isLoggedIn() ?
              <ListItem className={classes.listItem} onClick={() => handleLogout()}>{t("Log Out")}</ListItem>
                :
              <ListItem className={classes.listItem} onClick={handleLogin}>{t("Sign In")}</ListItem>
              }
            </List>
          </Drawer>
        </>
      }
    </div >
  );
}
