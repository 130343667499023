import { makeStyles } from '@mui/styles';
import { Button, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ContentItem from 'views/LandingPage/components/ContentItem';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  mainContainer: {
    width: "100%",
    height: "auto",
  },
  bannerImage: {
    width: "100%",
    height: "20vh",
  },
  gridContainer: {
    marginBottom: '5vh',
  },
  concertContainer:{
    position:"absolute",
    top:"70%",
    left:"2%",
    right:"2%",
    width:"96%",
    backgroundColor:"white",
    borderRadius:"10px",
    '@media (max-width: 992px)':{
      top:"40%"
    },
    '@media (max-width: 600px)': {
     top:"50%"
    },
  },
  concertBanner:{
    objectFit: "cover",
    width:"100%",
    height:"650px",
    margin: 0,
    '@media (max-width: 600px)': {
      // marginTop: '7vh',
      height:"50vh",
      objectFit:"cover"
    },
    '@media (max-width: 992px)':{
      height:'300px !important'
    },
  },
  emptyContainer:{
    height:"90vh"
  },
  backButton:{
    position:'absolute',
    top:"10% !important",
    left:"1% !important",
    zIndex:1,
    background:"rgba(0,0,0,0.9) !important",
  }
});

function LandingPage() {
  const classes = useStyles(); 
  
  const accordionContentTerms = {
    heading: "Terms & Condition",
    data: [{
      id: 'panel1',
      title: "Term you should read before booking events!!!",
      value: "Who's pulling up in the dopest costume this year?! The biggest Bay Area Halloween day party is back and we're ready for all the fire fites this year.",
    },
    {
      id: 'panel2',
      title: "Refund Policy",
      value: "If the event is cancelled, the ticket is refunded according to the promoter's guidelines and within 60 days after the date of the event. The service charge is not refundable.",
    },
    ],
  }
  const accordionContentDisclaimer = {
    heading: "Disclaimer",
    data: [{
      id: 'dclmr',
      title: "Term you should read before booking events!!!",
      value: "Who's pulling up in the dopest costume this year?! The biggest Bay Area Halloween day party is back and we're ready for all the fire fites this year.",
    },
    ],
  }


  const aboutContent = [
    {
      value: "Toasted Life Annual Halloween Day Party"
    },
    {
      value: "Who's pulling up in the dopest costume this year?! The biggest Bay Area Halloween day party is back and we're ready for all the fire fites this year."
    },
    {
      value: "Wear your best costume and be ready throw down! This event is sells out every year so be sure to buy your tickets in advance."
    },
    {
      value: "Hip/Hop R&B"
    },
    {
      value: "Saturday October 29th / 3PM - 8PM / 21+"
    }
  ];

  const contentItmeData = {
    year: " years",
    buttonText: "Watch now",
    aboutHeading: "About",
  }
  const artistNameAndImage = {
    title: "Artist",
    name: "James Doe",
    image: "https://cdn.vectorstock.com/i/1000x1000/31/95/user-sign-icon-person-symbol-human-avatar-vector-12693195.webp"
  }
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const { eventData,artistMedia,eventMediaUrl } = useSelector((state) => state.event);
  const { eventArtists,mediaIds } = eventData;
  useEffect(() => {
    dispatch({
      type: "setPreviosEventStateAction",
      data: [
        {
          state: "eventBookingDetails",
          value: {isEventBookingDone:false},
        },
      ],
    });
    dispatch({type:"getEventAction", data: {eventId: params?.eventId}});
  }, []);

  
  return (
    <Grid className={classes.mainContainer}>
      <Grid className={classes.gridContainer}>
        {/* <CarouselImage imagesUrl={imagesUrl} time="2000" /> */}
        <Grid>
        {mediaIds&&mediaIds?.length>0&&<img className={classes.concertBanner} src={mediaIds[0]?.mediaUrl}/>}
        <Grid className={classes.emptyContainer}></Grid>
        <Grid className={classes.concertContainer}>
        <ContentItem
          artistNameAndImage={artistNameAndImage}
          accordionContentTerms={accordionContentTerms}
          accordionContentDisclaimer={accordionContentDisclaimer}
          contentItmeData={contentItmeData}
          eventData={eventData}
          artistMedia={artistMedia}
          aboutContent={aboutContent}
        />
        </Grid>
        </Grid>
       
      </Grid>
    </Grid>
  )
}

export default LandingPage;