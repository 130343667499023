import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Button, ButtonGroup, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const AddRemoveItem = (pops) => {
  const { count, setCount, decButton, incButton,handleAddItem,handleSubstractItem } = pops;
  const increment = () => {
    setCount((prevCount) => (prevCount += 1));
    if(handleAddItem){
      handleAddItem();
    }
  };

  const decrement = () => {
    setCount((prevCount) => {
      if (prevCount > 1) {
        return (prevCount -= 1);
      } else {
        return (prevCount = 1);
      }
    });
    if(handleSubstractItem){
      handleSubstractItem();
    }
  };

  return (
      <ButtonGroup size="large" aria-label="outlined primary large button group" sx={{boxShadow: "0px 3px 7px lightgrey",height:46}} >
        <Button
          onClick={decrement}
          className={decButton}
          variant="text"
          size="large"
        >
          -
        </Button>
        <Button variant="text" size="large">
          {count <= 9 ? "0" + count : count}
        </Button>
        <Button
          onClick={increment}
          className={incButton}
          variant="text"
          size="large"
        >
          +
        </Button>
      </ButtonGroup>
  );
};

export default AddRemoveItem;
