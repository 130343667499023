import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { isValidJsonString } from 'utils';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
// import { Carousel } from 'react-carousel-minimal';
import { useTranslation } from 'react-i18next';
import { Loader } from 'components/Shared/Loader';

const useStyles = makeStyles({
  cardContainer: {
    margin: '3rem 0rem'
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
    // textAlign: "center",
    // minHeight: "100vh"
  },
  cardFormContainer: {
    // backgroundColor: "#F6F6F6",
    maxWidth: 210,
    margin: 'auto'
  },
  banner: {
    // position: "absolute",
    // top: 0,
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },
  cardDetailsSection: {
    // position: "absolute",
    // right: "5%",
    // top: "0",
    height: "100%",
    backgroundColor: "white",
    padding: "1rem",
    // maxWidth: "100px",
    width: "40%",
    marginTop: 45
  }
});

const CardValidation = ({ handleCardDetailsSavedSuccessfully }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isCardVerify,setCardVerify] = useState(false);

  const { token, expiry } = useSelector((state) => state.cardInfo);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (token, expiry) {
      dispatch({
        type: "saveCardTokenAction",
        payload: {
          reqPayload: { token, expiry },
          onSuccessCB: (res) => {
            setCardVerify(false);
            handleCardDetailsSavedSuccessfully(res);
          },
          onErrorCB: (err) => { }
        },
      });
    }
  }, [token]);

  return (
    <div style={{ display: 'flex', maxHeight: '100vh' }}>
      { isCardVerify && <Loader isOpen={true} /> }
      <div style={{ width: '65%', position: 'relative' }}>
        <img
          className={classes.banner}
          src={require("assets/images/login-banner.jpg")}
          alt="login-banner"
        />
        {/* <Carousel
          data={[{
            image:
              'https://as2.ftcdn.net/v2/jpg/04/91/09/71/1000_F_491097109_NbqFULEQiM3V1VmO5suiDEEHDPrkkNCv.jpg',
          },
          {
            image:
              'https://as1.ftcdn.net/v2/jpg/05/02/19/70/1000_F_502197093_3Hmtkqh7w3KjVImgrvosDaDgff9NhE0O.jpg',
          },
          {
            image:
              'https://as1.ftcdn.net/v2/jpg/02/71/17/22/1000_F_271172252_md7S31KiBDq6mEOPaohRm6k33kBoGz8s.jpg',
          },
          {
            image:
              'https://as1.ftcdn.net/v2/jpg/03/10/94/20/1000_F_310942037_aekjJ5SNvOixyf1zP8U0NsLq6f0Tbex1.jpg',
          },]}
          time={2000}
          radius="5px"
          height="40vh"
          style={{
            // width: "35%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: 'translate(-50%, -50%)'
          }}
          automatic={true}
          dots={true}
          showNavBtn={false}
        /> */}
      </div>
      <div className={classes.cardDetailsSection}>
        <div className={classes.cardContainer}>
          <div className={classes.cardFormContainer}>
            <h3 className="mb-5">{t("Credit Card Details")}</h3>
            <iframe id="tokenframe" name="tokenframe"
              src={encodeURI("http://fts-uat.cardconnect.com/itoke/ajax-tokenizer.html?useexpiry=true&usecvv=true&invalidcreditcardevent=true&invalidcvvevent=true&css=body{margin:0px}.error{color:%20red;}input{width:200px;height:30px;margin:5px 0 20px}select{width:88px;height:30px;margin:5px 0 20px}label{font-size:14px;color:darkslategray}")}
              frameBorder="0" scrolling="no" width="100%" height="240"></iframe>
            <div><Button className="right-gradient" variant="contained" >Submit</Button></div>
          </div>
          {/* <h2 className="my-5">Token : {token}</h2>
          <h2 className="my-5">Expiry : {expiry}</h2> */}
        </div>
      </div>
    </div>


  )
}

export default CardValidation