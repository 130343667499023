import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    dashboardTableHeaders: [
        { key: "date", name: "Date", hasSorting: true },
        { key: "noOfTicketsPurchased", name: "No. of tickets purchased", hasSorting: true },
        { key: "noOfViewers", name: "No. of viewers", hasSorting: true },
        { key: "totalAmountAccumulated", name: "Total amount accumlated", hasSorting: true },
    ],
    reportsInsightTableHeaders: [
        { key: "Sno", name: "S.No", hasSorting: true },
        { key: "sourceName", name: "Source Name", hasSorting: true },
        // { key: "partnerId", name: "Partner Id", hasSorting: true },
        { key: "commissionAmount", name: "Commission Amount", hasSorting: true },
        { key: "commissionPerUser", name: "Commission Per User", hasSorting: true },
        { key: "userCount", name: "User Count", hasSorting: true }
    ],
    dashboardReportsData: [],
    commissionReportsData: [],
    bookingType: [],
    totalRecords: null,
    totalCommission: null,
    currentPage: 1
}

const dashboardInfoSlice = createSlice({
    name: 'dashboardInfo',
    initialState,
    reducers: {
        setReportsData(state, action) {
            const { data, total } = action.payload;
            state.dashboardReportsData = data;
            state.totalRecords = total;
        },
        setCommissionReportsData(state, action) {
            const { commissionList, totalCommission } = action.payload;
            state.commissionReportsData = commissionList;
            state.totalCommission = totalCommission;
        },
        setTicketType(state, action) {
            state.bookingType = action.payload;
        },
        setCurrentPage(state, action) {
            state.currentPage = action.payload;
        }
    },
});


export default dashboardInfoSlice.reducer;
// Actions
export const {
    setReportsData,
    setCommissionReportsData,
    setTicketType,
    setCurrentPage
} = dashboardInfoSlice.actions;
