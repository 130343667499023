import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Icon, IconButton, TableSortLabel, Tooltip, Chip, Button } from '@material-ui/core';
import { camelCase } from 'lodash';
// import { convertDate } from 'app/views/utilities/DateFormat';
import { styled } from '@material-ui/styles';
import { Badge, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import AddRemoveItem from 'views/TicketTypeDonation/components/AddRemoveItem';
import { useTranslation } from 'react-i18next';
import { capitalize, convertToDateAndTime } from 'utils';
import { Link } from 'react-router-dom';
// import { capitalizeFirstLetter } from 'utils';

let StickyTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "white",
        right: '-1rem',
        // position: 'sticky',
        zIndex: 100,
        border: 'none',
        paddingLeft: '5rem',
        paddingRight: '2rem',
        paddingTop: '0px',
        paddingBottom: '1px',
        width: "2rem"
    },
    body: {
        backgroundColor: 'inherit',
        marginRight: 0,
        marginLeft: 0,
        right: '-1rem',
        left: 0,
        // position: 'sticky',
        zIndex: 550,
    },
}))(TableCell)

const useStyles = makeStyles((theme) => ({
    chip: {
        backgroundColor: '#FFFFFF',
        marginRight: '3px'
    },
    link: {
        textDecoration: 'underline',
        color: '#2C3E93',
        marginLeft: '5px'
    },
    table: {
        marginTop: '0rem',
        fontSize: '1rem',
        backgroundColor: "white",
        borderRadius: '4px 4px 0px 0px',
        "& .MuiTableCell-root": {
            // padding: "6px 5px 6px 22px" // <-- arbitrary value
        }
    },
    Paper: {
        padding: "0.5rem 0 0rem 0rem",
        boxShadow: "none",
        backgroundColor: "transparent",
        visibility: "none",
        '&::-webkit-scrollbar': {
            width: "0.5rem",
            height: "0.5rem"
        },
        '&:hover': {
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#DEDFE1'
            },
            cursor: "pointer"
        }


    },
    iconsColor: {
        color: '#9f9f9e',
        display: 'flex',
        alignItems: 'center',
    },
    tableHeader: {
        backgroundColor: '#FCFCFD',
        whiteSpace: 'nowrap',
        padding: '5px !important',
        color: "red",
        borderBottom: "0.5px solid rgba(0, 0, 0, 0.1) !important"
    },
    TableCell: {
        whiteSpace: 'nowrap',
        color: '#00000099',
        fontSize: "0.8rem",
        fontWeight: "500 !important",
        color: "#474646 !important",
        padding:'12px 16px'
    },
    tableRow: {
        borderBottom: "0.5px solid rgba(0, 0, 0, 0.1)",
        borderLeft: "0.5px solid transparent",
        borderRight: "0.5px solid transparent",
        height: "0.5rem",
        '&:hover': {
            border: "0.5px solid rgba(0, 0, 0, 0.2)",
            cursor: "pointer",
            borderRight: "none",
            borderLeft: "none"

        },
        '&:last-child ': {
            borderBottom: "0.5px solid transparent"
        },
        '&:first-child ': {
            borderTop: "0.5px solid transparent"
        }

    },
    paginationCount: {
        marginLeft: 'auto',
        marginRight: '2rem',
        marginTop: '1rem',
    },
    rangeSelector: {
        border: '1px solid gray',
        borderBottom: 'none',
    },
    disabledView: {
        backgroundColor: 'rgba(242, 242, 242, .99) !important',
        //  backgroundColor: '#EDEDF0 !important',
        //  opacity: '.54',
        position: 'relative',
        borderBottom: "0.5px solid rgba(0, 0, 0, 0.1)",
        borderLeft: "0.5px solid transparent",
        borderRight: "0.5px solid transparent",
        // borderBottom: "0.5px solid transparent",
        '&:hover': {
            border: "0.5px solid rgba(0, 0, 0, 0.2)",
            cursor: "pointer",
            borderRight: "none",
            borderLeft: "none"

        },
        '& .MuiTableCell-body': {
            color: "rgba(0, 0, 0, .3) !important",

        }
    },
    disabledViewIcons: {
        //  backgroundColor: '#EDEDF0 !important',
        backgroundColor: 'rgba(242, 242, 242, .99) !important',
    },
    inActiveBtn: {
        color: '#BF334C !important',
        fontSize: '13px',
        border: '1px solid #C13C54',
        zIndex: '1',
        padding: "0.1rem 0.2rem",
        width: '73px',
        backgroundColor: "#EAD9DD"
    },
    activeBtn: {
        color: '#2C3E93 !important',
        fontSize: '13px',
        padding: "0.1rem .2rem",
        width: '73px',
        border: '1px solid #2C3E93',
        backgroundColor: '#daedf4'
    },
    draftedBtn: {
        color: '#00000099  !important',
        border: '1px solid #00000099',
        fontSize: '13px',
        padding: '0.1rem .2rem',
        width: '73px'
    },
    iconColumnActive: {
        backgroundColor: '#F4F6FC'
    },
    headerName: {
        fontSize: '0.8rem !important',
        fontWeight: "500 !important",
        color: "#8B93A3 !important"
    },
    filterContainer: {
        float: "right",
        margin: "0.3rem 2rem"
    },
    searchField: {
        height: "40px",
        minWidth: "330px",
        marginRight: "1rem",
        paddingLeft: "0 !important"
    },
    decButton: {
        color: "#1c6afc !important",
    },
    incButton: {
        color: "#fff !important",
        background: "#1c6afc !important",
    },
    Completed:{
        color: 'green',
        background: 'aliceblue !important',
        padding: '0.3rem 1.2rem',
        borderRadius: '20px'
    },
    Bookings_Closed:{
        color:"#E65959",
        background:"#FDECEC",
        padding: '0.3rem 1.2rem',
        borderRadius: '20px'
    },
    NoRecordHeading:{
        position:'absolute',
        left:'50%',
        top:'50%',
        Transform:'translate(-50%, -50%)',
        textWrap:'nowrap',
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //     backgroundColor: '#FFF'
    // },
    // '&:nth-of-type(even)': {
    //     backgroundColor: '#F4F6FC'
    // },
    // root: {
    //     zIndex: theme.zIndex.appBar + 1
    // }
}));

function DataTable({
    hasDeleteIcon,
    hasEditIcon,
    hasViewIcon,
    hasInfoIcon,
    tableHeading,
    tableData,
    handleEdit,
    handleDelete,
    handleInfo,
    handleView,
    handleSort,
    columnToSort,
    sortDirection,
    setIsOpen,
    isOpen,
    reference,
    filterDetails,
    hasFilter,
    handleSubstractItem,
    handleAddItem,
    setCount,
    handleAction,
    hasNoData
}) {
    const classes = useStyles();
    const [dotActive, setDotActive] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (isOpen) {
            setDotActive(true);
        } else {
            setDotActive(false);
        }
    }, [isOpen])

    const handlePopup = () => {
        setIsOpen(true);
    }

    return (
        <div>
            <TableContainer className={classes.Paper}
                component={Paper}>
                {hasFilter && <Grid className={classes.filterContainer}>
                    <TextField InputProps={{
                        className: classes.searchField,
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton className='pr-0'>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }} placeholder='Search' />
                    <Button className={`${classes.filter} capitalize`} variant='outlined'><FilterListIcon className='mr-2 color-gray' /> Filters</Button>
                </Grid>}
                <Table
                    className={`${classes.table} w-full`}
                    aria-label="simple table"
                >
                    <TableHead ref={reference} className={classes.tableHeader}>
                        <TableRow style={{ borderBottom: "1px solid #eee" }}>
                            {tableHeading.map((heading, item) => {
                                return (
                                    <TableCell
                                        key={heading.key}
                                        className={`color-primary font-semibold text-14 border-none cursor-pointer ${classes.TableCell} ${heading.alignmentDirection ? heading.alignmentDirection : ''}`}
                                    >
                                        {!heading.hasSorting ? <Typography className={classes.headerName}>{t(heading.name)}</Typography> : <TableSortLabel
                                            active={columnToSort === heading && heading.name ? t(heading.name.toUpperCase().split(" ").join("_")) : ''}
                                            direction={sortDirection === 'DESC' ? "desc" : "asc"}
                                            // onClick={() => handleSort(heading.name)
                                            // }
                                        // IconComponent={UnfoldMoreIcon}
                                        >
                                            <Typography className={classes.headerName}>{t(heading.name)}</Typography>

                                            {columnToSort === heading.name.toUpperCase().split(" ").join("_") ? (
                                                <Box component="span" className="hidden">
                                                    {sortDirection === 'DESC' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {hasNoData&&<h3 className={classes.NoRecordHeading}>No Records Found.</h3>}
                        {tableData &&
                            tableData.map((userProp, index) => (

                                <StyledTableRow
                                    key={index}
                                    className={
                                        `
                                        ${tableData[index].status ===
                                            'INACTIVE' ||
                                            tableData[index].status === 'DRAFT'
                                            ? classes.disabledView
                                            : classes.tableRow} ${tableData[index].status ===
                                                'INACTIVE' ? 'color-primary' : ''}`
                                    }
                                >

                                    {tableHeading.map((el, index) => {

                                        return (
                                            <>
                                                {el.key === 'actionHeader'
                                                    ? (
                                                        <StickyTableCell
                                                            className={` border-none p-0`} 
                                                        >
                                                            <TableCell className={`border-none px-2 py-0`}>
                                                                <TableCell className={`p-0 border-none`} >
                                                                    <div
                                                                        className={
                                                                            classes.iconsColor
                                                                        }
                                                                    >
                                                                        {
                                                                            <IconButton onClick={() => handleAction(userProp, 'edit')}>{el.icon}</IconButton>
                                    
                                                                        }
                                                                    </div>
                                                                </TableCell>
                                                            </TableCell>
                                                        </StickyTableCell>
                                                    ) :
                                                    (
                                                        <TableCell
                                                            component="th"
                                                            scope="row"

                                                            className={`${el.key === 'status' && `${classes[camelCase(userProp[el.key])]}`} border-none font-normal ${classes.TableCell} ${classes.body} ${el.alignmentDirection ? el.alignmentDirection : ''}`}
                                                        // onClick={() =>
                                                        //     handleView(
                                                        //         userProp
                                                        //     )
                                                        // }
                                                        >
                                                            {console.log(el.type)}
                                                            {(userProp[el.key] === "" || ((userProp[el.key] === null))) ? "-" : ""}
                                                            {el.isIcon ? <Icon className={classes.iconsColor}>{userProp[el.key]}</Icon> : userProp[el.key] && typeof userProp[el.key] !== 'object' && userProp[el.key].length > 20 ?
                                                                <Tooltip title={capitalize(userProp[el.key])} arrow>
                                                                   {el.type === 'Link'?<Link className='color-primary'>{capitalize(userProp[el.key]).substring(0, 20) + '...'}</Link> : 
                                                                    <div>
                                                                        {capitalize(userProp[el.key]).substring(0, 20) + '...'}
                                                                    </div>}
                                                                </Tooltip>
                                                                : ['quantity'].includes(el.key) ? <AddRemoveItem
                                                                    count={userProp[el.key]}
                                                                    setCount={setCount}
                                                                    handleAddItem={() => handleAddItem(userProp)}
                                                                    handleSubstractItem={() => handleSubstractItem(userProp)}
                                                                    decButton={classes.decButton}
                                                                    incButton={classes.incButton}
                                                                /> :
                                                                    (['price', 'total'].includes(el.key) ? `$${userProp[el.key]}` : (el.key === 'startDate' ? convertToDateAndTime(userProp[el.key]) : (el.key === 'status' ? <span className={classes[userProp[el.key].replace(/ /g, '_')]}>{userProp[el.key]}</span> : userProp[el.key])))}

                                                        </TableCell>
                                                    )
                                                }
                                            </>
                                        )
                                    })}
                                </StyledTableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

const renderMultiChipsView = (data, key, displayLink, classes, handleEdit) => {
    const { pathname } = window.location;
    const path = pathname.replace("/", '');
    if (data[key] && data[key].length > 2) {
        return <>
            {/* {data[key] && data[key].slice(0, 2).map((chipValue)=>(
                    <Chip
                        variant="outlined"
                        label={chipValue.name ? chipValue.name : chipValue.role ? chipValue.role : chipValue.firstName ? `${chipValue.lastName}` : chipValue}
                        className={classes.chip}
                    />))}
                    <Tooltip title={data[key]?.slice(2)?.map((chipValue)=>{
                        return <span>{chipValue.name ? chipValue.name : chipValue.role ? chipValue.role : chipValue.firstName ? `${chipValue.lastName}` : chipValue},</span>
                    })} placement="bottom">
                    <Chip
                        variant="outlined"
                        label={`+${data[key].length-2}`}
                        className={classes.chip}
                    />
                    </Tooltip> */}
        </>
    } else if (data[key] === null || data[key]?.length === 0) {
        return <p onClick={() => handleEdit(data)} className={`${displayLink && classes.link} cursor-pointer`}>
            {!displayLink ? "-" : "ASSIGN"}
        </p>
    } else if (data[key] && data[key].length <= 2) {
        return data[key].map((chipValue) => (
            <Chip
                variant="outlined"
                label={chipValue && chipValue.name ? chipValue.name : chipValue && chipValue.role ? chipValue.role : chipValue && chipValue.firstName ? `${chipValue.firstName} ${chipValue.middleName} ${chipValue.lastName}` : chipValue}
                className={classes.chip}
            />
        ))
    }
}

const renderSingleChipView = (chipValue, classes) => {
    return <>
        {chipValue ? <Chip
            variant='outlined'
            label={chipValue}
            className={classes.chip}
        /> : "-"}

    </>
}

export default DataTable;
