import { makeStyles, styled } from "@mui/styles";
import { Box } from "@mui/system";
import Carousels from "components/Shared/Carousels";
import SwitchLanguage from "components/Shared/SwitchLanguage";
import React from "react";
import ResetForm from "./components/ResetForm";
import constant from "../../constant/";
import { Grid } from "@mui/material";

const { IMAGES_ARRAY } = constant;

const StyledBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  "& .MuiSvgIcon-root": {
    fontSize: "1.2rem",
    marginTop: "6px",
  },
});

const useStyles = makeStyles({
  signUpBanner: {
    position: "absolute",
    top: 0,
    objectFit: "cover",
    width: "70%",
    height: "100%",
    '@media(max-width:600px)':{
      display:'none'
    },
    // '@media screen (max-width:1468px)':{
    //   top:"-10%",
    //   width:"70%",
    //   height:"110vh"
    // }, 
    // '@media screen and (max-width: 1474px) and (min-width: 1341px)':{
    //   top:"-10%",
    //   width:"70%",
    //   height:"110vh"
    // },
     // small laptops
     '@media screen and (max-width: 1339px) and (min-width: 993px)':{
      top:"-10%",
      width:"65%",
      height:"110vh"
    },
    // Tabs
    '@media screen and (max-width: 992px) and (min-width: 700px) ':{
      top:"-10%",
      width:"55%",
      height:"110vh"
    },
    '@media screen and (max-width: 760px) and (min-width: 600px) ':{
      display:"none"
    }
  },
  card: {
    position: "absolute",
    right: "5%",
    top: "8%",
    backgroundColor: "white",
    height: "22rem",
    padding: "1rem",
    // maxWidth: "500px",
    width: "370px",
    borderRadius: "5px",
    '@media(max-width:600px)':{
      width:"100%",
      right:"0",
      padding:0
    },
    '@media screen (max-width:1468px)':{
      width:"490px !important",
      padding:"0",
      right:0
    },
    '@media screen and (max-width: 1474px) and (min-width: 1341px)':{
      width:"390px !important",
      padding:"0",
      right:0
    },
    // small laptops
    '@media screen and (max-width: 1339px) and (min-width: 993px)':{
      width:"290px !important",
      padding:"0"
    },
    // Tabs
    '@media screen and (max-width: 992px) and (min-width: 761px)':{
      width:"300px !important"
    },
    '@media screen and (max-width: 760px) and (min-width: 600px) ':{
      width:"75%",
      right:"5rem",
      padding:0
    }
  },
  header:{
    position: "absolute",
    top: "1rem",
    zIndex: 1,
    backgroundColor: "white",
    width: "417px !important",
    right: "0%",
    display: "flex",
    alignItems: "center",
    '@media(max-width:600px)':{
      width:"100% !important"
    },
    '@media screen and (max-width: 1474px) and (min-width: 1341px)':{
      width:"380px !important",
      right:"1%",
    },
     // small laptops
     '@media screen and (max-width: 1339px) and (min-width: 993px)':{
      width:"340px !important",
      right:"1%"
    },
    // Tabs
    '@media screen and (max-width: 992px) and (min-width: 700px)':{
      width:"340px !important"
    },
    '@media screen and (max-width: 760px) and (min-width: 600px)': {
      width: "100% !important"
    }
  },
  headerButton: {
    '@media(max-width: 760px)' :{
      display: "flex",
      justifyContent: "flex-end",
    }
  }
});

function Reset() {
  const classes = useStyles();
  const formContent = [
    {
      label: "Email*",
      name: "email",
    },
  ];
  return (
    <div>
      <img
        className={classes.signUpBanner}
        src={require("assets/images/login-banner.jpg")}
        alt="login-banner"
      />
      <Carousels
        items={IMAGES_ARRAY}
        originalHeight={'20px'}
        originalWidth={20}
        showBullets={true}
        showNav={false}
        autoPlay={true}
        slideInterval={3000}
        showFullscreenButton={false}
        showPlayButton={false}
      />
      <Grid container className={classes.header}>
          <Grid item xs={8}>
            <img src={require("assets/images/logo.png")} className="ml-4" />
          </Grid>
          <Grid item xs={4} className={classes.headerButton}>
            <SwitchLanguage StyledBox={StyledBox} />
          </Grid>
        </Grid>
      <div className={classes.card}>
        <ResetForm
          formHeading="Reset Password"
          formSubHeading="Enter your email for a password reset link."
          hasFormSubHeadingLink={false}
          formSubHeadingLink="Forgot Email?"
          formContent={formContent}
          buttonText="Send Reset Link"
          hasFormFooterLink={true}
          FormFooterLink="Back to Sign in"
        />
      </div>
    </div>
  );
}

export default Reset;
