import { takeEvery, all, put, call } from 'redux-saga/effects';
import { getLocationDetailsService } from './LocationService';
import { setLocationInfo } from './LocationSlice';


function* getLocationDetails(action) {
    try {
        const data = yield call(getLocationDetailsService, action.payload);
        if (data) {
            yield put(setLocationInfo(data))
        }
    } catch (error) {
        console.error(error);
    }
}

function* watchForGetLocationDetails() {
    yield takeEvery('getLocationInfo', getLocationDetails);
}

// Actions
export default function* locationSaga() {
    yield all([
        watchForGetLocationDetails()
    ])
}
