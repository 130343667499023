import React, { useEffect } from 'react'
import { makeStyles } from "@mui/styles";
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { convertDate } from 'helper';
import EmptyImg from "../../../assets/images/EmptyImg.png"
import breakpoints from 'components/Shared/MediaQueries/BreakPoints';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';
import { getLocalData } from 'utils';
import { setInitialState } from 'redux/EventManagement/EventManagementSlice';
import { setTicketInitialState } from 'redux/TicketManagement/TicketManagementSlice';
import { ArrowForward } from '@mui/icons-material';

const useStyles = makeStyles({
    container: {
        overflow: "hidden",
        margin: "1.25rem 0",
        padding: "0 1.25rem",
        [breakpoints.sm_down]: {
            padding: "0 0.25rem",
        }
    },
    viewallButton: {
        color: "#0a58ca",
    },
    homePageTitle: {
        marginTop: "0.5rem",
        marginLeft: "1.5rem",
        fontWeight: "500",
        marginBottom: "0.3rem",
        [breakpoints.sm_down]: {
            fontSize: "1.2rem",
            marginLeft: "0.5rem"
        }
    },
    mainContainer: {
        display: 'flex',
        flexWrap: "nowrap",
        justifyContent: 'flex-start',
        overflowX: "scroll",
        '&::-webkit-scrollbar': {
            width: "0"
        }
    },
    childContainer: {
        width: "350px",
        marginRight: "10px",
        boxSizing: "border-box",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "row",
        verticalAlign: 'top',
        border: '1px solid rgba(0,0,0,0.3)',
        borderRadius: "6px",
        '&:hover': {
            cursor: "pointer",
        },
        '& img': {
            borderRadius: "5px 0 0 5px"
        },
        [breakpoints.sm_down]: {
            width: "250px"
        }
    },
    innerCardItem: {
        width: "218px",
        padding: "0.6rem 0.3rem",
        letterSpacing: '0.5px',
        lineHeight: '24px',
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        marginLeft: "0.8rem"
    },
    eventName: {
        fontWeight: "600",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    emptyText: {
        margin: "0 auto"
    },
    ticketImg: {
        background: "aliceblue",
        padding: "0.6rem",
        borderRadius: "100px",
        width: "3.5rem",
        [breakpoints.sm_down]: {
            width: "3rem !important"
        }
    },
    ticketTitle: {
        display: "flex",
        alignItems: "center",
        marginLeft: "1.25rem",
        marginTop: "0.25rem",
        [breakpoints.sm_down]: {
            marginLeft: "0",
        }
    },
    viewAllContainer: {
        marginRight: "1.5rem !important",
        justifyContent: "end",
        [breakpoints.sm_down]: {
            paddingTop: "0.4rem",
            paddingRight: "0",
            marginRight: "0 !important"
        }
    }
});

function RegisteredConcert(props) {
    const { registeredConcert } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [purchasedTickets, setPurchasedTickets] = React.useState([]);
    const { ticketMediaUrls } = useSelector((state) => state.event);
    const { ticketShareAndRedeemSuccess } = useSelector((state) => state.ticket);

    const handleClick = (e, id) => {
        e.stopPropagation();
        navigate(`/events/${id}/details`);
    };

    const getUserTickets = () => {
        dispatch({
            type: "getUserTicketsAction",
            payload: {
                onSuccessCB: (res) => {
                    const activeTickets = res.filter((ticketObj) => String(ticketObj.assignedUserId) === getLocalData('userId'));
                    setPurchasedTickets(activeTickets);
                },
                onErrorCB: (error) => {
                    console.log(error)
                }
            },
        })
    }

    useEffect(() => {
        getUserTickets();
    }, []);

    useEffect(() => {
        if (ticketShareAndRedeemSuccess) {
            getUserTickets();
        }
    }, [ticketShareAndRedeemSuccess]);

    useEffect(() => {
        dispatch({
            type: setTicketInitialState.type
        })
    }, [dispatch]);

    useEffect(() => {
        dispatch({
            type: setInitialState.type
        })
    }, [dispatch]);

    useEffect(() => {
        purchasedTickets.map((item) => {
            if (item?.mediaId) {
                dispatch({
                    type: "getTicketMediaAction",
                    mediaId: item?.mediaId
                });
            }
        });
    }, [purchasedTickets]);

    const renderMedia = (ticket) => {
        return ticketMediaUrls.find((media) => media?.mediaId === ticket?.mediaId)?.ticketUrl;
    }

    return (
        <>
            <Grid container className='mt-3 items-center justify-between'>
                <Grid item>
                    <Grid className={classes.ticketTitle}>
                        <img className={classes.ticketImg} src={require('assets/icons/ticket.png')} />
                        <h2 className={classes.homePageTitle}>{t("Active Tickets")}</h2>
                    </Grid>
                </Grid>
            </Grid>
            <div className={`${classes.container}`}>
                <div className={`${classes.mainContainer}`}>
                    {
                        purchasedTickets.length > 0 ? purchasedTickets.slice(0, 3).map((data, index) => {
                            const imgSrc = data?.img || EmptyImg;
                            return (
                                <div key={index} className={`${classes.childContainer}`} onClick={(e) => handleClick(e, data?.eventId)}>
                                    <img
                                        src={data?.mediaIds?.[0]?.mediaUrl || "https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM="}
                                        alt={data?.eventName}
                                        width={100}
                                        height={75}
                                        onError={event => {
                                            event.target.src = EmptyImg;
                                            event.onerror = null;
                                        }}
                                        style={{ objectFit: "cover" }}
                                    />
                                    <div className={classes.innerCardItem}>
                                        <h6 className={`mb-1 ${classes.eventName}`}>{data?.eventName}</h6>
                                        <p className='text-12 font-medium m-0'>{data?.eventStartDate}, {(data?.eventStartTime)}</p>
                                    </div>
                                </div>
                            )
                        }) : <span className={classes.emptyText}>{t("There are no active tickets at this moment")}</span>
                    }
                    {purchasedTickets.length > 3 && <Button onClick={() => navigate('/my-tickets/active-ticket')} variant='outlined' className='ml-3'>View All <ArrowForward sx={{ ml: 1 }} /> </Button>}
                </div>
            </div>
        </>
    )
}

export default RegisteredConcert;