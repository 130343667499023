import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    countryCode: "",
    country: "",
    state: ""
}

const locationSlice = createSlice({
    name: 'cardInfo',
    initialState,
    reducers: {
        setInitialState(state, action) {
            state.countryCode = initialState.countryCode;
            state.country = initialState.country;
            state.state = initialState.state;
        },
        setLocationInfo(state, action) {
            state.countryCode = action.payload.country_code;
            state.country = action.payload.country_name;
            state.state = action.payload.state;
        }
    },
});

export default locationSlice.reducer;

// Actions
export const {
    setLocationInfo
} = locationSlice.actions;
