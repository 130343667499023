import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userDetails:{
        fullName:"",
        displayName:"",
        profileUrl:"",
        email:"",
        phoneNumber:"",
        countryCode:""
    },
    addressList: []
}

const profileInfoSlice = createSlice({
    name: 'profileInfo',
    initialState,
    reducers: {
        setInitialState(state, action) {
            const { email, fullName, displayName,phoneNumber,countryCode } = action.payload;
            state.userDetails = {
                ...state.userDetails,
                email,
                fullName,
                displayName,
                phoneNumber,
                countryCode
            }
        },
        setUpdateProfile(state,action){
            const { fullName, displayName } = action.payload;
            state.userDetails = {
                ...state.userDetails,
                fullName,
                displayName,
            }
        },
        setUserProfileImage(state,action){
            state.profileUrl = action.payload;
        },
        setEmailId(state,action){
            state.email = action.payload;
        },
        setAddressList(state, action){
            const data = action.payload;
            state.addressList = data;
        },
    },
});

export default profileInfoSlice.reducer;
// Actions
export const {
    setInitialState,
    setUserProfileImage,
    setEmailId,
    setAddressList,
    setUpdateProfile
} = profileInfoSlice.actions;
