import React, { useState } from "react";
import { Autocomplete, Checkbox, Box, Typography, Tabs, Tab, Button, Divider, Drawer, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, InputAdornment, List, MenuItem, OutlinedInput, Pagination, Radio, RadioGroup, Select, TablePagination, TextField, TextareaAutosize } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import ResuableTable from "components/Shared/ReusableTable";
import DataTable from "components/Shared/Table";
import GlobalDrawer from "components/Shared/Drawer";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from "@mui/x-date-pickers";
import Card from "components/Shared/DragAndDrop/Card";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Container } from "components/Shared/DragAndDrop";
import Dropzone from "components/Shared/FileUpload";
import { useEffect } from "react";
import { formatJSDateToNormalDate, formatJSDateToNormalTime, timeStringToJsDate } from "utils";
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { CheckBox, CheckBoxOutlineBlank, Delete, FirstPage, LastPage, NavigateBefore, NavigateNext } from "@mui/icons-material";
import { v4 as uuidv4 } from 'uuid';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "100%",
    minHeight: "75vh",
    padding: "0.5rem 0",
    border: "1px solid #ededed",
    boxShadow: "0px 1px 6px 1px #ededed",
    borderRadius: "6px"
  },
  gridContainer: {
    // marginTop: '3rem',
    marginLeft: '1vh',
    marginRight: '1vh',
    marginBottom: '5vh',
  },
  footer: {
    position: 'absolute !important',
    top: 'auto !important'
  },
  input: {
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  priceCard: {
    width: '100%',
    // height: '12rem',
    // margin: '1rem 1rem 0 0 !important',
  },
  discountContainer: {
    border: "1px solid #ccc",
    borderRadius: "3px",
    width: "100%",
    // height: "100%",
    paddingRight: 25,
    margin: 1,
    // position: 'relative'
  },
  deleteIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
    color: "#FF0000",
    cursor: 'pointer'
  },
  errorMessage: {
    color: '#ff0000',
    fontSize: 12,
    margin: '3px 0 0 2px'
  },
  pagination: {
    '& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar': {
      display: 'flex',
      alignItems: 'end',
    },
    '& .css-1cgewji-MuiInputBase-root-MuiTablePagination-select': {
      marginBottom: '0.5rem'
    },
    '& .css-levciy-MuiTablePagination-displayedRows': {
      margin: 'auto 1rem'
    }
  },
}));

const validations = {
  eventTitle: { isRequired: true, requireMsg: 'Event Name is required' },
  eventDescription: { isRequired: true, requireMsg: 'Event Description is required' },
  withAdPrice: { isRequired: true, requireMsg: 'Price is required' },
  withoutAdPrice: { isRequired: true, requireMsg: 'Price is required' },
  recordedPrice: { isRequired: true, requireMsg: 'Price is required' },
  stateId: { isRequired: true, requireMsg: 'Please select the State' },
  city: { isRequired: true, requireMsg: 'Please Enter the City' },
  address: { isRequired: true, requireMsg: 'Please Enter the Address' },
  startDate: { isRequired: true, requireMsg: 'Please select Event start date' },
  startTime: { isRequired: true, requireMsg: 'Please select Event start time' },
  endDate: { isRequired: true, requireMsg: 'Please select Event end date' },
  endTime: { isRequired: true, requireMsg: 'Please select Event end time' },
  // actualStartTime: { isRequired: true, requireMsg: 'Please select Actual Start time' },
  // graceTime: { isRequired: true, requireMsg: 'Please select Grace time' },
  mediaIds: { isRequired: true, requireMsg: 'Please upload Banner photo' },
  artistsIds: { isRequired: true, requireMsg: 'Please select Artists' },
  // ageRestriction: { isRequired: true, requireMsg: 'Please select Event end time' },
  // ticketCount: { isRequired: false, regexp: /^[1-9][0-9]$/, requireMsg: 'Maximum Tickets count is required', errorMsg: 'Please enter positive values' },
  ticketCountPerUser: { isRequired: true, requireMsg: 'Maximum Tickets per use is required' },
  ticketingFee: { isRequired: true, requireMsg: 'Please enter Ticketing Fee' },
  paymentProcessingFee: { isRequired: true, requireMsg: 'Please enter Processing Fee' },
  termsAndConditions: { isRequired: true, requireMsg: 'Terms and Conditions are required' },
  disclaimer: { isRequired: true, requireMsg: 'Disclaimer is required' },
}

function AdminEvents() {
  const classes = useStyles();
  const { eventsTableHeaders } = useSelector((state) => state.event);
  const { countryRecords, stateRecords } = useSelector((state) => state.auth);
  const { adminArtistList } = useSelector((state) => state.artist);
  const [pageSize, setPageSize] = useState(10);
  const [artistDragDropValues, setArtistDrapDropValues] = useState([]);
  const [rearrangedArtistIds, setRearrangedArtistIds] = useState([]);
  const [withAdPrice, setWithAdPrice] = useState(0);
  const [withoutAdPrice, setWithoutAdPrice] = useState(0);
  const [recordedPrice, setRecordedPrice] = useState(0);
  const [open, setOpen] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [actionType, setActionType] = useState("");
  const [country, setCountry] = useState('2');
  const [stateValue, setStateValue] = useState("");
  const [eventTypeValue, setEventTypeValue] = useState("");
  const [mediaThumbnail, setMediaThumbnail] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [closeDrawer, setCloseDrawer] = useState(false);
  const [offers, setOffers] = useState([{
    code: "",
    discountPercentage: 0,
    discountPrice: 0,
    unit: '$',
    id: uuidv4(),
    offerName: "",
    validUntil: null
  }]);
  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {

    setPage(newPage);
  };

  useEffect(() => {
    dispatch({
      type: "getAllAdminEventsAction",
      data: {
        pageNumber: (page + 1),
        pageLimit: 10
      },
      onSuccessCB: (count) => {
        setTotalRecords(count)
      }
    });
  }, [page]);

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: "getAllAdminEventsAction",
      data: {
        pageNumber: 1,
        pageLimit: parseInt(event.target.value, 10)
      },
      onSuccessCB: (count) => {
        setTotalRecords(count)
      }
    });
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const { allAdminEventsData } = useSelector(state => state.event);
  const dispatch = useDispatch();
  const maxCharacters = {
    disclaimer: 1000,
    termsAndConditions: 1000,
    eventTitle: 100,
    shortDescription: 500,
  }
  let initialState = {
    eventTitle: '',
    eventDescription: '',
    ticketCount: null,
    ticketCountPerUser: null,
    artistsIds: [],
    ageRestriction: '18',
    // subType: '',
    address: '',
    city: '',
    stateId: null,
    ticketingFeeUnit: '$',
    ticketingFee: null,
    paymentProcessingFeeUnit: '$',
    paymentProcessingFee: null,
    prices: [
      {
        "bookingType": "LIVE_STREAM_WITH_COMMERCIALS",
        "id": 0,
        "paymentProcessingFee": 0,
        "paymentProcessingPercentage": 0,
        "price": 0,
        "taxPercentage": 0,
        "ticketingFee": 0
      },
      {
        "bookingType": "LIVE_STREAM_WITHOUT_COMMERCIALS",
        "id": 0,
        "paymentProcessingFee": 0,
        "paymentProcessingPercentage": 0,
        "price": 0,
        "taxPercentage": 0,
        "ticketingFee": 0
      },
      {
        "bookingType": "RECORDED",
        "id": 0,
        "paymentProcessingFee": 0,
        "paymentProcessingPercentage": 0,
        "price": 0,
        "taxPercentage": 0,
        "ticketingFee": 0
      }
    ],
    offers: [],
    disclaimer: '',
    endDate: null,
    endTime: null,
    actualStartTime: null,
    graceTime: null,
    mediaIds: [],
    shortDescription: '',
    startDate: null,
    startTime: null,
    termsAndConditions: ''
  }
  const [drawerInputValues, setDrawerInputValues] = useState(initialState);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    dispatch({
      type: "getAdminArtistListAction"
    });
    dispatch({
      type: "setCountryDataAction",
    });
    dispatch({
      type: "getAllAdminEventsAction",
      data: {
        pageNumber: 1,
        pageLimit: 10
      },
      onSuccessCB: (count) => {
        setTotalRecords(count)
      }
    });

  }, []);

  useEffect(() => {
    if (countryRecords.length > 0 && actionType === 'edit') {
      const countryName = countryRecords.find(country => country.value === eventData.countryName);
      setStateValue(eventData.stateId);
      setCountry(countryName?.name);
      setEventTypeValue(eventData.eventSubType);
    }
    if (countryRecords.length) {
      setCountryList(countryRecords);
      const states = countryRecords.find(country => country.name === 2)?.states;
      setStateList(states);
    }
  }, [countryRecords, stateRecords, actionType, eventData]);
  useEffect(() => {
    if (drawerInputValues?.artistsIds?.length > 0) {
      let artistListArray = [...adminArtistList];
      let filteredArtists = artistListArray.filter(obj => drawerInputValues.artistsIds.includes(obj.artistId));
      setArtistDrapDropValues(filteredArtists);
    }
  }, [drawerInputValues.artistsIds])

  const handleDrawer = () => {
    setOpen(!open);
    setDrawerInputValues(initialState);
    setErrors({});
    setActionType('');
    setMediaThumbnail([]);
    setWithAdPrice(0);
    setWithoutAdPrice(0);
    setRecordedPrice(0);
    setCountry('2');
    setStateValue('');
    setOffers([{
      code: "",
      discountPercentage: 0,
      discountPrice: 0,
      unit: '$',
      id: uuidv4(),
      offerName: "",
      validUntil: null
    }]);
  };
  const handleRemoveBanner = (isRemoved) => {
    if (isRemoved) {
      isValidValue('mediaIds', null);
      setMediaThumbnail([]);
      setDrawerInputValues((prevState) => ({
        ...prevState,
        ['mediaIds']: []
      }));
    }
  }

  const isValidEvent = (event) => {
    let errors = {};
    let isValid = true;
    Object.entries(validations)?.forEach(([key, value]) => {
      console.log('Test ', key, value, event);
      if (value.isRequired && (key === 'withAdPrice' ? !withAdPrice : key === 'withoutAdPrice' ? !withoutAdPrice : key === 'recordedPrice' ? !recordedPrice : (event[key]?.length === 0 || event[key] == null))) {
        errors[key] = value.requireMsg;
        isValid = false;
      }
    });
    return { isValid, errors };
  };

  const isValidValue = (name, value) => {
    const { isRequired, requireMsg } = validations[name] || {};
    if (isRequired) {
      setErrors(prevState => ({ ...prevState, [name]: value ? '' : requireMsg }));
    }
  }
  const removeSecondsFromTime = (time)=>{
    return time.split(":").slice(0, 2).join(":");
  }
  const handleCreate = () => {
    const { isValid, errors } = isValidEvent(drawerInputValues);
    if (isValid) {
      let updatedInputValue = drawerInputValues;
      let updatedPrices = [
        {
          "bookingType": "LIVE_STREAM_WITH_COMMERCIALS",
          "id": 0,
          "paymentProcessingFee": 0,
          "paymentProcessingPercentage": 0,
          "price": withAdPrice,
          "taxPercentage": 0,
          "ticketingFee": 0
        },
        {
          "bookingType": "LIVE_STREAM_WITHOUT_COMMERCIALS",
          "id": 0,
          "paymentProcessingFee": 0,
          "paymentProcessingPercentage": 0,
          "price": withoutAdPrice,
          "taxPercentage": 0,
          "ticketingFee": 0
        },
        {
          "bookingType": "RECORDED",
          "id": 0,
          "paymentProcessingFee": 0,
          "paymentProcessingPercentage": 0,
          "price": recordedPrice,
          "taxPercentage": 0,
          "ticketingFee": 0
        }
      ]
      updatedInputValue.startDate = formatJSDateToNormalDate(drawerInputValues.startDate);
      updatedInputValue.endDate = formatJSDateToNormalDate(drawerInputValues.endDate);
      updatedInputValue.startTime = formatJSDateToNormalTime(drawerInputValues.startTime) !== "aN:aN"?formatJSDateToNormalTime(drawerInputValues.startTime):removeSecondsFromTime(eventData?.startTime);
      updatedInputValue.endTime = formatJSDateToNormalTime(drawerInputValues.endTime)!== "aN:aN"?formatJSDateToNormalTime(drawerInputValues.endTime):removeSecondsFromTime(eventData?.endTime);
      // updatedInputValue.actualStartTime = formatJSDateToNormalTime(drawerInputValues.actualStartTime);
      // updatedInputValue.graceTime = formatJSDateToNormalTime(drawerInputValues.graceTime);
      updatedInputValue.artistsIds = rearrangedArtistIds;
      updatedInputValue.ticketingFeePercentage = drawerInputValues.ticketingFeeUnit === '%';
      updatedInputValue.paymentProcessingPercentage = drawerInputValues.paymentProcessingFeeUnit === '%';
      delete updatedInputValue.ticketingFeeUnit;
      delete updatedInputValue.paymentProcessingFeeUnit;
      delete updatedInputValue.actualStartTime;
      delete updatedInputValue.graceTime
      updatedInputValue.artistsIds = rearrangedArtistIds;
      updatedInputValue.prices = updatedPrices;
      if (actionType === 'edit') {
        updatedInputValue.eventId = eventData.eventId;
        updatedInputValue.offers = offers?.map((offer) => {
          const unit = offer?.unit;
          delete offer?.unit;
          return ({ ...offer, percentage: unit === '%' });
        });
        dispatch({
          type: "setEventAction",
          data: drawerInputValues,
          onSuccessCB: (response) => {
            if (response) {
              dispatch({
                type: "getAllAdminEventsAction",
                data: {
                  pageNumber: 1,
                  pageLimit: 10
                },
                onSuccessCB: (count) => {
                  setTotalRecords(count)
                }
              });
              setCloseDrawer(true);
            }
          },
          actionType
        });
      } else {
        const updatedOffers = offers.map((offer) => {
          delete offer.unit;
          const { id, ...rest } = offer;
          return { ...rest, percentage: offer?.unit === '%' };
        });
        updatedInputValue.offers = updatedOffers;
        dispatch({
          type: "setEventAction",
          data: drawerInputValues,
          onSuccessCB: (response) => {
            if (response) {
              dispatch({
                type: "getAllAdminEventsAction",
                data: {
                  pageNumber: 1,
                  pageLimit: 10
                },
                onSuccessCB: (count) => {
                  setTotalRecords(count)
                }
              });
              setCloseDrawer(true);
            }
          },
          actionType
        });
      }
    }
    else {
      setErrors(errors);
    }
  }

  const handleDragValues = (values) => {
    if (values.length > 0) {
      let artistIds = []
      values.map((rearrangedArtits) => {
        artistIds.push(rearrangedArtits.artistId);
      });
      setRearrangedArtistIds(artistIds);
    }
  }

  const handleChange = (event, autocompleteValue) => {
    const { target: { name, value, type } } = event;

    if (type === 'number') {
      isValidValue(name, value);
      setDrawerInputValues((prevState) => ({
        ...prevState,
        [name]: Math.abs(Number(value))
      }));
    } else if (typeof value !== 'number') {
      isValidValue(name, value);
      // setErrors(errors);
      if (!maxCharacters[name] || value.length <= maxCharacters[name]) {
        setDrawerInputValues((prevState) => ({
          ...prevState,
          [name]: value
        }));
      }
    } else {
      isValidValue(name, name === 'artistsIds' ? (autocompleteValue.length > 0 ? autocompleteValue : null) : value);
      console.log('artistsIds 1234 ', event.target, name, autocompleteValue, value);
      setDrawerInputValues((prevState) => ({
        ...prevState,
        [name]: name === 'artistsIds' ? autocompleteValue : value
      }));
    }
  }

  const handleDrop = (acceptedFiles) => {
    let data = new FormData();
    data.append('file', acceptedFiles[0], acceptedFiles[0].name);
    dispatch({
      type: "uploadMediaAction",
      data: {
        mediaType: 'EVENT_BANNER',
        file: data
      },
      onSuccessCB(data) {
        isValidValue('mediaIds', data);
        setDrawerInputValues((prevState) => ({
          ...prevState,
          ['mediaIds']: [data.id]
        }));
      }
    })
  };

  const handleAction = (rowValue, actionType) => {
    setActionType(actionType);
    dispatch({
      type: "getEventByIdAction",
      data: {
        eventId: rowValue?.eventId
      },
      onSuccessCB(data) {
        setEventData(data);
      }
    });
    setOpen(true);
  }
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  useEffect(() => {
    if (eventData) {
      eventData?.prices?.map((ticket) => {
        if (ticket.bookingTypeLabel === 'Live stream with commercials') {
          setWithAdPrice(ticket.price);
        } else if (ticket.bookingTypeLabel === 'Live stream without commercials') {
          setWithoutAdPrice(ticket.price);
        } else {
          setRecordedPrice(ticket.price);
        }
      });
      eventData?.mediaIds?.map((media) => setMediaThumbnail([...mediaThumbnail, media.mediaThumbnail]))
      const offers = eventData.offers?.map(offer => ({ ...offer, unit: offer?.percentage ? '%' : '$' }));
      setOffers(offers);
      setDrawerInputValues((prevState) => ({
        ...prevState,
        eventTitle: eventData.eventTitle,
        eventDescription: eventData.eventDescription,
        ageRestriction: eventData.ageRestriction,
        startDate: eventData.startDate,
        endDate: eventData.endDate,
        startTime: timeStringToJsDate(eventData.startTime),
        endTime: timeStringToJsDate(eventData.endTime),
        actualStartTime: eventData.actualStartTime ? timeStringToJsDate(eventData.actualStartTime) : null,
        graceTime: eventData.graceTime ? timeStringToJsDate(eventData.graceTime) : null,
        ticketCount: eventData.ticketCount,
        ticketCountPerUser: eventData.ticketCountPerUser,
        shortDescription: eventData.shortDescription,
        prices: eventData.prices,
        termsAndConditions: eventData.termsAndConditions,
        address: eventData.address,
        city: eventData.city,
        mediaIds: eventData?.mediaIds?.map((media) => media.mediaId),
        stateId: eventData.stateId,
        paymentProcessingFee: eventData.paymentProcessingFee,
        ticketingFee: eventData.ticketingFee,
        ticketingFeeUnit: eventData.ticketingFeePercentage ? '%' : '$',
        paymentProcessingFeeUnit: eventData.processingFeePercentage ? '%' : '$',
        artistsIds: eventData?.eventArtists?.map((artist) => artist.artistId),
        // subType:eventData.eventSubType,
        disclaimer: eventData.disclaimer
      }));
    }
  }, [eventData]);

  useEffect(() => {
    if (closeDrawer) {
      handleDrawer();
      setDrawerInputValues(initialState);
      setWithAdPrice(0);
      setWithoutAdPrice(0);
      setRecordedPrice(0);
      setCloseDrawer(false);
    }
  }, [closeDrawer]);
  const addDiscountContainer = () => {
    setOffers([...offers,
    {
      code: "",
      discountPercentage: 0,
      unit: '$',
      discountPrice: 0,
      id: uuidv4(),
      offerName: "",
      validUntil: null
    }]);
  };

  const removeDiscountContainer = (id) => {
    setOffers(prevOffers => {
      return prevOffers.filter(offer => offer.id !== id);
    });
  }

  const handleDiscountFields = (id, event) => {
    if (event.hasOwnProperty('target')) {
      const { target: { value, name, type } } = event;
      if (type === 'number') {
        setOffers(prevOffers => {
          return prevOffers.map(offer => {

            if (offer.id === id) {
              return { ...offer, [name]: Number(value) };
            }
            return offer;
          });
        });
      } else {
        setOffers(prevOffers => {
          return prevOffers.map(offer => {
            if (offer.id === id) {
              return { ...offer, [name]: value };
            }
            return offer;
          });
        });
      }
    } else {

      setOffers(prevOffers => {
        return prevOffers.map(offer => {
          if (offer.id === id) {
            return { ...offer, ['validUntil']: event };
          }
          return offer;
        });
      });
    }
  }

  const handleCountryChange = (event) => {
    const states = countryRecords.find(country => country.name === event.target.value)?.states;
    setStateList(states);
    setCountry(event.target.value);
  }

  const drawerProps = {
    header: [{ title: `${actionType === 'edit' ? 'Update Event' : 'Create Event'}` }, { icon: <CloseIcon />, handler: handleDrawer }],
    fields: [
      {
        component: [
          <Grid container spacing={3}>
            <Grid item lg={6} className="flex flex-column">
              <FormLabel id="demo-row-radio-buttons-group-label">Event Name*</FormLabel>
              <TextField name="eventTitle" value={drawerInputValues.eventTitle} onChange={(e) => handleChange(e)} />
              {errors['eventTitle'] && (<p className={classes.errorMessage}>{errors['eventTitle']}</p>)}
              <FormHelperText style={{ textAlign: 'right' }}>{`${drawerInputValues.eventTitle?.length || 0}/${maxCharacters.eventTitle}`}</FormHelperText>
            </Grid>
            <Grid item lg={6} className="flex flex-column">
              <FormLabel id="demo-row-radio-buttons-group-label">Event Short Description</FormLabel>
              <TextField name="shortDescription" value={drawerInputValues.shortDescription} onChange={(e) => handleChange(e)} />
              <FormHelperText style={{ textAlign: 'right' }}>{`${drawerInputValues.shortDescription?.length || 0}/${maxCharacters.shortDescription}`}</FormHelperText>
            </Grid>
            <Grid item lg={12} className="flex flex-column pt-2">
              <FormLabel id="demo-row-radio-buttons-group-label">Event Description*</FormLabel>
              <TextField name="eventDescription" value={drawerInputValues.eventDescription} onChange={(e) => handleChange(e)} multiline minRows={2} maxRows={4} />
              {errors['eventDescription'] && (<p className={classes.errorMessage}>{errors['eventDescription']}</p>)}
              {drawerInputValues.eventDescription?.length > 0 && <FormHelperText style={{ textAlign: 'right' }}>{`${drawerInputValues.eventDescription?.length || 0}`}</FormHelperText>}
            </Grid>
            <Grid item lg={12} className={`${classes.priceCard}`}>
              <FormLabel className="mb-4" id="demo-row-radio-buttons-group-label">Event Prices*</FormLabel>
              <Grid container spacing={3}>
                <Grid item lg={6}>
                  <TextField type="number"
                    required
                    // inputProps={{min: 0}}
                    InputProps={{
                      classes: {
                        input: classes.input,
                      },
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }} name="withAdPrice" value={withAdPrice || ''} className="w-full" label='Live Stream With Commercials Price' onChange={(e) => (isValidValue('withAdPrice', e.target.value), setWithAdPrice(e.target.value ? Math.abs(Number(e.target.value)) : null))} />
                  {errors['withAdPrice'] && (<p className={classes.errorMessage}>{errors['withAdPrice']}</p>)}
                </Grid>
                <Grid item lg={6}>
                  <TextField type="number"
                    required
                    InputProps={{
                      classes: {
                        input: classes.input,
                      },
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }} name="withoutAdPrice" value={withoutAdPrice || ''} className="w-full" label='Live Stream Without Commercials Price' onChange={(e) => (isValidValue('withoutAdPrice', e.target.value), setWithoutAdPrice(e.target.value ? Math.abs(Number(e.target.value)) : null))} />
                  {errors['withoutAdPrice'] && (<p className={classes.errorMessage}>{errors['withoutAdPrice']}</p>)}
                </Grid>

                <Grid item lg={6}>
                  <TextField type="number"
                    required
                    InputProps={{
                      classes: {
                        input: classes.input,
                      },
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }} name="recordedPrice" value={recordedPrice || ''} className="w-full mt-6" label='Recorded Price' onChange={(e) => (isValidValue('recordedPrice', e.target.value), setRecordedPrice(e.target.value ? Math.abs(Number(e.target.value)) : null))} />
                  {errors['recordedPrice'] && (<p className={classes.errorMessage}>{errors['recordedPrice']}</p>)}
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid lg={12} className="flex flex-column">
              <FormLabel id="demo-row-radio-buttons-group-label">Event Type</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                onChange={(e) => handleChange(e)}
                name="subType"
                value={drawerInputValues.subType || eventTypeValue}
              >
                <FormControlLabel value="ON_LINE" control={<Radio />} label="Online" />
                <FormControlLabel value="OFF_LINE" control={<Radio />} label="Offline" />
                <FormControlLabel value="BOTH" control={<Radio />} label="Both" />
              </RadioGroup>
            </Grid> */}
            <Grid item lg={6} className="flex flex-column">
              <FormLabel id="demo-row-radio-buttons-group-label">Country*</FormLabel>
              <Select onChange={(e) => handleCountryChange(e)} value={country}>
                {countryList && countryList.length > 0 && countryList.map((option) => {
                  return <MenuItem key={option.name} value={option.name}>
                    {option.value}
                  </MenuItem>
                })}
              </Select>
            </Grid>
            <Grid item lg={6} className="flex flex-column">
              <FormLabel id="demo-row-radio-buttons-group-label">State*</FormLabel>

              <Select onChange={(e) => handleChange(e)} name="stateId" value={drawerInputValues.stateId || stateValue}>
                {stateList && stateList.length > 0 && stateList.map((option) => {
                  return <MenuItem key={option.name} value={option.name}>
                    {option.value}
                  </MenuItem>
                })}
              </Select>
              {errors['stateId'] && (<p className={classes.errorMessage}>{errors['stateId']}</p>)}
            </Grid>
            <Grid item lg={6} className="flex flex-column">
              <FormLabel id="demo-row-radio-buttons-group-label">City*</FormLabel>
              <TextField value={drawerInputValues.city} name="city" onChange={(e) => handleChange(e)} />
              {errors['city'] && (<p className={classes.errorMessage}>{errors['city']}</p>)}
            </Grid>
            <Grid item lg={6} className="flex flex-column">
              <FormLabel id="demo-row-radio-buttons-group-label">Address*</FormLabel>
              <TextField value={drawerInputValues.address} name="address" onChange={(e) => handleChange(e)} />
              {errors['address'] && (<p className={classes.errorMessage}>{errors['address']}</p>)}
            </Grid>
            <Grid item lg={6} className="flex flex-column">
              <FormLabel id="demo-row-radio-buttons-group-label">Event Start Date*</FormLabel>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  inputProps={{ style: { height: 2 } }}
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  id="mui-pickers-date1"
                  format="dd-MM-yyyy"
                  name="from"
                  value={drawerInputValues.startDate}
                  onChange={(e) => (isValidValue('startDate', new Date(e)), setDrawerInputValues((prevState) => ({
                    ...prevState,
                    ['startDate']: new Date(e)
                  })))}
                  KeyboardButtonProps={{
                    'aria-label': 'From',
                  }}
                />
              </MuiPickersUtilsProvider>
              {errors['startDate'] && (<p className={classes.errorMessage}>{errors['startDate']}</p>)}
            </Grid>
            <Grid item lg={6} className="flex flex-column">
              <FormLabel id="demo-row-radio-buttons-group-label">Event Start Time*</FormLabel>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  inputProps={{ style: { height: 2 } }}
                  id="time-picker"
                  value={drawerInputValues.startTime}
                  onChange={(e) => (isValidValue('startTime', new Date(e)), setDrawerInputValues((prevState) => ({
                    ...prevState,
                    ['startTime']: new Date(e)
                  })))}
                  keyboardIcon={<AccessTimeIcon />}
                  inputVariant="outlined"
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                />
              </MuiPickersUtilsProvider>
              {errors['startTime'] && (<p className={classes.errorMessage}>{errors['startTime']}</p>)}
            </Grid>
            <Grid item lg={6} className="flex flex-column">
              <FormLabel id="demo-row-radio-buttons-group-label">Event End Date*</FormLabel>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  inputProps={{ style: { height: 2 } }}
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  id="mui-pickers-date1"
                  format="dd-MM-yyyy"
                  value={drawerInputValues.endDate}
                  name="from"
                  onChange={(e) => (isValidValue('endDate', new Date(e)), setDrawerInputValues((prevState) => ({
                    ...prevState,
                    ['endDate']: new Date(e)
                  })))}
                  KeyboardButtonProps={{
                    'aria-label': 'From',
                  }}
                />
              </MuiPickersUtilsProvider>
              {errors['endDate'] && (<p className={classes.errorMessage}>{errors['endDate']}</p>)}
            </Grid>
            <Grid item lg={6} className="flex flex-column">
              <FormLabel className="" id="demo-row-radio-buttons-group-label">Event End Time*</FormLabel>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  inputProps={{ style: { height: 2 } }}
                  hideTabs
                  allowKeyboardControl={false}
                  id="time-picker"
                  keyboardIcon={<AccessTimeIcon />}
                  value={drawerInputValues.endTime}
                  onChange={(e) => (isValidValue('endTime', new Date(e)), setDrawerInputValues((prevState) => ({
                    ...prevState,
                    ['endTime']: new Date(e)
                  })))}
                  inputVariant="outlined"
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <AccessTimeIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </MuiPickersUtilsProvider>
              {errors['endTime'] && (<p className={classes.errorMessage}>{errors['endTime']}</p>)}
            </Grid>
            <Grid item lg={6} className="flex flex-column">
              <FormLabel className="" id="demo-row-radio-buttons-group-label">Actual Start Time</FormLabel>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  inputProps={{ style: { height: 2 } }}
                  hideTabs
                  allowKeyboardControl={false}
                  id="time-picker"
                  keyboardIcon={<AccessTimeIcon />}
                  value={drawerInputValues.actualStartTime}
                  onChange={(e) => (isValidValue('actualStartTime', new Date(e)), setDrawerInputValues((prevState) => ({
                    ...prevState,
                    ['actualStartTime']: new Date(e)
                  })))}
                  inputVariant="outlined"
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <AccessTimeIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </MuiPickersUtilsProvider>
              {errors['actualStartTime'] && (<p className={classes.errorMessage}>{errors['actualStartTime']}</p>)}
            </Grid>
            <Grid item lg={6} className="flex flex-column">
              <FormLabel className="" id="demo-row-radio-buttons-group-label">Grace Time</FormLabel>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  inputProps={{ style: { height: 2 } }}
                  hideTabs
                  allowKeyboardControl={false}
                  id="time-picker"
                  keyboardIcon={<AccessTimeIcon />}
                  value={drawerInputValues.graceTime}
                  onChange={(e) => (isValidValue('graceTime', new Date(e)), setDrawerInputValues((prevState) => ({
                    ...prevState,
                    ['graceTime']: new Date(e)
                  })))}
                  inputVariant="outlined"
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <AccessTimeIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </MuiPickersUtilsProvider>
              {errors['graceTime'] && (<p className={classes.errorMessage}>{errors['graceTime']}</p>)}
            </Grid>
            <Grid item lg={12} className="flex flex-column">
              <FormLabel id="demo-row-radio-buttons-group-label">Event Banner*</FormLabel>
              <Dropzone
                height={300}
                width={'100%'}
                acceptedTypes={{
                  'image/jpeg': [],
                  'image/png': []
                }}
                maxSize={1 * 1024 * 1024}
                acceptedfileSizeText={"Recommended 1280 * 720px and not more than 10Mb"}
                name="mediaIds"
                mediaThumbnail={mediaThumbnail}
                handleRemoveBanner={handleRemoveBanner}
                onDrop={handleDrop} />
              {errors['mediaIds'] && (<p className={classes.errorMessage}>{errors['mediaIds']}</p>)}
            </Grid>
            <Grid item lg={12} className="flex flex-column">
              <FormLabel id="demo-row-radio-buttons-group-label">Select Artists*</FormLabel>
              <Autocomplete
                multiple
                id="artists-checkboxes"
                limitTags={3}
                value={drawerInputValues.artistsIds ? adminArtistList?.filter(artist => drawerInputValues.artistsIds?.includes(artist.artistId)) : []}
                options={adminArtistList}
                disableCloseOnSelect
                getOptionLabel={(option) => option.fullname}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.fullname}
                  </li>
                )}
                componentName="artistsIds"
                renderInput={(params) => (
                  <TextField {...params} name="artistsIds" />
                )}
                onChange={(e, artists) => {
                  setDrawerInputValues(prevState => ({ ...prevState, artistsIds: artists.map(artist => artist.artistId) }));
                }}
              />
              {/* <Select
                multiple
                onChange={handleChange}
                value={drawerInputValues.artistsIds || []}
                name="artistsIds"
                input={<OutlinedInput />}
                MenuProps={MenuProps}
              >
                {adminArtistList && adminArtistList.length > 0 && adminArtistList.map((artist) => {
                  return (
                    <MenuItem key={artist.artistId} sx={{ fontSize: "0.8rem" }} value={artist.artistId}>
                      {artist.fullname}
                    </MenuItem>
                  )
                })}
              </Select> */}
              {errors['artistsIds'] && (<p className={classes.errorMessage}>{errors['artistsIds']}</p>)}
            </Grid>
            {console.log('artistsIds ', drawerInputValues.artistsIds)}
            {
              drawerInputValues.artistsIds?.length > 0 &&
              <Grid item lg={12}>
                <FormLabel id="demo-row-radio-buttons-group-label">Order Artists(Drag And Drop)</FormLabel>
                <DndProvider backend={HTML5Backend}>
                  <Container artistDragDropValues={artistDragDropValues} handleDragValues={handleDragValues} />
                </DndProvider>
              </Grid>
            }
            < Grid item lg={12} className="flex flex-column" >
              <FormLabel id="demo-row-radio-buttons-group-label">Age Restrictions*</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                onChange={(e) => handleChange(e)}
                name="ageRestriction"
                value={typeof drawerInputValues.ageRestriction === 'string' ? drawerInputValues.ageRestriction : `${drawerInputValues.ageRestriction}+`}
              >
                <FormControlLabel value="18" control={<Radio size="small" />} label="18+" />
                <FormControlLabel value="13" control={<Radio size="small" />} label="13+" />
                <FormControlLabel value="all" control={<Radio size="small" />} label="all" />
              </RadioGroup>
            </Grid >
            <Grid item lg={6} className="flex flex-column">
              <FormLabel id="demo-row-radio-buttons-group-label">Maximum Tickets</FormLabel>
              <TextField
                type="number"
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                }}
                value={drawerInputValues.ticketCount || ''}
                name="ticketCount"
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid item lg={6} className="flex flex-column">
              <FormLabel id="demo-row-radio-buttons-group-label">Maximum Tickets Per User*</FormLabel>
              <TextField
                type="number"
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                }}
                value={drawerInputValues.ticketCountPerUser || ''}
                name="ticketCountPerUser"
                onChange={(e) => handleChange(e)}
              />
              {errors['ticketCountPerUser'] && (<p className={classes.errorMessage}>{errors['ticketCountPerUser']}</p>)}
            </Grid>
            <Grid item lg={6} className="flex flex-column">
              <FormLabel id="demo-row-radio-buttons-group-label">Ticketing Fee Unit*</FormLabel>
              <Select onChange={(e) => handleChange(e)} name="ticketingFeeUnit" defaultValue="$" value={drawerInputValues.ticketingFeeUnit}>
                <MenuItem key={"$"} value={"$"}>Dollar ($)</MenuItem>
                <MenuItem key={"%"} value={"%"}>Percent (%)</MenuItem>
              </Select>
            </Grid>
            <Grid item lg={6} className="flex flex-column">
              <FormLabel id="demo-row-radio-buttons-group-label">Ticketing Fee*</FormLabel>
              <TextField
                type="number"
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                  endAdornment: drawerInputValues.ticketingFeeUnit === '%' ? <InputAdornment position="end">%</InputAdornment> : '',
                  startAdornment: drawerInputValues.ticketingFeeUnit === '$' ? <InputAdornment position="start">$</InputAdornment> : '',
                  // startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                value={drawerInputValues.ticketingFee || ''}
                name="ticketingFee"
                onChange={(e) => handleChange(e)}
              />
              {errors['ticketingFee'] && (<p className={classes.errorMessage}>{errors['ticketingFee']}</p>)}
            </Grid>
            <Grid item lg={6} className="flex flex-column">
              <FormLabel id="demo-row-radio-buttons-group-label">Payment Processing Fee Unit*</FormLabel>
              <Select onChange={(e) => handleChange(e)} name="paymentProcessingFeeUnit" defaultValue="$" value={drawerInputValues.paymentProcessingFeeUnit}>
                <MenuItem key={"$"} value={"$"}>Dollar ($)</MenuItem>
                <MenuItem key={"%"} value={"%"}>Percent (%)</MenuItem>
              </Select>
            </Grid>
            <Grid item lg={6} className="flex flex-column">
              <FormLabel id="demo-row-radio-buttons-group-label">Payment Processing Fee*</FormLabel>
              <TextField
                type="number"
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                value={drawerInputValues.paymentProcessingFee || ''}
                name="paymentProcessingFee"
                onChange={(e) => handleChange(e)}
              />
              {errors['paymentProcessingFee'] && (<p className={classes.errorMessage}>{errors['paymentProcessingFee']}</p>)}
            </Grid>
            {/* <Grid item lg={6} className="flex flex-column">
              <FormLabel id="demo-row-radio-buttons-group-label">Activation Date For Selling Tickets</FormLabel>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  inputProps={{ style: { height: 2 } }}
                  className="mt-2"
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  id="mui-pickers-date1"
                  format="dd-MM-yyyy"
                  value={drawerInputValues.endDate}
                  name="from"
                  onChange={(e) => (isValidValue('endDate', new Date(e)), setDrawerInputValues((prevState) => ({
                    ...prevState,
                    ['endDate']: new Date(e)
                  })))}
                  KeyboardButtonProps={{
                    'aria-label': 'From',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid> */}
            {/* Discount Container start */}
            <Grid lg={12} item className="flex justify-between">
              <FormLabel id="demo-row-radio-buttons-group-label">Add Discount Information</FormLabel>
            </Grid>
            {
              offers && offers.length > 0 && offers.map((card, index) => {
                return (
                  <Grid item lg={12} className="flex flex-column pl-12 pb-6">
                    <Grid container spacing={3} className={classes.discountContainer} position={'relative'} style={{ paddingBottom: (index + 1 !== offers.length) ? 24 : '' }}>
                      <Grid item lg={6} className="flex flex-column">
                        <FormLabel id="demo-row-radio-buttons-group-label">Discount Code</FormLabel>
                        <TextField name="code" value={card.code} onChange={(e) => handleDiscountFields(card.id, e)} />
                      </Grid>
                      <Grid item lg={3} className="flex flex-column">
                        <FormLabel id="demo-row-radio-buttons-group-label">Discount Unit</FormLabel>
                        <Select onChange={(e) => handleDiscountFields(card.id, e)} name="unit" defaultValue="$" value={card.unit}>
                          <MenuItem key={"$"} value={"$"}>Dollar ($)</MenuItem>
                          <MenuItem key={"%"} value={"%"}>Percent (%)</MenuItem>
                        </Select>

                        {/* <TextField
                          type="number"
                          InputProps={{
                            classes: {
                              input: classes.input,
                            }
                          }}
                          value={card.discountPrice || ''}
                          name="discountPrice"
                          onChange={(e) => handleDiscountFields(card.id, e)}
                        /> */}
                      </Grid>
                      {/* <Typography className="mt-10">Or</Typography> */}
                      <Grid item lg={3} className="flex flex-column">
                        <FormLabel id="demo-row-radio-buttons-group-label">Discount</FormLabel>
                        <TextField
                          type="number"
                          InputProps={{
                            classes: {
                              input: classes.input,
                            },
                            endAdornment: card.unit === '%' ? <InputAdornment position="end">%</InputAdornment> : '',
                            startAdornment: card.unit === '$' ? <InputAdornment position="start">$</InputAdornment> : '',
                          }}
                          value={card.unit === '$' ? card.discountPrice : card.discountPercentage || ''}
                          name={card.unit === '$' ? "discountPrice" : "discountPercentage"}
                          onChange={(e) => handleDiscountFields(card.id, e)}
                        />
                      </Grid>
                      <Grid item lg={6} className="flex flex-column">
                        <FormLabel id="demo-row-radio-buttons-group-label">Offer Name</FormLabel>
                        <TextField name="offerName" value={card.offerName} onChange={(e) => handleDiscountFields(card.id, e)} />
                      </Grid>

                      <Grid item lg={6} className="flex flex-column">
                        <FormLabel className="mb-2" id="demo-row-radio-buttons-group-label">Validity Date</FormLabel>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            inputProps={{ style: { height: 2 } }}
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            id="mui-pickers-date1"
                            format="dd-MM-yyyy"
                            value={card.validUntil}
                            name="validUntil"
                            onChange={(e) => handleDiscountFields(card.id, new Date(e))}
                            KeyboardButtonProps={{
                              'aria-label': 'From',
                            }}
                          />

                        </MuiPickersUtilsProvider>
                      </Grid>
                      {/* <Grid item lg={6} className="flex flex-column">
                        <FormLabel className="mb-2" id="demo-row-radio-buttons-group-label">Activation Date</FormLabel>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            inputProps={{ style: { height: 2 } }}
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            id="mui-pickers-date1"
                            format="dd-MM-yyyy"
                            value={card.validUntil}
                            name="validUntil"
                            onChange={(e) => handleDiscountFields(card.id, new Date(e))}
                            KeyboardButtonProps={{
                              'aria-label': 'From',
                            }}
                          />

                        </MuiPickersUtilsProvider>
                      </Grid> */}
                      {/* <Grid item lg={6} className="flex flex-column">
                        <FormLabel className="mb-2" id="demo-row-radio-buttons-group-label">Activation Time</FormLabel>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardTimePicker
                            inputProps={{ style: { height: 2 } }}
                            hideTabs
                            allowKeyboardControl={false}
                            id="time-picker"
                            keyboardIcon={<AccessTimeIcon />}
                            value={drawerInputValues.graceTime}
                            onChange={(e) => (isValidValue('graceTime', new Date(e)), setDrawerInputValues((prevState) => ({
                              ...prevState,
                              ['graceTime']: new Date(e)
                            })))}
                            inputVariant="outlined"
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton>
                                    <AccessTimeIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid> */}
                      {/* <Grid item lg={6} className="flex flex-column">
                        <FormLabel className="mb-2" id="demo-row-radio-buttons-group-label">Expiration Date</FormLabel>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            inputProps={{ style: { height: 2 } }}
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            id="mui-pickers-date1"
                            format="dd-MM-yyyy"
                            value={card.validUntil}
                            name="validUntil"
                            onChange={(e) => handleDiscountFields(card.id, new Date(e))}
                            KeyboardButtonProps={{
                              'aria-label': 'From',
                            }}
                          />

                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item lg={6} className="flex flex-column">
                        <FormLabel className="mb-2" id="demo-row-radio-buttons-group-label">Expiration Time</FormLabel>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardTimePicker
                            inputProps={{ style: { height: 2 } }}
                            hideTabs
                            allowKeyboardControl={false}
                            id="time-picker"
                            keyboardIcon={<AccessTimeIcon />}
                            value={drawerInputValues.graceTime}
                            onChange={(e) => (isValidValue('graceTime', new Date(e)), setDrawerInputValues((prevState) => ({
                              ...prevState,
                              ['graceTime']: new Date(e)
                            })))}
                            inputVariant="outlined"
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton>
                                    <AccessTimeIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid> */}

                      {(index + 1 === offers.length) && <Grid item lg={12} className="flex items-center mb-5">
                        <Button variant="outlined" onClick={addDiscountContainer}>Add More Coupons</Button>
                      </Grid>}
                      {index !== 0 && <Delete className={classes.deleteIcon} onClick={() => removeDiscountContainer(card.id)} />}
                    </Grid>
                  </Grid>
                )
              })
            }
            {/* Discount Container end */}
            <Grid item lg={12} className="flex flex-column">
              <FormLabel id="demo-row-radio-buttons-group-label">Terms & Conditions*</FormLabel>
              <FormControl>
                <TextField name="termsAndConditions" value={drawerInputValues.termsAndConditions} onChange={(e) => handleChange(e)} multiline minRows={2} maxRows={4} />
                {errors['termsAndConditions'] && (<p className={classes.errorMessage}>{errors['termsAndConditions']}</p>)}
                <FormHelperText style={{ textAlign: 'right' }}>{`${drawerInputValues.termsAndConditions?.length || 0}/${maxCharacters.termsAndConditions}`}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item lg={12} className="flex flex-column ">
              <FormLabel id="demo-row-radio-buttons-group-label">Disclaimer*</FormLabel>
              <FormControl>
                <TextField name="disclaimer" value={drawerInputValues.disclaimer} onChange={(e) => handleChange(e)} multiline minRows={2} maxRows={4} />
                {errors['disclaimer'] && (<p className={classes.errorMessage}>{errors['disclaimer']}</p>)}
                <FormHelperText style={{ textAlign: 'right' }}>{`${drawerInputValues.disclaimer?.length || 0}/${maxCharacters.disclaimer}`}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid >
        ]
      }
    ],
    footer: [{ title: 'Cancel', style: "outlined", handler: handleDrawer }, { title: actionType === 'edit' ? 'Update' : 'CREATE', style: "contained", handler: handleCreate }],
    footerStyle: classes.footer
  }

  return (
    <div>
      <div className="d-flex justify-content-between">
        <h2>Events</h2>
        <Button variant="contained" onClick={handleDrawer} className="mb-5 float-right"><AddIcon className="mr-2" /> CREATE EVENT</Button>
      </div>
      <Grid className={classes.mainContainer} >
        <Grid className={classes.gridContainer}>
          <GlobalDrawer open={open} drawerProps={drawerProps} drawerClass={classes.drawer} drawerPaperClass={classes.drawerPaper} />
          <DataTable
            hasDeleteIcon={true}
            hasEditIcon={true}
            tableHeading={eventsTableHeaders}
            tableData={allAdminEventsData || []}
            pageSize={pageSize}
            setPageSize={setPageSize}
            handleAction={handleAction}
            hasNoData={allAdminEventsData.length === 0 ? true : false}
          />
          {allAdminEventsData && allAdminEventsData.length > 0 && <TablePagination
            component="div"
            count={totalRecords}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={() => (
              <div style={{ display: 'flex', flexWrap: 'nowrap', alignSelf: 'center' }}>
                <IconButton
                  onClick={() => { setPage(0) }}
                  disabled={page === 0}
                  aria-label="first page"
                >
                  <FirstPage />
                </IconButton>
                <IconButton disabled={page === 0} onClick={() => { setPage(page - 1) }} aria-label="previous page">
                  <NavigateBefore />
                </IconButton>
                <IconButton
                  onClick={() => { setPage(page + 1) }}
                  disabled={page >= Math.ceil(totalRecords / 10) - 1}
                  aria-label="next page"
                >
                  <NavigateNext />
                </IconButton>
                <IconButton
                  onClick={() => { setPage(Math.ceil(totalRecords / 10) - 1) }}
                  disabled={page >= Math.ceil(totalRecords / 10) - 1}
                  aria-label="last page"
                >
                  <LastPage />
                </IconButton>
              </div>
            )}
            className={classes.pagination}
          />}
        </Grid>
      </Grid>
    </div>
  );
}

export default AdminEvents;
