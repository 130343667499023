import { makeStyles, styled } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { Grid, Button, Divider } from "@mui/material";
import { convertDate } from "helper";
import { useTranslation } from "react-i18next";
import TicketDonation from "./TicketDonation";
import { useDispatch, useSelector } from "react-redux";
import ModalPopup from "components/Shared/ModalPopup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { isLoggedIn } from "utils";
import ConfirmModal from "components/Shared/ConfirmModal";
import CloseIcon from '@mui/icons-material/Close';

const halfCirlce = {
  width: "1.4rem",
  height: "0.7rem",
  border: "1px solid #d2caca",
  borderRadius: "10rem 10rem 0 0",
  borderBottomColor: "transparent",
  position: "absolute",
};

const useStyles = makeStyles({
  card: {
    right: "5%",
    top: "0",
    backgroundColor: "white",
    height: "auto",
    padding: "1rem",
    maxWidth: "500px",
    width: "370px",
    borderRadius: "15px",
    position: "relative",
    border: "1px solid lightgray",
    "&.h6,h6": {
      color: "#92bafc",
      textTransform: "uppercase",
      fontWeight: "600",
      fontSize: "0.8rem",
    },
  },
  startTime: {
    width: "40%",
    fontSize: "0.7rem",
    marginBottom: "24px",
  },
  event: {
    width: "55%",
    fontSize: "0.8rem",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  discountBox: {
    marginTop: "8px",
  },
  sideText: {
    color: "#c0e0fd",
    textTransform: "capitalize",
    transform: "rotate(90deg)",
    float: "right",
    paddingTop: "40px",
    marginRight: "-36px",
    fontWeight: "500",
    marginTop: "1rem"
  },
  leftHalfCircle: {
    ...halfCirlce,
    transform: "rotate(90deg)",
    margin: "-5px 0px 0px -21px",
  },
  rightHalfCircle: {
    ...halfCirlce,
    transform: "rotate(270deg)",
    margin: "-5px 0 0 334px",
  },
  styledGrid: {
    display: "flex",
    justifyContent: "flex-end",
    fontSize: "0.8rem",
    fontWeight: "bold",
  },
  serviceElements: {
    display: "flex",
    justifyContent: "flex-end",
    fontSize: "0.7rem",
    fontWeight: "500",
    padding: "2px 0 2px 0"
  },
  itemsElements: {
    padding: "2px 0 2px 0px",
    fontSize: "0.7rem !important",
    fontWeight: "600"
  },
  media: {
    height: 'auto',
    "@media (max-width: 500px)": {
      margin: "20px 20px 20px 20px",
    }
  },
  goToCartButton: {
    width: "48% !important",
    marginRight: 12,
    border: "1px solid lightgray !important",
  },
  confirmButton: {
    width: "48% !important",
    backgroundColor: "#1c6afc !important",
    position: "absolute",
    top: "0",
    color: "#fff !important"
  },
});

const OrderSummary = (props) => {
  const { t, i18n } = useTranslation();
  const {
    orderSummaryText,
    eventData,
    values,
    orderSummary,
    discountAmount,
    buyNowHandle,
    eventPriceId,
    setOrderSummary,
    setEventPriceId = () => { },
    handleClose,
    selectedEventId
  } = props;
  const { orderSummaryTitle, ticketType, subTotalText, buyNowText } =
    orderSummaryText;
  const classes = useStyles();
  const [count, setCount] = useState(1);
  const [totalPrice, setTotalPrice] = useState(null);
  const [cartText, setCartText] = useState("");
  const processingFee = orderSummary.paymentProcessingFee < orderSummary.maxProcessingFee ? ((count * orderSummary.paymentProcessingFee) < orderSummary.maxProcessingFee ? (count * orderSummary.paymentProcessingFee) : orderSummary.maxProcessingFee) : orderSummary.maxProcessingFee;
  const subTotal = parseFloat(parseInt(count) * parseFloat(totalPrice)).toFixed(2);
  const [selectedTicket, setSelectedTicket] = useState({});
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const navigate = useNavigate();
  const getSubTotalAmout = (amount) => {
    if(amount){
      setTotalPrice(amount.price);
    }
  }
  const dispatch = useDispatch();
  const location = useLocation();

  const [openConfirmModal, setOpenConfimModal] = useState(false);

  const handleredirect=()=>{
    setOpenConfimModal(false);
    navigate("/login" + `?redirect=${encodeURIComponent(location.pathname + location.search)}`);
  }

  const handleCloseConfirmModal=()=>{
    setOpenConfimModal(false);
    navigate(location.pathname);
  }

  const handleAddToCart = (eventId) => { 
    // if(cartItems.length>0){
    //  let duplicateItem = cartItems.some(obj => obj.eventId === selectedTicket.eventId);
    //  if(!duplicateItem){
    //   dispatch({
    //     type:'addToCartAction',
    //     data:{
    //       selectedTicket,
    //       eventData,
    //       count,
    //       eventId,
    //       totalPrice,
    //       eventPriceId,
    //       selectedEventId
    //     }
    //   })
    //  }else{

    //  }
    // }
    if (isLoggedIn()) {
    if(cartItems.length === 1){
      setShowWarningPopup(true);
    } else {
      dispatch({
        type: 'addToCartAction',
        data: {
          selectedTicket,
          eventData,
          eventId,
          count,
          totalPrice,
          eventPriceId,
          selectedEventId,
          actionType: 'add'
        }
      })
    }
    }
    else {
      setOpenConfimModal(true);
    }
  }
  const goToCartHandler = () => {
    navigate('/cart')
  }
  const confirmationHandler = () => {
    dispatch({
      type: 'addToCartAction',
      data: {
        selectedTicket,
        eventData,
        count,
        eventId: eventData.eventId,
        totalPrice,
        eventPriceId,
        selectedEventId,
        actionType: 'add'
      }
    })
    if(eventData.eventId!==cartItems[0].userCart[0].eventId){
        dispatch({
          type: "removeFromCartAction",
          data: {
              eventId:cartItems[0].userCart[0].eventId,
              showMessage:'false'
          }
      })
    }
    setShowWarningPopup(false);
    handleClose();
  }
  const cartWarningModal = {
    title: "Your Previous Concert Details Will Be Removed From The Cart.",
    content: "Are You Sure You Want To Proceed With New Ticket Booking?",
    buttons: [{
      text: 'go to cart',
      style: classes.goToCartButton,
      handler: goToCartHandler
    }, {
      text: 'yes',
      style: classes.confirmButton,
      handler: confirmationHandler
    }]
  };
  const { cartItems } = useSelector((state) => state.cart);

  const getTicketType = (ticketType) => {
    let duplicateItem = cartItems.some(obj => (obj.priceId === ticketType) && (obj.userCart[0].eventId === selectedTicket.eventId));
    setCartText(duplicateItem ? "go to cart" : "add to cart");
  }

  return (
    <Grid item xs={12} md={3} className={classes.media}>
      {showWarningPopup && <ModalPopup isOpen={true} hideCheckBox={true} setShowWarningPopup={setShowWarningPopup} modalData={cartWarningModal} />}
      <div className={classes.card}>
        <h6>
          <div className="flex items-center justify-between">
            {t(orderSummaryTitle)}
            <CloseIcon style={{color:'gray'}} onClick={handleClose} className="cursor-pointer" />
          </div>
          <span className={classes.sideText}>{t(ticketType)}</span>
        </h6>

        <div className={classes.event}>
          <p>{eventData.name}</p>
        </div>
        <div className={classes.startTime}>
          <p>
            {t('Starts At')}: {eventData.startTime}, <span>{convertDate(eventData.startDate)}</span>
          </p>
        </div>
        <div className={classes.leftHalfCircle}></div>
        <div className={classes.rightHalfCircle}></div>
        <Divider
          sx={{
            border: "2px dotted lightgray",
            borderStyle: "none none dotted",
            marginLeft: "-1%",
            marginRight: "-1%",
            marginBottom: "1rem"
          }}
        />
        <TicketDonation
          eventData={eventData}
          values={values}
          buyNowText={orderSummaryText.buyNowText}
          cartTextValue={cartText}
          count={count}
          setCount={setCount}
          getSubTotalAmout={getSubTotalAmout}
          totalPrice={subTotal}
          setEventPriceId={setEventPriceId}
          setSelectedTicket={setSelectedTicket}
          getTicketType={getTicketType}
        />
        {/* <div class={classes.rightHalfCircle}></div>
        <Grid container direction="row" padding="24px 0px 10px 0px">
          <Grid item xs={8} className={classes.itemsElements}>
          {orderSummary?.bookingTypeLabel || orderSummary?.bookingType}
          </Grid>
          <Grid item className={classes.styledGrid} xs={4}>
            + ${count * orderSummary?.price}
          </Grid>

          <Grid item xs={8} className={classes.itemsElements}>
          {t('Service Fee')}
          </Grid>
          <Grid item className={classes.styledGrid} xs={4}>
            + $
            {(processingFee + (count * orderSummary?.ticketingFee))}
          </Grid>

          <Grid
            item
            xs={6}
            marginLeft={1}
            sx={{ fontSize: "0.7rem", fontWeight: "500" }}
          >
            {t('Ticketing Fee')}
          </Grid>
          <Grid item marginLeft={6} className={classes.serviceElements} xs={4}>
            + ${count * orderSummary?.ticketingFee}
          </Grid>

          <Grid
            item
            xs={6}
            marginLeft={1}
            sx={{ fontSize: "0.7rem", fontWeight: "500" }}
          >
            {t('Payment Processing Fee')}
          </Grid>
          <Grid item marginLeft={6} className={classes.serviceElements} xs={4}>
            + ${processingFee}
          </Grid>

          <Grid item xs={8} className={classes.itemsElements}>
          {t('Donate To Hurricane Ian Victims')}
          </Grid>
          <Grid item className={classes.styledGrid} xs={4}>
            + ${values.donateAmount ? values.donateAmount : 0}
          </Grid>

          <Grid item xs={8} className={classes.itemsElements}>
          {t('Taxes')}
          </Grid>
          <Grid item className={classes.styledGrid} xs={4}>
            + ${count * orderSummary.taxPrice}
          </Grid>
          <Divider />
          <Grid item xs={8} className={classes.itemsElements}>
          {t('Discount Amount')}
          </Grid>
          <Grid item className={classes.styledGrid} xs={4}>
            - ${discountAmount}
          </Grid>
        </Grid> */}
        <Divider />
        <Grid container padding="10px 0 0 0">
          <Grid item xs={10} sx={{ fontSize: "0.7rem", color: "#000", fontWeight: "bold" }}>
            {t(subTotalText)}
          </Grid>
          <Grid item xs={2} className={classes.styledGrid}>
            ${subTotal}
          </Grid>
        </Grid>
        <Grid container padding="10px 0 0 0">
          <Grid item lg={6} xs={10} sx={{ fontSize: "0.7rem", color: "#000", fontWeight: "bold" }}>
            {t('Tax')}
          </Grid>
          <Grid item lg={6} xs={2} className={classes.styledGrid}>
            {t('Calculated At Checkout')}
          </Grid>
        </Grid>
        <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: "1rem 0" }}>
          {cartText === 'go to cart' ? <Link style={{ width: `${t(cartText) === 'go to cart' ? '48%' : 'auto'}`, marginRight: 12, whiteSpace: 'nowrap' }} to='/cart'>
            <Button
              onClick={() => handleAddToCart(eventData.eventId)}
              variant="contained"
              color="secondary"
              style={{ width: "100%", marginRight: 12, border: "1px solid lightgray", top: "0.7rem" }}
            >
              {t(cartText)}
            </Button>
          </Link> : <Button
            onClick={() => handleAddToCart(eventData.eventId)}
            variant="contained"
            color="secondary"
            style={{ width: `${t(cartText) === ('add to cart' || 'go to cart') ? '48%' : 'auto'}`, marginRight: 12, border: "1px solid lightgray", top: "0.7rem" }}
          >
            {t(cartText)}
          </Button>}
          <Button
            onClick={() => buyNowHandle(count)}
            style={{
              width: `${['add to cart', 'go to cart'].includes(t(cartText)) ? '48%' : 'auto'}`,
              backgroundColor: "#1c6afc !important",
              top: "0.7rem"
            }}
            variant="contained"
          >
            {t(buyNowText)}
          </Button>
        </Grid>
      </div>
      <ConfirmModal open={openConfirmModal} content={t("Please Sign In to complete this action")} buttonText={t("Sign In")} handleConfirm={handleredirect} handleClose={handleCloseConfirmModal} />
    </Grid>
  );
};

export default OrderSummary;
