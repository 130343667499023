import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import englishLangugae from 'Languages/English.json'
import spanishLangugae from 'Languages/Spanish.json'
import { getLocalData } from "utils";

const language = getLocalData('lang');
const resources = {
    en: {
        translation: englishLangugae
    },
    es: {
        translation: spanishLangugae
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: "en",
    interpolation: {
        escapeValue: false
    }
});

export default i18n;