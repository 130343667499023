import React from 'react'
import { makeStyles } from '@mui/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { Modal } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DayMonthDateFormatDate, getLocalData, isAdmin } from 'utils';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    ticketContainer: {
        width: "30%",
        height: "400px",
        marginLeft: "-125px",
        marginTop: "-200px",
        backgroundColor: "white",
        border: "1px solid black",
        borderRadius: "5px",
        position: "absolute",
        top: "55%",
        left: "50%",
        whiteSpace: "nowrap",
        overflowX: "scroll"
    },
    ticketContainerHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "-0.5rem"
    },
    ticket: {
        width: "1000px",
        height: "300px",
        border: "1px solid lightgray",
        margin: "0 1.4rem",
        padding: "0.7rem",
        borderRadius: "10px"
    },
    ticketImage: {
        width: "100%",
        height: "50%",
        objectFit: "cover",
        borderRadius: "10px"
    },
    test: {
        width: "100%",
        display: "flex"
    },
    parent: {
        position: "absolute",
        width: "350px",
        height: "90%",
        marginLeft: "-150px",
        marginTop: "-150px",
        left: "50%",
        top: "30%",
        boxSizing: "border-box",
        padding: "30px",
        whiteSpace: "nowrap",
        borderRadius: "8px",
        boxShadow: "1px 2px 10px 0.5px #00000029",
        backgroundColor: "#fff"
    },

    child: {
        width: "260px",
        // height: "400px",
        marginRight: "30px",
        padding: "1rem",
        // paddingBottom: "3rem",
        boxSizing: "border-box",
        border: "1px solid lightgray",
        borderRadius: "10px",
        display: "inline-block",
        marginTop: "1rem",
        verticalAlign: 'top'
        // marginBottom: "3rem"
    },
    childContainer: {
        width: "100%",
        height: "100%",
        paddingBottom: "1rem",
        marginLeft:"1.2rem",
        overflowX: "scroll",
        '&::-webkit-scrollbar': {
            width: "0"
        }
    },
    divider: {
        width: "100%",
        height: "0.2rem",
        borderBottom: "1px dotted lightgray",
    },
    ticketText: {
        marginBottom: "0.5rem",
        marginTop: "0.2rem"
    }
});
function Tickets({ isOpen, showTicket }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const lan = getLocalData("lan");
    
    const handleClose = () => showTicket(false);
    const closePopUp = () => showTicket(false);
    const dispatch = useDispatch();
    const { myTickets,ticketMediaUrls,loading } = useSelector((state) => state.event);
    useEffect(() => {
        if(!isAdmin()&&isOpen){
            dispatch({
                type: "getMyTicketsAction"
            });
        }
    }, [isOpen]);
    useEffect(()=>{
        if(!isAdmin()){
            myTickets.map((ticket)=>{
                dispatch({
                    type:"getTicketMediaAction",
                    mediaId: ticket.mediaIds[0]
                  });
            });
        }
    },[myTickets]);
    return (
        <>
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <div className={classes.parent}>
                    <div className={classes.ticketContainerHeader}>
                        <h5 className=''>{t("My Orders")}</h5>
                        <CancelIcon onClick={closePopUp} className='color-primary cursor-pointer' />
                    </div>
                    <div className={classes.childContainer}>
                        {myTickets.length>0&&myTickets.map((ticket,index) => {
                            return (
                                <div key={index} className={classes.child}>
                                     <img className={classes.ticketImage} src={ticketMediaUrls.find(img => img?.mediaId == ticket?.mediaIds?.[0])?.ticketUrl} />
                                    <div>
                                        <span className='text-15 font-bold'>{t("Order ID")}: {ticket.orderId}</span>
                                        <div className='text-11 font-normal mt-1 whitespace-nowrap'>{t("Ticket ID")}: <span className='text-12 font-medium'>{ticket.ticketId || '-'}</span></div>
                                        <div className='text-11 font-normal mt-1 whitespace-nowrap'>{t("Ticket Type")}: <span className='text-12 font-medium'>{t(ticket.bookingTypeLabel)}</span></div>
                                        <div className='text-11 font-normal mt-1 whitespace-wrap'>{t("No. of Tickets Purchased")}: <span className='text-12 font-medium'>{ticket.listOfTicketId?.length}</span></div>
                                        <div className='text-11 font-normal mt-1 whitespace-wrap'>{t("List of Tickets")}: <span className='text-11 font-medium'>{ticket.listOfTicketId?.join(', ')}</span></div>
                                        <p className='text-13 font-bold mt-5 whitespace-wrap'>{ticket.eventName}</p>
                                        <p className={` ${classes.ticketText} text-12 font-normal`}>{DayMonthDateFormatDate(ticket.startDate)}, {ticket.startTime}</p>
                                        <p className={` ${classes.ticketText} text-12 font-normal`}>{t("Total Amount")}: <span className='text-12 font-medium'>${ticket.price}</span></p>
                                        <div className={classes.divider}></div>
                                        <p className={`${classes.ticketText} text-12 font-medium mt-3`}>{t("Ticket Sent To Email ID")}:</p>
                                        <p className={`${classes.ticketText} text-12 font-bold`}>{ticket.emailId}</p>
                                    </div>
                                </div>
                            )
                        })}
                        {(!loading&&myTickets.length===0)&&
                        <div className={classes.child}>
                            <h4 className='text-center'>No tickets found</h4>
                        </div>
                        }
                    </div>
                </div>
            </Modal>

        </>
        // <div className={classes.ticketContainer}>
        //     <div className={classes.ticketContainerHeader}>
        //         <h5 className='ml-5'>My Tickets</h5>
        //         <CancelIcon className='color-primary mr-5'/>
        //     </div>
        //     <div className={classes.test}>
        //     <div className={classes.ticket}>
        //         <img className={classes.ticketImage} src={"https://as2.ftcdn.net/v2/jpg/04/91/09/71/1000_F_491097109_NbqFULEQiM3V1VmO5suiDEEHDPrkkNCv.jpg"}/>
        //     </div>
        //     <div className={classes.ticket}>
        //         <img className={classes.ticketImage} src={"https://as2.ftcdn.net/v2/jpg/04/91/09/71/1000_F_491097109_NbqFULEQiM3V1VmO5suiDEEHDPrkkNCv.jpg"}/>
        //     </div>
        //     <div className={classes.ticket}>
        //         <img className={classes.ticketImage} src={"https://as2.ftcdn.net/v2/jpg/04/91/09/71/1000_F_491097109_NbqFULEQiM3V1VmO5suiDEEHDPrkkNCv.jpg"}/>
        //     </div>
        //     <div className={classes.ticket}>
        //         <img className={classes.ticketImage} src={"https://as2.ftcdn.net/v2/jpg/04/91/09/71/1000_F_491097109_NbqFULEQiM3V1VmO5suiDEEHDPrkkNCv.jpg"}/>
        //     </div>
        //     </div>

        // </div>
    )
}

export default Tickets