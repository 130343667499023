import { Button, Drawer, Grid, Icon, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react'

const drawerWidth = '60%';

const useStyles = makeStyles((theme) => ({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      '& label': {
        fontSize: 14,
        marginBottom: 5
      },
      '& .MuiInputBase-input': {
        fontSize: 13,
        height: 8
      },
      '& .MuiSelect-select': {
        padding: '9px 32px 9px 12px'
      }
    },
    drawerPaper: {
      width: drawerWidth,
      overflowX: 'hidden',
      [theme.breakpoints.down('sm')]: {
        width: "100% !important",
      },
    },

}));

function GlobalDrawer(props) {
    const { open, drawerProps } = props;
    const classes = useStyles();

    return (
        <Drawer
            anchor="right"
            open={open}
            // onClose={handleClose}
            className={classes.drawer}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <Grid className='mb-5'>
                <Grid className='flex items-center justify-between border-bottom-lightgray mb-5 pl-8 py-2 pr-4'>
                    {drawerProps.header.map((headerValue) => {
                        return (
                            <Grid>
                                <Grid className='font-medium'>{headerValue.title}</Grid>
                                {headerValue.icon && <IconButton onClick={headerValue.handler}>{headerValue.icon}</IconButton>}
                            </Grid>
                        )
                    })}
                </Grid>
                <Grid className='mb-6 px-8 py-2'>
                    {drawerProps.fields.map((fieldValue) => {
                        return fieldValue.component;
                    })}
                </Grid>
                <Grid className={`flex items-center justify-between border-top-lightgray  ${drawerProps.footerStyle} w-full`}>
                    {drawerProps.footer.map((footerValue) => {
                        return (
                            <Grid>
                                <Button disabled={footerValue.disabled} className='mx-8 my-4' variant={footerValue.style} onClick={footerValue.handler}>{footerValue.title}</Button>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
        </Drawer>
    )
}

export default GlobalDrawer;