import { call, put, takeEvery, all } from 'redux-saga/effects'
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from 'redux/slices/snackbar';
import { getAddressListService, getUserProfileService, saveAddressService, setUserProfile, updateAddressService } from 'redux/ProfileManagement/ProfileManagementService';
import { setAddressList, setUserProfileImage, setInitialState, setUpdateProfile } from 'redux/ProfileManagement/ProfileManagementSlice';
import ApiConstant from 'constant/ApiConstant';
import i18n from 'i18next';
import { isLoggedIn } from 'utils';

function* translateAndDispatchSuccess(message) {
    const translation = yield call([i18n, 't'], message);
    yield put(SNACKBAR_SUCCESS(translation));
}
function* translateAndDispatchError(message) {
    const translation = yield call([i18n, 't'], message);
    yield put(SNACKBAR_ERROR(translation));
}

const { RESPONSE } = ApiConstant;









// Actions
export default function* reportsManagementSaga() {
    yield all([
        
    ])
}
