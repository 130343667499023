import { 
    Typography, 
    Accordion, 
    AccordionSummary, 
    AccordionDetails,
    } from '@mui/material';
    import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
    import React from 'react';
import { useTranslation } from 'react-i18next';

const AccordianComponent = (props) => { 
  const { t, i18n } = useTranslation();
  const { title, value, onChangeStyle, accordionState, id, ids } = props;
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
   return(
    <Accordion elevation={0} expanded={expanded === id} onChange={handleChange(id)}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls={id}
      id={id}
      sx={{minHeight: 0}}
      onClick={onChangeStyle}
    >
      <Typography className='text-12 font-medium mr-1'
        sx={{ fontWeight: accordionState && id === ids ? "bold !important" : null }}>
        {t(title)}
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography className='text-12 font-medium mr-1'>{t(value)}</Typography>
    </AccordionDetails>
  </Accordion>
   )
}
export default AccordianComponent;