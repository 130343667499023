const constant = {
  MONTHS: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  DAY: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  IMAGES_ARRAY: [
    {
      original:
        "https://as2.ftcdn.net/v2/jpg/04/91/09/71/1000_F_491097109_NbqFULEQiM3V1VmO5suiDEEHDPrkkNCv.jpg",
    },
    {
      original:
        "https://as1.ftcdn.net/v2/jpg/05/02/19/70/1000_F_502197093_3Hmtkqh7w3KjVImgrvosDaDgff9NhE0O.jpg",
    },
    {
      original:
        "https://as1.ftcdn.net/v2/jpg/02/71/17/22/1000_F_271172252_md7S31KiBDq6mEOPaohRm6k33kBoGz8s.jpg",
    },
    {
      original:
        "https://as1.ftcdn.net/v2/jpg/03/10/94/20/1000_F_310942037_aekjJ5SNvOixyf1zP8U0NsLq6f0Tbex1.jpg",
    },
  ],
};

export default constant;
