import { ExpandMoreSharp } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material'
import React from 'react'
import Ticket from './Ticket'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { makeStyles } from '@mui/styles';
import breakpoints from 'components/Shared/MediaQueries/BreakPoints';
import { useTranslation } from 'react-i18next';

function PurchasedTicket(props) {
  const { t } = useTranslation();
  const { tickets,handleTicketButtonClick } = props;
  const useStyles = makeStyles({
    eventName: {
      [breakpoints.sm_down]: {
        fontSize: "0.9rem"
      }
    },
    accordianDetails: {
      [breakpoints.sm_down]: {
        margin: "0rem",
        padding: "0rem !important"
      }
    },
    emptyMessage:{
     textAlign:'center'
    }
  })

  const classes = useStyles();
  const ticketButtons = [{
        buttonText:"Watch Show With This Ticket",
        buttonSecondaryText:"Watch Show",
        // icon:<CheckCircleOutlinedIcon/>,
        showModal:true
      },{
        buttonText:"Gift Ticket To Others",
        buttonSecondaryText:"Gifted",
        icon:<CheckCircleOutlinedIcon/>,
        class:"shareBtn",
        showModal:true
      } ]
  return (
    <Grid sx={{ width: "100%",display:'grid' }}>
      {(tickets&&tickets.length>0)?tickets.map((ticket,index) => {
        return (
          <>
          <span className='text-light-gray text-10 ml-3'>{t("Booked On")} {ticket[0].bookingDate}</span>
          <Accordion className='mt-3 mb-3' defaultExpanded={index===0&&true}>
            <AccordionSummary
              expandIcon={<ExpandMoreSharp />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h5 className={classes.eventName}>{ticket[0]?.eventName}<span className='text-light-gray'>- {ticket.length} {ticket.length>1?t("tickets"):t("ticket")}</span></h5>
            </AccordionSummary>
            <AccordionDetails className={classes.accordianDetails}>
              <Ticket tickets={ticket} handleTicketButtonClick={handleTicketButtonClick} ticketButtons={ticketButtons}/>
            </AccordionDetails>
          </Accordion>
          </>
        )
      }):<span className={classes.emptyMessage}>{t("No Ticket Has Been Purchased Yet.")}</span>}
    </Grid>
  )
}

export default PurchasedTicket