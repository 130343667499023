import { call, put, takeEvery, all } from "redux-saga/effects";
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from "redux/slices/snackbar";
import {
  cancelEventService,
  eventBookingService,
  getAllAdminEventsService,
  getAllEventsService,
  getAmountDetailsService,
  getArtistEventsService,
  getEventByIdService,
  getEventService,
  getMediaService,
  getMyTicketsService,
  getVerifyCouponService,
  setEventsService,
  uploadMediaService,
} from "redux/EventManagement/EventManagementService";
import {
  emptyArtistMedia,
  setArtistEvents,
  setArtistMedia,
  setArtistMediaId,
  setCouponDetails,
  setEventBookingDetails,
  setEventData,
  setEventMedia,
  setLoading,
  setMyTickets,
  setAllEvents,
  setTicketMedia,
  removeEventBookingDetails,
  setAllAdminEvents,
} from "redux/EventManagement/EventManagementSlice";
import ApiConstant from "constant/ApiConstant";
import { setPreviosStateData } from "redux/EventManagement/EventManagementSlice";
import i18n from 'i18next';
import { loading } from "redux/Loader/LoaderSlice";
import { isLoggedIn } from "utils";
import { v4 as uuidv4 } from 'uuid';

function* translateAndDispatchSuccess(message) {
  const translation = yield call([i18n, 't'], message);
  yield put(SNACKBAR_SUCCESS(translation));
}
function* translateAndDispatchError(message) {
  const translation = yield call([i18n, 't'], message);
  yield put(SNACKBAR_ERROR(translation));
}

const { RESPONSE } = ApiConstant;

function* getAllEvents() {
  try {
    const response = yield call(getAllEventsService);
    if(response.status === RESPONSE.SUCCESS) {
      const data = response.data.listOfEvents;
      yield put(setAllEvents(data));
    }
  } catch (error) {
    // console.error(error);
  }
}
function* getAllAdminEvents(payload){
  try {
    const {data,onSuccessCB} =payload;
    const response = yield call(getAllAdminEventsService,data);
    if(response.status === RESPONSE.SUCCESS) {
      const data = response.data;
      yield put(setAllAdminEvents(data));
      onSuccessCB(response.count);
    }
  } catch (error) {
    
  }
}
function* getVerifyCouponCode(payload) {
  let requestId = Math.random();
  try {
    const { eventId, couponCode, priceId } = payload.data;
    yield put(loading({loading:true,requestId}));
    const response = yield call(
      getVerifyCouponService,
      eventId,
      couponCode,
      priceId
    );
    if (response.status === RESPONSE.SUCCESS) {
      yield put(setCouponDetails(response));
      yield translateAndDispatchSuccess(response.message);
      yield put(loading({loading:false,requestId}));
    }
  } catch (error) {
    yield translateAndDispatchError(error.error);
    console.error(error);
    yield put(loading({loading:false,requestId}));
  }
}
function* eventBooking(action) {
  const { data, onSuccessCB, onErrorCB } = action.payload;
  let requestId = Math.random();
  try {
    yield put(loading({loading:true,requestId}));
    const response = yield call(eventBookingService, data);
    if (response) {
      onSuccessCB(response);
      yield put(loading({loading:false,requestId}));
      // yield translateAndDispatchSuccess(response.message);
      yield put(setEventBookingDetails(response));
    }
  } catch (error) {
    onErrorCB(error);
    yield put(loading({loading:false,requestId}));
    yield translateAndDispatchError(error.error);
    console.error(error);
  }
}
function* getEvent(payload) {
  let requestId = Math.random();
  try {
    const { eventId } = payload.data;
    if(eventId){
      yield put(loading({loading:true,requestId}));
      const response = yield call(getEventService, eventId);
      if (response.status === RESPONSE.SUCCESS) {
        yield put(setEventData(response));
        yield put(loading({loading:false,requestId}));
      }
    }
  } catch (error) {
    yield translateAndDispatchError(error.error);
    console.error(error);
    yield put(loading({loading:false,requestId}));
  }
}

function* getMyTickets() {
  let requestId = Math.random();
  try {
    if (isLoggedIn()) {
      yield put(loading({loading:true,requestId}));
      let response = yield call(getMyTicketsService);
      const { data } = response;
      yield put(setMyTickets(data));
      yield put(setLoading(false));
      yield put(loading({loading:false,requestId}));
    }
  } catch (error) {
    yield put(setLoading(false));
    yield put(loading({loading:false,requestId}));
    console.error(error);
  }
}

function* setEventPreviosState(payload) {
  try {
    const { data } = payload;
    yield put(setPreviosStateData(data));
  } catch (error) {
    yield translateAndDispatchError(error.error);
    console.error(error.error);
  }
}

function* cancelEvent(payload) {
  const { eventId, onSuccess } = payload;
  try {
    let data = yield call(cancelEventService, eventId);
    if (data) {
      yield translateAndDispatchSuccess(data.message);
      onSuccess(data);
    }
  } catch (error) {
    yield translateAndDispatchError(error.error);
    console.error(error);
  }
}

function* getArtistEvents(payload) {
  let requestId = Math.random();
  try {
    const { data: { artistId } } = payload;
    if(artistId){
      yield put(loading({loading:true,requestId}));
      const response = yield call(getArtistEventsService, artistId);
      yield put(setArtistEvents(response.data));
      yield put(loading({loading:false,requestId}));
    }
  } catch (error) {
    yield translateAndDispatchError(error.error);
    yield put(loading(false));
    yield put(loading({loading:false,requestId}));
  }

}
function* emptyArtistMediaArray() {
  try {
    yield put(emptyArtistMedia())
  } catch (error) {
    yield translateAndDispatchError(error.error);
  }
}
function* setArtistMediaIdValue(payload) {
  try {
    const { data: { mediaId } } = payload;
    yield put(setArtistMediaId(mediaId));
  } catch (error) {
    yield translateAndDispatchError(error.error);
  }
}

function* getAmountDetails(action) {
  const { addressId, eventId, quantity, couponCode, onSuccessCB, onErrorCB } = action.payload;
  let requestId = Math.random();
  try {
    yield put(loading({loading:true,requestId}));
    const response = yield call(getAmountDetailsService, addressId, eventId, quantity, couponCode);
    // yield put(setArtistEvents(response.data));
    onSuccessCB(response);
    yield put(loading({loading:false,requestId}));

  } catch (error) {
    onErrorCB(error)
    yield translateAndDispatchError(error.error);
    yield put(loading({loading:false,requestId}));
  }

}
function* setEvents(payload){
  let requestId = Math.random();
  try {
    const {data,actionType,onSuccessCB} = payload;
    console.log(data,payload)
    yield put(loading({loading:true,requestId}));
    let response = yield call(setEventsService,data);
    if(response){
      yield put(loading({loading:false,requestId}));
      yield translateAndDispatchSuccess(response.message);
      onSuccessCB(response);
    }
  } catch (error) {
    yield translateAndDispatchError(error.error);
    yield put(loading({loading:false,requestId}));
  }
}
function* uploadMedia(payload){
  let requestId = Math.random();
  const{data,onSuccessCB} = payload;
  try {
    yield put(loading({loading:true,requestId}));
   let response = yield call(uploadMediaService,data);
   onSuccessCB(response);
   yield put(loading({loading:false,requestId}));
   yield translateAndDispatchSuccess(response.message);
  } catch (error) {
    yield translateAndDispatchError(error.error);
    yield put(loading({loading:false,requestId}));
  }
}
function* getEventById(payload){
  let requestId = Math.random();
  const {data:{eventId},onSuccessCB} = payload;
  try {
   yield put(loading({loading:true,requestId}));
   let response = yield call(getEventByIdService,eventId);
   yield put(loading({loading:false,requestId}));
   onSuccessCB(response?.data);
  } catch (error) {
    yield translateAndDispatchError(error.error);
    yield put(loading({loading:false,requestId}));
  }
}

function* watchRemoveEventBookingDetails() {
  yield takeEvery("removeEventBookingDetails", function* () {
    yield put(removeEventBookingDetails({}))
  });
}
function* watchGetAmountDetails() {
  yield takeEvery("getAmountDetailsAction", getAmountDetails);
}
function* watchGetAllEvents() {
  yield takeEvery("getAllEventsAction", getAllEvents);
}
function* watchGetAdminAllEvents(){
  yield takeEvery("getAllAdminEventsAction", getAllAdminEvents);
}
function* watchGetEventAction() {
  yield takeEvery("getEventAction", getEvent);
}
function* watchGetVerifyCouponCode() {
  yield takeEvery("getVerifyCouponCodeAction", getVerifyCouponCode);
}
function* watchEventBooking() {
  yield takeEvery("eventBookingAction", eventBooking);
}
function* watchGetMyTickets() {
  yield takeEvery("getMyTicketsAction", getMyTickets);
}
function* watchSetPreviosEventState() {
  yield takeEvery("setPreviosEventStateAction", setEventPreviosState);
}
function* watchCancelEvent() {
  yield takeEvery("cancelEvent", cancelEvent);
}
function* watchArtistEvent() {
  yield takeEvery("getArtistEventsAction", getArtistEvents);
}
function* watchEmptyArtistMedia() {
  yield takeEvery("emptyArtistMediaAction", emptyArtistMediaArray);
}
function* watchSetArtistMediaIdValue() {
  yield takeEvery("setArtistMediaIdValueAction", setArtistMediaIdValue)
}
function* watchSetEvent(){
  yield takeEvery('setEventAction',setEvents);
}
function* watchUploadMedia(){
  yield takeEvery('uploadMediaAction',uploadMedia);
}
function* watchGetEventById(){
  yield takeEvery('getEventByIdAction',getEventById)
}
// Actions
export default function* eventManagementSaga() {
  yield all([
    watchGetAllEvents(),
    watchGetEventAction(),
    watchGetVerifyCouponCode(),
    watchEventBooking(),
    watchGetMyTickets(),
    watchSetPreviosEventState(),
    watchCancelEvent(),
    watchArtistEvent(),
    watchEmptyArtistMedia(),
    watchSetArtistMediaIdValue(),
    watchGetAmountDetails(),
    watchRemoveEventBookingDetails(),
    watchSetEvent(),
    watchUploadMedia(),
    watchGetEventById(),
    watchGetAdminAllEvents()
  ]);
}
