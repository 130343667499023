import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Slider from 'react-slick';
import { Badge, Button, Grid, Tooltip } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DayMonthDateFormatDate, getLocalData, isAdmin } from 'utils';
import { useTranslation } from 'react-i18next';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css';
import breakpoints from 'components/Shared/MediaQueries/BreakPoints';

const useStyles = makeStyles({
    mainContainer: {
        width: "100%",
        minHeight: "30rem",
        placeItems: 'center',
    },
    header:{
        fontSize: "1.5rem",
        margin:0,
    },
    ticketContainerHeader: {
        display: "flex",
        alignItems: "center",
        height:'2rem',
        [breakpoints.sm_down]:{
            margin:"2rem 1.5rem",
        }
    },
    ticketImage: {
        width: "100%",
        height: "20vh",
        objectFit: "cover",
        borderRadius: "10px"
    },
    parent: {
        width: "85%",
        height: "100%",
        margin: "0 auto",
        boxSizing: "border-box",
        padding: "0.5rem",
        whiteSpace: "nowrap",
        borderRadius: "8px",
        backgroundColor: "#fff",
        [breakpoints.sm_down]:{
            width:"100%",
            padding:0
        }
    },
    childEmptyCart: {
        width: "95%",
        height: "inherit",
        padding: "1rem",
        boxSizing: "border-box",
        border: "1px solid lightgray",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent:"center",
        marginTop: "0.5rem",
        verticalAlign: 'top'
    },
    child: {
        width: "270px !important",
        height: 'fit-content',
        padding: "1rem",
        boxSizing: "border-box",
        border: "1px solid lightgray",
        borderRadius: "10px",
        verticalAlign: 'top'
    },
    childContainer: {
        width: "100%",
        height: "27rem",
    },
    divider: {
        width: "100%",
        height: "0.2rem",
        borderBottom: "1px dotted lightgray",
    },
    ticketText: {
        marginBottom: "0.5rem",
        marginTop: "0.2rem"
    },
    copy_icon : {
        color: "white",
    },
    copy_button : {
        padding: "0",
    },
    countBadge : {
        marginLeft: "1rem",
    }

});
function MyOrder() {
    const classes = useStyles();
    const { t } = useTranslation();
    const lan = getLocalData("lan");
    const navigate = useNavigate();
    
    const dispatch = useDispatch();
    const { myTickets,ticketMediaUrls,loading } = useSelector((state) => state.event);
    
    const settings = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 960,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 650,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
            }
        }
      ]
    };
    useEffect(() => {
        if(!isAdmin()){
            dispatch({
                type: "getMyTicketsAction"
            });
        }
    }, []);
    
useEffect(()=>{
    if(!isAdmin()){
        myTickets.map((ticket)=>{
            dispatch({
                type:"getTicketMediaAction",
                mediaId: ticket.mediaIds[0]
              });
        });
    }
},[myTickets]);

const handleCopy = (text) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(text)
    }
};
const handleBack =()=>{
    navigate('/');
}


return (
    <>
        <Grid className={classes.mainContainer}>
            <div className={classes.parent}>
                <div className={classes.ticketContainerHeader}>
                    {/* <Button className='cursor-pointer' onClick={handleBack}><ArrowBackIcon className="text-light-gray" /></Button> */}
                    <h5 className={classes.header}>{t("My Orders")}</h5>
                </div>
                <div className={classes.childContainer}>
                    <Slider {...settings}>
                      {myTickets.length>0&&myTickets.map((ticket,index) => {
                        const remainingData = ticket?.listOfTicketId.slice(1);
                        return (
                            <div className={`slickSlider ${classes.child}`} key={index}>
                                <img className={classes.ticketImage} src={ticket?.mediaIds?.[0]?.mediaUrl} />
                                <div>
                                    <span className='text-15 font-bold'>{t("Order ID")}: {ticket.orderId}</span>
                                    {/* <div className='text-11 font-normal mt-1 whitespace-nowrap'>{t("Ticket ID")}: <span className='text-12 font-medium'>{ticket.ticketId || '-'}</span></div> */}
                                    <div className='text-11 font-normal mt-1 whitespace-nowrap'>
                                        {t("Ticket Type")}: 
                                        {
                                            ticket.bookingTypeLabel.length>20?<Tooltip title={ticket.bookingTypeLabel}><span className='text-12 font-medium cursor-pointer'>
                                            {t(ticket.bookingTypeLabel.substring(0,20)+'...')}
                                        </span></Tooltip>:<span className='text-12 font-medium'>
                                            {t(ticket.bookingTypeLabel)}
                                        </span>
                                        }
                                    </div>
                                    <div className='text-11 font-normal mt-1 whitespace-wrap'>{t("No. of Tickets Purchased")}: <span className='text-12 font-medium'>{ticket.listOfTicketId?.length}</span></div>
                                    <div className='text-11 font-normal mt-1 whitespace-wrap'>{t("List of Tickets")}: <span className='text-11 font-medium'>
                                        <span className='text-11 font-medium'>{ticket?.listOfTicketId[0]};</span>
                                        {ticket?.listOfTicketId.length > 1 && (
                                            <Tooltip title={
                                                <div style={{ maxWidth: 300 }}>
                                                {remainingData?.map((item) => (
                                                    <div key={item} style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                                                        <span style={{ marginRight: 8,fontSize:'0.8rem' }}>{item}</span>
                                                            <Button
                                                            variant="text"
                                                            size="small"
                                                            onClick={() => handleCopy(item)}
                                                            className={classes.copy_button}
                                                            >
                                                                <FileCopyIcon className={classes.copy_icon} />
                                                            </Button>
                                                        </div>
                                                    ))}
                                                </div>
                                            }>
                                            <Badge className={classes.countBadge} badgeContent={`+${ticket?.listOfTicketId.length - 1}`} color="primary">
                                            </Badge>
                                          </Tooltip>
                                        )}
                                        </span></div>
                                    <p className='text-13 font-bold whitespace-wrap'>{ticket.eventName}</p>
                                    <p className={` ${classes.ticketText} text-12 font-normal`}>{t("Booked On")}: {DayMonthDateFormatDate(ticket.bookingDate)}</p>
                                    <p className={` ${classes.ticketText} text-12 font-normal`}>{t("Total Amount")}: <span className='text-12 font-medium'>${ticket.price}</span></p>
                                    <div className={classes.divider}></div>
                                    <p className={`${classes.ticketText} text-12 font-medium`}>{t("Ticket Sent To Email ID")}:</p>
                                    <p className={`${classes.ticketText} text-12 font-bold`}>{ticket.emailId}</p>
                                </div>
                            </div>
                        )
                        })}
                    </Slider>
                    {(!loading&&myTickets.length===0)&&
                        <div className={classes.childEmptyCart}>
                            <h4 className='text-center'>{t("No Orders Found")}</h4>
                        </div>
                    }
                </div>
            </div>
        </Grid>
    </>
)
}

export default MyOrder;