import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    token: "",
    cardsList: [],
    cardError:[]
}

const cardValidationSlice = createSlice({
    name: 'cardInfo',
    initialState,
    reducers: {
        setInitialState(state, action) {
            state.token = initialState.token;
            state.cardError = initialState.cardError;
        },
        setCardInfo(state, action) {
            state.token = action.payload.token;
            state.expiry = action.payload.expiry;
        },
        setCardsList(state, action) {
            const data = action.payload;
            state.cardsList = data;
        },
        setCardErrors(state,action){
            state.cardError = [...state.cardError,action.payload];
        }
    },
});

export default cardValidationSlice.reducer;

// Actions
export const {
    setInitialState,
    setCardInfo,
    setCardsList,
    setCardErrors
} = cardValidationSlice.actions;
