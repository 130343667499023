import { Grid, Chip, Paper, styled, Select, MenuItem, Button, TextField, InputAdornment, Icon } from '@mui/material';
import { makeStyles } from "@mui/styles";
import TagFacesIcon from '@mui/icons-material/TagFaces';
import React, { useState } from "react";
import { DateFormatFromDateObj, DayMonthDateFormatDate, formatDate } from 'utils';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setReportsFilterDetails } from 'redux/DashboardManagement/DashboardManagementSlice';
import { PhonelinkLockOutlined } from '@mui/icons-material';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider, cre } from "@material-ui/styles";
import { date } from 'yup';

const materialTheme = createTheme({
  overrides: {
    MuiInputBase: {
      root: {
        height: "2.7rem",
        width: "100%"
      },
    },
    MuiInputLabel: {
      formControl: {
        fontSize: "0.9rem",
        top: "-10%"
      },
      shrink: {
        top: "0 !important"
      }

    }

  },
});

const useStyles = makeStyles({
  root: {
    '& .MuiPickersToolbar-toolbar': {
      backgroundColor: "red !important",
    },
  },
  label: {
    fontWeight: "600",
    fontSize: "0.8rem",
    color: "#756F86",
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    "& li": {
      fontWeight: 200,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: "12px",
    },
    "& li.Mui-selected": {
      width: '100%',
    },
    "& li.Mui-selected:hover": {
      width: '100%'
    },
    "& li:hover": {
      width: '100%'
    }
  },
  chipContainer: {
    display: "flex",
    listStyle: "none",
    alignItems: "center",
    flexDirection: "row",
    '@media(max-width:600px)': {
      flexDirection: "column !important",
      marginBottom: "1.5rem !important",
      marginLeft: "0"
    },
    '@media screen and (max-width: 992px) and (min-width: 700px)': {
      width: "70%",
      marginBottom: "1.5rem !important",
      marginRight:'10rem !important'
    }
  },
  container: {
    '@media(max-width:600px)': {
      flexDirection: "column !important"
    },
    '@media screen and (max-width: 992px) and (min-width: 700px)': {
      flexDirection: "column !important",
      alignItems: "baseline !important",
      width:'8rem !important'
    },
    '@media screen and (max-width: 1024px) and (min-width: 790px)': {
        display:'flex',
        flexWrap:"wrap"
    }
  },
  formButton: {
    padding: "0.5rem !important",
    marginBottom: "1.1rem !important",
    '@media(max-width:600px)': {
      marginTop: "1.5rem !important"
    }
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    '@media(max-width:600px)': {
      flexDirection: "column !important",
      alignItems: "center !important",
      marginLeft: "0.5rem"
    },
    '@media screen and (max-width: 992px) and (min-width: 700px)': {
      width:'30rem !important',
      marginRight:'10rem !important'
    },
  },
  dateIcon: {
    fontSize: "1.1rem !important",
    color: "black",
    marginRight: "0.7rem"
  }
});

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const ReportHeader = ({ pageMode, filter, setFilter, applyFilter }) => {
  const classes = useStyles();
  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper,
    },
  }
  const [chipData, setChipData] = useState([]);
  const [ischipDeleted, setIsChipDeleted] = useState(false);
  const [defaultSelectValue, setDefaultSelectValue] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const dispatch = useDispatch();
  const handleDelete = (chipToDelete) => () => {
    if (chipToDelete === "fromDate") {
      setFromDate(null);
    } else if (chipToDelete === "toDate") {
      setToDate(null);
    }
    setIsChipDeleted(true);
    chipData.map((chip) => {
      for (const key of Object.keys(chip)) {
        if (key === chipToDelete) {
          setFilter((prev) => ({
            ...prev,
            [key]: ""
          }));
        }
      }
    })
  };
  const handleFilter = (filter, dateFormat, fieldName) => {
    if (dateFormat) {
      if (fieldName === 'fromDate') {
        setFromDate(filter);
      } else {
        setToDate(filter);
      }
      setFilter((prev) => ({
        ...prev,
        [fieldName]: DateFormatFromDateObj(filter)
      }));
    } else {
      const { name, value } = filter.target;
      setDefaultSelectValue("");
      setFilter((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  }
  useEffect(() => {
    setChipData([filter]);
    if (ischipDeleted) {
      applyFilter(true);
      setIsChipDeleted(false);
    }
  }, [filter]);
  useEffect(() => {
    setDefaultSelectValue("Live Streaming");
  }, []);

  const ticketType = {
    titleLable: "Ticket type & donation",
    ticketTypeLabel: "Ticket type",
    ticketDropDownValues: [
      {
        label: "Live Streaming",
        value: "Live Streaming",
      },
      {
        label: "Unlimited Views - Recorded",
        value: "Unlimited Views - Recorded",
      },
      {
        label: "One Day Watch - Recorded ",
        value: "One day watch - Recorded",
      },
    ],
  };

  return (
    <Grid className={`flex justify-between items-center mt-8 ${classes.container}`}>
      {(filter.fromDate || filter.toDate || filter.ticketType) && <Grid className={classes.chipContainer}>
        {chipData.map((data) => {
          let icon;
          let keys = Object.keys(data);
          if (data.label === 'React') {
            icon = <TagFacesIcon />;
          }
          return (
            <>
              {keys.map((el) => {
                return (<ListItem
                  sx={{
                    width: '100%',
                    '& .MuiButtonBase-root': {
                      border: '1px solid lightgray',
                      backgroundColor: 'white',
                    },
                    '@media(max-width: 600px)': {
                      width: '-webkit-fill-available',
                    },
                  }}>
                  {data[el] && <Chip
                    icon={icon}
                    label={data[el]}
                    onDelete={data[el] === 'React' ? undefined : handleDelete(el)}
                  />}

                </ListItem>)
              })}
            </>

          );
        })}
      </Grid>}
      <Grid className={`${classes.filterContainer}`} md={6}>
        <Select
          value={defaultSelectValue ? defaultSelectValue : filter.ticketType}
          onChange={(e) => handleFilter(e)}
          name="ticketType"
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            height: "5vh",
            width: "100%",
            padding: "1.3rem",
            marginRight: "1rem",
            marginBottom: "0.70rem",
            fontSize: "12px",
            '@media screen and (max-width: 900px)': {
              height: "4vh",
              width: "100%",
              padding: "1.3rem",
            },
          }}
          MenuProps={menuProps}
          classes={{
            select: classes.select,
          }}
        >
          {ticketType.ticketDropDownValues.length &&
            ticketType.ticketDropDownValues.map((obj, key) => {
              return (
                <MenuItem key={key} value={obj.value} sx={{ fontSize: "13px", }}>
                  {obj.label}
                </MenuItem>
              );
            })
          }
        </Select>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ThemeProvider theme={materialTheme}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              label="From date"
              name="fromDate"
              format="dd-MM-yyyy"
              maxDate={Date.now()}
              className="mr-4 mb-3 w-full"
              value={fromDate}
              InputAdornmentProps={{ position: "end" }}
              onChange={date => handleFilter(date, "Date", "fromDate")}
            />
            <KeyboardDatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              label="To date"
              name="toDate"
              format="dd-MM-yyyy"
              className="mr-4 mb-3 w-full"
              maxDate={Date.now()}
              minDate={fromDate}
              value={toDate}
              InputAdornmentProps={{ position: "end" }}
              onChange={date => handleFilter(date, "Date", "toDate")}
            />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid>
        <Button onClick={() => applyFilter(true)} className={`${classes.formButton} bg-primary`} variant='contained'>Apply Filter</Button>
      </Grid>
    </Grid>
  );
}
export default ReportHeader;
