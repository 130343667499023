import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import artistImage from "../../assets/images/Ed_Sheeran_artist.webp";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import { Cancel, CheckCircle, ExpandMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setCouponCode, setRemoveCoupon } from "redux/EventManagement/EventManagementSlice";
import CheckoutSummary from "./CheckoutSummary";
import { useParams } from "react-router-dom";
import breakpoints from 'components/Shared/MediaQueries/BreakPoints';
import ApiConstant from "../../constant/ApiConstant";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import CheckoutVerificationModal from "./CheckoutVerificationModal";
import ModalPopup from "components/Shared/ModalPopup";
import { Loader } from "components/Shared/Loader";
import { useSnackbar } from 'notistack';
import { SNACKBAR_SUCCESS } from "redux/slices/snackbar";
import CountryCodes from "../../utils/CountryCodes.json";
import { setInitialState } from "redux/CardValidation/CardValidationSlice";

const { RESPONSE } = ApiConstant;

const useStyles = makeStyles(theme => ({
    container: {
        // width: "100%",
        // height: "100vh",
        marginTop: "0.5rem",
        letterSpacing: 1,
        display: "flex",
        justifyContent: "center",
        '& .MuiInputBase-root': {
            '& input': {
                fontSize: 13
            }
        },
        '& .MuiSelect-select': {
            fontSize: 13
        },
        '& .css-ihdtdm': {
            width: 0
        }
    },
    savedCardInputContainer: {
        display: 'flex',
        marginTop: "1rem",
        [breakpoints.sm_down]: {
            flexDirection: "column"
        }
    },
    savedCardInput: {
        width: '100%',
        [breakpoints.sm_down]: {
            width: "93% !important"
        }
    },
    adressContainer: {
        marginLeft: "0.3rem !important",
        width: "100% !important"
    },
    saveAddressCheckbox: {
        marginLeft: "1.3rem !important",
        marginTop: "1rem !important"
    },
    title: {
        fontWeight: 600,
        margin: '0 !important',
        alignItems: 'center',
        justifyContent: 'space-between',
        // flexDirection: 'column',
        '& .sub-title': {
            fontSize: 11,
            fontWeight: 400,
            letterSpacing: 'normal',
            opacity: .7
        }
    },
    accordion: {
        backgroundColor: '#f2f2f2 !important',
        padding: 5,
        boxShadow: "none !important",
        margin: '20px 0'
        // '& .title': {
        //     fontWeight: 600,

        // }
    },
    radioButtonLabel: {
        fontSize: '12px !important',
        whiteSpace: 'nowrap',
    },
    checkboxLabel: {
        fontSize: '10px !important',
        whiteSpace: 'nowrap',
    },
    selectedCardStyles: {
        border: '1px solid #0000003b',
        borderRadius: 6,
        padding: 10,
        margin: '10px -10px',
        backgroundColor: '#FFF'
    },
    fieldLabel: {
        fontWeight: "600",
        fontSize: 12,
        color: "#2f4f4f",
        whiteSpace: 'nowrap'
    },
    applyCoupon: {
        fontWeight: "bold",
        fontSize: "13px",
        cursor: "pointer",
        color: "#4688fc",
        marginTop: 30
    },
    appliedCoupon: {
        fontWeight: "bold",
        fontSize: "13px",
        cursor: "pointer",
        color: "#70c44e",
        marginTop: 30
    },
    crossIcon: {
        fontWeight: "bold",
        fontSize: "13px",
        cursor: "pointer",
        // margin: "13px 0px 0px -541px",
        fontSize: "18px !important",
        color: "#1c6afc",
        "@media (max-width: 500px)": {
            margin: "12px 0 0 -227px",
        },
    },
    nextButton: {
        width: "auto",
        display: 'none !important',
        backgroundColor: "#1c6afc !important",
        marginTop: "39px !important",
        [breakpoints.sm_down]: {
            display: "block !important",
            width: "100vw",
            marginLeft: "1.5rem !important",
            marginBottom: "2rem !important"
        },
        [breakpoints.sm_up]: {
            display: "block !important",
            width: "30%",
            marginLeft: "80% !important",
            marginBottom: "2rem !important"
        },
        [breakpoints.md_up]: {
            display: 'none !important',
        }
    },
    orderSummary: {
        display: 'block !important',
        '@media only screen and (max-width: 900px)': {
            display: 'none !important'
        },
    },
    accordionContainer: {
        padding: '8px 24px 16px',
        width: "90%",
        '@media only screen and (max-width: 900px)': {
            padding: '0 !important',
            width: "100% !important"
        },
    },
    hideText: {
        "-webkit-text-security": "disc",
        "-moz-text-security": "disc",
        "-ms-text-security": "disc",
        "text-security": "disc"
    },
    selectDropdown: {
        width: '12rem',
        [breakpoints.sm_down]: {
            width: "100%",
            marginLeft: "1.7rem"
        }
    },
    ErrorMessage: {
        color: '#ff0000',
        fontSize: 12,
        marginBottom: 0
    },
    cardImage: {
        width: "32px",
        // height: "32px",
        marginRight: "1rem"
    }

}));

const addressFields = [
    {
        label: "Country*",
        name: "country",
    },
    {
        label: "State*",
        name: "state",
    },
    {
        label: "City*",
        name: "city",
    },
    {
        label: "Address*",
        name: "address",
    },
    {
        label: "Zip Code*",
        name: "zipCode",
    },
];

const contactFields = [
    {
        label: "Contact Method*",
        name: "contactType",
    },
    {
        label: "Country Code*",
        name: "countryCode",
    },
    {
        label: "Phone Number*",
        name: "phoneNumber"
    }
]

const contactTypes = [
    {
        label: 'Text me',
        name: 'Text me'
    },
    {
        label: 'Call me',
        name: 'Call me'
    }
]

const discountAndDonationFields = [
    {
        label: 'Discount Coupon',
        name: 'discountCode'
    },
    // We will use in future
    // {
    //     label: 'Donate to Hurricane Ian Victims',
    //     name: 'donateAmount'
    // }
]

const verificationSuccessModal = {
    title: "OTP Verification Successful",
    content: "Thank you for verifying your otp, Click ok to continue",
    buttonText: "Ok",
};

const Checkout = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const queryParams = new URLSearchParams(window.location.search);
    const priceId = queryParams.get("priceId");
    const count = queryParams.get("count");
    const { t } = useTranslation();

    // const [isSavedCard, setIsSavedCard] = useState(false);
    // const [isSavedAddress, setIsSavedAddress] = useState(false);
    const [addressId, setAddressId] = useState("");
    const [cardId, setCardId] = useState("");
    const [values, setValues] = useState({
        address: "",
        state: "",
        country: 2,
        city: "",
        zipCode: "",
        contactType: "Text me",
        countryCode: "+1",
        phoneNumber: "",
        discountCode: "",
        // donateAmount: "" // We will use in future,
        saveCardDetail: false,
        saveAddress: false
    });
    const [cityError, setCityError] = useState(false);
    const [ZipCodeErrorMsg, setZipCodeErrorMsg] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [disableButton, setDisableButton] = useState(true);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [tempCoupon, setTempCoupon] = useState(false);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [validCouponCode, setValidCouponCode] = useState("");
    const [offerId, setOfferId] = useState();
    // const [count, setCount] = useState(1);
    const [priceBreakup, setPriceBreakup] = useState({});
    const [showNextPage, setShowNextPage] = useState(false);
    const [showDiscountCode, setShowDiscountCode] = useState(false);
    const { countryRecords, stateRecords } = useSelector((state) => state.auth);
    const { couponData, couponCode, eventData, eventBookingDetails } = useSelector((state) => state.event);
    const { token, expiry, cardsList, cardError } = useSelector((state) => state.cardInfo);
    const {cartItems} = useSelector((state) => state.cart);
    const { addressList,userDetails } = useSelector((state) => state.profile);
    const [loading, setLoading] = useState(false);

    const [cardVerification, setCardVerification] = useState(false);
    const [openVerficationModal, setOpenVerficationModal] = useState(false);
    const [isCurrentAddressDefault, setCurrentAddressAsDefault] = useState(true);
    const [isCurrentCardDefault, setCurrentCardAsDefault] = useState(true);

    useEffect(() => {
        if (eventBookingDetails?.isEventBookingDone) {
            dispatch({
                type:"getCartItemsAction"
            })
            // dispatch({
            //     type: "removeFromCartAction",
            //     data: {
            //         eventId:cartItems[0]?.userCart[0]?.eventId,
            //         showMessage:'false'
            //     }
            // })
            // const selectedAddress = addressList?.find(add => (add.id).toString() === addressId);
            // dispatch({
            //     type: "updateAddressAction",
            //     payload: {
            //         reqPayload: { id: selectedAddress?.id, primary: isCurrentAddressDefault },
            //         onSuccessCB: (res) => {
            //             setAddressId(res?.id);
            //             dispatch({
            //                 type: "getAddressListAction",
            //                 data: {
            //                     userId: params.eventId,
            //                 },
            //             });
            //         },
            //         onErrorCB: (err) => { }
            //     },
            // });
            // const selectedCard = cardsList?.find(card => (card.id).toString() === cardId);
            // dispatch({
            //     type: "updateCardTokenAction",
            //     payload: {
            //         reqPayload: { id: selectedCard?.id, primary: isCurrentCardDefault },
            //         onSuccessCB: (res) => {
            //             setCardVerification(false);
            //             setCardId(res?.id);
            //             dispatch({
            //                 type: "getCardsListAction"
            //             });
            //         },
            //         onErrorCB: (err) => {
            //             setCardVerification(false);
            //         }
            //     },
            // });
        }
    }, [eventBookingDetails?.isEventBookingDone]);

    const handleDefaultCardChange = (cardId, value) => {
        dispatch({
            type: "updateCardTokenAction",
            payload: {
                reqPayload: { id: cardId, primary: value },
                onSuccessCB: (res) => {
                    setCardVerification(false);
                    setCardId(res?.id);
                    dispatch({
                        type: "getCardsListAction"
                    });
                },
                onErrorCB: (err) => {
                    setCardVerification(false);
                }
            },
        });
    }

    const handleDefaultAddressChange = (addressId, value) => {
        dispatch({
            type: "updateAddressAction",
            payload: {
                reqPayload: { id: addressId, primary: value },
                onSuccessCB: (res) => {
                    setAddressId(res?.id);
                    dispatch({
                        type: "getAddressListAction",
                        data: {
                            userId: params.eventId,
                        },
                    });
                },
                onErrorCB: (err) => { }
            },
        });
    }

    const ErrorSnackbar = ({ message }) => {
        const { enqueueSnackbar } = useSnackbar();

        React.useEffect(() => {
            enqueueSnackbar(message, {
                variant: 'error',
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });
        }, [enqueueSnackbar, message]);

        return null;
    }


    useEffect(() => {
        if (cardsList?.length > 0) {
            // setIsSavedCard(true);
            if (!cardId)
                setCardId(cardsList?.find(card => card.primary)?.id || cardsList[0]?.id);
        } else {
            // setIsSavedCard(false);
            setCardId("");
        }
    }, [cardsList])

    useEffect(() => {
        if (addressList?.length > 0) {
            // setIsSavedAddress(true);
            setAddressId(addressList?.find(add => add.primary)?.id || addressList[0]?.id);
        } else {
            // setIsSavedAddress(false);
            setAddressId("");
        }
    }, [addressList]);

    useEffect(() => {
        if (params.eventId || addressId || validCouponCode) {
            getAmountDetails(addressId, params.eventId, validCouponCode);
        }
        else {
            setPriceBreakup(prevState => ({ ...prevState, taxPrice: 0 }));
        }
    }, [params.eventId, addressId, validCouponCode]);

    useEffect(()=>{
        dispatch({
            type:setInitialState.type
        })
    },[window.location.href]);

    useEffect(() => {
        dispatch({
            type: "setCountryDataAction",
        });
        dispatch({
            type: "getEventAction",
            data: {
                eventId: params.eventId,
            },
        });
        dispatch({
            type: "getAddressListAction",
            data: {
                userId: params.eventId,
            },
        });
        dispatch({
            type: "getCardsListAction"
        });
        dispatch({
            type:"getUserProfileAction"
          });
    }, []);

    useEffect(()=>{
        if(userDetails){
            setValues({...values,'phoneNumber':userDetails?.phoneNumber,'countryCode':userDetails?.countryCode});
        }
    },[userDetails]);

    const getAmountDetails = (addressId, eventId, couponCode) => {
        if (addressId, eventId) {
            dispatch({
                type: "getAmountDetailsAction",
                payload: {
                    addressId,
                    eventId,
                    quantity: count,
                    couponCode: couponCode,
                    onSuccessCB: (res) => {
                        if (res?.data?.priceList) {
                            const priceObj = res?.data.priceList?.find(obj => obj.priceId == priceId);
                            priceObj["ticketingFee"] = res?.data?.ticketFee;
                            priceObj["paymentProcessingFee"] = res?.data?.paymentProcessingFee; 
                            setPriceBreakup(priceObj);
                        }
                    },
                    onErrorCB: (err) => { }
                },
            });
        }
    }

    const handleVerifyAndSave = () => {
        setCardVerification(true);
        dispatch({
            type: "saveCardTokenAction",
            payload: {
                reqPayload: { token, expiry, primary: isCurrentCardDefault },
                onSuccessCB: (res) => {
                    setCardVerification(false);
                    setCardId(res?.id);
                    dispatch({
                        type: "getCardsListAction"
                    });
                },
                onErrorCB: (err) => {
                    setCardVerification(false);
                }
            },
        });
    }

    const handleSaveAddress = () => {
        dispatch({
            type: "saveAddressAction",
            payload: {
                reqPayload: { country: values.country, stateId: values.state, city: values.city, address: values.address, zipCode: values.zipCode, primary: isCurrentAddressDefault },
                onSuccessCB: (res) => {
                    setAddressId(res?.id);
                    dispatch({
                        type: "getAddressListAction",
                        data: {
                            userId: params.eventId,
                        },
                    });
                    // getAmountDetails(res?.id, params.eventId);
                },
                onErrorCB: (err) => { }
            },
        });
    }

    // useEffect(() => {
    //     if (priceId && eventData) {
    //         const priceObj = eventData.priceList?.find(obj => obj.priceId == priceId);
    //         // console.log('priceObj ', priceObj);
    //         setPriceBreakup({ ...priceObj, taxPrice: 0 });
    //     }
    // }, [eventData]);

    useEffect(() => {
        if (countryRecords.length) {
            setCountryList(countryRecords);
            const states = countryRecords.find(country => country.name === 2)?.states;
            setStateList(states);
        }
    }, [countryRecords, stateRecords]);

    useEffect(() => {
        if (couponData?.isCouponApplied) {
            setTempCoupon(true);
            setDiscountAmount(couponData.dicountPrice);
            setOfferId(couponData.offerId);
            setValidCouponCode(couponData.code);
        }
    }, [couponData]);

    useEffect(() => {
        if (values.contactType && values.countryCode && (values.phoneNumber && !phoneError)) {
            dispatch(SNACKBAR_SUCCESS(t("Contact Details Saved Successfully")));
        }
    }, [values.contactType, values.countryCode, values.phoneNumber, phoneError]);

    useEffect(() => {
        if (cardId) {
            dispatch(SNACKBAR_SUCCESS(t("Credit Card Information Saved Successfully")))
        }
    }, [cardId]);

    useEffect(() => {
        if (addressId) {
            dispatch(SNACKBAR_SUCCESS(t("Billing Information Saved Successfully")))
        }
    }, [addressId]);

    const cancelCoupon = () => {
        setDiscountAmount(0);
        setTempCoupon(false);
        setOfferId();
        dispatch({
            type: setCouponCode.type,
            payload: ""
        });
        dispatch({
            type: setRemoveCoupon.type
        });
        setValidCouponCode("");
        setValues({ ...values, 'discountCode': '' });
    }
    const applyCoupon = () => {
        dispatch({
            type: "getVerifyCouponCodeAction",
            data: {
                eventId: params.eventId,
                couponCode: values.discountCode,
                priceId: priceId,
            },
        });
    };

    const handleChangeCard = (event) => {
        setCardId(event.target.value);
    };
    const handleChangeAddress = (event) => {
        if (event.target.value) {
            setAddressId(event.target.value);
        }
    }
    const isChecked = (e) => {
        setValues({ ...values, [e.target.name]: e.target.checked });
    };
    const handleChange = (prop) => (event) => {
        const { value, name } = event.target;
        const regex = /^[0-9]*\.?[0-9]{0,2}$/;
        const validate = /^[1-9][0-9]{6,14}$/;
        if (name === 'donateAmount' && !regex.test(value)) {
            return null;
        }
        if (name === 'zipCode' && value?.length > 10) {
            return null;
        }
        if (name === 'phoneNumber') {
            if (value?.length > 14 || value?.startsWith('0')) return null;
            if (validate.test(value)) {
                setPhoneError(false);
            } else {
                setPhoneError(true);
            }
        }
        // if(name === 'countryCode'){
        //     if (value?.length > 3 ||value?.startsWith('0')) return null;
        // }
        if (name === 'city') {
            if (value.length < 3) {
                setCityError(true);
            } else {
                setCityError(false);
            }
        }
        if (name === 'zipCode') {
            // if (value?.startsWith('0')) return null;
            if (value.length < 5 || parseInt(value, 10) < 1000) {
                setZipCodeErrorMsg(true);
            } else {
                setZipCodeErrorMsg(false);
            }
        }
        setValues({ ...values, [prop]: value });
        if (name === "country") {
            setValues(prevState => ({ ...prevState, state: '' }));
            const states = countryRecords.find(country => country.name === value)?.states;
            setStateList(states);
        }
        if ("state" === name && values.address && values.ticketCode) {
            setDisableButton(false);
        }
    };

    const handleCheckout = (event, reason, cancel) => {
        if (reason && reason == "backdropClick")
            return;
        if (cancel) {
            setOpenVerficationModal(false);
        }
        else {
            setLoading(true);
            setOpenVerficationModal(false);
            const data = {
                eventId: params.eventId*1,
                couponCode: validCouponCode,
                bookingType: priceBreakup.bookingType,
                // donation: values.donateAmount,
                eventPriceId: priceId*1,
                offerId: offerId?offerId*1:0,
                quantity: count*1,
                addressId,
                cartItem:true,
                userCardTokensId: cardId*1,
                contactMethod:values.contactType === 'Call me'?'CALL':'TEXT_MESSAGE',
                countryCode:values.countryCode,
                phoneNumber:values.phoneNumber,
            }
            // if (res.status === RESPONSE.SUCCESS) {
            // setCardVerify(true)
            dispatch({
                type: "eventBookingAction",
                payload: {
                    data,
                    onSuccessCB: (res) => {
                        setLoading(false);
                        cancelCoupon();
                    },
                    onErrorCB: () => {
                        setLoading(false);
                    }
                },
            });
        }
        // }
    };
    const handleNextPage = () => {
        setShowNextPage(!showNextPage);
    }

    const handleClickShowDiscountCode = () => {
        setShowDiscountCode(!showDiscountCode)
    }

    const handleCloseSuccessModal = () => {
        dispatch({
            type: "removeEventBookingDetails",
            payload: {}
        })
    }
    const removeErrorCheck = ()=>{
        dispatch({
            type:setInitialState.type
        })
    }
    return (
        <>
            <Loader isOpen={loading} />
            {cardError && cardError.length > 0 && cardError.map((error) => {
                return <ErrorSnackbar message={error} />
            })}
            {cardVerification && <Loader isOpen={cardVerification} content="Verifying" />}
            <div className={`${classes.container}`}>
                <Grid container spacing={3} className={classes.accordionContainer}>
                    {!showNextPage && <Grid item lg={8} md={8} sm={12} xs={12} >
                        <Accordion className={classes.accordion} defaultExpanded sx={{ marginTop: 0 }}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                classes={{ content: classes.title }}
                            >
                                <div className="d-flex flex-column">
                                    <h3 className="m-0">{t("How Do We Contact You?")}*</h3>
                                    <span className="sub-title">{t("We'll only contact you if we have important news about concert....promise!")}</span>
                                </div>
                                {(values.contactType && values.countryCode && (values.phoneNumber && !phoneError)) && <CheckCircle fontSize="small" sx={{ color: "#10BA10", marginRight: '10px' }} />}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    {contactFields.map((el, index) => {
                                        return (
                                            <Grid item xs={6} sm={el.name === 'countryCode' ? 2 : 5} md={el.name === 'countryCode' ? 2 : 4} key={index}>
                                                <label className={`${classes.fieldLabel}`}>
                                                    {t(el.label)}
                                                </label>
                                              
                                                <TextField
                                                    name={el.name}
                                                    fullWidth
                                                    onChange={handleChange(el.name)}
                                                    sx={{ "& .MuiInputBase-root": { height: 35, border: "1px solid #DBE2EA" }, "& input": { height: 0 } }}
                                                    value={values[el.name]}
                                                    select={["contactType", "countryCode"].includes(el.name) ? true : false}
                                                    SelectProps={{ MenuProps: { disableScrollLock: true }, renderValue: (val) => val }}
                                                    // type={el.name === "phoneNumber" ? validate() : "text"}
                                                    InputProps={{
                                                        // startAdornment: el.name ==="countryCode"&&<InputAdornment position="start">+</InputAdornment>,
                                                        min: (el.name === "phoneNumber") && 0,
                                                        onKeyPress: ["phoneNumber"].includes(el.name) ? (event) => {
                                                            const regex = new RegExp("^[0-9]+$");
                                                            const key = String.fromCharCode(event.charCode);
                                                            if (!regex.test(key)) {
                                                                event.preventDefault();
                                                            }
                                                        } : null
                                                    }}
                                                >
                                                    {el.name === "contactType" &&
                                                        contactTypes.map((option) => (
                                                            <MenuItem sx={{ fontSize: "0.8rem" }} key={option.name} value={option.name}>
                                                                {t(option.label)}
                                                            </MenuItem>
                                                        ))}
                                                    {el.name === "countryCode" &&
                                                        CountryCodes.map((option) => (
                                                            <MenuItem sx={{ fontSize: "0.8rem" }} key={option.name} value={option.dial_code}>
                                                                {option.name} {option.dial_code}
                                                            </MenuItem>
                                                        ))}
                                                </TextField>
                                                {el.name === 'phoneNumber' && phoneError && (
                                                    <p className={classes.ErrorMessage}>{t(`Please enter valid phone number`)}</p>
                                                )}
                                                <br />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordion}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                classes={{ content: classes.title }}
                            >
                                <h3 className="m-0">{t("Apply Discount Coupon")}</h3>
                                {validCouponCode && (<CheckCircle fontSize="small" sx={{ color: "#10BA10", marginRight: '10px' }} />)}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    {discountAndDonationFields.map((el, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <Grid item xs={6} sm={5} md={4} key={index}>
                                                    <label className={`${classes.fieldLabel}`}>{t(el.label)}</label>
                                                    <TextField
                                                        name={el.name}
                                                        fullWidth
                                                        onChange={handleChange(el.name)}
                                                        className={el.name === "discountCode" && (showDiscountCode ? "" : classes.hideText)}
                                                        sx={{ "& .MuiInputBase-root": { height: 35, border: "1px solid #DBE2EA", paddingRight: 0 }, "& input": { height: 0 } }}
                                                        value={values[el.name] || validCouponCode}
                                                        type={el.name === "donateAmount" ? "number" : "text"}
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">
                                                                {(['discountCode'].includes(el.name)) &&
                                                                    <IconButton
                                                                        size='small'
                                                                        style={{ paddingRight: 4 }}
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowDiscountCode}
                                                                    >
                                                                        {!showDiscountCode ? (
                                                                            <VisibilityOffOutlined fontSize="small" />
                                                                        ) : (
                                                                            <VisibilityOutlined fontSize="small" />
                                                                        )}
                                                                    </IconButton>
                                                                }
                                                                {(el.name === "discountCode" && tempCoupon && validCouponCode) &&
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                    >
                                                                        <Cancel className={classes.crossIcon} onClick={cancelCoupon} />
                                                                    </IconButton>
                                                                }
                                                            </InputAdornment>,
                                                            maxLength: 9
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item md={2} sx={{ zIndex: 1 }}>
                                                    {el.name === "discountCode" && !tempCoupon && (
                                                        <label className={classes.applyCoupon} onClick={applyCoupon}>
                                                            {t('APPLY')}
                                                        </label>
                                                    )}
                                                    {(el.name === "discountCode" && tempCoupon && validCouponCode) && (
                                                        <>
                                                            <label
                                                                className={classes.appliedCoupon}
                                                                onClick={applyCoupon}
                                                            >
                                                                {t('APPLIED')}
                                                            </label>
                                                        </>

                                                    )}
                                                </Grid>
                                            </React.Fragment>
                                        );
                                    })}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordion} defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                classes={{ content: classes.title }}
                            >
                                <h3 className="m-0">{t("Credit Card Information")}*</h3>
                                {cardId && <CheckCircle fontSize="small" sx={{ color: "#10BA10", marginRight: '10px' }} />}
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="NEW"
                                        name="radio-buttons-group"
                                        value={cardId || 'NEW'}
                                        onChange={(e, val) => {
                                            // setIsSavedCard(!isSavedCard);
                                            if (val === "NEW") {
                                                setCardId("");
                                            }
                                            else {
                                                if (val) setCardId(val);
                                            }
                                        }}
                                    >
                                        <Grid className="mb-5">
                                            {
                                                cardsList.length === 0 ? <p>No Card Saved</p> :
                                                    cardsList.map((card, i) => {
                                                        const isSelectedCard = cardId?.toString() === (card?.id).toString();
                                                        return (
                                                            <Grid key={i} className={`flex items-center ${isSelectedCard ? classes.selectedCardStyles : ""}`}>
                                                                <FormControlLabel name={`saved-card-${i}`} onClick={removeErrorCheck} style={{ marginRight: 0, alignItems: isSelectedCard && "self-start" }} classes={{ label: classes.radioButtonLabel }} value={card.id} control={<Radio size="small" />} label={
                                                                    <div>
                                                                        <div className="flex items-center ml-3">
                                                                            <img className={classes.cardImage} src={(card?.cardType && card?.cardType?.toLowerCase() !== 'unknown') ? require(`assets/card-logos/${(card?.cardType).toLowerCase()}.jpg`) :  require(`assets/icons/credit-card.png`)} />
                                                                            <p className="m-0 p-0">{`${card.cardNumber || card.lastFourDigits || 'XXXX'}`}<span className="fw-bold">{`${card.primary ? ' (Default)' : ''}`}</span></p>
                                                                        </div>
                                                                        {isSelectedCard && <FormControlLabel
                                                                            sx={{ marginLeft: '.2rem' }}
                                                                            label={t("Set as Default Card")}
                                                                            classes={{ label: classes.checkboxLabel }}
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={card.primary}
                                                                                    onChange={(event) => handleDefaultCardChange(card.id, event.target.checked)}
                                                                                    size="small"
                                                                                />
                                                                            }
                                                                        />}
                                                                    </div>
                                                                } />

                                                            </Grid>
                                                        );
                                                    })
                                            }
                                        </Grid>

                                        {/* <div className={classes.savedCardInputContainer}>
                                            <FormControlLabel classes={{ label: classes.radioButtonLabel }} value="SAVED" control={<Radio size="small" />} label={t("Saved Credit Card")} />
                                            <FormControl className={`${classes.savedCardInput}`}>
                                                <TextField
                                                    select
                                                    className={classes.selectDropdown}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={isSavedCard ? cardId : ''}
                                                    SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                                    onChange={handleChangeCard}
                                                    size="small"
                                                    autoWidth
                                                    sx={{ fontSize: '12px' }}
                                                    disabled={!isSavedCard}
                                                    placeholder="Select Card"
                                                > */}
                                        {/* <MenuItem sx={{ fontSize: '12px' }} value={10}>XXXX XXXX XXXX 2345</MenuItem>
                                                    <MenuItem sx={{ fontSize: '12px' }} value={11}>XXXX XXXX XXXX 3598</MenuItem>
                                                    <MenuItem sx={{ fontSize: '12px' }} value={12}>XXXX XXXX XXXX 7895</MenuItem> */}
                                        {/* {cardsList.length === 0 ?
                                                        <MenuItem sx={{ fontSize: '12px' }} value="">No Saved Cards</MenuItem> :
                                                        cardsList?.map((card, index) => <MenuItem key={index} sx={{ fontSize: '12px' }} value={card.id}>{`${card.lastFourDigits || 'XXXX'} - ${card.expiry} ${card.primary ? '(Default)' : ''}`}</MenuItem>)}
                                                </TextField>
                                            </FormControl>
                                        </div> */}
                                        <FormControlLabel classes={{ label: classes.radioButtonLabel }} value="NEW" control={<Radio size="small" />} label={t("Add New Credit Card")} />
                                    </RadioGroup>
                                    {
                                        !cardId && <><iframe id="tokenframe" name="tokenframe"
                                            style={{ margin: "10px 25px" }}
                                            src={encodeURI("https://fts-uat.cardconnect.com/itoke/ajax-tokenizer.html?invalidexpiryevent=true&useexpiry=true&usecvv=true&invalidcreditcardevent=true&invalidcvvevent=true&tokenizewheninactive=true&inactivityto=2000&css=body{margin:0px}.error{color:%20red;}input{width:200px;height:30px;margin:5px 0 20px;background-color:transparent;border:1px solid rgb(0 0 0 / 33%);border-radius:4px;}select{width:88px;height:30px;margin:5px 0 20px;background-color:transparent;border:1px solid rgb(0 0 0 / 33%);border-radius:4px;}label{font-size:12px;color:darkslategray;font-weight:600}")}
                                            frameBorder="0" scrolling="no" width="100%" height="240"></iframe>
                                            {/* <FormControlLabel
                                                style={{ marginLeft: '1rem' }}
                                                label={"Save This Card Details"}
                                                name="saveCardDetail"
                                                control={<Checkbox size="small" />}
                                                checked={values.saveCardDetail}
                                                onChange={isChecked}
                                            /> */}
                                            <div className="d-flex justify-content-center" style={{ width: 200, margin: "-10px 25px 0" }}>
                                                <Button
                                                    disabled={!(token && expiry)}
                                                    onClick={() => handleVerifyAndSave()}
                                                    color="primary"
                                                    variant="contained"
                                                    size="small"
                                                >
                                                    {t("Verify & Save")}
                                                </Button>
                                            </div>
                                            <div className="mt-3">
                                                <FormControlLabel
                                                    label={t("Set as Default Card")}
                                                    classes={{ label: classes.radioButtonLabel }}
                                                    control={
                                                        <Checkbox
                                                            checked={isCurrentCardDefault}
                                                            onChange={(event) => setCurrentCardAsDefault(event.target.checked)}
                                                            size="small"
                                                        />
                                                    }
                                                />
                                            </div>
                                        </>
                                    }
                                </FormControl>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordion}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                classes={{ content: classes.title }}
                                className="d-flex"
                            >
                                <div>
                                    <h3 className="m-0">{t("Billing Information")}*</h3>
                                    <span className="sub-title">{t("Tax amount will be calculated based on state...!")}</span>
                                </div>
                                {addressId && <CheckCircle fontSize="small" sx={{ color: "#10BA10", marginRight: '10px' }} />}
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="address-radio-buttons-group-label"
                                        defaultValue="NEW"
                                        name="radio-buttons-group"
                                        value={addressId || 'NEW'}
                                        onChange={(e, val) => {
                                            // setIsSavedAddress(!isSavedAddress);
                                            if (val === "NEW")
                                                setAddressId("");
                                            else {
                                                if (val) setAddressId(val);
                                            }
                                        }}
                                    >
                                        <Grid className="mb-5">
                                            {
                                                addressList.length === 0 ? <p>No Address Saved</p> :
                                                    addressList.map((address) => {
                                                        const isSelectedAddress = addressId?.toString() === (address?.id).toString();
                                                        return (
                                                            <Grid className={`flex items-center my-1  ${isSelectedAddress ? classes.selectedCardStyles : ""}`}>
                                                                <FormControlLabel name="default-address" style={{ marginRight: 0 }} classes={{ label: classes.radioButtonLabel }} value={address.id} control={<Radio size="small" />} label={
                                                                    <div>
                                                                        <div className="flex items-center ml-3">
                                                                            {/* <img className={classes.cardImage} src={require('assets/icons/location.png')} /> */}
                                                                            <span>{`${address.address}, ${address.city}, ${address.state}, ${address.country}, ${address.zipCode}`}<span className="fw-bold">{`${address.primary ? ' (Default)' : ''}`}</span></span>
                                                                        </div>
                                                                        {isSelectedAddress && <FormControlLabel
                                                                            sx={{ marginLeft: '.2rem' }}
                                                                            label={t("Set as Default Address")}
                                                                            classes={{ label: classes.checkboxLabel }}
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={address.primary}
                                                                                    onChange={(event) => handleDefaultAddressChange(address.id, event.target.checked)}
                                                                                    size="small"
                                                                                />
                                                                            }
                                                                        />}
                                                                    </div>
                                                                } />

                                                            </Grid>
                                                        );
                                                    })
                                            }
                                        </Grid>
                                        {/* <div className={classes.savedCardInputContainer}>
                                            <FormControlLabel classes={{ label: classes.radioButtonLabel }} value="SAVED" control={<Radio size="small" />} label={t("Saved Address")} />
                                            <FormControl className={`${classes.savedCardInput}`}>
                                                <TextField
                                                    select
                                                    className={classes.selectDropdown}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={isSavedAddress ? addressId : ''}
                                                    SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                                    onChange={handleChangeAddress}
                                                    size="small"
                                                    autoWidth
                                                    sx={{ fontSize: '12px' }}
                                                    disabled={!isSavedAddress}
                                                    placeholder="Select Card"
                                                >
                                                    {addressList.length === 0 ?
                                                        <MenuItem sx={{ fontSize: '12px' }} value="">No saved Address</MenuItem> :
                                                        addressList?.map((obj, index) => <MenuItem sx={{ fontSize: '12px' }} value={obj.id} key={index}>{`${obj.address} - ${obj.state} ${obj.primary ? '(Default)' : ''}`}</MenuItem>)}
                                                </TextField>
                                            </FormControl>
                                        </div> */}
                                        <FormControlLabel classes={{ label: classes.radioButtonLabel }} value="NEW" control={<Radio size="small" />} label={t("Add New Address")} />
                                    </RadioGroup>
                                    {
                                        !addressId &&
                                        <>
                                            <Grid container className={classes.adressContainer} spacing={3}>
                                                {addressFields.map((el, index) => {
                                                    return (
                                                        <Grid item xs={12} sm={6} md={4} key={index}>
                                                            <label className={`${classes.fieldLabel}`}>
                                                                {t(el.label)}
                                                            </label>
                                                            <TextField
                                                                name={el.name}
                                                                fullWidth
                                                                onChange={handleChange(el.name)}
                                                                // className="mb-2 mt-0"
                                                                sx={{ "& .MuiInputBase-root": { height: 35, border: "1px solid #DBE2EA" }, "& input": { height: 0 } }}
                                                                SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                                                value={values[el.name]}
                                                                select={["country", "state"].includes(el.name) && countryList?.length > 0 ? (el.name === 'state' ? (values.country === 2) : true) : false}

                                                                // type={el.name === "zipCode" ? "number" : "text"}
                                                                inputProps={{
                                                                    min: (el.name === "zipCode") && 0,
                                                                    onKeyPress: el.name === "city" ? (event) => {
                                                                        const regex = new RegExp("^[A-Za-z ]+$");
                                                                        const key = String.fromCharCode(event.charCode);
                                                                        if (!regex.test(key) || (event.target.value.trim() === "" && key === " ")) {
                                                                          event.preventDefault();
                                                                        }
                                                                      }
                                                                        : (el.name === "zipCode") ? (event) => {
                                                                            const key = event.key;
                                                                            const regex = new RegExp("^[0-9]$");
                                                                            const keystr = String.fromCharCode(event.charCode);
                                                                            if (!regex.test(keystr)) {
                                                                                event.preventDefault();
                                                                            }
                                                                            if (key === "e" || key === "E" || key === "-") {
                                                                                event.preventDefault();
                                                                            }
                                                                        }
                                                                            :
                                                                            undefined
                                                                }}
                                                            >
                                                                {el.name === "country" &&
                                                                    countryList.map((option) => (
                                                                        <MenuItem key={option.name} value={option.name}>
                                                                            {option.value}
                                                                        </MenuItem>
                                                                    ))}
                                                                {el.name === "state" &&
                                                                    stateList.map((option) => (
                                                                        <MenuItem key={option.name} value={option.name}>
                                                                            {option.value}
                                                                        </MenuItem>
                                                                    ))}
                                                            </TextField>
                                                            {el.name === 'city' && cityError && (
                                                                <p className={classes.ErrorMessage}>{t(`Please enter atleast 3 characters`)}</p>
                                                            )}
                                                            {el.name === 'zipCode' && ZipCodeErrorMsg && (
                                                                <p className={classes.ErrorMessage}>{t(`Please enter atleast 5 digits`)}</p>
                                                            )}
                                                            <br />
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                            <div className="d-flex justify-content-end">
                                                <Button
                                                    disabled={(!(values.country && values.state && values.city && values.address && values.zipCode && !cityError && !ZipCodeErrorMsg) && !addressId)}
                                                    onClick={handleSaveAddress}
                                                    color="primary"
                                                    className={`${classes.primaryButton}`}
                                                    variant="contained"
                                                >
                                                    {t("Save & Submit")}
                                                </Button>
                                            </div>
                                            <div>
                                                <FormControlLabel
                                                    label={t("Set as Default Address")}
                                                    classes={{ label: classes.radioButtonLabel }}
                                                    control={
                                                        <Checkbox
                                                            checked={isCurrentAddressDefault}
                                                            onChange={(event) => setCurrentAddressAsDefault(event.target.checked)}
                                                            size="small"
                                                        />
                                                    }
                                                />
                                            </div>
                                        </>
                                    }

                                </FormControl>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>}
                    <Grid item style={{ display: `${showNextPage && 'grid'}`, placeItems: `${showNextPage && 'center'}` }} sm={showNextPage && 12} xs={showNextPage && 12} lg={4} md={4} className={!showNextPage && classes.orderSummary}>
                        <>
                            <CheckoutSummary
                                eventData={eventData}
                                values={values}
                                orderSummary={priceBreakup}
                                count={count}
                                discountAmount={discountAmount}
                                handleCheckout={() => setOpenVerficationModal(true)}
                                hasValidDetails={cardId && addressId && (values.contactType && values.countryCode && (values.phoneNumber && !phoneError))}
                            />
                        </>
                    </Grid>
                    <Button variant="contained" className={classes.nextButton} onClick={handleNextPage}>{showNextPage ? t("Previous") : t("Next")}</Button>
                </Grid>
            </div>
            {openVerficationModal && <CheckoutVerificationModal
                handleClose={handleCheckout}
                open={openVerficationModal}
                classes={classes.verifyButton}
                modalData={verificationSuccessModal}
                contactData ={{contactMethod:values.contactType === 'Call me'?'CALL':'TEXT_MESSAGE',countryCode:values.countryCode}}
                t={t}
            />}
            {eventBookingDetails?.isEventBookingDone &&
             <ModalPopup isOpen={true} 
             modalData={{ title: `${t("Order ID")} : ${eventBookingDetails.orderId}`, 
                          content: `${t("Event is booked, confirmation sent to")} ${eventBookingDetails.message?.split(' ').pop()}`, 
                          buttons:[{text:'Okay',
                                    style:'mb-1 pt-3 pb-3 right-gradient text-white flex items-center',
                                  }]}}
                          url={`/my-tickets/purchased-ticket`} 
                          onClose={() => handleCloseSuccessModal()} />}
        </>
    );
};

export default Checkout;
