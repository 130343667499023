import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { getLocalData, isAdmin } from "utils";
import LoginForm from "views/Login/components/LoginForm";
import StatusConstant from "../../constant/StatusConstant";
import SwitchLanguage from "components/Shared/SwitchLanguage";
import { Box, Grid } from "@mui/material";
import Carousels from "components/Shared/Carousels";
import constant from "../../constant/";

const { IMAGES_ARRAY } = constant;
const { STATUS } = StatusConstant;

const useStyles = makeStyles({
  loginBanner: {
    position: "absolute",
    top: 0,
    objectFit: "cover",
    width: "70%",
    height: "100%",
    '@media(max-width:600px)':{
      display:"none"
    },
    // '@media screen (max-width:1468px)':{
    //   top:"-10%",
    //   width:"70%",
    //   height:"110vh"
    // }, 
    // '@media screen and (max-width: 1474px) and (min-width: 1341px)':{
    //   top:"-10%",
    //   width:"70%",
    //   height:"110vh"
    // },
    // small laptops
    '@media screen and (max-width: 1339px) and (min-width: 993px)':{
      top:"-10%",
      width:"65%",
      height:"110vh"
    },
      //very small laptop
      '@media screen and (max-width: 1190px) and (min-width: 994px)':{
        width:"60%",
      },
    // Tab
    '@media screen and (max-width: 992px) and (min-width: 700px) ':{
      top:"-10%",
      width:"55%",
      height:"110vh"
    },
    '@media screen and (max-width: 760px) and (min-width: 600px) ':{
      display:"none"
    }
  },
  card: {
    position: "absolute",
    right: "1%",
    top: "10%",
    height: "300px",
    backgroundColor: "white",
    padding: "1rem",
    // maxWidth: "500px",
    width: "430px",
    '@media(max-width:600px)':{
      width:"100%",
      right:"0",
      padding:0
    },
    '@media screen (max-width:1468px)':{
      width:"490px !important",
      padding:"0",
      right:0
    },
    '@media screen and (max-width: 1474px) and (min-width: 1341px)':{
      width:"390px !important",
      padding:"0",
      right:0
    },
      // small laptops
      '@media screen and (max-width: 1339px) and (min-width: 993px)':{
        width:"420px !important",
        padding:"0",
        right:0
      },
      //very small laptop
      '@media screen and (max-width: 1190px) and (min-width: 994px)':{
        width:"400px !important",
      },
      // Tabs
    '@media screen and (max-width: 992px) and (min-width: 760px) ':{
      width:"340px",
      right:"0",
      padding:0
    },
    '@media screen and (max-width: 760px) and (min-width: 600px) ':{
      width:"75%",
      right:"4rem",
      padding:0
    }
  },
  header:{
    position: "absolute",
    top: "1rem",
    zIndex: 1,
    backgroundColor: "white",
    width: "425px !important",
    right: "0%",
    display: "flex",
    alignItems: "center",
    '@media(max-width:600px)':{
      width: "100% !important",
    },
    '@media screen and (max-width: 1474px) and (min-width: 1341px)':{
      width:"380px !important",
      right:"1%",
    },
    // small laptops
    '@media screen and (max-width: 1339px) and (min-width: 993px)':{
      width:"400px !important",
      right:"1%",
    },
    //very small laptop
    '@media screen and (max-width: 1190px) and (min-width: 994px)':{
      width:"390px !important",
    },
    // Tabs
    '@media screen and (max-width: 992px) and (min-width: 700px)':{
      width:"340px !important"
    },
    '@media screen and (max-width: 760px) and (min-width: 600px) ':{
      width: "100% !important"
    }
  },
  headerButton: {
    '@media(max-width: 760px)' :{
      display: "flex",
      justifyContent: "flex-end",
    }
  },
  corosalImg:{
    height:'40rem !important',
    width:'40rem !important'
  }
});

const StyledBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  "& .MuiSvgIcon-root": {
    fontSize: "1.2rem",
    marginTop: "6px",
  },
});

function Login() {
  const classes = useStyles();
  const location = useLocation();
  const { isAuthenticated, userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const formContent = [
    {
      label: "Email*",
      name: "emailId",
    },
    {
      label: "Password*",
      name: "password",
    },
  ];
  const searchParams = new URLSearchParams(location.search);
  const ticketParam = searchParams.get('tkt');
  const intendedPath = searchParams.get('redirect');
  

  useEffect(() => {
    // console.log('login ', location);
    if (getLocalData("accessToken")) {
      if (!isAdmin()) {
        if (userInfo.eventBooked) {
          navigate(ticketParam? `/?tkt=${ticketParam}` : intendedPath ? `${intendedPath}` : '/');
        }
        else {
          switch (userInfo.verificatonStatus) {
            case STATUS.EMAIL_NOT_VERIFIED:
              navigate("/email-verification");
              break;
            case STATUS.EMAIL_VERIFIED:
            case STATUS.CARD_VERIFIED:
              navigate(ticketParam? `/?tkt=${ticketParam}` : (intendedPath || '/'));
              break;
              // navigate(`/`);
              // navigate(`/events/${userInfo.eventId}/details`);
              // break;
            default:
              navigate("/login");
          }
        }
      }
      else if (isAdmin()) { //  && userInfo.eventId
        navigate(`/events/${1}/reports`);
      }
    }
    else {
      let navigatePath = "/login";

      if (ticketParam) {
        navigatePath += `?tkt=${ticketParam}`;
      } else if (intendedPath) {
        navigatePath += `?redirect=${encodeURIComponent(intendedPath)}`;
      } else if (ticketParam === null && intendedPath === null) {
        navigatePath = "/login";
      }
      navigate(navigatePath);
    }
  }, [userInfo]);

  return (
    <div>
      <img
        className={classes.loginBanner}
        src={require("assets/images/login-banner.jpg")}
        alt="login-banner"
      />
      <Carousels
        items={IMAGES_ARRAY}
        originalHeight={'20px'}
        originalWidth={20}
        showBullets={true}
        showNav={false}
        autoPlay={true}
        slideInterval={3000}
        showFullscreenButton={false}
        showPlayButton={false}
      />
      <Grid container className={classes.header}>
          <Grid item xs={8}>
            <Link to="/">
              <img src={require("assets/images/logo.png")} className={`ml-4`}/>
            </Link>
          </Grid>
          <Grid item xs={4} className={classes.headerButton}>
            <SwitchLanguage StyledBox={StyledBox} />
          </Grid>
        </Grid>
      <div className={classes.card}>
        <LoginForm
          formHeading="Sign in"
          formSubHeading="Don't have an account yet?"
          hasFormSubHeadingLink={true}
          formSubHeadingLinkOne="Sign up to buy tickets"
          formSubHeadingLinkTwo="Already have a Ticket ID?"
          formContent={formContent}
          buttonText="Sign In"
          hasFormFooterLink={true}
          FormFooterLink="Forgot password?"
        />
      </div>
    </div>
  );
}

export default Login;
